import { surveyPlugin } from 'survey-vue3-ui';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { surveyApiClientPlugin } from '../api';
import { SurveyServiceClient } from './survey-service-client';

export * from './survey-service-client';

export const surveyServiceClientPlugin =
  ProviderPluginFactory.create<SurveyServiceClient>({
    key: Symbol('SurveyServiceClient'),
    defaultFactory: {
      create: () =>
        new SurveyServiceClient(
          surveyApiClientPlugin.get(),
          storagePlugin.get(),
        ),
    },
  });

export const surveyServicePlugin = ProviderPluginFactory.create({
  key: Symbol('Survey'),
  defaultFactory: {
    create: (app) => {
      app.use(surveyPlugin);
    },
  },
});
