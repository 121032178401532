import { TourDropAddress } from '@/features/tours';
import { TourDrop } from '@/features/tour-drops';

export function getCustomerFullNameFromTourDrop(
  tourDrop: TourDrop | null | undefined,
): string | undefined {
  if (!tourDrop) {
    return undefined;
  }

  const firstName = (tourDrop.address as TourDropAddress)?.firstName;
  const lastName = (tourDrop.address as TourDropAddress)?.lastName;

  return firstName !== undefined && lastName !== undefined
    ? `${firstName} ${lastName}`
    : undefined;
}
