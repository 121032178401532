import { watch } from 'vue';
import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import router from '@/features/core/router';
import { loggerServicePlugin } from '@/features/core/logger';

export class WakeLockService {
  private wakeLock: WakeLockSentinel | null = null;

  constructor() {
    this.wakeLock = null;
    watch(router.get().currentRoute, () => {
      if (router.get().currentRoute.value?.meta?.activatedScreenWakeLock) {
        void this.enableWakeLock();
      } else {
        this.releaseWakeLock();
      }
    });
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange.bind(this),
    );
  }

  public removeListener(): void {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange.bind(this),
    );
  }

  private handleLockRelease(): void {
    if (router.get().currentRoute.value?.meta?.activatedScreenWakeLock) {
      void this.enableWakeLock();
    }
  }

  private handleVisibilityChange(): void {
    if (
      this.wakeLock !== null &&
      document.visibilityState === 'visible' &&
      router.get().currentRoute.value?.meta?.activatedScreenWakeLock
    ) {
      void this.enableWakeLock();
    }
  }

  private async enableWakeLock(): Promise<void> {
    if ('wakeLock' in navigator) {
      try {
        this.wakeLock = await navigator.wakeLock.request('screen');
        this.wakeLock.addEventListener(
          'release',
          this.handleLockRelease.bind(this),
        );
      } catch (e) {
        loggerServicePlugin.get().error(e as Error);
      }
    } else {
      loggerServicePlugin
        .get()
        .error(
          'Screen Wake Lock API not supported. Screen Wake Lock API is only available when served over HTTPS.',
        );
    }
  }

  private releaseWakeLock(): void {
    if (this.wakeLock !== null) {
      void this.wakeLock.release().then(() => {
        this.wakeLock = null;
      });
    }
  }
}

export const wakeLockServicePlugin = ProviderPluginFactory.create({
  key: Symbol('WakeLockService'),
  defaultFactory: {
    create: () => new WakeLockService(),
  },
});

export const wakeLockPlugin = MultiPluginFactory.with({
  wakeLockServicePlugin,
});
