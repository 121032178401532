<template>
  <svg
    width="24"
    viewBox="0 0 30 30"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3799 7.20405C14.664 6.48809 14.664 5.32729 15.3799 4.61133L17.6128 6.84425C17.6204 6.85132 17.6279 6.85855 17.6353 6.86592L21.913 11.1437C22.271 11.5017 22.271 12.0821 21.913 12.4401C21.5551 12.798 20.9747 12.798 20.6167 12.4401L15.3799 7.20405Z"
      stroke="none"
    />
    <path
      d="M24.4732 14.0836C24.9794 14.0836 25.3899 14.494 25.3899 15.0002C25.3899 15.2645 25.278 15.5027 25.099 15.67L17.6353 23.1345C17.6279 23.1419 17.6204 23.1491 17.6128 23.1562L15.3799 25.3891C14.664 24.6731 14.664 23.5123 15.3799 22.7964L22.2597 15.9169H5.52799C5.02173 15.9169 4.61133 15.5065 4.61133 15.0002C4.61133 14.494 5.02173 14.0836 5.52799 14.0836H24.4732Z"
      stroke="none"
    />
  </svg>
</template>
