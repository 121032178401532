<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7982 5.61506C18.8683 7.88046 19.0716 11.285 17.2858 13.7806L14.4004 17.7966C13.9142 18.4734 14.0585 19.4147 14.7252 19.9147L18.5057 14.6535C20.7037 11.5819 20.4535 7.39154 17.9056 4.60328C14.7338 1.13224 9.26652 1.13224 6.09474 4.60328C3.54687 7.39154 3.29667 11.5819 5.49465 14.6535L11.8352 23.5005C12.5228 23.0476 12.6955 22.1137 12.2154 21.4448L6.71456 13.7806C4.92874 11.285 5.13203 7.88046 7.20212 5.61506C9.77913 2.7949 14.2212 2.7949 16.7982 5.61506Z"
      fill="#101316"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0002 6.49623C10.0671 6.49623 8.50008 8.06317 8.50008 9.99609C8.50008 11.929 10.0671 13.4959 12.0002 13.4959C13.9332 13.4959 15.5003 11.929 15.5003 9.99609C15.5003 8.06317 13.9332 6.49623 12.0002 6.49623ZM10.0001 9.99609C10.0001 8.89156 10.8956 7.99617 12.0002 7.99617C13.1048 7.99617 14.0002 8.89156 14.0002 9.99609C14.0002 11.1006 13.1048 11.996 12.0002 11.996C10.8956 11.996 10.0001 11.1006 10.0001 9.99609Z"
      fill="#101316"
    />
  </svg>
</template>
