<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    stroke="#0D3A93"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18.4999L12 16.9497L12 12L12 7.05023L12.0001 5.50001"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M12.0001 12L7.05032 12L5.5 12M16.9498 12L18.5 12M16.9498 12L15.7124 12M16.9498 12L14.8741 12"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M20.5 12.75C20.5 11.9216 19.8284 11.25 19 11.25L17.5 11.25L17.5 12.75L20.5 12.75Z"
      stroke="none"
    />
    <path
      d="M11.25 3.5C12.0784 3.5 12.75 4.17157 12.75 5L12.75 6.5L11.25 6.5L11.25 3.5Z"
      stroke="none"
    />
    <path
      d="M3.5 11.25C3.5 12.0784 4.17157 12.75 5 12.75L6.5 12.75L6.5 11.25L3.5 11.25Z"
      stroke="none"
    />
    <path
      d="M12.75 20.5C11.9216 20.5 11.25 19.8284 11.25 19L11.25 17.5L12.75 17.5L12.75 20.5Z"
      stroke="none"
    />
  </svg>
</template>
