<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.2559 30.9072C54.1355 31.7849 54.1371 33.2095 53.2595 34.0892L36.6306 50.7559C35.7539 51.6347 34.331 51.6373 33.451 50.7618L24.5179 41.875C26.2707 40.113 29.12 40.1057 30.8819 41.8585L35.0319 45.987L50.0739 30.9109C50.9516 30.0312 52.3762 30.0296 53.2559 30.9072Z"
      fill="#101316"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.0049 11.42C24.2179 11.42 11.4199 24.218 11.4199 40.005C11.4199 43.9033 12.1992 47.6166 13.6104 51C13.7873 51.4243 13.9742 51.8433 14.1707 52.2569C15.1591 54.337 14.6773 56.9175 12.7632 58.1982L12.4889 58.3817C12.0342 57.702 11.6039 57.0046 11.1995 56.2906C10.8446 55.6641 10.5095 55.0248 10.1951 54.3737C9.89854 53.7595 9.62037 53.1347 9.36135 52.5C7.78735 48.6436 6.91992 44.4242 6.91992 40.005C6.91992 21.7327 21.7326 6.92004 40.0049 6.92004C58.2773 6.92004 73.0899 21.7327 73.0899 40.005C73.0899 58.2774 58.2773 73.09 40.0049 73.09C35.3901 73.09 30.9931 72.1441 26.9999 70.4354C26.3731 70.1672 25.7562 69.8802 25.15 69.5752C24.5756 69.2861 24.0108 68.9807 23.4562 68.6598C22.7987 68.2793 22.1556 67.8769 21.5278 67.4535L21.7123 67.1799C23 65.2705 25.5821 64.7981 27.6586 65.7942C28.0996 66.0057 28.5468 66.2062 28.9999 66.3954C32.386 67.8092 36.1028 68.59 40.0049 68.59C55.792 68.59 68.5899 55.7921 68.5899 40.005C68.5899 24.218 55.792 11.42 40.0049 11.42Z"
      fill="#101316"
    />
  </svg>
</template>
