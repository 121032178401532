import {
  createRegistryEntityRepositoryRegistryPlugin,
  RegistryEntityRepository,
  RegistryEntityRepositoryOptions,
} from './registry-entity-repository';
import { ProviderPluginFactory } from '../plugin';
import { EntityRepository } from './entity-repository';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OnlineEntityRepositoryOptions
  extends RegistryEntityRepositoryOptions {}

export class OnlineEntityRepository extends RegistryEntityRepository {}

export const onlineEntityRepositoryRegistryPlugin =
  createRegistryEntityRepositoryRegistryPlugin(
    Symbol('OnlineEntityRepositoryRegistry'),
  );

export const onlineEntityRepositoryPlugin =
  ProviderPluginFactory.create<EntityRepository>({
    key: Symbol('OnlineEntityRepository'),
    defaultFactory: {
      create: () => {
        return new OnlineEntityRepository({
          registry: onlineEntityRepositoryRegistryPlugin.get(),
        });
      },
    },
  });
