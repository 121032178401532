import type { Ref } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import type { FeatureToggles, UseFeatureToggle } from '../types';
import { features } from '../types';

const storageName = 'feature-toggle';
const featureList = useSessionStorage(storageName, features, {
  mergeDefaults: true,
}) as Ref<FeatureToggles>;
export function useFeatureToggle(): UseFeatureToggle {
  return { featureList };
}
