<template>
  <AppServiceResolver>
    <template #ready>
      <!-- Added key in order to rerender page component after changing route to the same component -->
      <RouterView :key="$route.path" />
    </template>
  </AppServiceResolver>
  <Notification />
  <RouteLeaveGuard />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AppServiceResolver } from '@/features/core/resolver/';
import Notification from '@/features/core/notifications/components/Notification.vue';
import RouteLeaveGuard from '@/features/route-leave-guard/components/RouteLeaveGuard.vue';

export default defineComponent({
  name: 'App',
  components: {
    AppServiceResolver,
    Notification,
    RouteLeaveGuard,
  },
});
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $default-text-color;
}
</style>
