export type MappableObject = Record<string | number, unknown>;

export function deepClone<T>(
  obj: T,
  shouldThrowErrorOnMaxDepth = true,
  maxDepth = 100,
): T {
  return deepCloneRecursive(
    obj,
    new WeakMap(),
    0,
    maxDepth,
    shouldThrowErrorOnMaxDepth,
  );
}

function deepCloneRecursive<T>(
  obj: T,
  map = new WeakMap(),
  depth = 0,
  maxDepth = 100,
  throwErrorOnMaxDepth = true,
): T {
  /* The code below is mainly used to still log the object when max-depth is exceeded */
  if (depth > maxDepth + 1) {
    if (throwErrorOnMaxDepth) {
      throw new Error('Parsing Error: Exceeded maximum depth for cloning');
    } else {
      return undefined as unknown as T;
    }
  }

  if (obj === null || obj instanceof Event || typeof obj !== 'object') {
    return obj;
  }

  if (map.has(obj as MappableObject)) {
    return map.get(obj as MappableObject) as T;
  }

  if (Array.isArray(obj)) {
    const clone: T[] = [];
    map.set(obj, clone);
    obj.forEach((value, index) => {
      const cloneValue = deepCloneRecursive(
        value,
        map,
        depth + 1,
        maxDepth,
        throwErrorOnMaxDepth,
      ) as T;

      if (typeof cloneValue !== 'undefined') {
        clone[index] = cloneValue;
      }
    });

    return clone as unknown as T;
  }

  if (obj instanceof Date) {
    const cloneDate = new Date(obj.getTime());
    map.set(obj, cloneDate);
    return cloneDate as unknown as T;
  }

  const clone = Object.create(Object.getPrototypeOf(obj)) as MappableObject;
  map.set(obj as MappableObject, clone);

  Object.keys(obj as MappableObject).forEach((key) => {
    const desc = Object.getOwnPropertyDescriptor(obj, key);
    if (desc && typeof desc.value === 'object') {
      const cloneValue = deepCloneRecursive(
        desc.value,
        map,
        depth + 1,
        maxDepth,
        throwErrorOnMaxDepth,
      ) as T;

      if (typeof cloneValue !== 'undefined') {
        clone[key as keyof MappableObject] = cloneValue;
      }
    } else {
      Object.defineProperty(clone, key, desc as PropertyDescriptor);
    }
  });

  return clone as T;
}
