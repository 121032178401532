import { PipelinePlugin } from '@ads/plugin-pipeline';
import { appCrashServicePlugin } from '@/features/app-crash';
import { OrderPluginDto } from '../types';

export class AppCrashPlugin implements PipelinePlugin<OrderPluginDto> {
  public async execute(
    dataTransferObject: OrderPluginDto,
  ): Promise<OrderPluginDto> {
    await appCrashServicePlugin
      .get()
      .updateProcessedOrderData(dataTransferObject.order);

    return dataTransferObject;
  }
}
