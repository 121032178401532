<template>
  <component :is="comp" :viewBox="viewBox" :width="width"></component>
</template>

<script>
import { defineComponent } from 'vue';
import * as icons from '@/features/ui/icons';

export default defineComponent({
  name: 'ProductIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '18',
    },
    viewBox: {
      type: String,
      default: '0 0 18 18',
    },
  },
  computed: {
    comp() {
      return defineComponent(icons[this.name + 'Icon']);
    },
  },
});
</script>
