import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type {
  OrderItem,
  OrderItemRaw,
  OrderItemResponse,
  OrderParsePluginDto,
} from '../types';

/**
 * Sort bag items to the end of the list
 **/
export class SortItemsWithBagsPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    if (this.itemsIncludeBags(dataTransferObject.order.items)) {
      dataTransferObject.order.items.sort((a) => {
        if (!this.isBagItem(a)) {
          return -1;
        }
        return 0;
      });
    }
    return dataTransferObject;
  }

  private itemsIncludeBags(items: OrderItem[]) {
    return items.some((item) => this.isBagItem(item));
  }

  private isBagItem(
    item: OrderItemRaw | OrderItemResponse | OrderItem,
  ): boolean {
    return item.id.startsWith('bags_');
  }
}
