import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationDTO } from '../types';
import { Status } from '../types';

/**
 * Set order age verification status from DTO
 */

export class SetVerificationStatusPlugin
  implements PipelinePlugin<AgeVerificationDTO>
{
  public execute(dto: AgeVerificationDTO): AgeVerificationDTO {
    if (dto.order.ageVerification) {
      dto.order.ageVerification.status = dto.isVerified
        ? Status.Verified
        : Status.Rejected;
    }
    return dto;
  }
}
