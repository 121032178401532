<template>
  <RouterView v-if="loaded" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { RouterView } from 'vue-router';

export default defineComponent({
  name: 'AuthLayout',
  components: {
    RouterView,
  },
  setup() {
    const loaded = ref(false);

    return { loaded };
  },
});
</script>
