import { EntitySyncData, SyncStatus } from '../types';
import { Sync } from '../entities';
import { EventBusEvent } from '@/features/core/event-bus';
import { Entity } from '@/features/core/entity-repository';
import { deepClone } from '@/utils/helpers/deepClone';
import { loggerServicePlugin } from '@/features/core/logger';

export class SyncSchedulerEvent implements EventBusEvent {
  name = 'SyncSchedulerEvent';

  public readonly data!: EntitySyncData;

  constructor(data: EntitySyncData) {
    // The clone is added to avoid problems with reactivity
    // The async request to save will happen only in the next js-stack-call (or whenever internet is available)
    // Changes to order variable (Proxy) will be reactive https://jira.aldi-sued.com/browse/CICS-46286
    let cloneOrder: unknown;
    try {
      cloneOrder = deepClone(data.entitySnapshot);
    } catch (e) {
      loggerServicePlugin.get().error(e as Error, data.entitySnapshot);

      return;
    }

    this.data = {
      ...data,
      entitySnapshot: cloneOrder as Entity,
    };
  }
}

export class UpdateSyncStatusEvent implements EventBusEvent {
  name = 'UpdateSyncStatusEvent';

  constructor(public readonly syncStatus: SyncStatus) {}
}

export class SyncStatusChangedEvent implements EventBusEvent {
  name = 'SyncStatusChangedEvent';

  constructor(public readonly sync: Sync) {}
}

export class SyncTableUpdateEvent implements EventBusEvent {
  name = 'SyncTableUpdateEvent';
}

export class SyncFailureEvent implements EventBusEvent {
  name = 'SyncFailureEvent';
}

export class ErrorTriggerEvent implements EventBusEvent {
  name = 'ErrorTriggerEvent';

  constructor(public readonly error: string) {}
}
