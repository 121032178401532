import { ConfigurationService } from '@/features/configuration/services/configuration-service';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { ConfigurationValueReturnType } from '../types';

/**
 * Get configuration feature option and store it to provided DTO key
 */

export class GetConfigurationPlugin<T> implements PipelinePlugin<T> {
  constructor(
    private configurationService: ConfigurationService,
    private featureId: string,
    private optionId: string,
    private returnType: ConfigurationValueReturnType,
    private dtoKey: keyof T,
  ) {}

  public async execute(dto: T): Promise<T> {
    const featureOption = await this.configurationService.getFeatureOption(
      this.featureId,
      this.optionId,
      this.returnType,
    );
    dto[this.dtoKey] = featureOption as unknown as T[keyof T];
    return dto;
  }
}
