<template>
  <router-link
    class="ut-d-flex"
    :to="to"
    v-slot="{ href, navigate }"
    data-e2e="header-back-link"
  >
    <a class="ut-d-flex" :href="href" @click="navigate">
      <ArrowLeftIcon width="24" />
    </a>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { ArrowLeftIcon } from '@/features/ui/icons';

export default defineComponent({
  name: 'BackButton',
  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: '/',
    },
  },
  components: {
    ArrowLeftIcon,
  },
});
</script>
