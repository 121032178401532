export interface OauthConfig {
  hostUri: string;
  providers: {
    [providerId: string]: OauthProviderConfig;
  };
}

export interface OauthProviderConfig {
  clientId: string;
  authUri: string;
  tokenUri: string;
  responseType: string;
  scopes?: { require?: string[]; request?: string[] };
  grant_type: string;
  redirect_uri: string;
  logoutUri: string;
  scope?: string;
  useDeviceToken?: boolean;
}

export interface OauthService {
  login(providerId?: string): void;

  logout(): void;
}

export interface OauthLoginAuthParams {
  email: string;
  password: string;
  [param: string]: unknown;
}

export interface OauthLoginAuthResponse {
  code: string;
  [param: string]: unknown;
}

export interface SalteConfig {
  responseType: string;
  redirectUrl: string;
  scope?: string;
  url: string;
  clientID: string;
  grant_type: string;
  tokenUri: string;
  logoutUri: string;
  validation?: {
    nonce: boolean;
    state: boolean;
  };
  code_challenge_method?: string;
  code_challenge?: string;
}

export const MAX_INACTIVITY_HOURS_BEFORE_LOGOUT = 8;
