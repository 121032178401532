<template>
  <Button
    class="logout-button"
    v-if="!isLogoutHide"
    :btn-outline="true"
    @click="handleLogout"
    data-e2e="logout-button"
    :disabled="isLogoutDisabled"
  >
    {{ $t('common.logout.text') }}
  </Button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import Button from './Button.vue';
import { useOauthLogout } from '@/features/login/composables';

export default defineComponent({
  name: 'LogoutButton',
  components: { Button },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isLogoutCanBeDisabled, isLogoutCanBeHide, handleLogout } =
      useOauthLogout();

    const isLogoutDisabled = computed(
      () => props.disabled && isLogoutCanBeDisabled.value,
    );

    const isLogoutHide = computed(() => props.hide && isLogoutCanBeHide.value);

    return {
      handleLogout,
      isLogoutDisabled,
      isLogoutHide,
    };
  },
});
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(214, 216, 218, 0.05);
  backdrop-filter: blur(20px);

  &__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 32px 24px 16px;
    margin-right: -50%;
    width: calc(100% - 32px);
    background: #fff;
    border-radius: 18px;
    box-shadow: 0 3px 16px rgba(13, 58, 147, 0.12);
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }

  &__title {
    margin: 10px 0 16px;
    font-size: 24px;
    color: #101316;
    line-height: 32px;
  }

  &__icon {
    margin: 0 auto;
    max-width: 64px;
  }
}
</style>
