import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import {
  NotifyNotificationService,
  NotificationService,
} from './notification-service';

export const notificationPlugin =
  ProviderPluginFactory.create<NotificationService>({
    key: Symbol('NotifyNotificationService'),
    defaultFactory: {
      create: () => new NotifyNotificationService(),
    },
  });

export const notificationConfigPlugin = MultiPluginFactory.with({
  notificationPlugin,
});
