import { BaseApiClient } from '@/features/core/api';
import { SearchProductError, SearchProductWrongDataError } from '../errors';
import {
  ProductSearchResponse,
  ProductSearchResponseItem,
  SearchProductsParams,
} from '../types';
import { AuthService } from '@/features/core/auth';
import { errorPlugin } from '@/features/core/errors';

export class ProductsSearchApiClient {
  constructor(
    private productSearchApi: BaseApiClient,
    private authService: AuthService,
  ) {}

  async searchProducts(
    params: SearchProductsParams,
  ): Promise<ProductSearchResponseItem | void> {
    try {
      const merchantReference = await this.authService.getMerchantReference();

      const productsSearchResponse =
        await this.productSearchApi.client.get<ProductSearchResponse>(
          '/catalog-search-product-offers',
          {
            innerErrorCode: SearchProductError.Code,
            params: {
              merchantReference,
              ...params,
            },
          },
        );

      if (
        productsSearchResponse.data.data &&
        productsSearchResponse.data.data[0]
      ) {
        return productsSearchResponse.data.data[0];
      } else {
        errorPlugin.get().handle(new SearchProductWrongDataError());
      }
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  }
}
