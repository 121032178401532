import { computed, Ref } from 'vue';
import { OrderItem, OrderItemStatus } from '../types';

export function useAggregateOrderItems(
  orderList: Ref<OrderItem[] | undefined>,
): {
  aggregatedOrderItems: Ref<OrderItem[]>;
} {
  const aggregatedOrderItems: Ref<OrderItem[]> = computed(() => {
    const separatedItemsDefault: [OrderItem[], OrderItem[]] = [[], []];
    const [orderItems, replacementCandidates] = orderList.value
      ? orderList.value.reduce((acc, item) => {
          const [items, replacements] = acc;
          if (item.originalId && item.status !== OrderItemStatus.rejected) {
            return [items, [...replacements, item]];
          }
          return [[...items, item], replacements];
        }, separatedItemsDefault)
      : separatedItemsDefault;
    return orderItems.reduce((acc, orderItem) => {
      const replacements = replacementCandidates.filter(
        (candidateItem) => orderItem.id === candidateItem.originalId,
      );

      let augmentedOrderItem = orderItem;
      if (replacements.length) {
        augmentedOrderItem = { ...augmentedOrderItem, replacements };
      }
      return [...acc, augmentedOrderItem];
    }, [] as OrderItem[]);
  });

  return {
    aggregatedOrderItems,
  };
}
