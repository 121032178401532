import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { TourDropItem } from '../entities';
import { DropStatus, TourDrop } from '../types';
import { formatDateAtom } from '@/composables/useDateFormat';

export class DropsService {
  async patchDrop(
    drop: TourDrop,
    scheduled = true,
    waitForSync = false,
  ): Promise<void> {
    const status = drop.status as DropStatus;
    drop.statusChangedAt = formatDateAtom(new Date());
    const result = entityRepositoryPlugin
      .get()
      .save(TourDropItem.from({ ...drop, status }), { waitForSync });

    if (scheduled) {
      await result.scheduled;
    }
    if (waitForSync) {
      await result.completed;
    }
  }
}
