import { RepositoryReadOptions } from '@/features/core/entity-repository';
import { Storage } from '@/features/core/storage';
import { ProcessedOrder } from '../entities';
import { ProcessedOrderParams, ProcessedOrderServiceInterface } from '../types';

export class ProcessedOrderService implements ProcessedOrderServiceInterface {
  constructor(private storage: Storage) {}

  async setProcessedOrder(
    processedOrderData: ProcessedOrderParams,
  ): Promise<void> {
    await this.storage.save(
      ProcessedOrder.from({
        type: 'processedOrder',
        id: processedOrderData.id,
        lastRoute: processedOrderData.lastRoute,
        processedBy: processedOrderData.processedBy,
      }),
    );
  }

  async getById(id: string): Promise<ProcessedOrder | undefined> {
    return await this.storage.getById(ProcessedOrder, {
      id,
    });
  }

  async getAll(
    options: RepositoryReadOptions = {},
  ): Promise<ProcessedOrder[] | undefined> {
    return await this.storage.getAll(ProcessedOrder, options);
  }

  async removeById(processedOrderId: string): Promise<void> {
    return await this.storage.remove(
      ProcessedOrder.from({
        id: processedOrderId,
        type: 'processedOrder',
      }),
    );
  }

  async removeByIds(processedOrderIds: string[]): Promise<void[]> {
    return await Promise.all(
      processedOrderIds.map(async (id: string) => await this.removeById(id)),
    );
  }

  async clearAll(): Promise<void> {
    await this.storage.removeAll(ProcessedOrder);
  }
}
