import { ExecPluginFactory } from '@/features/core/plugin';
import { storageSetupPlugin } from '@/features/core/storage';
import { TourItem, TourStorageSetup } from './tour';
import {
  TourDriverNotificationItem,
  TourDriverNotificationStorageSetup,
} from './tour-driver-notifiaction';

export * from './tour';

export const toursEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin.get().add(TourItem, new TourStorageSetup());
  storageSetupPlugin
    .get()
    .add(TourDriverNotificationItem, new TourDriverNotificationStorageSetup());
});
