export const BarcodePatterns = {
  ProductBarcode: /^[\d]{4,28}$/,
  FrameRegexp: /DF[0-9]{3,3}\.[0-9]{3,3}-[0-9]{3,3}\.[0-9]{3,3}/,
  BoxRegexp: /[0-9].[0-9]{3,3}-[0-9]{3,3}.[0-9]{2,2}\.[0-9]{3,3}/,
  BagRegexp: /[A-Z].[0-9]{3,3}-[0-9]{3,3}.[0-9]{2,2}\.[0-9]{3,3}/,
  BagAndBoxRegexp: /[0-Z]\.[0-9]{3,3}-[0-9]{3,3}\.[0-9]{2,2}\.[0-9]{3,3}/,
  ScaleRegexp: /^S([\d]{4})([\d]{5})([\d]{6})$/,
  CheckinRegexp: /^(handover|picking)\|([\d-]+)$/,
  BagsCollectionRegexp: /^pickupCode=\d+;storageZoneId=\d+;labelNumber=\d+;?$/,
  BagsScanCollectionRegexp: /^BAG:[\w-]+$/,
  ShelfPositionRegexp: /^[\w]+$/,
  EmbeddedBarcodeRegexp: /^([\d]{6})[\d]([\d]{4,5})([\d])$/,
};
