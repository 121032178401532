export function getArrayHashMap<T>(
  array: T[],
  hashName: keyof T,
): Record<string, T[]> {
  const hashMap: Record<string | number, T[]> = {};

  array.forEach((element) => {
    const hashField = element[hashName];
    if (typeof hashField === 'string' || typeof hashField === 'number') {
      if (!Array.isArray(hashMap[hashField])) {
        hashMap[hashField] = [];
      }
      hashMap[hashField].push(element);
    }
  });

  return hashMap;
}
