import type { BarcodeLookupComponents } from '../types';
import { BarcodePatterns } from '../constants';

export function extractBarcodeComponents(
  barcode: string,
): BarcodeLookupComponents {
  const regex = new RegExp(BarcodePatterns.EmbeddedBarcodeRegexp);
  const barcodeMatchList = barcode.match(regex);
  const sku = barcodeMatchList?.[1];
  const rawEmbeddedValue = barcodeMatchList?.[2];
  return {
    sku,
    rawEmbeddedValue,
  };
}
