import { apiPlugin } from '@/features/core/api';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';

import { StorageZoneApiClient } from './storageZone';
import { authServicePlugin } from '@/features/core/auth';
import { registerStorageZoneApi } from './storageZoneRegister';

export * from './storageZone';
export * from './storageZoneRegister';

export const storageZoneApiPlugin =
  ProviderPluginFactory.create<StorageZoneApiClient>({
    key: Symbol('StorageZoneApiClient'),
    defaultFactory: {
      create: () =>
        new StorageZoneApiClient(apiPlugin.get(), authServicePlugin.get()),
    },
  });

export const storageZoneApiRegistryPlugin = ExecPluginFactory.fn(() => {
  const storageZoneApi = storageZoneApiPlugin.get();
  registerStorageZoneApi(
    onlineEntityRepositoryRegistryPlugin.get(),
    storageZoneApi,
  );
});
