import {
  LogLevel,
  MessageTransferObject,
  SaveLogsToMemoryTransporterInterface,
} from '../types';

export class SaveLogsToMemoryTransporter
  implements SaveLogsToMemoryTransporterInterface
{
  defaultLogLevel: LogLevel = 20;
  private logHistory: MessageTransferObject[] = [];
  private maxLogHistorySize = 1000;

  private currentLogLevel: LogLevel = this.defaultLogLevel;
  write(logLevel: LogLevel, msgObject: MessageTransferObject): void {
    if (logLevel < this.currentLogLevel) {
      return;
    }

    if (this.logHistory.length >= this.maxLogHistorySize) {
      this.logHistory.shift(); // Remove the oldest log
    }

    this.logHistory.push(msgObject);
  }

  setLogLevel(logLevel: LogLevel): void {
    this.currentLogLevel = logLevel;
  }

  getLogHistory(): MessageTransferObject[] {
    return [...this.logHistory];
  }

  clearLogHistory(): void {
    this.logHistory.length = 0;
  }
}
