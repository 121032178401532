import { CompletePipelineWith, PipelinePlugin } from '@ads/plugin-pipeline';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { VerificationDialogShow } from '@/features/login/events';
import { authServicePlugin } from '@/features/core/auth';
import { RefreshUserTokenDTO } from '../types';

export class ShowUserVerificationDialogPlugin<T extends RefreshUserTokenDTO>
  implements PipelinePlugin<T>
{
  public async execute(
    dto: T,
    completePipelineWith: CompletePipelineWith<T>,
  ): Promise<T> {
    if (dto.isTokenRefreshFailed) {
      await authServicePlugin.get().removeUserToken();
      eventBusServicePlugin.get().emit(new VerificationDialogShow());
      return completePipelineWith(dto);
    }
    return dto;
  }
}
