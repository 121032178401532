import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';

import { EventBus } from './types';
import { EventBusService } from './event-bus';

export const eventBusServicePlugin = ProviderPluginFactory.create<EventBus>({
  key: Symbol('EventBusService'),
  defaultFactory: { create: () => new EventBusService() },
});

export const eventBusPlugin = MultiPluginFactory.with({
  eventBusServicePlugin,
});
