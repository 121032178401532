<template>
  <svg
    width="24"
    viewBox="0 0 30 30"
    fill="#0D3A93"
    stroke="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6213 22.7964C15.3372 23.5123 15.3372 24.6731 14.6213 25.3891L12.3883 23.1562C12.3808 23.1491 12.3733 23.1419 12.3659 23.1345L8.08815 18.8567C7.73017 18.4988 7.73017 17.9184 8.08815 17.5604C8.44613 17.2024 9.02653 17.2024 9.38451 17.5604L14.6213 22.7964Z"
      stroke="none"
    />
    <path
      d="M5.52799 15.9169C5.02173 15.9169 4.61133 15.5065 4.61133 15.0002C4.61133 14.7359 4.7232 14.4977 4.90218 14.3304L12.3659 6.86593C12.3733 6.85857 12.3807 6.85136 12.3883 6.8443L14.6213 4.61133C15.3372 5.32729 15.3372 6.48809 14.6213 7.20405L7.7415 14.0835L24.4732 14.0835C24.9794 14.0835 25.3899 14.494 25.3899 15.0002C25.3899 15.5065 24.9794 15.9169 24.4732 15.9169H5.52799Z"
      stroke="none"
    />
  </svg>
</template>
