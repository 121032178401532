import { PushProvider, PushService } from '../types';

export class PushServiceImpl implements PushService {
  constructor(private provider: PushProvider) {}

  init(): void {
    this.provider.init();
  }

  async unSubscribe(): Promise<boolean> {
    return await this.provider.deleteToken();
  }

  getProvider(): PushProvider | null {
    return this.provider;
  }
}
