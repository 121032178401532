import { ApiClient } from '@/features/core/api';
import { GetTourDocumentApiError } from '../errors';
import { TourDocumentApiResponse } from '../types';

export class TourDocumentApiClient {
  constructor(private api: ApiClient, private apiUrl: string) {}

  public async getTourDocument(tourReference: string): Promise<string> {
    try {
      const { data: tourDocumentResponse } =
        await this.api.client.get<TourDocumentApiResponse>(
          this.apiUrl + tourReference,
          {
            disableErrorHandling: true,
            headers: {
              'Cache-Control': 'no-store',
            },
          },
        );
      return tourDocumentResponse.content;
    } catch (error) {
      throw new GetTourDocumentApiError();
    }
  }
}
