import { Storage } from '@/features/core/storage';
import { DataSyncScopes, DataSyncService } from '@/features/data-sync';
import { Product } from '@/features/products/entities';
import { getArrayDiff } from '@/utils/helpers/getArrayDiff';
import { StorageZoneService } from '@/features/storageZone';
import { ProductsService } from '@/features/products';
import { DataBackgroundSyncService } from '../types';

export class DataBackgroundSyncServiceImplementation
  implements DataBackgroundSyncService
{
  constructor(
    private storage: Storage,
    private storageZoneService: StorageZoneService,
    private productsService: ProductsService,
    private dataSyncService: DataSyncService,
  ) {}

  async fetchDataOnBackgroundSync(): Promise<void> {
    const isProductReadyToSync = await this.isDataReadyToSync(
      DataSyncScopes.ProductSync,
    );
    const isStorageZonesReadyToSync = await this.isDataReadyToSync(
      DataSyncScopes.StorageZoneSync,
    );

    if (isProductReadyToSync) {
      await this.fetchAndStoreAllProducts();
    }
    if (isStorageZonesReadyToSync) {
      await this.fetchAndStoreStorageZones();
    }
  }

  private async isDataReadyToSync(scope: DataSyncScopes) {
    return await this.dataSyncService.isDataReadyToSync(scope);
  }

  private async updateDataSyncDate(scope: DataSyncScopes, date = new Date()) {
    await this.dataSyncService.setDataSync(scope, date);
  }

  private async fetchAndStoreAllProducts() {
    const fetchedProducts = await this.productsService.fetchAll();
    await this.updateDataSyncDate(DataSyncScopes.ProductSync);
    await this.removeOutdatedProducts(fetchedProducts);
  }

  private async fetchAndStoreStorageZones() {
    await this.storageZoneService.fetchAllStorageZones();
    await this.updateDataSyncDate(DataSyncScopes.StorageZoneSync);
  }

  private async removeOutdatedProducts(
    fetchedProducts: Product[],
  ): Promise<void> {
    const fetchedProductsIds = this.getProductsIds(fetchedProducts);
    const storedProducts = await this.storage.getAll(Product);
    const storedProductsIds = this.getProductsIds(storedProducts);
    const outdatedProductsIds = getArrayDiff(
      fetchedProductsIds,
      storedProductsIds,
    );
    const removedProducts = storedProducts.filter((product) =>
      outdatedProductsIds.includes(product.id),
    );
    await Promise.all(
      removedProducts.map(async (product) =>
        this.productsService.clearProduct(product),
      ),
    );
  }

  private getProductsIds(products: Product[]): string[] {
    return products.map((product) => product.id);
  }
}
