import { SomethingWrongError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [GetAllStorageZonesRequestError.Code]: 'GetAllStorageZonesRequestError';
  }
}

export class GetAllStorageZonesRequestError extends SomethingWrongError {
  static Code = 22 as const;
}
