import { TourDropAddress, TourOriginAddress } from '@/features/tours';
import { configurationServicePlugin } from '@/features/configuration';

const openNavigation = (address: string): void => {
  const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    address,
  )}&travelmode=driving&dir_action=navigate`;
  window.open(url, '_blank');
};

const navigateToMap = async (
  address: TourDropAddress | TourOriginAddress,
  includeAddress2 = false,
): Promise<void> => {
  const useGeolocationForNavigation: boolean = await configurationServicePlugin
    .get()
    .getFeatureOption('driverApp', 'useGeolocationForNavigation', 'boolean');
  const { latitude, longitude } = address;
  if (useGeolocationForNavigation && latitude && longitude) {
    openNavigation(`${latitude},${longitude}`);
  } else {
    const { address1, address2, zipCode, city } = address;
    const fullAddress = includeAddress2
      ? `${address1} ${address2} ${zipCode} ${city}`
      : `${address1} ${zipCode} ${city}`;
    openNavigation(fullAddress);
  }
};

export { navigateToMap };
