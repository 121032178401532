import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationOnHandoverDTO } from '../types';
import { Status } from '../types';

/**
 * Check if age verification is needed and store it to the DTO
 */

export class IsVerificationNeededPlugin
  implements PipelinePlugin<AgeVerificationOnHandoverDTO>
{
  public execute(
    dto: AgeVerificationOnHandoverDTO,
  ): AgeVerificationOnHandoverDTO {
    dto.isVerificationRequired = Boolean(
      dto.order.ageVerification &&
        dto.order.ageVerification.status === Status.NotVerified,
    );
    return dto;
  }
}
