<template>
  <div class="pulsating-dot"></div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PulsatingDot',
});
</script>
<style lang="scss" scoped>
.pulsating-dot {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: #d70000;
  border-radius: 50%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: #d70000;
    border-radius: 200px;
    opacity: 1;
    content: '';
    transform: scale(1);
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
}

@keyframes ping {
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>
