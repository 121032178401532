import { Ref } from 'vue';
import { $t } from '@/i18n';
import { StorageSortDirection } from '@/features/core/storage';
import { OrderItem, OrderWeight } from '@/features/orders';

export interface FilterItem {
  value: string;
  count: number;
  label: string;
}

export type HandoverFilterRecord = Record<string, FilterItem>;

export interface FilterRecord {
  value: string;
  label: string;
  e2eLabel: string;
  count?: number;
}

export interface FilterRecordCount {
  [key: string]: number;
}

export interface UseScalableImage {
  setImageScale: (
    imageElement: HTMLElement,
    scalableProduct: HTMLElement,
    maxScalableWidth: number,
  ) => void;
  isImagePopupVisible: Ref<boolean>;
  toggleImagePopup: () => void;
  resetHammerJs: () => void;
}

export interface SortTypeValue {
  sortBy: string;
  sort: StorageSortDirection.ASC | StorageSortDirection.DESC;
}

export interface SortTypes {
  value: SortTypeValue;
  label: string;
  e2eLabel: string;
}

export const sortTypes: SortTypes[] = [
  {
    value: {
      sortBy: 'startTime',
      sort: StorageSortDirection.ASC,
    },
    label: 'components.order-filter.startTime-asc-label.text',
    e2eLabel: 'startTime-' + StorageSortDirection.ASC,
  },
  {
    value: {
      sortBy: 'startTime',
      sort: StorageSortDirection.DESC,
    },
    label: 'components.order-filter.startTime-desc-label.text',
    e2eLabel: 'startTime-' + StorageSortDirection.DESC,
  },
  {
    value: {
      sortBy: 'itemsCount.total',
      sort: StorageSortDirection.DESC,
    },
    label: 'components.order-filter.itemsCount-desc-label.text',
    e2eLabel: 'itemsCount-' + StorageSortDirection.DESC,
  },
  {
    value: {
      sortBy: 'itemsCount.total',
      sort: StorageSortDirection.ASC,
    },
    label: 'components.order-filter.itemsCount-asc-label.text',
    e2eLabel: 'itemsCount-' + StorageSortDirection.ASC,
  },
];

export enum TabVariants {
  All = 'all',
  Found = 'staged',
  Rejected = 'rejected',
  NotPicked = 'not_picked',
  Picked = 'picked',
  Changed = 'changed',
}

export const pickingFilters = {
  [TabVariants.NotPicked]: {
    value: TabVariants.NotPicked,
    label: 'To be Picked',
    count: 0,
  },
  [TabVariants.Changed]: {
    value: TabVariants.Changed,
    label: 'Changed',
    count: 0,
  },
  [TabVariants.Picked]: {
    value: TabVariants.Picked,
    label: 'Picked',
    count: 0,
  },
};

export const handoverFilters = {
  [TabVariants.All]: {
    value: TabVariants.All,
    label: $t('pages.handover-order.filters.all.label'),
    count: 0,
  },
  [TabVariants.Changed]: {
    value: TabVariants.Changed,
    label: $t('pages.handover-order.filters.changed.label'),
    count: 0,
  },
  [TabVariants.Found]: {
    value: TabVariants.Found,
    label: $t('pages.handover-order.filters.found.label'),
    count: 0,
  },
  [TabVariants.Rejected]: {
    value: TabVariants.Rejected,
    label: $t('pages.handover-order.filters.rejected.label'),
    count: 0,
  },
};

export const reviewFilters = {
  [TabVariants.All]: {
    value: TabVariants.All,
    label: 'All',
    count: 0,
  },
  [TabVariants.Changed]: {
    value: TabVariants.Changed,
    label: 'Changed',
    count: 0,
  },
  [TabVariants.Picked]: {
    value: TabVariants.Picked,
    label: 'Picked',
    count: 0,
  },
};

export enum StatusTypes {
  Default = '',
  Discard = 'discard',
  Submit = 'submit',
  DiscardFromBarcode = 'discardFromBarcode',
}

export interface WeightPopupComposableValue {
  order: Ref<OrderItem | undefined>;
  visible: Ref<boolean>;
  weightBefore: Ref<OrderWeight[]>;
  initialWeight: Ref<number>;
}

export interface ScrollEvent {
  target: {
    scrollTop: number;
    clientHeight: number;
    scrollHeight: number;
  };
}

export enum LabelsLayoutEnum {
  Default = 'default',
  ShowOriginalQuantity = 'showOriginalQuantity',
}

export const SecretToggleEvent = {
  click: 'click',
} as const;

export type secretToggleEvent = keyof typeof SecretToggleEvent;

export const MIN_BARCODE_CHARS = 12;
export const MAX_BARCODE_CHARS = 13;
