import { UseRejectionModal } from '../types';
import { Ref, ref } from 'vue';

const showRejectionModal: Ref<boolean> = ref(false);

export const useRejectionModal = (): UseRejectionModal => {
  const toggleRejectionModal = () => {
    showRejectionModal.value = !showRejectionModal.value;
  };

  return {
    showRejectionModal,
    toggleRejectionModal,
  };
};
