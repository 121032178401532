import { ref, Ref } from 'vue';

type UseWeightList = {
  isBarcodeValidating: Ref<boolean>;
};

const isBarcodeValidating = ref(false);
export const useWeightList = (): UseWeightList => {
  return {
    isBarcodeValidating,
  };
};
