<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    stroke="#101316"
    fill="#101316"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 4H18.5L15.6789 16.2249C15.5742 16.6786 15.1701 17 14.7045 17H7.5"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.1001 17.75C6.1001 16.9216 6.77167 16.25 7.6001 16.25L9.1001 16.25L9.1001 17.75L6.1001 17.75Z"
      stroke="none"
    />
    <path
      d="M16 13.5H7.09103C6.15971 13.5 5.35166 12.8572 5.14224 11.9497L4 7H11.25H12.9375"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 6.25C15 7.07843 14.3284 7.75 13.5 7.75L12 7.75L12 6.25L15 6.25Z"
      stroke="none"
    />
    <circle cx="14.5" cy="20" r="1" stroke="none" />
    <circle cx="7.5" cy="20" r="1" stroke="none" />
  </svg>
</template>
