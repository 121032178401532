import { Storage } from '@/features/core/storage';
import { ImageCachingQueue } from '../entities';

export class ImageCachingQueueServiceImplementation {
  private urls = new Set<string>();
  private width = '800';

  constructor(private storage: Storage) {}

  replaceUrlWidth(url: string): string {
    return url.replace('{width}', this.width);
  }

  addToCachingQueue(url: string): void {
    this.urls.add(this.replaceUrlWidth(url));
  }

  deleteFromCachingQueue(url: string): void {
    this.urls.delete(this.replaceUrlWidth(url));
  }

  async setCachingQueue(): Promise<void> {
    await this.storage.bulkSave(
      Array.from(this.urls).map((url) =>
        ImageCachingQueue.from({
          type: 'imageCachingQueue',
          id: url,
          url: url,
        }),
      ),
    );
  }

  async getAll(): Promise<ImageCachingQueue[]> {
    return await this.storage.getAll(ImageCachingQueue);
  }

  async clearAll(): Promise<void> {
    await this.storage.removeAll(ImageCachingQueue);
  }

  async removeByIds(ids: string[]): Promise<void> {
    await this.storage.removeSeveral(ImageCachingQueue, { ids });
  }

  async fetchAll(removeAfterFetch = true): Promise<void> {
    const urls = await this.getAll();

    const ids: string[] = [];
    urls.forEach((imgUrl: ImageCachingQueue) => {
      void fetch(imgUrl.url, {
        method: 'GET',
        redirect: 'follow',
      }).catch(() => {
        // do nothing
      });
      ids.push(imgUrl.id);
    });
    if (removeAfterFetch) await this.removeByIds(ids);
  }
}
