export const permissionErrorCode = 'messaging/permission-blocked';

export interface FirebaseProviderConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  vapidKey: string;
}
