import { Ref } from 'vue';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import {
  BooleanSwitcherPlugin,
  HandleRestrictedOrderPlugin,
  NavigateToTargetRoutePlugin,
  Order,
  OrderPluginDto,
  SaveOrderToStorage,
  SetCustomerNoteAsTargetRoutePlugin,
  SetTargetRoutePlugin,
  TrackOrderEventsPlugin,
  setPickingEventsAsAlreadyPatchedPlugin,
} from '@/features/orders';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';

export async function startHandoverProcess(
  order: Order,
  switcher: Ref<boolean>,
): Promise<void> {
  try {
    const startOrderPlugin = new PluginPipeline<OrderPluginDto>();

    startOrderPlugin.registerPlugins([
      new HandleRestrictedOrderPlugin(),
      new BooleanSwitcherPlugin(switcher, true),
      new setPickingEventsAsAlreadyPatchedPlugin(),
      new SetTargetRoutePlugin({
        name: 'handover-storagezones',
        params: {
          id: order.id,
        },
      }),
      new TrackOrderEventsPlugin(),
      new SaveOrderToStorage(),
      new SetCustomerNoteAsTargetRoutePlugin(),
      new NavigateToTargetRoutePlugin(),
      new BooleanSwitcherPlugin(switcher, false),
    ]);

    await startOrderPlugin.execute({
      order,
    });
  } catch (error) {
    switcher.value = false;
    if (error instanceof PipelineExecutionError) {
      errorPlugin.get().handle(error.originalError);
    } else {
      errorPlugin.get().handle(error);
    }
    await router.get().push('/');
  }
}
