import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type {
  OrderItemRaw,
  OrderItemResponse,
  OrderParsePluginDto,
} from '../types';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';

/**
 * Reset order items quantity to zero
 **/
export class ResettingItemQuantityPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public readonly requiredPlugins = [NormalizeOrderPlugin];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    dataTransferObject.rawItems.map(
      (item: OrderItemRaw | OrderItemResponse) => {
        item.quantity = 0;
        item.amount = 0;
      },
    );
    return dataTransferObject;
  }
}
