<template>
  <svg
    width="18"
    viewBox="0 0 18 18"
    fill="#55C3F0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.62555 5.18849C5.65836 3.35291 7.15659 1.875 9 1.875C10.864 1.875 12.375 3.38604 12.375 5.25L12.3748 9.375C12.3748 9.99632 12.8785 10.5 13.4998 10.5L13.5005 5.0625H13.4962C13.3979 2.66417 11.4225 0.75 9 0.75C6.57753 0.75 4.60213 2.66417 4.50384 5.0625H4.50055V5.17896C4.50018 5.2026 4.5 5.22628 4.5 5.25L4.50055 12.9375C4.50055 13.2482 4.75239 13.5 5.06305 13.5H8.43732V16.5C8.43732 16.8107 8.68916 17.0625 8.99982 17.0625C9.31048 17.0625 9.56232 16.8107 9.56232 16.5V13.5H13.4061C13.4061 12.8787 12.9024 12.375 12.2811 12.375H5.62555V5.18849Z"
    />
  </svg>
</template>
