import { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderItemStatus, OrderPluginDto } from '../types';

export class SetItemStatusPlugin implements PipelinePlugin<OrderPluginDto> {
  constructor(private status: OrderItemStatus) {}
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    dataTransferObject.order.items = dataTransferObject.order.items.map(
      (orderItem) => ({
        ...orderItem,
        status: this.status,
      }),
    );
    return dataTransferObject;
  }
}
