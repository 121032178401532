import { MultiPluginFactory } from '@/features/core/plugin';
import { dataSyncEntityPlugin } from './entities';
import { dataSyncServicePlugin } from './services';

export * from './types';
export * from './entities';
export * from './services';

export const dataSyncPlugin = MultiPluginFactory.with({
  dataSyncEntityPlugin,
  dataSyncServicePlugin,
});
