import {
  BaseError,
  SomethingWrongError,
} from '@/features/core/errors/error-classes';
import { $t } from '@/i18n';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [PushPermissionError.Code]: 'PushPermissionError';
    [NoMerchantForUserError.Code]: 'NoMerchantForUserError';
    [GetDeviceTokenError.Code]: 'GetDeviceTokenError';
  }
}

export class PushPermissionError extends BaseError {
  static Code = 4 as const;
  static Message = $t('errors.push-permission-error.message');
}

export class NoMerchantForUserError extends BaseError {
  static Code = 5 as const;
  static Message = $t('errors.no-merchant-for-user-error.message');
}

export class GetDeviceTokenError extends SomethingWrongError {
  static Code = 15 as const;
}
