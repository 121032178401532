import { Subject, Subscription, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const DEFAULT_WAIT_TIME = 500;
const DEFAULT_SUBJECT_KEY = 'default';

type DebouncePayload<T> = {
  func: (...args: T[]) => void;
  args: T[];
};

type UseDebounce<T> = {
  debounce: (
    func: (...args: T[]) => void,
    subjectKey?: string,
    waitTime?: number,
  ) => (...args: T[]) => void;
  destroy: () => void;
  skipWait: () => void;
  interruptOngoingOperations: () => void;
};

type DebounceSubjects<T> = Map<string, Subject<DebouncePayload<T>>>;

const useDebounce = <T>(): UseDebounce<T> => {
  const skipDebounce: Subject<void> = new Subject();
  const subjects: DebounceSubjects<T> = new Map();
  const subscriptions: Map<string, Subscription> = new Map();

  const debounce: UseDebounce<T>['debounce'] = (
    func,
    subjectKey = DEFAULT_SUBJECT_KEY,
    waitTime = DEFAULT_WAIT_TIME,
  ) => {
    if (!subjects.has(subjectKey)) {
      subjects.set(subjectKey, new Subject<DebouncePayload<T>>());
      setupSubscription(subjectKey, waitTime);
    }
    return (...args: T[]) => {
      const payload: DebouncePayload<T> = { func, args };
      subjects.get(subjectKey)?.next(payload);
    };
  };

  const setupSubscription = (subjectKey: string, waitTime: number): void => {
    if (subscriptions.has(subjectKey)) {
      return;
    }

    const subscription = subjects
      .get(subjectKey)
      ?.pipe(takeUntil(skipDebounce), debounceTime(waitTime))
      .subscribe((payload: DebouncePayload<T>) => {
        payload.func(...payload.args);
      });

    if (subscription) {
      subscriptions.set(subjectKey, subscription);
    }
  };

  const clearAllSubjects = (): void => {
    subjects.forEach((subject) => {
      subject.complete();
    });

    subjects.clear();
    skipDebounce.complete();
  };

  const skipWait = (): void => {
    skipDebounce.next();
  };

  const interruptOngoingOperations = (): void => {
    subscriptions.forEach((subscription) => subscription.unsubscribe());
    subscriptions.clear();
  };

  const destroy = (): void => {
    interruptOngoingOperations();
    clearAllSubjects();
  };

  return {
    debounce,
    destroy,
    skipWait,
    interruptOngoingOperations,
  };
};

export { useDebounce };
