import { ProviderPluginFactory } from '@/features/core/plugin';
import { useRouteProcessGuard } from '../composables';

export const routeProcessGuardPlugin = ProviderPluginFactory.create({
  key: Symbol('RouteProcessGuardPlugin'),
  defaultFactory: {
    create: () => {
      const { setupProcessGuard } = useRouteProcessGuard();
      setupProcessGuard();
    },
  },
});
