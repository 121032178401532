import { EntityUpdate } from '@/features/service-worker';
import { EventBusEvent } from '@/features/core/event-bus';
import { EntityType } from './entity';

export class EntityUpdatedEvent implements EventBusEvent {
  name = 'EntityUpdatedEvent';

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public readonly updateEntityPayload: EntityUpdate<EntityType<any>>[],
  ) {}
}
export class OrderUpdateEvent implements EventBusEvent {
  name = 'OrderUpdateEvent';

  constructor(public readonly updateOrderIds: string) {}
}
