import { GetEnvironmentConfigError } from './errors';

type Config = Record<string, string | number | boolean | RegExp>;

type SelectiveConfig = {
  environmentCondition: RegExp;
} & Config;

interface ServiceConfig {
  defaultConfig?: Config;
  selectiveConfigs?: SelectiveConfig[];
}

export interface EnvironmentConfigurationOptions {
  configSets: {
    [key: string]: ServiceConfig;
  };
}

export class EnvironmentConfiguration {
  private readonly originRegex =
    /^https:\/\/[\w-]+\.([\w]+)\.([\w]+)\.commerce\.ci-aldi\.com$/;
  private readonly origin: string;
  private environment = '';
  private region = '';
  private config = new Map<string, Config>();

  constructor(private options: EnvironmentConfigurationOptions) {
    this.origin = globalThis.location.origin;
    this.extractRegionAndEnvironment();
    this.generateConfig();
  }

  private extractRegionAndEnvironment(): void {
    const match = this.originRegex.exec(this.origin);
    if (match) {
      this.region = match[1];
      this.environment = match[2];
    }
  }

  private generateConfig(): void {
    const { configSets } = this.options;
    if (configSets) {
      for (const configSetName in configSets) {
        const { defaultConfig, selectiveConfigs } = configSets[configSetName];
        const selectiveConfig = selectiveConfigs
          ? this.getSelectiveConfig(selectiveConfigs)
          : undefined;

        const configSet = {
          ...(defaultConfig || {}),
          ...(selectiveConfig || {}),
        };

        const configKeys = Object.keys(configSet).filter(
          (key) => key !== 'environmentCondition',
        );

        for (const key of configKeys) {
          let value = configSet[key] as string;
          if (this.environment && typeof value === 'string') {
            value = value.replace('[ENVIRONMENT]', this.environment);
          }
          if (this.region && typeof value === 'string') {
            value = value.replace('[REGION]', this.region);
          }
          configSet[key] = value;
        }

        this.config.set(configSetName, configSet);
      }
    }
  }

  private getSelectiveConfig(
    selectiveConfigs: SelectiveConfig[],
  ): SelectiveConfig | undefined {
    const origin = this.origin;
    return selectiveConfigs.find((selectiveConfig: SelectiveConfig) => {
      return selectiveConfig.environmentCondition.test(origin);
    });
  }

  getConfig(configSetName: string): Config {
    const config = this.config.get(configSetName);
    if (!config) {
      throw new GetEnvironmentConfigError();
    }
    return config;
  }

  getConfigSafe(configSetName: string): Config | undefined {
    return this.config.get(configSetName);
  }

  getOrigin(): string {
    return this.origin;
  }
}
