import { eventBusServicePlugin } from '@/features/core/event-bus';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { DataFetchQueueServiceImplementation } from './data-fetch-queue-service';

export * from './data-fetch-queue-service';

export const dataFetchQueueServicePlugin =
  ProviderPluginFactory.create<DataFetchQueueServiceImplementation>({
    key: Symbol('DataFetchQueueService'),
    defaultFactory: {
      create: () => {
        return new DataFetchQueueServiceImplementation(
          storagePlugin.get(),
          eventBusServicePlugin.get(),
        );
      },
    },
  });
