<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    :fill="iconFill"
    :stroke="iconStroke"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <g :fill="iconFill" :stroke="iconStroke">
      <slot />
    </g>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    width: { type: [String, Number], default: 20 },
    height: { type: [String, Number], default: 20 },
    viewBox: { type: String, default: '0 0 20 20' },
    iconName: { type: String, default: 'box' },
    iconFill: { type: String, default: '#0d3a93' },
    iconStroke: { type: String, default: '#0d3a93' },
  },
});
</script>
