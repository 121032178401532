<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1739 16.6522C12.7217 16.6522 14.2348 16.1932 15.5218 15.3332C16.8088 14.4733 17.8119 13.251 18.4042 11.821C18.9966 10.391 19.1515 8.81741 18.8496 7.29929C18.5476 5.78118 17.8022 4.38671 16.7077 3.29221C15.6132 2.19771 14.2188 1.45235 12.7007 1.15038C11.1825 0.848408 9.60898 1.00339 8.17895 1.59573C6.74892 2.18807 5.52665 3.19116 4.66671 4.47815C3.80677 5.76514 3.34778 7.27824 3.34778 8.82609V9.4281"
      stroke="#0D3A93"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 7.26086L3.34783 9.60869L5.69565 7.26086"
      stroke="#0D3A93"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
