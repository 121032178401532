const MS_PER_MINUTE = 60000;

export function isDueSoonOrder(
  startTime: Date,
  pickingBuffer: number,
): boolean {
  return !(
    new Date().getTime() <
    new Date(startTime?.getTime() - pickingBuffer * MS_PER_MINUTE).getTime()
  );
}
