import { MultiPluginFactory } from '@/features/core/plugin';
import { dataFetchQueueEntityPlugin } from './entities';
import { dataFetchQueueServicePlugin } from './services';

export * from './entities';
export * from './services';
export * from './types';

export const dataFetchQueuePlugin = MultiPluginFactory.with({
  dataFetchQueueEntityPlugin,
  dataFetchQueueServicePlugin,
});
