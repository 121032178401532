import { BooleanNumber } from '@/features/core/storage';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { Order } from '../entities';
import { isOrderResponse, mergeOrderStatus } from '../helpers';
import type {
  EventList,
  OrderCheckIn,
  OrderParsePluginDto,
  OrderRaw,
  OrderResponse,
  UsedStorageZone,
  UsedStorageZoneResponse,
} from '../types';
import { OrderActionStatus, OrderLocalStatus } from '../types';
import type { DeliveryUnit } from '@/features/delivery-unit/types';
import { OrderMetadata } from '@/features/orderMetadata';

/**
 * Prepare order data from orderRaw
 **/
export class NormalizeOrderPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    const {
      orderReference,
      status,
      pickupCode,
      cartNote,
      actions,
      firstName,
      lastName,
      creationTime,
      events,
      storageZones,
      startTime,
      endTime,
      items,
      deliveryUnits,
    } = isOrderResponse(dataTransferObject.orderRaw)
      ? dataTransferObject.orderRaw.attributes
      : dataTransferObject.orderRaw;

    // Workaround for Driver App
    if (actions.length === 0 && status.toLowerCase() === 'out for delivery') {
      actions.push({ title: '', targetStatus: OrderActionStatus.handover });
    }

    const localStatus = mergeOrderStatus(
      actions,
      dataTransferObject.existingOrder,
    );

    dataTransferObject.order = Order.from({
      type: 'order',
      id: dataTransferObject.orderRaw.id,
      orderReference,
      status,
      pickupCode,
      cartNote,
      actions,
      customer: {
        firstName: firstName,
        lastName: lastName,
      },
      itemsCount: {
        total: 0,
        chiller: 0,
        fresh: 0,
        ambient: 0,
        freezer: 0,
      },
      creationTime,
      orderMetadata: dataTransferObject.orderRaw.id as unknown as OrderMetadata, // NOTE: See "FKs" keys in entity
      lastLocalStatusChange: null,
      events: this.getParsedEvents(events, dataTransferObject.existingOrder),
      storageZones: this.getParsedStorageZones(
        storageZones,
        localStatus,
        dataTransferObject.existingOrder,
      ),
      localStatus,
      startTime: startTime instanceof Date ? startTime : new Date(startTime),
      endTime: endTime instanceof Date ? endTime : new Date(endTime),
      checkIn: this.getParsedCheckIn(dataTransferObject.orderRaw),
      productSkus: this.getProductSKUs(dataTransferObject.orderRaw),
      deliveryUnits: this.getDeliveryUnits(
        deliveryUnits,
        dataTransferObject.existingOrder,
      ),
    });

    dataTransferObject.rawItems = items;

    return dataTransferObject;
  }

  private getProductSKUs(orderRaw: OrderRaw | OrderResponse) {
    if (!isOrderResponse(orderRaw)) {
      return orderRaw.productSkus;
    }

    const itemsSkus = orderRaw.attributes.items.map((item) => item.sku);
    return itemsSkus.filter(
      (itemSku, index) => itemsSkus.indexOf(itemSku) === index,
    );
  }

  private getParsedEvents(
    events: EventList[] | undefined,
    existingOrder: Order | undefined,
  ) {
    const eventsAsArray = events || [];

    return existingOrder ? existingOrder.events : eventsAsArray;
  }

  private getDeliveryUnits(
    deliveryUnits: DeliveryUnit[] | undefined,
    existingOrder: Order | undefined,
  ): DeliveryUnit[] {
    return existingOrder && existingOrder.deliveryUnits?.length
      ? existingOrder.deliveryUnits
      : deliveryUnits || [];
  }

  private getParsedStorageZones(
    storageZones: UsedStorageZone[] | UsedStorageZoneResponse[],
    localStatus: OrderLocalStatus,
    existingOrder: Order | undefined,
  ): UsedStorageZone[] {
    return localStatus === OrderLocalStatus.PickingInProgress && existingOrder
      ? existingOrder.storageZones
      : (storageZones as UsedStorageZone[]);
  }

  private getParsedCheckIn(orderRaw: OrderRaw | OrderResponse): OrderCheckIn {
    const checkIn = isOrderResponse(orderRaw)
      ? orderRaw.attributes.checkin
      : orderRaw.checkIn;

    const isCheckedIn = Boolean(checkIn);

    let orderCheckIn: OrderCheckIn = {
      isCheckedIn: isCheckedIn ? BooleanNumber.True : BooleanNumber.False,
    };

    if (checkIn) {
      orderCheckIn = {
        ...checkIn,
        ...orderCheckIn,
        timestamp: String(checkIn.timestamp),
      };
    }

    return orderCheckIn;
  }
}
