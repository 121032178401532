<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="#0D3A93"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    data-e2e="transition-spinner"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.63874 7.74995C6.21125 5.0263 9.06365 3.50112 12.0008 3.49841C12.415 3.49803 12.7505 3.16194 12.7501 2.74772C12.7497 2.33351 12.4136 1.99803 11.9994 1.99841C8.54887 2.00159 5.19074 3.79387 3.33971 6.99995C0.578283 11.7829 2.21703 17.8988 6.99996 20.6602C11.7829 23.4216 17.8988 21.7829 20.6602 17C21.3492 15.8066 21.7648 14.5285 21.9252 13.2396C21.9505 13.0367 21.9694 12.8337 21.9821 12.6306C21.9952 12.4203 22.0017 12.2101 22.0015 12.0002C22.0013 11.7705 21.9932 11.5413 21.9773 11.3129L21.7279 11.3303C21.0392 11.3783 20.5295 11.9778 20.476 12.6661C20.4659 12.7956 20.4528 12.925 20.4367 13.0543C20.3006 14.1478 19.9481 15.2334 19.3612 16.25C17.014 20.3154 11.8154 21.7084 7.74996 19.3612C3.68447 17.014 2.29153 11.8154 4.63874 7.74995Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
