import { ApiClient } from '@/features/core/api';
import { Tour, TourResponseItem } from '../types';
import { userServicePlugin } from '@/features/user';
import {
  DriverNotAssignedError,
  GetTourRequestError,
  isDriverNotAssignedError,
  PatchTourError,
} from '../errors';
import { ErrorHandler } from '@/features/core/errors';

export class TourApiClient {
  constructor(private api: ApiClient, private errorHandler?: ErrorHandler) {}

  async getTours(): Promise<Tour[]> {
    const user = await userServicePlugin.get().getUser();

    if (!user) {
      throw new Error('useUser: No User!');
    }

    const { data: toursResponse } = await this.api.client.get<{
      data: TourResponseItem[];
    }>(`/tours`, {
      innerErrorCode: GetTourRequestError.Code,
      useDeviceToken: false,
    });

    return (
      toursResponse.data?.map((tour) => ({
        id: tour.id,
        ...tour.attributes,
      })) ?? []
    );
  }

  async patchTours(tour: Tour): Promise<Tour> {
    const request = {
      data: {
        type: 'tours',
        attributes: {
          status: tour.status,
          statusChangedAt: tour.statusChangedAt,
        },
      },
    };
    await this.api.client
      .patch<{
        data: {
          attributes: {
            status: string;
            statusChangedAt: string | null;
          };
        };
      }>(`/tours/${tour.reference}`, request, {
        disableErrorHandling: true,
        innerErrorCode: PatchTourError.Code,
      })
      .catch((error) => {
        let errorObject;

        if (isDriverNotAssignedError(error)) {
          errorObject = new DriverNotAssignedError();
          this.errorHandler?.handle(errorObject);
          errorObject.handled = true;
        } else {
          errorObject = new PatchTourError();
        }

        return Promise.reject(errorObject);
      });

    return tour;
  }
}
