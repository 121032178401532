import { apiPlugin } from '@/features/core/api';
import { errorPlugin } from '@/features/core/errors';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { SurveyApiClient } from './survey-api-client';
import { registerSurveyApi } from './surveyRegister';

export * from './survey-api-client';
export * from './surveyRegister';

export const surveyApiClientPlugin =
  ProviderPluginFactory.create<SurveyApiClient>({
    key: Symbol('SurveyApiClient'),
    defaultFactory: {
      create: () => new SurveyApiClient(apiPlugin.get(), errorPlugin.get()),
    },
  });

export const surveyApiRegistryPlugin = ExecPluginFactory.fn(() => {
  registerSurveyApi(
    onlineEntityRepositoryRegistryPlugin.get(),
    surveyApiClientPlugin.get(),
  );
});
