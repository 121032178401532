import { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';
import { SurveyAnswerItem } from '../entities';
import { SurveyApiClient } from './survey-api-client';

export const registerSurveyApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  surveyApi: SurveyApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(SurveyAnswerItem, {
    save: async (entity: SurveyAnswerItem): Promise<SurveyAnswerItem> =>
      surveyApi.postDropSurveyAnswers(entity),
  });
};
