import { SomethingWrongError } from '@/features/core/errors/error-classes';

export class NoActiveDropError extends SomethingWrongError {
  static Code = 206 as const;
}

export class NotExpectedDeliveryUnitFormatError extends SomethingWrongError {
  static Code = 207 as const;
}

export class DeliveryUnitNotFoundError extends SomethingWrongError {
  static Code = 209 as const;
}
