import { ExecPluginFactory } from '@/features/core/plugin';
import { storageSetupPlugin } from '@/features/core/storage';
import { SurveyAnswerStorageSetup, SurveyAnswerItem } from './surveyAnswer';

export * from './surveyAnswer';

export const surveyAnswerEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin
    .get()
    .add(SurveyAnswerItem, new SurveyAnswerStorageSetup());
});
