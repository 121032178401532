import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { Order } from '../entities/order';
import { OrdersService } from '../services';

/**
 * Save order
 */

export class SaveOrderPlugin<T extends { order: Order }>
  implements PipelinePlugin<T>
{
  constructor(
    private ordersService: OrdersService,
    private scheduled: boolean = true,
    private waitForSync: boolean = false,
  ) {}

  public async execute(dto: T): Promise<T> {
    await this.ordersService.saveOrder(
      dto.order,
      this.scheduled,
      this.waitForSync,
    );
    return dto;
  }
}
