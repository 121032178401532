import { AuthService } from '@/features/core/auth';
import { Storage } from '@/features/core/storage';
import { User } from '../entities';

export class UserHelperService {
  constructor(private storage: Storage, private authService: AuthService) {}

  async getUser(): Promise<User | undefined> {
    const merchantReference = await this.authService.getMerchantReference();

    if (!merchantReference) {
      throw new Error('UserHelperService: User is not logged in!');
    }

    const user = await this.storage.getById(User, {
      id: merchantReference,
    });

    return user;
  }
}
