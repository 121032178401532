import { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderPluginDto } from '../types';

export class SetEventsToPatchPlugin implements PipelinePlugin<OrderPluginDto> {
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    dataTransferObject.order.events.forEach(
      (event) => (event.isPatchedToBackEnd = true),
    );
    return dataTransferObject;
  }
}
