import { toRaw } from 'vue';

export function toRawRecursive<T>(observed: T): T {
  const obj = toRaw(observed);

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const valueRef = obj[i] as T;
      const value = toRawRecursive(valueRef);
      if (value !== valueRef) {
        obj[i] = value;
      }
    }
  } else if (typeof obj === 'object') {
    for (const key in obj) {
      const valueRef = obj[key];
      const value = toRawRecursive(valueRef);
      if (value !== valueRef) {
        obj[key] = value;
      }
    }
  }

  return obj;
}
