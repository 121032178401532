import { Storage } from '@/features/core/storage';
import { StorageZoneApiClient } from '..';
import { StorageZone } from '../entities';

export class StorageZoneService {
  constructor(
    private storage: Storage,
    private storageZoneApi: StorageZoneApiClient,
  ) {}

  async fetchAllStorageZones(): Promise<StorageZone[]> {
    await this.storage.removeAll(StorageZone);
    const zones = await this.storageZoneApi.getAll();

    const savedZones = await this.storage.bulkSave(zones);

    return savedZones;
  }

  async clearAllStorageZones(): Promise<void> {
    await this.storage.removeAll(StorageZone);
  }
}
