import { ApiClient, Response } from '@/features/core/api';
import { AuthService } from '@/features/core/auth';
import { ConfigurationService } from '@/features/configuration';
import { Product } from '../entities';
import { ProductResponseItem } from '../types';
import {
  GetAllProductsRequestError,
  GetProductBySkuRequestError,
} from '../errors';
import { parseConfigurationBags } from '@/features/orders/helpers/parseConfigurationBags';

export class ProductApiClient {
  constructor(
    private api: ApiClient,
    private authService: AuthService,
    private configurationService: ConfigurationService,
  ) {}

  private availableBagsSkus: string[] = [];

  private parseProduct(product: ProductResponseItem) {
    const {
      id,
      sku,
      productName,
      temperatureClass,
      barcodes,
      ageRestriction,
      rwpType,
      pickingOrder,
      categoryUrlSlugText,
      unit,
      maxQuantity,
      image,
      serviceType,
      price,
    } = product.attributes;

    return Product.from({
      id,
      sku,
      productName,
      temperatureClass,
      barcodes,
      image,
      ageRestricted: ageRestriction,
      rwpType,
      pickingOrder,
      categoryUrlSlugText,
      unit,
      maxQuantity,
      isBag: this.isBagProduct(sku),
      noImage: !image,
      serviceType,
      price,
    });
  }

  private isBagProduct(sku: string): boolean {
    return this.availableBagsSkus.includes(sku);
  }

  private async setAvailableBagsSkus(): Promise<void> {
    if (this.availableBagsSkus.length) {
      return;
    }
    const configurationBags = await this.configurationService.getFeatureOption(
      'bags',
      'availableBags',
      'string',
    );
    this.availableBagsSkus = parseConfigurationBags(configurationBags).map(
      (bag) => bag.sku,
    );
  }

  private async parseProducts(
    products: ProductResponseItem[],
  ): Promise<Product[]> {
    await this.setAvailableBagsSkus();
    const parsedProducts = products.map((product) => {
      return this.parseProduct(product);
    });

    return parsedProducts ?? [];
  }

  async getAll(): Promise<Product[]> {
    const merchantReference = await this.authService.getMerchantReference();
    const productsResponse = await this.api.client.get<
      Response<ProductResponseItem[]>
    >(`/merchants/${String(merchantReference)}/products`, {
      innerErrorCode: GetAllProductsRequestError.Code,
    });

    return this.parseProducts(productsResponse.data.data);
  }

  async getBySkus(productSkus: string[]): Promise<Product[]> {
    const merchantReference = await this.authService.getMerchantReference();
    // TODO: Fetch products by SKUs once API supports it
    const productsResponse = await this.api.client.get<
      Response<ProductResponseItem[]>
    >(`/merchants/${String(merchantReference)}/products`, {
      innerErrorCode: GetProductBySkuRequestError.Code,
    });
    // TODO: Remove filtering when fetched by SKUs
    const filteredProductsBySku = productsResponse.data.data.filter(
      (product) => {
        return productSkus.includes(product.attributes.sku);
      },
    );

    return this.parseProducts(filteredProductsBySku);
  }

  async getByIds(productSkus: string[]): Promise<Product[]> {
    const merchantReference = await this.authService.getMerchantReference();
    const idsList = productSkus.join(',');
    const productsResponse = await this.api.client.get<
      Response<ProductResponseItem[]>
    >(
      `/merchants/${String(
        merchantReference,
      )}/products?filter[product.ids]=${idsList}`,
    );

    return this.parseProducts(productsResponse.data.data);
  }
}
