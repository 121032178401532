import { MultiPluginFactory } from '@/features/core/plugin';
import { userReportsApiClientPlugin } from './api';
import { userReportsServiceClientPlugin } from './services';

export * from './api';
export * from './composables';
export * from './services';
export * from './types';

export const userReportsPlugin = MultiPluginFactory.with({
  userReportsApiClientPlugin,
  userReportsServiceClientPlugin,
});
