import { ApiClient, Response } from '@/features/core/api';
import { ResponseStorageZone } from '../types';
import { StorageZone } from '../entities';
import { AuthService } from '@/features/core/auth';
import { GetAllStorageZonesRequestError } from '../errors';

export class StorageZoneApiClient {
  constructor(private api: ApiClient, private authService: AuthService) {}

  async getAll(): Promise<StorageZone[]> {
    const merchantReference = await this.authService.getMerchantReference();

    const storageResponse = await this.api.client.get<
      Response<ResponseStorageZone[]>
    >(`/merchants/${String(merchantReference)}/storage-zones`, {
      innerErrorCode: GetAllStorageZonesRequestError.Code,
    });

    const storages = storageResponse.data.data.map((storageZone, i) =>
      StorageZone.from({
        id: storageZone.id,
        title: storageZone.attributes?.title,
        temperatureClasses: storageZone.attributes?.temperatureClasses,
        sortIndex: i,
      }),
    );

    return storages ?? [];
  }
}
