import { Entity, EntityType } from '@/features/core/entity-repository';
import { Order } from '@/features/orders/entities';
import { Product } from '@/features/products/entities';
import { ServiceWorkerEventTypes } from '@/features/service-worker';
import { StorageZone } from '@/features/storageZone';
import { NotificationQueue } from '../helpers/notification-queue';
import { DeepReadonly } from 'vue';

export { PushProviderError } from './PushProviderError';
export { PushProviderPermissionError } from './PushProviderPermissionError';

export type FirebaseRegistrationId = string;

export interface PushService {
  init(): void;

  unSubscribe(): Promise<boolean> | boolean;

  getProvider(): PushProvider | null;
}

export interface PushPlugin {
  initialize(): void;

  registration(): Promise<FirebaseRegistrationId>;
}

export interface PushProvider {
  init(): void;

  getToken(): Promise<string>;

  deleteToken(): Promise<boolean>;
}

export interface EntityTypeMap {
  [key: string]: EntityType<Order | Product | StorageZone>;
}

export interface SWNotificationEvent {
  entity: string;
  action?: string;
  actions?: string;
  ids?: string;
  type: ServiceWorkerEventTypes;
  data?: unknown;
  eventName?: string;
  fcmMessageId?: string;
}

export enum entityType {
  products = 'products',
  orders = 'orders',
}

export enum NotificationType {
  CheckIn = 'checkin',
  SameDateOrders = 'same-date-orders',
  DueSoonOrders = 'due-soon-orders',
  FailedPushHandle = 'failed-push-handle',
}

export enum NotificationTags {
  CheckIn = 'picking-app-check-in-{ID}',
  SameDateOrder = 'picking-app-same-date-order-{ID}',
  DueSoonOrder = 'picking-app-due-soon-order-{ID}',
}

export enum PushTypes {
  Orders = 'orders',
  Products = 'products',
  StorageZones = 'storage-zones',
}

export const entityMap: EntityTypeMap = {
  [PushTypes.Orders]: Order,
  [PushTypes.Products]: Product,
  [PushTypes.StorageZones]: StorageZone,
};

export const pushApiType = 'web';

export const failedPushHandleTag = 'failed-push-handle';

//src/Pyz/Zed/OrderStatus/OrderStatusConfig.php::getItemStateNameOrderStatusMapping
export enum OrderItemPushEvents {
  CheckedInCodeCleared = 'check-in code cleared',
  PickingIsInProgress = 'picking is in progress',
}

//There are a lot of order item states, which are sent, but not handled
type UnhandledOrderItemPushEvents = Exclude<string, OrderItemPushEvents>;

export interface OrderItemPushEventRecords {
  [id: string]: OrderItemPushEvents | UnhandledOrderItemPushEvents;
}

export interface OrderItemPushEventTimestampRecords {
  [id: string]: string;
}

// Defined for type assertion on PushEvent.data
export interface PushEventData {
  data: PushNotificationDTO['eventData'];
  fcmMessageId?: string;
}

export interface PushNotificationDTO {
  canChangeRetryAmount: boolean;
  eventData: {
    entity: string | undefined;
    ids: string[] | string | undefined;
    action: string | undefined;
    fcmMessageId: string | undefined;
    syncTag?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventRaw: any;
    requestTimestamp: string | undefined;
    receivedTimestamp: string;
    eventNames: string | undefined; //Only undefined if eventData.entity is not 'orders'
    eventTimestamps: string | undefined; //Only undefined if eventData.entity is not 'orders'
  };
  eventType?: string;
  rawEntityType?: string;
  entityType?: EntityType<Entity>;
  ids: string[];
  action?: string;
  notifications: NotificationQueue;
  clientNotifications: ClientNotification[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interceptor?: any;
  fcmMessageId?: string;
  entities: Entity[];
  storedEntities: Entity[];
  eventNames: OrderItemPushEventRecords;
  eventTimestamps: OrderItemPushEventTimestampRecords;
  notificationDelay?: string;
  timestamps: {
    id: string;
    timestamp: string;
  }[];
}

export interface NativeNotificationPayload {
  body?: string;
  icon?: string;
  badge?: string;
  tag: NotificationTags | string;
  data: {
    route: string;
  };
}

export interface NativeNotification {
  readonly payload: DeepReadonly<NativeNotificationPayload>;
  readonly title: string;
  ids: string[];
  readonly type: NotificationType;
}

export interface ClientNotification {
  type: ServiceWorkerEventTypes;
}
