import { SomethingWrongError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [GetDriverNotificationsError.Code]: 'GetDriverNotificationsError';
    [MarkDriverNotificationAsReadError.Code]: 'MarkDriverNotificationAsReadError';
  }
}

export class GetDriverNotificationsError extends SomethingWrongError {
  static Code = 50 as const;
}

export class MarkDriverNotificationAsReadError extends SomethingWrongError {
  static Code = 51 as const;
}
