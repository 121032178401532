import { SomethingWrongError } from '@/features/core/errors';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [GetTourDocumentApiError.Code]: 'GetTourDocumentApiError';
    [DecodeTourDocumentError.Code]: 'DecodeTourDocumentError';
    [NoTourReferenceProvidedError.Code]: 'NoTourReferenceProvidedError';
  }
}

export class GetTourDocumentApiError extends SomethingWrongError {
  static Code = 70 as const;
}

export class DecodeTourDocumentError extends SomethingWrongError {
  static Code = 71 as const;
}

export class NoTourReferenceProvidedError extends SomethingWrongError {
  static Code = 72 as const;
}
