<template>
  <Dialog
    :data-e2e="`order-start-confirmation-popup-${order.id}`"
    :visible="isVisible"
    :title-text="title"
    :confirm-text="confirmButtonText"
    :cancel-text="$t('components.start-confirmation-popup.cancel-button.text')"
    @confirmed="emit('confirm', order)"
    @canceled="emit('cancel')"
    isSpaceToContentExist
    full-width
    :isTitleCentered="false"
  >
    <div v-if="order">
      <template v-if="isPicking">
        <div>
          {{
            $t('components.start-confirmation-popup.order-id.text', {
              orderReference: order.orderReference,
            })
          }}
        </div>
        <div>
          {{
            $t('components.start-confirmation-popup.articles.text', {
              itemsLength: order.items.length,
              itemsCountTotal: order.itemsCount.total,
            })
          }}
        </div>
        <div>
          {{
            $t('components.start-confirmation-popup.pickup-time.text', {
              startTime: formatHours(order.startTime),
            })
          }}
        </div>
      </template>
      <template v-else>
        <div>
          {{
            $t('components.start-confirmation-popup.pickup-code.text', {
              pickupCode: order.pickupCode,
            })
          }}
        </div>
        <div>
          {{
            $t('components.start-confirmation-popup.total-bags.text', {
              totalBags,
            })
          }}
        </div>
      </template>
      <div class="red-text mt-24">
        {{ $t('components.start-confirmation-popup.cannot-abort.text') }}
      </div>
    </div>
  </Dialog>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { Dialog } from '@/features/ui/components';
import { formatHours } from '@/composables/useDateFormat';
import { $t } from '@/i18n';
import { Order } from '../entities';
import { isOrderPicking } from '../helpers';
import { OrderLocalStatus } from '../types';

export default defineComponent({
  name: 'StartConfirmationPopup',
  components: {
    Dialog,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isPicking = computed(() => isOrderPicking(props.order));
    const title = computed(() =>
      isPicking.value
        ? $t(
            'components.start-confirmation-popup.start-picking-confirmation.text',
          )
        : $t(
            'components.start-confirmation-popup.start-handover-confirmation.text',
          ),
    );
    const confirmButtonText = computed(() => {
      if (props.order.localStatus === OrderLocalStatus.PickingInProgress) {
        return $t('components.start-confirmation-popup.continue-picking.text');
      }

      return isPicking.value
        ? $t('components.start-confirmation-popup.start-picking.text')
        : $t('components.start-confirmation-popup.start-handover.text');
    });
    const totalBags = computed(() => {
      return props.order
        ? props.order.bags.reduce((acc, bag) => {
            return (acc += bag.quantity);
          }, 0)
        : 0;
    });
    return {
      emit,
      formatHours,
      isPicking,
      title,
      confirmButtonText,
      totalBags,
    };
  },
});
</script>
