import { RepositoryReadOptions } from '@/features/core/entity-repository';
import { Storage } from '@/features/core/storage';
import { OrderMetadata } from '../entities';
import { OrderMetadataService } from '../types';
import { Order } from '@/features/orders/entities';

export class OrderMetadataServiceImplementation
  implements OrderMetadataService
{
  constructor(private storage: Storage) {}

  async save(orderMetadata: OrderMetadata): Promise<void> {
    await this.storage.save(orderMetadata);
  }

  async bulkSave(orderMetadata: OrderMetadata[]): Promise<void> {
    await this.storage.bulkSave(orderMetadata);
  }

  async getById(id: string): Promise<OrderMetadata | undefined> {
    return await this.storage.getById(OrderMetadata, {
      id,
    });
  }

  async getAll(options: RepositoryReadOptions = {}): Promise<OrderMetadata[]> {
    return await this.storage.getAll(OrderMetadata, options);
  }

  async removeById(id: string): Promise<void> {
    await this.storage.remove(
      OrderMetadata.from({
        id: id,
      }),
    );
  }

  async removeByIds(ids: string[]): Promise<void> {
    await this.storage.removeSeveral(OrderMetadata, { ids });
  }

  async clearAll(): Promise<void> {
    await this.storage.removeAll(OrderMetadata);
  }

  async checkAndSetOrdersMetadata(orders: Order[]): Promise<void> {
    const metadataIds = (await this.getAll())?.map(
      (orderMetadata) => orderMetadata.id,
    );

    let newOrders = orders;
    if (metadataIds?.length) {
      newOrders = orders.filter((order) => !metadataIds.includes(order.id));
    }

    if (newOrders.length) {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const ordersMetadata = newOrders.map((order) => {
        let isSeen = true;
        if (order.creationTime) {
          const orderCreationDate = new Date(order.creationTime).setHours(
            0,
            0,
            0,
            0,
          );
          isSeen = currentDate !== orderCreationDate;
        }
        return OrderMetadata.from({ id: order.id, seen: isSeen });
      });
      await this.bulkSave(ordersMetadata);
    }
  }

  isOrderUnseen(metadata: OrderMetadata): boolean {
    return (
      !metadata.seen ||
      (!!metadata.showAsUnseenUntil && new Date() < metadata.showAsUnseenUntil)
    );
  }
}
