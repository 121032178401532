<template>
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.83238 0.350098C5.03129 0.350098 5.22206 0.429115 5.36271 0.569768L9.60535 4.81241C9.82259 5.02965 9.88537 5.35741 9.76379 5.63956L8.66918 8.17959L13.9987 13.5091C14.5845 14.0949 14.5845 15.0446 13.9987 15.6304L7.2487 8.88043C7.03146 8.66319 6.96868 8.33542 7.09026 8.05328L8.18487 5.51324L4.83238 2.16076L3.59489 3.39824C1.93517 5.05797 1.93517 7.74892 3.59489 9.40865L12.0802 17.8939C13.7399 19.5537 16.4309 19.5537 18.0906 17.8939L19.328 16.6565L16.6759 14.0044C16.0902 13.4186 16.0902 12.4688 16.6759 11.883L20.919 16.1261C21.2119 16.419 21.2119 16.8939 20.919 17.1868L19.1512 18.9546C16.9057 21.2001 13.265 21.2001 11.0195 18.9546L2.53423 10.4693C0.288719 8.2238 0.288719 4.5831 2.53423 2.33758L4.30205 0.569768C4.4427 0.429115 4.63347 0.350098 4.83238 0.350098Z"
    />
  </svg>
</template>
