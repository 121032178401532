<template>
  <svg
    width="25"
    viewBox="0 0 25 24"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.1385 7.74995C6.71101 5.0263 9.56341 3.50112 12.5005 3.49841C12.9148 3.49803 13.2502 3.16194 13.2499 2.74772C13.2495 2.33351 12.9134 1.99803 12.4992 1.99841C9.04863 2.00159 5.6905 3.79387 3.83946 6.99995C1.07804 11.7829 2.71679 17.8988 7.49972 20.6602C12.2826 23.4216 18.3985 21.7829 21.16 17C21.8489 15.8066 22.2646 14.5285 22.425 13.2396C22.4503 13.0367 22.4692 12.8337 22.4819 12.6306C22.495 12.4203 22.5014 12.2101 22.5013 12.0002C22.5011 11.7705 22.493 11.5413 22.477 11.3129L22.2276 11.3303C21.539 11.3783 21.0293 11.9778 20.9757 12.6661C20.9657 12.7956 20.9526 12.925 20.9365 13.0543C20.8004 14.1478 20.4478 15.2334 19.8609 16.25C17.5137 20.3154 12.3152 21.7084 8.24972 19.3612C4.18423 17.014 2.79129 11.8154 5.1385 7.74995Z"
      stroke="none"
    />
  </svg>
</template>
