export const cacheReset = async (): Promise<void> => {
  const keys = await caches.keys();
  for (const key of keys) {
    await caches.delete(key);
  }

  await navigator.serviceWorker.ready.then(async (registration) => {
    await registration.update();
  });

  window.location.reload();
};
