const CI_REGEX = /https:\/\/driver-app.[\w]+.[\w]+.commerce.ci-aldi.com/;

export const configSets = {
  tourDocument: {
    defaultConfig: {
      apiUrl:
        process.env.VUE_APP_TOUR_DOCUMENT_DEFAULT_API_ENDPOINT ??
        'https://mock.us.demo.commerce.ci-aldi.com/driver-app/tour-document/',
    },
    selectiveConfigs: [
      {
        environmentCondition:
          /https:\/\/driver-app.\w+.\w+.commerce.ci-aldi.com/,
        apiUrl: '/ma/tour-document/',
      },
    ],
  },
  'health-monitoring': {
    defaultConfig: {
      isActive: false,
      monitoringIntervalInSeconds: 60,
    },
    selectiveConfigs: [
      {
        environmentCondition: CI_REGEX,
        isActive: true,
        monitoringIntervalInSeconds: 60,
      },
    ],
  },
  'open-sync-monitor': {
    defaultConfig: {
      isActive: false,
      monitoringIntervalInMin: 1,
    },
    selectiveConfigs: [
      {
        environmentCondition: CI_REGEX,
        isActive: true,
        monitoringIntervalInMin: 1,
      },
    ],
  },
};
