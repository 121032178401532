import { apiPlugin } from '@/features/core/api';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { DriverNotificationsApiClient } from './driver-notification';

export * from './driver-notification';

export const driverNotificationsApiPlugin =
  ProviderPluginFactory.create<DriverNotificationsApiClient>({
    key: Symbol('DriverNotificationsApiClient'),
    defaultFactory: {
      create: () => new DriverNotificationsApiClient(apiPlugin.get()),
    },
  });
