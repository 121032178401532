<template>
  <Dialog
    :data-e2e="`order-agerestricted-popup-${order.id}`"
    :visible="isVisible"
    :content-text="$t('components.age-confirmation-popup.content.text')"
    :title-text="$t('components.age-confirmation-popup.title.text')"
    :confirm-text="$t('components.age-confirmation-popup.confirm.text')"
    :cancel-text="$t('components.age-confirmation-popup.cancel.text')"
    @confirmed="emit('confirm', order)"
    @canceled="emit('cancel')"
    isSpaceToContentExist
    full-width
    :isTitleCentered="false"
  />
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Dialog } from '@/features/ui/components';
import { formatHours } from '@/composables/useDateFormat';
import { Order } from '../entities';

export default defineComponent({
  name: 'StartConfirmationPopup',
  components: {
    Dialog,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup(props, { emit }) {
    return { emit, formatHours };
  },
});
</script>
