import { computed, ref } from 'vue';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { useDeviceInfo } from '@/features/device-id';
import { useUser } from '@/features/user';
import { $t } from '@/i18n';
import { userReportsServiceClientPlugin } from '../services';
import { DESCRIPTION_MAX_LENGTH, UserReport, UseUserReports } from '../types';

const processing = ref(false);
const description = ref('');

export function useUserReport(): UseUserReports {
  const hasError = computed(
    () =>
      !description.value || description.value.length > DESCRIPTION_MAX_LENGTH,
  );

  const resetAll = () => {
    description.value = '';
  };

  const getSubject = async () => {
    const { deviceId } = useDeviceInfo();
    const { isDriver } = useUser();
    return [
      `${(await isDriver()) ? 'Driver' : 'Picker'} report`,
      deviceId.value,
      process.env.VUE_APP_VERSION,
    ]
      .filter(Boolean)
      .map((s) => `[${s}]`)
      .join(' ');
  };

  const handleSubmit = async () => {
    if (hasError.value) return;
    processing.value = true;
    try {
      const userReport: UserReport = {
        subject: await getSubject(),
        description: description.value,
      };
      await postReports(userReport);
      resetAll();
      loggerServicePlugin.get().info('Problem report was sent successfully');
      notificationPlugin.get().show({
        text: $t('pages.user-report.report-sent-notification.text'),
        type: NotificationType.Success,
      });
      router.get().back();
    } catch (error) {
      errorPlugin.get().handle(error);
    }
    processing.value = false;
  };

  const postReports = async (userReport: UserReport): Promise<void> => {
    return await userReportsServiceClientPlugin.get().postReports(userReport);
  };

  return {
    description,
    handleSubmit,
    hasError,
    processing,
  };
}
