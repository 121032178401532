import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import { apiPlugin } from '@/features/core/api';
import { DeviceApiClient } from './device-api';
import { registerDeviceApi } from './device-api-register';

export * from './device-api';

export const deviceApiPlugin = ProviderPluginFactory.create<DeviceApiClient>({
  key: Symbol('DeviceApiClient'),
  defaultFactory: {
    create: () => {
      return new DeviceApiClient(apiPlugin.get());
    },
  },
});

export const deviceApiRegistryPlugin = ExecPluginFactory.fn(() => {
  const deviceApi = deviceApiPlugin.get();
  registerDeviceApi(onlineEntityRepositoryRegistryPlugin.get(), deviceApi);
});
