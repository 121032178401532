import { SomethingWrongError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [GetMerchantByIdRequestError.Code]: 'GetUserByIdRequestError';
  }
}

export class GetMerchantByIdRequestError extends SomethingWrongError {
  static Code = 28 as const;
}
