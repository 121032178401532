import { ProviderPluginFactory } from '@/features/core/plugin';
import { userReportsApiClientPlugin } from '../api';
import { UserReportsServiceClient } from './user-reports-service';

export * from './user-reports-service';

export const userReportsServiceClientPlugin =
  ProviderPluginFactory.create<UserReportsServiceClient>({
    key: Symbol('UserReportsServiceClient'),
    defaultFactory: {
      create: () =>
        new UserReportsServiceClient(userReportsApiClientPlugin.get()),
    },
  });
