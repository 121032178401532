<template>
  <notifications position="bottom right" width="">
    <template v-slot:body="{ item, close }">
      <div data-e2e="error-notification" class="base-notification">
        <div
          class="base-notification__wrapper"
          :class="`base-notification__wrapper--${item.type}`"
        >
          <component
            class="base-notification__icon"
            width="30"
            :is="getNotifyIconName(item.type)"
          />
          <span
            class="base-notification__text"
            data-e2e="base-notification-text"
          >
            {{ item.text }}
            <a
              href=""
              @click.prevent="item.data.link.onClick"
              v-if="item.data.link"
            >
              {{ item.data.link.text }}
            </a>
          </span>
          <button
            class="base-notification__close-btn"
            @click="close"
            data-e2e="notification-close-button"
          >
            <BaseIcon
              viewBox="0 0 24 24"
              width="24"
              height="24"
              :iconStroke="getNotifyIconColor(item.type)"
              :iconFill="getNotifyIconColor(item.type)"
            >
              <NotifyCancelIcon />
            </BaseIcon>
          </button>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { NotificationType } from '@/features/core/notifications';
import BaseIcon from '@/features/ui/icons/BaseIcon.vue';
import NotifyCancelIcon from '@/features/ui/icons/NotifyCancelIcon.vue';
import NotifyErrorIcon from '@/features/ui/icons/NotifyErrorIcon.vue';
import NotifyInfoIcon from '@/features/ui/icons/NotifyInfoIcon.vue';
import NotifySuccessIcon from '@/features/ui/icons/NotifySuccessIcon.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    BaseIcon,
    NotifyCancelIcon,
    NotifyErrorIcon,
    NotifyInfoIcon,
    NotifySuccessIcon,
  },
  setup() {
    const getNotifyIconName = (notifyType: NotificationType): string => {
      switch (notifyType) {
        case NotificationType.Info:
          return 'NotifyInfoIcon';
        case NotificationType.Success:
          return 'NotifySuccessIcon';
        default:
          return 'NotifyErrorIcon';
      }
    };

    const getNotifyIconColor = (notifyType: NotificationType): string => {
      switch (notifyType) {
        case NotificationType.Info:
          return '#0D3A93';
        case NotificationType.Success:
          return '#258900';
        default:
          return '#E00016';
      }
    };

    return { getNotifyIconName, getNotifyIconColor };
  },
});
</script>

<style lang="scss" scoped>
.base-notification {
  display: flex;
  justify-content: flex-end;

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 0 8px 8px 17px;
    max-width: 340px;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(113, 119, 126, 0.15);

    &--info {
      background-color: #b3d7ef;
    }

    &--success {
      background-color: #ddeabe;
    }

    &--error {
      background-color: #f3b3b3;
    }
  }

  &__icon {
    min-width: 30px;
  }

  &__text {
    padding: 2px 8px 0;
  }

  &__close-btn {
    display: flex;
    padding: 0;
    margin-left: auto;
    background: none;
    border: none;
    cursor: pointer;
  }
}
</style>
