import { SomethingWrongError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [GetAllProductsRequestError.Code]: 'GetAllProductsRequestError';
    [GetProductBySkuRequestError.Code]: 'GetProductBySkuRequestError';
  }
}

export class GetAllProductsRequestError extends SomethingWrongError {
  static Code = 19 as const;
}

export class GetProductBySkuRequestError extends SomethingWrongError {
  static Code = 20 as const;
}
