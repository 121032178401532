import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewStorageZone extends RestorableEntity implements NewEntity {
  type = 'storageZone';
}

export class StorageZone extends NewStorageZone implements Entity {
  id!: string;
  title!: string;
  temperatureClasses!: Array<string>;
  sortIndex!: number;
}

export class StorageZoneStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.StorageZone_1).stores({ [storeName]: '&id,sortIndex' });

    return Promise.resolve();
  }
}
