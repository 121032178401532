import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewSurveyAnswer extends RestorableEntity implements NewEntity {
  type = 'surveyAnswer';
}
export class SurveyAnswerItem extends NewSurveyAnswer implements Entity {
  id!: string;
  surveyId!: number;
  dropReference!: string;
  answers!: Record<string, string>;
}

export class SurveyAnswerStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.SurveyAnswer_1).stores({
      [`${storeName}`]: '&id, surveyId, dropReference, answers',
    });

    return Promise.resolve();
  }
}
