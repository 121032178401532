import { ExecPluginFactory } from '@/features/core/plugin';
import { storageSetupPlugin } from '@/features/core/storage';
import { DataFetchQueue, DataFetchQueueStorageSetup } from './dataFetchQueue';

export * from './dataFetchQueue';

export const dataFetchQueueEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin
    .get()
    .add(DataFetchQueue, new DataFetchQueueStorageSetup());
});
