import { nonNullable } from '@/utils/helpers/non-nullable';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderParsePluginDto } from '@/features/orders/types';

/**
 * Add defined actionStatuses to the order
 **/
export class DefineActionStatusesAttributePlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    dataTransferObject.order.actionStatuses = dataTransferObject.order.actions
      .map((action) => action.targetStatus)
      .filter(nonNullable);

    return dataTransferObject;
  }
}
