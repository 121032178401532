import { BaseError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [LoggingMessagesRequestError.Code]: 'LoggingMessagesRequestError';
  }
}

export class LoggingMessagesRequestError extends BaseError {
  static Code = 29 as const;
}
