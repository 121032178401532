import { HandledError } from '@/features/core/errors';
import * as axios from 'axios';
import { AxiosAdapter, AxiosError } from 'axios';
import { ErrorHandler, HttpGeneratedError } from '../errors';
import { OauthConfig } from '@/features/oauth/types';

export interface BaseApiClientOptions {
  baseURL: string;
  withCredentials?: boolean;
  config?: OauthConfig;
  timeout?: number;
  headers?: { [key: string]: string };
}
export interface Response<T> {
  data: T;
}

export class BaseApiClient {
  client: axios.AxiosInstance;

  constructor(
    public options: BaseApiClientOptions,
    protected errorHandler: ErrorHandler,
    private adapter?: AxiosAdapter,
  ) {
    this.client = axios.default.create({
      baseURL: this.options.baseURL,
      timeout: this.options.timeout,
      withCredentials: this.options.withCredentials || false,
      headers: {
        'Content-Type': 'application/json',
        ...this.options.headers,
      },
      adapter: this.adapter,
    });

    this.client.interceptors.response.use(
      undefined,
      this.onResponseError.bind(this),
    );
  }

  private onResponseError<T>(error: AxiosError): Promise<AxiosError | void> {
    error.config = {
      innerErrorCode: error.config?.innerErrorCode,
      disableErrorHandling: error.config?.disableErrorHandling,
      url: error.config?.url,
      method: error.config?.method,
      data: error.config?.data as T,
      baseURL: error.config?.baseURL,
    };
    if (error.config?.disableErrorHandling) return Promise.reject(error);
    this.errorHandler.handle(
      new HttpGeneratedError(error.config?.innerErrorCode, error),
    );
    return Promise.reject(new HandledError(error));
  }
}
