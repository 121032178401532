<template>
  <div
    class="product-box"
    v-if="temperatureClassList && temperatureClassList.length"
  >
    <div
      v-for="type in temperatureClassList"
      :key="type.name + type.label"
      class="product-box__item"
    >
      <ProductItem
        v-if="type && type.label && countOfProduct(type.storageZoneId) > 0"
        :id="id"
        :name="type.label"
        :count="countOfProduct(type?.storageZoneId)"
      >
        <span class="icons">
          <ProductIcons
            v-for="classListItem in type.temperatureClassesList"
            :key="type.name + type.label + classListItem"
            :name="classListItem"
          />
        </span>
      </ProductItem>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { capitalizeFirstLetter } from '@/utils/helpers/capitalizeFirstLetter';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { UsedStorageZone } from '@/features/orders';
import { StorageZone } from '@/features/storageZone';
import { basicHandoverTypes, TemperatureClass } from '../types';
import ProductIcons from './ProductIcons.vue';
import ProductItem from './ProductItem.vue';

export default defineComponent({
  components: { ProductItem, ProductIcons },
  props: {
    id: String,
    bagsList: {
      type: Array as PropType<UsedStorageZone[]>,
      default: () => {
        return [];
      },
    },
  },
  setup: function (props) {
    const bagsIds = props.bagsList.map(
      (bag: UsedStorageZone) => bag.storageZoneId?.id,
    );

    const storageZones = ref<StorageZone[]>([]);
    const temperatureClassList = ref<TemperatureClass[]>([]);

    onMounted(async () => {
      storageZones.value = (
        await entityRepositoryPlugin.get().getByIds(StorageZone, {
          ids: bagsIds,
          sortBy: 'sortIndex',
        })
      ).value;
      temperatureClassList.value = basicHandoverTypes.map((item) => {
        const itemName = item.name.toLowerCase();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const zone = storageZones.value.find(
          (zone: StorageZone) => zone.title.toLowerCase() === itemName,
        );
        return {
          ...item,
          storageZoneId: zone?.id,
          temperatureClassesList: zone?.temperatureClasses.map((item) =>
            capitalizeFirstLetter(item),
          ),
        };
      });
    });

    const countOfProduct = (bagId: string) => {
      const bags = props.bagsList.find((bag) => bag.storageZoneId.id === bagId);
      return bags?.quantity ?? 0;
    };

    return {
      countOfProduct,
      temperatureClassList,
      ...props,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 8px;
  margin: 15px 0 16px;
}
.icons {
  display: grid;
  grid-auto-flow: column;
  gap: 0 4px;
}
</style>
