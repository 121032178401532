import { MultiPluginFactory } from '@/features/core/plugin';
import { syncSchedulerEntityPlugin } from './entities';
import { syncSchedulerServicesPlugin } from './services';

export * from './types';
export * from './services';
export * from './entities';
export * from './events';
export * from './helpers';

export const syncSchedulerPlugin = MultiPluginFactory.with({
  syncSchedulerEntityPlugin,
  syncSchedulerServicesPlugin,
});
