import { ProviderPluginFactory } from '@/features/core/plugin';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { authServicePlugin } from '@/features/core/auth';
import { deviceIdServicePlugin } from '@/features/device-id';
import { pushNotificationServicePlugin } from '@/features/push-notification';
import { DeviceService } from './device-service';

export * from './device-service';

export const deviceServicePlugin = ProviderPluginFactory.create({
  key: Symbol('DeviceService'),
  defaultFactory: {
    create: () => {
      return new DeviceService(
        entityRepositoryPlugin.get(),
        deviceIdServicePlugin.get(),
        pushNotificationServicePlugin.get(),
        authServicePlugin.get(),
      );
    },
  },
});
