<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15621 14.8431L4.17969 19.8197"
      fill="none"
      stroke="#101316"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.998 8.79879V4H15.1992"
      fill="none"
      stroke="#101316"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.00195 15.1971V19.9959H8.80074"
      fill="none"
      stroke="#101316"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.8203 4.17908L14.8438 9.1556"
      fill="none"
      stroke="#101316"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7913 19.9987L13.3897 17.9367C13.0728 17.6699 12.9327 17.2335 13.0309 16.8193V16.8193C13.1073 16.4981 13.3181 16.2307 13.6041 16.0922C13.8901 15.9538 14.2204 15.9594 14.502 16.1073L15.2462 16.5V12C15.2462 11.4477 15.6719 11 16.1969 11V11C16.722 11 17.1477 11.4477 17.1477 12V15L18.411 15.222C19.3279 15.3827 19.9999 16.217 20 17.1947V20"
      fill="none"
      stroke="#101316"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
