import { PipelinePlugin } from '@ads/plugin-pipeline';
import { ordersServicePlugin } from '@/features/orders';
import { OrderPluginDto } from '../types';

export class SaveOrderAndSyncDirect implements PipelinePlugin<OrderPluginDto> {
  public async execute(
    dataTransferObject: OrderPluginDto,
  ): Promise<OrderPluginDto> {
    await ordersServicePlugin
      .get()
      .saveOrder(dataTransferObject.order, false, true);

    return dataTransferObject;
  }
}
