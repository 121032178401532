import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { SessionStorageService } from './sessionStorageService';

export const sessionStoragePlugin =
  ProviderPluginFactory.create<SessionStorageService>({
    key: Symbol('sessionStorageService'),
    defaultFactory: {
      create: () => new SessionStorageService(),
    },
  });

export const sessionStorageConfigPlugin = MultiPluginFactory.with({
  sessionStoragePlugin,
});
