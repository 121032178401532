import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewDataFetchQueue extends RestorableEntity implements NewEntity {
  type = 'dataFetchQueue';
}

export class DataFetchQueue extends NewDataFetchQueue implements Entity {
  id!: string;
  originalId!: string;
  entityType!: string;
  retries!: number;
  fcmMessageId!: string[];
  timestamp!: string;
  eventName!: string;
}

export class DataFetchQueueStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.DataFetchQueue_1).stores({
      [`${storeName}`]: '&id,originalId,entityType,retries',
    });

    return Promise.resolve();
  }
}
