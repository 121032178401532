import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { ResolverService } from './resolver-service';

export const resolverServicePlugin =
  ProviderPluginFactory.create<ResolverService>({
    key: Symbol('ResolverService'),
    defaultFactory: {
      create: () => new ResolverService(),
    },
  });

export const resolverServiceConfigPlugin = MultiPluginFactory.with({
  resolverServicePlugin,
});
