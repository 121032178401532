import { Order } from '../entities';
import { OrderRaw, OrderActionStatus } from '../types';

export function hasOrderAction(
  order: Order | OrderRaw,
  ...statuses: OrderActionStatus[]
): boolean {
  return hasActionStatus(order.actions, ...statuses);
}

export function hasActionStatus(
  actions: Order['actions'],
  ...statuses: OrderActionStatus[]
): boolean {
  const statusMap = statuses.reduce((acc, status) => {
    acc[status] = true;
    return acc;
  }, Object.create(null) as Record<OrderActionStatus, boolean>);

  return actions.some((action) => action.targetStatus in statusMap);
}
