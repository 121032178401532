import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { OrderMetadataServiceImplementation } from './order-metadata-service';

export * from './order-metadata-service';

export const orderMetadataServicePlugin =
  ProviderPluginFactory.create<OrderMetadataServiceImplementation>({
    key: Symbol('OrderMetadataService'),
    defaultFactory: {
      create: () => {
        return new OrderMetadataServiceImplementation(storagePlugin.get());
      },
    },
  });
