import { default as en } from './en.json';
import { default as enUS } from './en-US.json';
import { default as de } from './de.json';

import { LocaleMessages, LocaleMessageValue } from 'vue-i18n';

interface LocaleData {
  // structure of the JSON data
  [key: string]: LocaleMessageValue | LocaleMessages<string | string[]>;
}

export const languages: { [key: string]: LocaleData } = {
  en,
  'en-US': enUS,
  de,
};

export const fallbackLanguages: string[] = Object.keys(languages)
  .map(
    (language: string) =>
      (languages[language] as { fallbackLanguages: string[] })
        .fallbackLanguages,
  )
  .flat();
