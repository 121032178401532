import { SomethingWrongError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [GetConfigurationRequestError.Code]: 'GetConfigurationRequestError';
  }
}

export class GetConfigurationRequestError extends SomethingWrongError {
  static Code = 27 as const;
}
