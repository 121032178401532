import { computed } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/features/core/router';
import {
  entityRepositoryPlugin,
  StorageEntityRepository,
} from '@/features/core/entity-repository';
import { authServicePlugin } from '@/features/core/auth';
import { useTransmissions } from '@/features/transmissions';
import { oauthServicePlugin } from '@/features/oauth';
import { tourCrashServicePlugin } from '@/features/tour-crash';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useOauthLogout() {
  const route = useRoute();

  const {
    hasPendingTransmissions,
    hasFailedTransmissions,
    checkTransmissions,
  } = useTransmissions();

  checkTransmissions();

  const isLogoutCanBeDisabled = computed(
    () =>
      route.meta?.disabledLogout === true ||
      hasPendingTransmissions.value ||
      hasFailedTransmissions.value,
  );

  const isLogoutCanBeHide = computed(
    () => hasPendingTransmissions.value || hasFailedTransmissions.value,
  );

  const handleLogout = async () => {
    if (!hasPendingTransmissions.value) {
      tourCrashServicePlugin.get().removeLastRoute();
      const expiredSession = await authServicePlugin.get().isSessionExpired();
      await oauthServicePlugin.get().logout('empowerId', expiredSession);
      (entityRepositoryPlugin.get() as StorageEntityRepository).clearRefCache();
      if (expiredSession) {
        await router.get().push({ name: 'login' });
      }
    }
  };

  return {
    isLogoutCanBeDisabled,
    isLogoutCanBeHide,
    handleLogout,
  };
}
