import { RestorableEntity } from '@/features/core/entity-repository';
import {
  Storable,
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class DeviceId extends RestorableEntity implements Storable {
  id = 'device-id' as string;
  type = 'device-id' as const;
}

export class DeviceIdStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.DeviceId_1).stores({ [`${storeName}`]: '&id' });

    return Promise.resolve();
  }
}
