export const skuLeadingDigitsMatchAnyBarcodes = (
  barcodes: string[],
  sku: string,
  digits = 6,
): boolean => {
  const skuFirstDigits = sku.slice(0, digits);
  return barcodes.some(
    (barcode: string) => barcode.slice(0, digits) === skuFirstDigits,
  );
};
