import { ProviderPluginFactory } from '@/features/core/plugin';
import { apiPlugin } from '@/features/core/api';
import { TourApiClient } from './tour-api-client';
import { ExecPluginFactory } from '@/features/core/plugin';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import { registerTourApi } from './tourRegister';

export * from './tour-api-client';
export * from './tourRegister';

export const toursApiPlugin = ProviderPluginFactory.create<TourApiClient>({
  key: Symbol('ToursApiClient'),
  defaultFactory: {
    create: () => new TourApiClient(apiPlugin.get()),
  },
});

export const toursApiRegistryPlugin = ExecPluginFactory.fn(() => {
  registerTourApi(
    onlineEntityRepositoryRegistryPlugin.get(),
    toursApiPlugin.get(),
  );
});
