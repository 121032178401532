import { apiPlugin } from '@/features/core/api';
import { errorPlugin } from '@/features/core/errors';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { UserReportsApiClient } from './user-reports-api-client';

export * from './user-reports-api-client';

export const userReportsApiClientPlugin =
  ProviderPluginFactory.create<UserReportsApiClient>({
    key: Symbol('UserReportsApiClient'),
    defaultFactory: {
      create: () =>
        new UserReportsApiClient(apiPlugin.get(), errorPlugin.get()),
    },
  });
