import { Storage } from '@/features/core/storage';
import { ProductApiClient } from '..';
import { Product } from '../entities';

export class ProductsService {
  constructor(
    private storage: Storage,
    private productApiClient: ProductApiClient,
  ) {}

  async fetchBySkus(skus: string[]): Promise<Product[]> {
    const products = await this.productApiClient.getBySkus(skus);
    const savedProducts = await this.storage.bulkSave(products);

    return savedProducts;
  }

  async fetchBySkusFromStorage(skus: string[]): Promise<Product[]> {
    const products = await this.storage.getAll(Product);

    const filteredProducts = products.filter((item) => skus.includes(item.sku));

    return filteredProducts;
  }

  async fetchAll(): Promise<Product[]> {
    const products = await this.productApiClient.getAll();
    const savedProducts = await this.storage.bulkSave(products);

    return savedProducts;
  }

  async clearProduct(product: Product): Promise<void> {
    await this.storage.remove(product);
  }

  async clearAllProducts(): Promise<void> {
    await this.storage.removeAll(Product);
  }
}
