import { UserService } from '@/features/user';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationOnHandoverDTO } from '../types';

/**
 * Set redirect path of age verification depending on user role
 */

export class SetRedirectPathBasedOnUserRolePlugin
  implements PipelinePlugin<AgeVerificationOnHandoverDTO>
{
  constructor(private userService: UserService) {}

  public execute(
    dto: AgeVerificationOnHandoverDTO,
  ): AgeVerificationOnHandoverDTO {
    if (this.userService.isDriverRole(dto.userRoles)) {
      dto.redirect = `/tours/${String(
        dto.redirectTo.params.tourId,
      )}/drops/${String(dto.redirectTo.params.dropId)}/handover/${String(
        dto.redirectTo.params.id,
      )}/age-verification`;
    } else {
      dto.redirect = `/age-verification/${String(dto.redirectTo.params.id)}`;
    }
    return dto;
  }
}
