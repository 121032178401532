import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { configurationApiPlugin } from '../api';
import { ConfigurationService } from './configuration-service';

export * from './configuration-service';

export const configurationServicePlugin =
  ProviderPluginFactory.create<ConfigurationService>({
    key: Symbol('ConfigurationService'),
    defaultFactory: {
      create: () => {
        return new ConfigurationService(
          storagePlugin.get(),
          configurationApiPlugin.get(),
        );
      },
    },
  });
