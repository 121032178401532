import { Product } from '@/features/products/entities';
import { TemperatureClassList } from '@/features/products/types';
import { getArrayHashMap } from '@/utils/helpers/getArrayHashMap';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type {
  OrderItem,
  OrderItemCount,
  OrderItemRaw,
  OrderItemResponse,
  OrderParsePluginDto,
} from '../types';
import { LoadExistingOrderPlugin } from '@/features/orders/plugins/load-existing-order';
import { LoadOrderProductsPlugin } from '@/features/orders/plugins/load-order-products';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import { NormalizeItemsPlugin } from '@/features/orders/plugins/normalize-items';

export class GroupItemsPlugin implements PipelinePlugin<OrderParsePluginDto> {
  public readonly requiredPlugins = [
    LoadExistingOrderPlugin,
    NormalizeOrderPlugin,
    LoadOrderProductsPlugin,
    NormalizeItemsPlugin,
  ];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    const items = dataTransferObject.rawItems as OrderItemRaw[];
    const products = dataTransferObject.products || [];

    const groupedItems: OrderItemRaw[] = items.reduce(
      (acc: OrderItemRaw[], item: OrderItemRaw) => {
        const sku = item.product;
        this.calculateTotalOrderQuantity(
          item,
          products,
          sku,
          dataTransferObject.order.itemsCount,
        );
        this.groupItems(acc, item, sku);
        return acc;
      },
      [],
    );

    const itemsWithMissingProducts = this.filterItemsWithoutProduct(
      groupedItems,
      products,
    );

    const orderGroupedItemsHashMap = getArrayHashMap<OrderItemRaw>(
      groupedItems,
      'product',
    );

    const sortedGroupedItems = products
      .map((product) => orderGroupedItemsHashMap[product.sku])
      .flat()
      .filter(Boolean);

    sortedGroupedItems.push(...itemsWithMissingProducts);

    dataTransferObject.order.items =
      sortedGroupedItems as unknown[] as OrderItem[];
    return dataTransferObject;
  }

  private calculateTotalOrderQuantity(
    item: OrderItemRaw,
    products: Product[],
    sku: string,
    itemsQuantityCount: OrderItemCount,
  ) {
    const currentProd = products.find((product) => product.sku === sku);

    if (currentProd) {
      this.quantityCount(
        currentProd.temperatureClass,
        itemsQuantityCount,
        item,
      );
    }
  }

  private quantityCount(
    temperatureClass: string,
    itemsQuantity: OrderItemCount,
    item: OrderItemRaw | OrderItem | OrderItemResponse,
  ): void {
    itemsQuantity.total += item.quantityOriginal;
    if (temperatureClass === TemperatureClassList.freezer) {
      itemsQuantity.freezer += item.quantityOriginal;
    } else if (temperatureClass === TemperatureClassList.fresh) {
      itemsQuantity.fresh += item.quantityOriginal;
    } else if (temperatureClass === TemperatureClassList.chiller) {
      itemsQuantity.chiller += item.quantityOriginal;
    } else if (temperatureClass === TemperatureClassList.ambient) {
      itemsQuantity.ambient += item.quantityOriginal;
    }
  }

  private groupItems(arr: OrderItemRaw[], item: OrderItemRaw, sku: string) {
    const index = arr.findIndex(
      (i) => i.product === sku && i.status === item.status,
    );
    if (index >= 0) {
      arr[index].quantity += item.quantity;
      if (!item.originalId) {
        arr[index].quantityOriginal += item.quantityOriginal ?? 1;
      }
      arr[index].amount += item.amount ? Number(item.amount.toFixed(2)) : 0;
      arr[index].amountOriginal += item.amountOriginal
        ? Number(item.amountOriginal.toFixed(2))
        : 0;
      if (item.amount) {
        arr[index].weights?.push({
          weight: item.amount,
          relatedBarcode:
            item.scannedBarcodes && item.scannedBarcodes.length
              ? item.scannedBarcodes[0]
              : '',
        });
      }
    } else {
      if (item.amount) {
        item.weights?.push({
          weight: item.amount,
          relatedBarcode:
            item.scannedBarcodes && item.scannedBarcodes.length
              ? item.scannedBarcodes[0]
              : '',
        });
      }

      arr.push(item);
    }
  }

  private filterItemsWithoutProduct(
    items: OrderItemRaw[],
    products: Product[],
  ) {
    const productSkus = products.map((product) => product.sku);
    return items.filter((item) => !productSkus.includes(item.product));
  }
}
