import { ApiClient } from '@/features/core/api';
import { TourDropItem } from '../entities';
import { DropPatchObject } from '../types';

export class TourDropApiClient {
  constructor(private api: ApiClient) {}
  async patchDrop(drop: TourDropItem): Promise<TourDropItem> {
    const dropPatchObject: DropPatchObject = {
      status: drop.status,
      statusChangedAt: drop.statusChangedAt,
    };
    if (drop.rejectedDeliveryItems) {
      dropPatchObject.rejectedDeliveryItems = drop.rejectedDeliveryItems;
    }
    if (drop.bottleDepositReturns) {
      dropPatchObject.bottleDepositReturns = drop.bottleDepositReturns;
    }
    if (drop.exchangedDeliveryItems) {
      dropPatchObject.exchangedDeliveryItems = drop.exchangedDeliveryItems;
    }

    const request = {
      data: {
        type: 'drops',
        attributes: dropPatchObject,
      },
    };

    await this.api.client.patch(`/drops/${drop.reference}`, request);
    return drop;
  }
}
