import { Component, Ref, ref, shallowRef, ShallowRef } from 'vue';
import { $t } from '@/i18n';

interface DialogSlotComponent {
  component: Component;
  props?: Record<string, unknown>;
}

interface DialogResult {
  onConfirm: () => void;
  close: () => void;
  confirm: (opts: ConfirmOptions) => Promise<unknown>;
  showDialog: Ref<boolean>;
  confirmText: Ref<string>;
  titleText: Ref<string>;
  contentText: Ref<string>;
  cancelText: Ref<string>;
  showOnlyConfirm: Ref<boolean>;
  isTitleCentered: Ref<boolean>;
  component: ShallowRef<DialogSlotComponent | undefined>;
  secretToggle: Ref<boolean>;
  e2e: Ref<string>;
}

type ConfirmOptions = {
  title: string;
  isTitleCentered?: boolean;
  contentText?: string;
  confirmText?: string;
  cancelText?: string;
  showOnlyConfirm?: boolean;
  component?: DialogSlotComponent;
  e2e?: string;
};

const showDialog: Ref<boolean> = ref(false);
const titleText: Ref<string> = ref('');
const isTitleCentered: Ref<boolean> = ref(false);
const contentText: Ref<string> = ref('');
const confirmText: Ref<string> = ref('');
const cancelText: Ref<string> = ref('');
const e2e: Ref<string> = ref('');
const showOnlyConfirm: Ref<boolean> = ref(false);
const resolvePromise: Ref<((value: unknown) => void) | undefined> = ref();
const rejectPromise: Ref<((value: unknown) => void) | undefined> = ref();
const component: ShallowRef<DialogSlotComponent | undefined> = shallowRef();
const secretToggle: Ref<boolean> = ref(false);

export function useDynamicDialog(): DialogResult {
  const confirm = (opts: ConfirmOptions): Promise<unknown> => {
    titleText.value = opts.title || '';
    isTitleCentered.value = opts.isTitleCentered !== false;
    confirmText.value = opts.confirmText || '';
    cancelText.value = opts.cancelText || $t('common.cancel.text');
    contentText.value = opts.contentText || '';
    showOnlyConfirm.value = opts.showOnlyConfirm ?? false;
    showDialog.value = true;
    component.value = opts.component;
    secretToggle.value = false;
    e2e.value = opts.e2e || '';

    return new Promise((resolve, reject) => {
      resolvePromise.value = resolve;
      rejectPromise.value = reject;
    });
  };

  const onConfirm = (): void => {
    if (!resolvePromise.value) {
      return;
    }
    showDialog.value = false;
    return resolvePromise.value(true);
  };

  const close = (): void => {
    if (!resolvePromise.value) {
      return;
    }
    showDialog.value = false;
    return resolvePromise.value(false);
  };

  return {
    onConfirm,
    close,
    confirm,
    contentText,
    showDialog,
    confirmText,
    titleText,
    cancelText,
    showOnlyConfirm,
    isTitleCentered,
    component,
    secretToggle,
    e2e,
  };
}
