<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1251 5.87457L17.2501 6.74957L15.5001 8.49957L12.0001 11.9996L8.50012 15.4996L6.75012 17.2496L5.87512 18.1246"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M12.0001 11.9996L15.5001 15.4996L17.2501 17.2496L18.1251 18.1246M8.50012 8.49957L6.75012 6.74957L5.87512 5.87457M8.50012 8.49957L9.37512 9.37457M8.50012 8.49957L9.96784 9.96729"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M4.99988 3.9386C4.41409 4.52439 4.41409 5.47413 4.99988 6.05992L6.06054 7.12058L7.1212 6.05992L4.99988 3.9386Z"
      stroke="none"
    />
    <path
      d="M4.99988 20.0614C4.41409 19.4756 4.41409 18.5259 4.99988 17.9401L6.06054 16.8794L7.1212 17.9401L4.99988 20.0614Z"
      stroke="none"
    />
    <path
      d="M19.0001 20.0614C19.5859 19.4756 19.5859 18.5259 19.0001 17.9401L17.9395 16.8794L16.8788 17.9401L19.0001 20.0614Z"
      stroke="none"
    />
    <path
      d="M19.0001 3.9386C19.5859 4.52439 19.5859 5.47413 19.0001 6.05992L17.9395 7.12058L16.8788 6.05992L19.0001 3.9386Z"
      stroke="none"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    color: { type: String, default: '#0D3A93' },
  },
});
</script>
