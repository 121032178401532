import { Order } from '@/features/orders/entities';
import { sumOrderProperties } from '@/features/picking/helpers/sumOrderProperties';

/**
 * Returns whether the provided order is cancellable due to not having any items
 *
 * Quantity can be optionally provided if already calculated by the `useOrder` hook,
 * otherwise it will be calculated based on the provided `order` value.
 */
export const isCancellableOrder = (
  order: Pick<Order, 'items'> | null,
  quantity?: number,
): boolean =>
  order !== null && (quantity ?? sumOrderProperties(order, 'quantity')) === 0;
