import { SomethingWrongError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [PostReportsRequestError.Code]: 'PostReportsRequestError';
  }
}
export class PostReportsRequestError extends SomethingWrongError {
  static Code = 901 as const;
}
