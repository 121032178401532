<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.75 6.25C7.75 3.90279 9.65279 2 12 2C14.3472 2 16.25 3.90279 16.25 6.25C16.25 8.59721 14.3472 10.5 12 10.5C9.65279 10.5 7.75 8.59721 7.75 6.25ZM12 3.5C10.4812 3.5 9.25 4.73122 9.25 6.25C9.25 7.76878 10.4812 9 12 9C13.5188 9 14.75 7.76878 14.75 6.25C14.75 4.73122 13.5188 3.5 12 3.5Z"
    />
    <path
      d="M12 13.5C8.27396 13.5 5.41057 16.333 5.04046 19.7504C5.01369 19.9975 5 20.2477 5 20.5V20.625C5 21.3154 4.44036 21.875 3.75 21.875H3.5V20.5C3.5 20.1934 3.51664 19.8893 3.54918 19.5889C3.99731 15.4511 7.4674 12 12 12C16.8555 12 20.5 15.8713 20.5 20.5V21.25C20.5 21.6642 20.1642 22 19.75 22H8C8 21.2299 8.58036 20.5953 9.32759 20.5098V20.5H19C19 16.6741 16.0017 13.5 12 13.5Z"
    />
  </svg>
</template>
