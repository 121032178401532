import { Product } from '@/features/products';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { LoadOrderProductsPlugin } from '@/features/orders/plugins/load-order-products';
import type { OrderParsePluginDto } from '@/features/orders/types';

/**
 * Add temperature classes to the order
 **/
export class AddTemperatureClassesToOrderPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public readonly requiredPlugins = [LoadOrderProductsPlugin];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    dataTransferObject.order.temperatureClasses =
      dataTransferObject.products?.map(
        (product: Product) => product.temperatureClass,
      ) || [];

    return dataTransferObject;
  }
}
