import { SomethingWrongError } from '@/features/core/errors/error-classes';
import axios, { AxiosError } from 'axios';

export class PostDropSurveyAnswersError extends SomethingWrongError {
  static Code = 80 as const;
}

export class DropSurveyAnswersAlreadySubmittedError extends SomethingWrongError {
  static Code = 81 as const;
}

export const isSurveyAlreadySubmitted = (error: AxiosError): boolean => {
  return axios.isAxiosError(error) && error.response?.status === 409;
};

export class SurveyInitFailedError extends SomethingWrongError {
  static Code = 208 as const;
}
