import { MultiPluginFactory } from '@/features/core/plugin';
import { storageZoneApiPlugin, storageZoneApiRegistryPlugin } from './api';
import { storageZoneEntityPlugin } from './entities';
import { storageZoneServicePlugin } from './services';

export * from './api';
export * from './entities';
export * from './services';

export const storageZonesPlugin = MultiPluginFactory.with({
  storageZoneApiPlugin,
  storageZoneApiRegistryPlugin,
  storageZoneEntityPlugin,
  storageZoneServicePlugin,
});
