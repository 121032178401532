<template>
  <Transition name="animation" :appear="!isPageRedirected">
    <div class="root-wrapper">
      <h2 class="title">{{ $t('pages.login.screen-title.text') }}</h2>
      <div class="login-page">
        <div class="logo">
          <img
            src="@/assets/images/ALDI-logo.svg"
            alt="ALDI logo"
            data-e2e="aldi-logo"
          />
        </div>
        <div class="content">
          <h1 class="page-title" v-if="isPageRedirectedFromLogout">
            {{ $t('pages.login.logged-out.text') }}
          </h1>
          <h1 class="page-title" v-else>
            {{ $t('pages.login.headline.text') }}<br />{{
              $t('pages.login.subline.text')
            }}
          </h1>
          <div ref="scrollTo">
            <form class="login-form">
              <Error v-if="error" :error-message="error.description" />

              <Button
                data-e2e="login-button"
                @click.stop.prevent="handleLogin"
                :disabled="loading"
                :loading="loading"
                class="login-button"
              >
                {{ $t('pages.login.button-submit.text') }}
              </Button>
            </form>
          </div>
          <div
            class="app-version"
            :data-e2e="`picking-app-version`"
            ref="versionNumber"
          >
            v{{ appVersion }}
          </div>
        </div>
      </div>
      <Popup
        :visible="isFeatureWindowVisible"
        @close="() => closeFeatureWindow()"
        fullPageSize
        withoutHeader
      >
        <FeatureToggleSection />
      </Popup>
    </div>
  </Transition>
  <Dialog
    :visible="notify"
    :title-text="$t('pages.login.login-not-possible-dialog.title.text')"
    :content-text="notifyMessage"
    :confirmText="$t('pages.login.login-not-possible-dialog.confirm.text')"
    @confirmed="disableNotify"
    :showOnlyConfirm="true"
  />
  <Dialog
    :visible="showReloadMessage"
    :titleText="$t('pages.login.app-updated-dialog.title.text')"
    :contentText="$t('pages.login.app-updated-dialog.content.text')"
    :confirmText="$t('pages.login.app-updated-dialog.confirm.text')"
    :showOnlyConfirm="true"
    @confirmed="updateSW"
  />
  <Dialog
    :visible="showChangeLog"
    :titleText="changeLogTitleText"
    :confirmText="$t('pages.login.change-log-dialog.confirm.text')"
    :showOnlyConfirm="true"
    @confirmed="() => setChangeLogVisibility(false)"
  >
    <ul>
      <li v-for="(text, key) in changeLogContent" :key="key">
        {{ text }}
      </li>
    </ul>
  </Dialog>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { LocationQueryValue, useRoute } from 'vue-router';
import { Button, Dialog, Error, Popup } from '@/features/ui/components';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import {
  clickCount,
  FeatureToggleSection,
  timeToResetClickCounter,
} from '@/features/feature-toggle';
import { sessionStoragePlugin } from '@/features/session-storage';
import { Cancellable } from '@/utils/types';
import { $t } from '@/i18n';
import { useChangeLog, useOauthLogin } from '../composables';
import { SKIP_WAITING, UPDATE_SW, UseOauthLoginConfig } from '../types';
import { useSecretToggle } from '@/features/ui/composables';
import { Subscription } from 'rxjs';

export default defineComponent({
  name: 'LoginPage',
  components: {
    Dialog,
    Button,
    Error,
    Popup,
    FeatureToggleSection,
  },
  setup: function () {
    const versionNumber = ref<null | HTMLElement>(null);
    const scrollTo = ref<null | HTMLElement>(null);
    const route = useRoute();
    const user = ref({
      username: '',
      password: '',
    });

    const validateQuery = (
      query: Record<string, string | LocationQueryValue[] | null>,
    ) => {
      let filteredQuery = {};
      for (let queryKey in query) {
        if (typeof query[queryKey] === 'string') {
          filteredQuery = {
            ...filteredQuery,
            [queryKey]: query[queryKey],
          };
        }
      }
      return filteredQuery;
    };
    const validQuery: UseOauthLoginConfig = validateQuery(route.query);

    const {
      loading,
      login,
      error,
      requestTokenSet,
      notify,
      notifyMessage,
      disableNotify,
    } = useOauthLogin();
    // add validate query

    const isPageRedirected = computed(() => {
      const state = sessionStoragePlugin.get().getItem('state');
      return validQuery?.state && state;
    });

    const isPageRedirectedFromLogout = computed(() => {
      return sessionStoragePlugin.get().getItem('logout');
    });

    const isPageHasSameState = computed(() => {
      const state = sessionStoragePlugin.get().getItem('state');
      return validQuery.state === state;
    });

    const {
      version: changeLogVersion,
      content: changeLogContent,
      hasContent: changeLogHasContent,
    } = useChangeLog();

    const showChangeLog = ref(false);
    const changeLogTitleText = $t('pages.login.change-log-dialog.title.text', {
      changeLogVersion,
    });
    const setChangeLogVisibility = (visible: boolean) => {
      showChangeLog.value = visible;
    };

    async function handleLogin() {
      const registration = await navigator?.serviceWorker?.getRegistration();
      if (registration) {
        registration?.active?.postMessage(UPDATE_SW);
      }

      await login();
    }

    const appVersion = process.env.VUE_APP_VERSION;

    const showReloadMessage = computed(
      () => notificationPlugin.get().isShowMessage.value,
    );

    let refreshPageTimeout: null | ReturnType<typeof setTimeout> = null;

    const refreshPageAfterUpdate = () => {
      // remove controllerchange listener in case of fired event during picking flow
      navigator.serviceWorker?.removeEventListener(
        'controllerchange',
        refreshPageAfterUpdate,
      );

      if (refreshPageTimeout) {
        clearTimeout(refreshPageTimeout);
      }
      window.location.href = window.location.href + '&version-updated=true';
    };

    const updateSW = async (): Promise<void> => {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration && registration.waiting) {
        registration.waiting.postMessage(SKIP_WAITING);
        // refresh page after SW skip waiting in case if controllerchange event wasn't fired
        refreshPageTimeout = setTimeout(() => {
          refreshPageAfterUpdate();
        }, 3000);
        // refresh page after SW skip waiting
        navigator.serviceWorker.addEventListener(
          'controllerchange',
          refreshPageAfterUpdate,
        );
      } else {
        //To prevent show update notification popup several times
        notificationPlugin.get().hideMessage();
      }
    };

    const isFeatureWindowVisible = ref(false);

    const closeFeatureWindow = () => {
      isFeatureWindowVisible.value = false;
    };

    let showNotification: Cancellable;
    const notificationData = {
      link: {
        text: 'Learn More',
        onClick: (event: Event) => {
          event.preventDefault();
          showNotification?.cancel();
          setChangeLogVisibility(true);
        },
      },
    };

    watch(changeLogHasContent, () => {
      const hasUpdated = route.query['version-updated'] === 'true';
      if (hasUpdated) {
        showNotification = notificationPlugin.get().show({
          text: $t('pages.login.app-success-updated.text'),
          type: NotificationType.Success,
          ...(changeLogHasContent.value && {
            data: notificationData,
          }),
        });
      }
    });

    const subscription: Ref<Subscription | null> = ref(null);

    onMounted(async () => {
      subscription.value = useSecretToggle(
        versionNumber.value as HTMLElement,
        'click',
        () => (isFeatureWindowVisible.value = true),
        clickCount,
        timeToResetClickCounter,
      );

      window.onbeforeunload = () => {
        if (isPageRedirectedFromLogout.value) {
          sessionStoragePlugin.get().removeItem('logout');
        }
      };
      if (isPageRedirected.value) {
        if (isPageHasSameState.value) {
          if (validQuery?.code) {
            await requestTokenSet({
              code: validQuery.code,
            });
          }
        } else {
          notificationPlugin.get().show({
            text: $t('pages.login.something-went-wrong.text'),
            type: NotificationType.Info,
            duration: -1,
          });
        }
      }
    });

    onBeforeUnmount(() => {
      if (subscription.value) {
        subscription.value.unsubscribe();
      }
    });

    return {
      user,
      error,
      handleLogin,
      loading,
      scrollTo,
      appVersion,
      showReloadMessage,
      updateSW,
      isPageRedirected,
      isPageHasSameState,
      isPageRedirectedFromLogout,
      notify,
      notifyMessage,
      disableNotify,
      isFeatureWindowVisible,
      closeFeatureWindow,
      showChangeLog,
      changeLogTitleText,
      changeLogContent,
      setChangeLogVisibility,
      versionNumber,
    };
  },
});
</script>

<style lang="scss" scoped>
.root-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  margin: 0;
  max-width: 600px;
  flex: 1 1 auto;
  flex-direction: column;
}

.page-title {
  margin: 56px auto;
  letter-spacing: 0.05rem;
  line-height: 1.4;
  color: $default-text-color;
}

.title {
  position: fixed;
  top: 14px;
  right: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: $default-text-color;
  letter-spacing: 0.015em;
}

.reset_link {
  display: block;
  padding-left: 3px;
  margin-top: 16px;
  margin-bottom: 31px;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  color: $base-header-color;
  line-height: 18px;
}

.app-version {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #71777e;
  user-select: none;
}

// NOTE: dummy animation. without it, nested animations do not work if the transition has the attribute appear
$duration: 3s;
$delay: 0.5s;

.animation-enter-active {
  transition: opacity $duration linear;
}

.animation-enter-from {
  opacity: 1;
}

// animation of logo appearance
.animation-enter-active .logo {
  transition: transform 0.5s ease-in-out $delay;
}

.animation-enter-from .logo {
  transform: translateY(113px);
}

// animation of content appearance
.animation-enter-active .content {
  transition: opacity 0.2s ease-in calc($delay + 0.3s);
}

.animation-enter-from .content {
  opacity: 0;
}
</style>
