<template>
  <div class="search-fallback" v-if="!searchResultsAmount">
    <div class="search-fallback__no-results">
      <template v-if="noResults">
        <img
          src="@/assets/images/no-search-results.svg"
          :alt="$t('components.search-result-feedback.no-results-image.alt')"
          data-e2e="order-search-img-no-results"
        />
        <h2
          class="search-fallback__subtitle"
          data-e2e="order-search-no-results"
        >
          {{ $t('components.search-feedback.no-results.text') }}
        </h2>
      </template>
      <template v-else>
        <img
          src="@/assets/images/search-active.svg"
          :alt="searchHintText"
          data-e2e="order-search-img-hint"
        />
        <h2 class="search-fallback__subtitle" data-e2e="order-search-hint">
          {{ searchHintText }}
        </h2>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'SearchResultFeedback',

  props: {
    ordersAmount: {
      type: Number,
      default: 0,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    searchHintText: {
      type: String,
      default: $t('components.search-result-feedback.search-hint-text.text'),
    },
  },
  setup(props) {
    const searchResultsAmount = computed(() => {
      return props.isSearching ? props.ordersAmount : 0;
    });
    const noResults = computed(
      () => props.isSearching && searchResultsAmount.value === 0,
    );

    return {
      noResults,
      searchResultsAmount,
    };
  },
});
</script>
<style lang="scss" scoped>
.search-fallback {
  padding: 0 16px;
  &__no-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    margin-top: 23px;
    font-size: 16px;
    text-align: left;
    line-height: 24px;
  }
  &__subtitle {
    margin-top: 24px;
    max-width: 240px;
    font-size: 21px;
    line-height: 32px;
  }
}
</style>
