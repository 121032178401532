import { AnyEntity, Entity } from '@/features/core/entity-repository';

/**
 * Return an entity whose type is equal to the provided string.
 *
 * @param entityType entity type
 */
export function isEntity<T extends Entity>(
  actualEntity: AnyEntity,
  expectedEntity: T,
): actualEntity is T {
  return actualEntity.type === expectedEntity.type;
}
