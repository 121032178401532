import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationDTO } from '../types';

/**
 * set age verification attributes to the DTO
 */

export class SetVerificationAttributePlugin
  implements PipelinePlugin<AgeVerificationDTO>
{
  public execute(dto: AgeVerificationDTO): AgeVerificationDTO {
    if (dto.order.ageVerification) {
      dto.order.ageVerification.userIdType = dto.ageVerification.userIdType;
      dto.order.ageVerification.dateOfBirth = dto.ageVerification.dateOfBirth;
      dto.order.ageVerification.rejectionReason =
        dto.ageVerification.rejectionReason;
    }
    return dto;
  }
}
