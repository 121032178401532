import { MeasureMemoryUsage } from '../types';
import { loggerServicePlugin } from '@/features/core/logger';

export const measureMemoryUsage = (): MeasureMemoryUsage => {
  const MEAN_INTERVAL_IN_MS = 1.5 * 60 * 1000;
  const logMemoryMeasurement = (result: number | null) => {
    if (result === null) {
      return;
    }

    const memoryInMbs = result / Math.pow(1024, 2);
    loggerServicePlugin
      .get()
      .info(`Memory usage: ${memoryInMbs.toFixed(0)} MB`);
  };

  const measureAgentSpecificMemory = async (): Promise<number | null> => {
    if (typeof performance?.measureUserAgentSpecificMemory === 'undefined') {
      return null;
    }

    try {
      const memory: UserAgentSpecificMemory =
        await performance.measureUserAgentSpecificMemory();
      return memory.bytes;
    } catch (error) {
      if (error instanceof DOMException && error.name === 'SecurityError') {
        loggerServicePlugin.get().debug('The context is not secure.');
      }

      return null;
    }
  };

  const measureUsedHeapMemory = (): number | null => {
    return performance?.memory?.usedJSHeapSize || null;
  };
  const measureUsedMemory = async (): Promise<number | null> =>
    (await measureAgentSpecificMemory()) || measureUsedHeapMemory();

  const performMemoryAuditWithLogs = async (): Promise<void> => {
    const result = await measureUsedMemory();

    logMemoryMeasurement(result);

    scheduleMeasurement();
  };
  const measurementInterval = () => {
    return -Math.log(Math.random()) * MEAN_INTERVAL_IN_MS;
  };

  const scheduleMeasurement = () => {
    const interval = measurementInterval();

    setTimeout(() => void performMemoryAuditWithLogs(), interval);
  };

  return {
    performMemoryAuditWithLogs,
    measureUsedMemory,
  };
};
