import { TourApiClient } from './tour-api-client';
import { TourItem } from '../entities';
import { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';

export const registerTourApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  tourApi: TourApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(TourItem, {
    getAll: tourApi.getTours?.bind(tourApi) as () => Promise<TourItem[]>,
    save: async (entity: TourItem): Promise<TourItem> =>
      tourApi.patchTours(entity) as Promise<TourItem>,
  });
};
