import { ordersServicePlugin } from '@/features/orders';
import { LoadOrderPlugin } from '@/features/orders/plugins/load-order';
import { userServicePlugin } from '@/features/user';
import { LoadUserPlugin } from '@/features/user/plugins/load-user';
import { SetUserRolePlugin } from '@/features/user/plugins/set-user-role';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { AgeVerificationOnHandoverDTO } from '../types';
import { IsVerificationNeededPlugin } from './is-verification-needed';
import { SetRedirectPathBasedOnUserRolePlugin } from './set-redirect-path-based-on-user-role';

export function handoverAgeVerificationPluginRegister(
  orderId: string,
): PluginPipeline<AgeVerificationOnHandoverDTO> {
  const handleAgeVerificationForHandoverPlugin =
    new PluginPipeline<AgeVerificationOnHandoverDTO>();

  handleAgeVerificationForHandoverPlugin.registerPlugins([
    new LoadOrderPlugin<AgeVerificationOnHandoverDTO>(
      ordersServicePlugin.get(),
      orderId,
    ),
    new IsVerificationNeededPlugin(),
  ]);

  handleAgeVerificationForHandoverPlugin.registerPlugins(
    [
      new LoadUserPlugin<AgeVerificationOnHandoverDTO>(),
      new SetUserRolePlugin<AgeVerificationOnHandoverDTO>('userRoles'),
      new SetRedirectPathBasedOnUserRolePlugin(userServicePlugin.get()),
    ],
    (dto) => dto.isVerificationRequired,
  );

  return handleAgeVerificationForHandoverPlugin;
}
