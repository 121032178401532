import type { Transmission } from '../types';
import { ReportsService } from '@/features/reports';

export class FailedTransmissionsService {
  constructor(private reportService: ReportsService) {}

  async sendFailedTransmissionReports(
    failedTransmissions: Transmission[],
  ): Promise<boolean> {
    const length = failedTransmissions.length;
    let success = true;
    for (let i = 0; i < length; i++) {
      const transmission: Transmission = failedTransmissions[i];
      const result = await this.reportService.sendErrorReport(transmission);

      if (!success) {
        continue;
      }

      success = result;
    }

    return success;
  }
}
