<template>
  <div>
    <div class="image">
      <img
        class="ut-img-contain"
        src="@/assets/images/empty.svg"
        alt="Customer Note"
      />
    </div>
    <h3 class="title">{{ $t('components.empty-section.title.text') }}</h3>
  </div>
</template>

<style lang="scss" scoped>
.image {
  padding: 72px 16% 24px;
  max-width: 100%;
  max-height: 50vh;
  box-sizing: border-box;
}

.title {
  font-size: 21px;
  line-height: 30px;
}
</style>
