import { PipelinePlugin } from '@ads/plugin-pipeline';
import {
  OrderParsePluginDto,
  OrderEventNames,
  OrderLocalStatus,
} from '../types';

export class TrackEventHandoverReadyPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    const existingOrder = dataTransferObject.existingOrder;
    if (
      !existingOrder ||
      existingOrder.localStatus !== OrderLocalStatus.HandoverReady
    ) {
      const currentDate = new Date().toISOString();
      if (!dataTransferObject.order.events)
        dataTransferObject.order.events = [];
      dataTransferObject.order.events.push({
        name: OrderEventNames.pickup_ready,
        timestamp: currentDate,
      });
    }

    return dataTransferObject;
  }
}
