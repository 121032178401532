import { MultiPluginFactory } from '@/features/core/plugin';
import { processedOrderEntityPlugin } from './entities';
import { processedOrderServicePlugin } from './services';

export * from './types';
export * from './entities';
export * from './services';

export const processedOrderPlugin = MultiPluginFactory.with({
  processedOrderEntityPlugin,
  processedOrderServicePlugin,
});
