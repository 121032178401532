<template>
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.6665 11.6667C4.6665 13.5232 5.404 15.3037 6.71676 16.6164C8.02951 17.9292 9.80999 18.6667 11.6665 18.6667C13.523 18.6667 15.3035 17.9292 16.6163 16.6164C17.929 15.3037 18.6665 13.5232 18.6665 11.6667C18.6665 9.81017 17.929 8.02969 16.6163 6.71694C15.3035 5.40418 13.523 4.66669 11.6665 4.66669C9.80999 4.66669 8.02951 5.40418 6.71676 6.71694C5.404 8.02969 4.6665 9.81017 4.6665 11.6667V11.6667Z"
      fill="none"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.6973 19.6974C19.982 19.9822 21.1456 21.1458 21.1456 21.1458L21.8746 21.8751"
      fill="none"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.8413 22.6044C23.1579 23.2878 22.0499 23.2878 21.3664 22.6044L20.954 22.1919L22.1914 20.9545L23.8413 22.6044Z"
      stroke="none"
    />
    <path
      d="M17.5293 18.7667C18.2127 18.0832 19.3208 18.0832 20.0042 18.7667L20.4166 19.1791L19.1792 20.4166L17.5293 18.7667Z"
      stroke="none"
    />
  </svg>
</template>
