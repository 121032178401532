import { differenceInMinutes, format } from 'date-fns';
import { $d } from '@/i18n';

/**
 * Returns the formatted time string between two dates.
 * Examples: "666 min ago" or "In 666 min".
 *
 */
export function formatTimeDifference(
  laterDate: number | string | Date,
  earlierDate: Date | number,
): string {
  const timeSince = differenceInMinutes(
    new Date(laterDate),
    new Date(earlierDate),
    {
      roundingMethod: 'ceil',
    },
  );

  return timeSince > 0
    ? `In ${timeSince} min`
    : `${Math.abs(timeSince)} min ago`;
}

export function formatSinceMinsAgo(
  sinceDate: number | string | Date,
  toDate: Date | number,
): string {
  const timeSince = differenceInMinutes(new Date(toDate), new Date(sinceDate), {
    roundingMethod: 'ceil',
  });
  return `${timeSince} min ago`;
}

export function formatHours(
  date: Date,
  leadingZero = false,
  format24H = false,
): string {
  if (format24H) {
    return $d(date, 'short-time');
  }
  if (leadingZero) {
    return $d(date, 'short-time-leading-zero');
  } else {
    return $d(date, 'short-time-12hr').toLowerCase();
  }
}

export function formatDate(date: Date): string {
  return $d(date, 'short');
}

export function formatDateAtom(date: Date): string {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
}

export function formatDateTime(date: Date): string {
  return formatHours(date) + ' - ' + formatDate(date);
}

/**
 * Returns the formatted timeframe string between two dates.
 * Examples: "10:30 - 11:00" or "10:30 am - 11:00 am".
 */
export function formatTimeframe(
  startTime: string,
  endTime: string,
  is24HFormat: boolean,
): string {
  return `${formatHours(
    new Date(startTime),
    false,
    is24HFormat,
  )} - ${formatHours(new Date(endTime), false, is24HFormat)}`;
}
