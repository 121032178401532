<template>
  <div v-if="visible" class="dialog">
    <div class="dialog__wrap">
      <div class="dialog__icon">
        <NotifyErrorIcon width="64" v-if="!isOnline" />
        <NotifySuccessIcon width="64" v-if="isOnline" />
      </div>
      <h1 class="dialog__title" data-e2e="connection-dialog-title">
        {{
          isOnline
            ? $t('components.connection-dialog.title.online')
            : $t('components.connection-dialog.title.offline')
        }}
      </h1>
      <p data-e2e="connection-dialog-content">
        {{
          isOnline
            ? $t('components.connection-dialog.description.online')
            : $t('components.connection-dialog.description.offline')
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { useOnlineStatus } from '@/features/orders';
import NotifySuccessIcon from '@/features/ui/icons/NotifySuccessIcon.vue';
import NotifyErrorIcon from '@/features/ui/icons/NotifyErrorIcon.vue';

export default defineComponent({
  name: 'ConnectionDialog',
  components: { NotifyErrorIcon, NotifySuccessIcon },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { isOnline } = useOnlineStatus();

    watch(
      isOnline,
      (isOnlineValue) => {
        if (isOnlineValue) {
          setTimeout(() => {
            emit('close');
          }, 1200);
        }
      },
      { deep: true },
    );
    return {
      isOnline,
    };
  },
});
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(var(-aldi-gray-light), 0.05);
  backdrop-filter: blur(6px);

  &__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 32px 24px 16px;
    margin-right: -50%;
    width: calc(100% - 32px);
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 44px rgba(var(--aldi-mid-blue-100), 0.15);
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }

  &__title {
    margin: 10px 0 16px;
    font-size: 24px;
    color: rgba(var(--aldi-basic-black), 1);
    line-height: 32px;
  }

  &__icon {
    margin: 0 auto;
    max-width: 64px;
  }
}
</style>
