<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0664 15.249C11.8928 15.249 11.723 15.3003 11.5787 15.3965C11.4343 15.4926 11.3218 15.6293 11.2553 15.7892C11.1889 15.9491 11.1715 16.125 11.2053 16.2947C11.2392 16.4645 11.3228 16.6204 11.4456 16.7427C11.5684 16.8651 11.7248 16.9484 11.8952 16.9822C12.0655 17.016 12.242 16.9986 12.4024 16.9324C12.5628 16.8662 12.7 16.754 12.7964 16.6101C12.8929 16.4663 12.9444 16.2971 12.9444 16.124C12.9444 15.892 12.8519 15.6694 12.6872 15.5053C12.5226 15.3412 12.2993 15.249 12.0664 15.249Z"
      fill="#D70000"
    />
    <path
      d="M12.041 13.5V10.5"
      stroke="#D70000"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M19 18.999H4L12 3.99902L16 11.499L18 15.249"
      stroke="#D70000"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5 19.749C20.5 18.9206 19.8284 18.249 19 18.249L17.5 18.249L17.5 19.749L20.5 19.749Z"
      fill="#D70000"
    />
    <path
      d="M19.5508 16.4004C18.8333 16.8146 17.916 16.5688 17.5017 15.8514L16.7517 14.5523L18.0508 13.8023L19.5508 16.4004Z"
      fill="#D70000"
    />
  </svg>
</template>
