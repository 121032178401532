<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.08191 13.6462C8.72138 13.6642 8.36638 13.5579 8.08519 13.3476C7.804 13.1373 7.61647 12.8379 7.55867 12.507L7.05546 7.59814C6.95698 6.63448 7.89212 5.80798 9.08191 5.80798C10.2712 5.80798 11.2064 6.63535 11.1079 7.59814L10.6047 12.507C10.547 12.8379 10.3595 13.1373 10.0784 13.3476C9.79731 13.5579 9.44238 13.6642 9.08191 13.6462V13.6462Z"
      stroke="#8EB826"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.07861 5.80804V4.19849"
      stroke="#8EB826"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.31906 6.63475C7.08937 6.52064 6.83295 6.46082 6.57245 6.46057C6.14865 6.47583 5.74468 6.63129 5.43352 6.89889C5.12236 7.16649 4.92451 7.52859 4.8757 7.91978C4.65975 9.58874 4.92518 11.281 5.64482 12.8234C5.73413 13.0593 5.89862 13.2642 6.11642 13.4112C6.33422 13.5581 6.59502 13.64 6.86415 13.646C7.06086 13.6437 7.25432 13.5991 7.42928 13.5156C7.60425 13.4321 7.75596 13.312 7.87245 13.1648"
      stroke="#8EB826"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8034 6.58206C11.0022 6.50182 11.2166 6.46046 11.4333 6.46057V6.46057C11.857 6.47581 12.2609 6.63122 12.5721 6.89873C12.8832 7.16624 13.0811 7.52823 13.13 7.91935C13.346 9.5883 13.0805 11.2806 12.3609 12.823C12.2716 13.0588 12.1071 13.2638 11.8893 13.4107C11.6715 13.5577 11.4107 13.6396 11.1416 13.6456C10.9636 13.6442 10.788 13.6078 10.6263 13.5388C10.4646 13.4697 10.3205 13.3696 10.2036 13.245"
      stroke="#8EB826"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="9" cy="9" r="8.5" stroke="#8EB826" />
  </svg>
</template>
