type LocalStorageItem<T> = {
  value: T;
  expiry: number;
};

export const setWithExpiry = <T>(key: string, value: T, ttl: number): void => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  try {
    const now = new Date();

    const item: LocalStorageItem<T> = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    // Do nothing
  }
};

export const getWithExpiry = <T>(key: string): T | null => {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  try {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr) as LocalStorageItem<T>;
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return item.value;
  } catch (error) {
    return null;
  }
};
