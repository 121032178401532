import { ProviderPluginFactory } from '@/features/core/plugin';
import { DeliveryUnitService } from './delivery-unit-service';

export * from './delivery-unit-service';

export const deliveryUnitServicePlugin =
  ProviderPluginFactory.create<DeliveryUnitService>({
    key: Symbol('DeliveryUnitService'),
    defaultFactory: {
      create: () => {
        return new DeliveryUnitService();
      },
    },
  });
