import { getUUID } from '@/utils/helpers/getUUID';
import { DeliveryUnit } from '../types';

export class DeliveryUnitService {
  public create(
    type = 'BAG',
    reference = '',
    parentReference = '',
    label = '',
    code = '',
    position = '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    temperatureClasses: any[] = [],
    options?: {
      codeTemplate?: string;
      useReferenceAsLabel?: boolean;
      sliceLabelBy?: number;
    },
  ): DeliveryUnit {
    const _reference = reference || getUUID();

    let _code: string = code;
    if (!_code && options && options.codeTemplate !== undefined) {
      _code = options.codeTemplate
        .replace('{reference}', _reference)
        .replace('{type}', type.toUpperCase());
    }

    let _label: string = options?.useReferenceAsLabel ? _reference : label;
    if (_label && options && options.sliceLabelBy !== undefined) {
      _label = _reference.slice(options.sliceLabelBy);
    }

    return {
      type,
      reference: _reference,
      parentReference,
      label: _label,
      code: _code,
      position,
      temperatureClasses,
    };
  }

  public bagDeliveryUnitFactory(): DeliveryUnit {
    return this.create('BAG', '', '', '', '', '', [], {
      codeTemplate: 'BAG:{reference}',
      useReferenceAsLabel: true,
      sliceLabelBy: -8,
    });
  }

  public bagDeliveryUnitsFactory(amount: number): DeliveryUnit[] {
    return Array.from({ length: amount }, () => this.bagDeliveryUnitFactory());
  }
}
