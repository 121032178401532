import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';

export class NewTourDriverNotifications
  extends RestorableEntity
  implements NewEntity
{
  public type = 'tourDriverNotification';
}
export class TourDriverNotificationItem
  extends NewTourDriverNotifications
  implements Entity
{
  public id!: string;
  public notificationType!: string;
  public payload!: string;
}

export class TourDriverNotificationStorageSetup
  implements StorageSetupStrategy
{
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.DriverNotification_1).stores({
      [`${storeName}`]: '&id, notificationType, payload',
    });

    return Promise.resolve();
  }
}
