<template>
  <svg
    width="18"
    viewBox="0 0 18 18"
    fill="#8EB826"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.625 2.625C2.625 2.91313 2.73332 3.17597 2.91146 3.375C3.11746 3.60516 3.41681 3.75 3.75 3.75L7.5 3.75C10.6066 3.75 13.125 6.2684 13.125 9.375V12.3295L9.77275 8.97725C9.55308 8.75758 9.19692 8.75758 8.97725 8.97725C8.75758 9.19692 8.75758 9.55308 8.97725 9.77275L12.3295 13.125H9.375C6.2684 13.125 3.75 10.6066 3.75 7.5L3.75 6.375C3.75 6.15441 3.68651 5.94866 3.57682 5.775C3.52082 5.68636 3.45278 5.60608 3.375 5.53646C3.17597 5.35832 2.91313 5.25 2.625 5.25V7.5C2.625 11.2279 5.64708 14.25 9.375 14.25H13.4545L14.7898 15.5853C15.0095 15.805 15.3657 15.805 15.5853 15.5853C15.805 15.3657 15.805 15.0095 15.5853 14.7898L14.25 13.4545V9.375C14.25 5.64708 11.2279 2.625 7.5 2.625H2.625Z"
    />
  </svg>
</template>
