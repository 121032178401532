export * from './reports';

import { apiPlugin } from '@/features/core/api';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { ReportsApiClient } from './reports';

export const reportsApiClientPlugin =
  ProviderPluginFactory.create<ReportsApiClient>({
    key: Symbol('ReportsApiClient'),
    defaultFactory: {
      create: () => new ReportsApiClient(apiPlugin.get()),
    },
  });
