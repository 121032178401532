import { MultiPluginFactory } from '@/features/core/plugin';
import { orderMetadataEntityPlugin } from './entities';
import { orderMetadataServicePlugin } from './services';

export * from './types';
export * from './entities';
export * from './services';

export const orderMetadataPlugin = MultiPluginFactory.with({
  orderMetadataEntityPlugin,
  orderMetadataServicePlugin,
});
