import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type {
  OrderItemRaw,
  OrderItemResponse,
  OrderParsePluginDto,
} from '../types';
import { OrderItemStatus } from '../types';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';

/**
 * Prepare rawItems with unchanged data from order items
 **/
export class NormalizeItemsPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public readonly requiredPlugins = [NormalizeOrderPlugin];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    dataTransferObject.rawItems = (
      dataTransferObject.rawItems as Array<OrderItemResponse | OrderItemRaw>
    ).map((item: OrderItemRaw | OrderItemResponse) => {
      const sku = 'product' in item ? item.product : item.sku;

      // #region item values
      // NOTE: In some cases, BE sends "null" for "quantityOriginal" and "amountOriginal". For example substitute item. And for correct work we must set the same value.
      const quantity = item.quantity ?? 0;
      const quantityOriginal =
        item.quantityOriginal !== null ? item.quantityOriginal : quantity;
      const amount = item.amount ?? 0;
      const amountOriginal =
        item.amountOriginal !== null ? item.amountOriginal : amount;
      // #endregion

      return {
        id: item.id,
        quantity,
        quantityOriginal,
        amount,
        amountOriginal,
        unit: item.unit ?? '',
        // some statuses are capitalized
        status: item.status.toLowerCase() as OrderItemStatus,
        product: sku ?? '',
        weights: [],
        isReplaceable: item.isReplaceable,
        originalId: item.originalId || null,
        scannedBarcodes: item.scannedBarcodes,
      };
    });
    return dataTransferObject;
  }
}
