<template>
  <svg
    width="24"
    fill="#0d3a93"
    stroke="#0d3a93"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00049 7V13.5V15M7.00049 20H17.0005C17.5528 20 18.0005 19.5523 18.0005 19V12.9091V7"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.00049 7H19.0005"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0005 7V5.2C15.0005 4.88174 14.8741 4.57652 14.649 4.35147C14.424 4.12643 14.1187 4 13.8005 4H10.2005C9.88223 4 9.577 4.12643 9.35196 4.35147C9.12692 4.57652 9.00049 4.88174 9.00049 5.2V7"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25049 13.9999H6.75049V15.4999C6.75049 16.3284 6.07892 16.9999 5.25049 16.9999V13.9999Z"
      stroke="none"
    />
    <path
      d="M5.60059 20.7499C5.60059 19.9215 6.27216 19.2499 7.10059 19.2499L8.60059 19.2499L8.60059 20.7499L5.60059 20.7499Z"
      stroke="none"
    />
  </svg>
</template>
