import { EventBus, EventBusEvent, EventBusEventType } from './types';
import { Cancellable } from '@/utils/types';

export class EventBusService implements EventBus {
  private eventList = new Map<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    EventBusEventType<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Set<(event: any) => void>
  >();

  on<T extends EventBusEvent>(
    event: EventBusEventType<T>,
    callback: (event: T) => void,
  ): Cancellable {
    if (!this.eventList.has(event)) {
      this.eventList.set(event, new Set());
    }
    this.eventList.get(event)?.add(callback);
    return {
      cancel: () => {
        this.eventList.get(event)?.delete(callback);
        if (this.eventList.get(event)?.size === 0) {
          this.eventList.delete(event);
        }
      },
    };
  }

  emit(event: EventBusEvent): void {
    this.eventList
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .get(event.constructor as EventBusEventType<any>)
      ?.forEach((callback) => {
        callback(event);
      });
  }
}
