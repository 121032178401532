import { OrderItem, OrderItemStatus } from '@/features/orders/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationDTO } from '../types';
import { ITEM_REJECTION_REASON } from '../types';

/**
 * Set rejectionReason for age restricted staged items
 */

export class SetItemRejectionReasonPlugin
  implements PipelinePlugin<AgeVerificationDTO>
{
  public execute(dto: AgeVerificationDTO): AgeVerificationDTO {
    dto.order.items.forEach((item: OrderItem) => {
      if (
        item.product.ageRestricted > 0 &&
        item.status === OrderItemStatus.staged
      ) {
        item.rejectionReason = ITEM_REJECTION_REASON;
      }
    });
    return dto;
  }
}
