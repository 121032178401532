import { VibrationOnce } from './vibrations';
import {
  ActionRequiredSound,
  ScanSuccessSound,
  ScanSuccessThenActionRequiredSound,
} from './sounds';

export const playlistSuccessBarcodeScan = [
  new VibrationOnce(), //Some delay needs after the device native beep sound
  new ScanSuccessSound(),
];

export const playlistActionRequiredBarcodeScan = [
  new VibrationOnce(), //Some delay needs after the device native beep sound
  new ActionRequiredSound(),
];

export const playlistSuccessBarcodeScanThenActionRequired = [
  new VibrationOnce(), //Some delay needs after the device native beep sound
  new ScanSuccessThenActionRequiredSound(),
];
