import { GetConfigurationPlugin } from '@/features/configuration';
import { configurationServicePlugin } from '@/features/configuration/services';
import { SaveOrderPlugin } from '@/features/orders/plugins/save-order';
import { ordersServicePlugin } from '@/features/orders';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { AgeVerificationDTO } from '../types';
import { SetItemRejectionReasonPlugin } from './set-item-rejection-reason';
import { SetItemRejectionStatusPlugin } from './set-item-rejection-status';
import { SetVerificationAttributePlugin } from './set-verification-attribute';
import { SetVerificationStatusPlugin } from './set-verification-status';

export function ageVerificationPluginRegister(): PluginPipeline<AgeVerificationDTO> {
  const handleAgeVerificationPlugin = new PluginPipeline<AgeVerificationDTO>();

  handleAgeVerificationPlugin.registerPlugins(
    [new SetVerificationAttributePlugin(), new SetVerificationStatusPlugin()],
    (dto) => dto.order.ageVerification !== null,
  );

  handleAgeVerificationPlugin.registerPlugins(
    [
      new GetConfigurationPlugin<AgeVerificationDTO>(
        configurationServicePlugin.get(),
        'orderItemRejection',
        'askReasonForRejection',
        'boolean',
        'itemRejectionReasonRequired',
      ),
    ],
    (dto) => !dto.isVerified,
  );

  handleAgeVerificationPlugin.registerPlugins(
    [new SetItemRejectionReasonPlugin()],
    (dto) => !dto.isVerified && dto.itemRejectionReasonRequired,
  );

  handleAgeVerificationPlugin.registerPlugins(
    [new SetItemRejectionStatusPlugin()],
    (dto) => !dto.isVerified,
  );

  handleAgeVerificationPlugin.registerPlugins([
    new SaveOrderPlugin<AgeVerificationDTO>(ordersServicePlugin.get()),
  ]);

  return handleAgeVerificationPlugin;
}
