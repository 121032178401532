import { MultiPluginFactory } from '@/features/core/plugin';
import { imageCachingQueueEntityPlugin } from './entities';
import { imageCachingQueueServicePlugin } from './services';

export * from './entities';
export * from './services';

export const imageCachingQueuePlugin = MultiPluginFactory.with({
  imageCachingQueueEntityPlugin,
  imageCachingQueueServicePlugin,
});
