import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { FirebaseProviderConfig, FirebaseService } from './firebase';
import { NoopPushService, PushServiceImpl } from './services';
import { PushService } from './types';

export const pushNotificationServicePlugin = ProviderPluginFactory.create<
  PushService,
  FirebaseProviderConfig
>({
  key: Symbol('pushNotificationService'),
  defaultFactory: {
    create: (app, options) => {
      return process.env.NODE_ENV === 'production' &&
        process.env.VUE_APP_SKIP_PUSH_NOTIFICATION_REGISTRATION === undefined
        ? new PushServiceImpl(new FirebaseService(options))
        : new NoopPushService();
    },
  },
});

export const pushNotificationPlugin = MultiPluginFactory.with({
  pushNotificationServicePlugin,
});
