import { ApiClient, Response } from '@/features/core/api';
import { Merchant, MerchantResponse } from '../types';
import { GetMerchantByIdRequestError } from '../errors';

export class MerchantApiClient {
  constructor(private api: ApiClient) {}

  async getByMerchantReference(merchantReference: string): Promise<Merchant> {
    const merchantResponse = await this.api.client.get<
      Response<MerchantResponse>
    >(`merchants/${merchantReference}`, {
      innerErrorCode: GetMerchantByIdRequestError.Code,
    });

    return this.parseMerchant(merchantResponse.data.data);
  }

  private parseMerchant(merchant: MerchantResponse): Merchant {
    return {
      ...merchant.attributes,
      reference: merchant.id,
    };
  }
}
