import { ref, watch } from 'vue';
import {
  first,
  from,
  fromEvent,
  map,
  Observable,
  Subscription,
  switchMap,
  timer,
} from 'rxjs';
import { AuthService } from '@/features/core/auth';
import { Storage } from '@/features/core/storage';
import { noop } from '@/utils/helpers/noop';
import { LogLevel, MessageTransferObject, Transporter } from '../types';
import { LoggerApiClient } from '../api';
import { Logs } from '../entities';
import { getWithExpiry, setWithExpiry } from '@/utils/helpers/localStorage';
import { getUUID } from '@/utils/helpers/getUUID';

const storageKey = 'BackendLoggingTransporterLogLevel';
const storageTTL = 1000 * 60 * 60 * 12;

export class BackendLoggingTransporter implements Transporter {
  defaultLogLevel: LogLevel = 30;
  private currentLogLevel: LogLevel = this.defaultLogLevel;
  private hasDeviceToken = ref(false);

  constructor(
    private storage: Storage,
    private loggerApi: LoggerApiClient,
    private authService: AuthService,
  ) {
    void this.setDeviceTokenAvailability();
    this.authService.onDeviceTokenChange(
      () => void this.setDeviceTokenAvailability(),
    );

    try {
      this.currentLogLevel = getWithExpiry(storageKey) ?? this.defaultLogLevel;
    } catch (error) {
      // do nothing
    }
  }

  write(
    logLevel: LogLevel,
    msgObject: MessageTransferObject,
    timestamp = new Date(),
  ): void {
    if (logLevel < this.currentLogLevel) {
      return;
    }
    void this.addLog(logLevel, timestamp, msgObject);
  }

  setLogLevel(logLevel: LogLevel): void {
    this.currentLogLevel = logLevel;
    try {
      setWithExpiry(storageKey, this.currentLogLevel, storageTTL);
    } catch (error) {
      // do nothing
    }
  }

  isExtendedLogging(): boolean {
    return this.currentLogLevel === 20;
  }

  flushLogsPeriodically(): Subscription {
    return (
      timer(0, 60000) // execute directly and then every minute
        .pipe(
          // Check if Device Token is available. If not, wait until device token is available
          switchMap(() => {
            if (!this.hasDeviceToken.value) {
              return new Observable((subscriber) => {
                const unwatch = watch(this.hasDeviceToken, (newValue) => {
                  if (newValue) {
                    unwatch();
                    subscriber.next(true);
                    subscriber.complete();
                  }
                });
              });
            } else {
              return from([true]);
            }
          }),
          // Check if Browser is online or offline. If offline, wait until browser is back online
          switchMap(() => {
            if (navigator.onLine) {
              return from([true]);
            } else {
              return fromEvent(window, 'online').pipe(
                first(),
                map(() => true),
              );
            }
          }),
        )
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        .subscribe(async () => {
          await this.flushLogs();
        })
    );
  }

  async flushLogs(): Promise<void> {
    const storedLogs = await this.getAllLogs();
    if (storedLogs.length) {
      try {
        const storedLogsIds = storedLogs.map((log) => log.id);
        await this.loggerApi.sendLogs(storedLogs);
        await this.removeSeveral(storedLogsIds);
      } catch (error) {
        noop();
      }
    }
  }

  addLog(
    logLevel: LogLevel,
    timestamp = new Date(),
    message: MessageTransferObject,
  ): void {
    void this.storage.save(
      Logs.from({
        id: `${timestamp.toISOString()}_${getUUID()}`,
        type: 'logs',
        timestamp: timestamp.toISOString(),
        message,
        logLevel,
      }),
    );
  }

  private async setDeviceTokenAvailability() {
    const deviceToken = await this.authService.getDeviceToken();
    this.hasDeviceToken.value = Boolean(deviceToken);
  }

  private async getAllLogs(): Promise<Logs[]> {
    return await this.storage.getAll(Logs);
  }

  private async removeSeveral(ids: string[]): Promise<void> {
    await this.storage.removeSeveral(Logs, { ids });
  }
}
