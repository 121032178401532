<template>
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.62573 5.18849C1.65854 3.35291 3.15677 1.875 5.00018 1.875C6.86414 1.875 8.37518 3.38604 8.37518 5.25L8.375 9.375C8.375 9.99632 8.87868 10.5 9.5 10.5L9.50073 5.0625H9.49635C9.39805 2.66417 7.42266 0.75 5.00018 0.75C2.57771 0.75 0.602317 2.66417 0.504019 5.0625H0.500732V5.17896C0.500367 5.2026 0.500183 5.22628 0.500183 5.25L0.500732 12.9375C0.500732 13.2482 0.752572 13.5 1.06323 13.5H4.4375V16.5C4.4375 16.8107 4.68934 17.0625 5 17.0625C5.31066 17.0625 5.5625 16.8107 5.5625 16.5V13.5H9.40625C9.40625 12.8787 8.90257 12.375 8.28125 12.375H1.62573V5.18849Z"
      fill="#55C3F0"
    />
  </svg>
</template>
