import { calculateBarcodeCheckDigit } from './calculateBarcodeCheckDigit';

export const isValidBarcode = (barcode: string): boolean => {
  const result = calculateBarcodeCheckDigit(barcode);
  if (result === null) {
    return false;
  }

  return result.checkDigit === result.calculatedCheckDigit;
};
