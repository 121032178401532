import { ProviderPluginFactory } from '@/features/core/plugin';
import { TourCrashServiceConfig } from '../types';
import { TourCrashService } from './tour-crash';
import { router } from '@/features/core/router';

export * from './tour-crash';

export const tourCrashServicePlugin = ProviderPluginFactory.create<
  TourCrashService,
  TourCrashServiceConfig
>({
  key: Symbol('TourCrashService'),
  defaultFactory: {
    create: (_, options) => {
      return new TourCrashService(options, router.get());
    },
  },
});
