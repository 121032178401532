import { ExecPluginFactory } from '@/features/core/plugin';
import { storageSetupPlugin } from '@/features/core/storage';
import {
  ImageCachingQueue,
  ImageCachingQueueStorageSetup,
} from './imageCachingQueue';

export * from './imageCachingQueue';

export const imageCachingQueueEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin
    .get()
    .add(ImageCachingQueue, new ImageCachingQueueStorageSetup());
});
