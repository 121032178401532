const conversions = {
  hours: 1000 * 60 * 60,
  minutes: 1000 * 60,
  seconds: 1000,
  milliseconds: 1,
} as const;

export const calculateTimePassedSinceTimestamp = (
  timestamp: string | number,
  timeUnit: keyof typeof conversions = 'milliseconds',
  round = true,
): number => {
  const now = new Date();
  const targetTimestamp = new Date(timestamp);
  const timeDiffInMilliseconds = now.getTime() - targetTimestamp.getTime();

  if (!(timeUnit in conversions)) {
    throw new Error('Invalid time unit');
  }

  const result = timeDiffInMilliseconds / conversions[timeUnit];

  return round ? Math.floor(result) : result;
};
