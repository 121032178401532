import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { apiPlugin } from '@/features/core/api';
import { authServicePlugin } from '@/features/core/auth';
import { deviceIdServicePlugin } from '@/features/device-id';
import { ServiceWorkerEventTypes } from '@/features/service-worker';
import {
  loggerServiceOptions,
  SaveLogsToMemoryTransporterInterface,
  Transporter,
} from './types';
import {
  BackendLoggingTransporter,
  BrowserLoggingTransporter,
  DynatraceTransporter,
  LoggerService,
  NewRelicTransporter,
  NoopNewRelicTransporter,
} from './services';
import { logsEntityPlugin } from './entities';
import { LoggerApiClient } from './api';
import { SaveLogsToMemoryTransporter } from './services/save-logs-to-memory-transporter';

export const visibilityChangeLogExecutePlugin = ExecPluginFactory.fn(() => {
  loggerServicePlugin.get().info('App was opened');

  if (document === undefined) {
    return;
  }

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      loggerServicePlugin.get().info('App is now in the foreground');
    }
  });
});

export const loggerApiPlugin = ProviderPluginFactory.create<LoggerApiClient>({
  key: Symbol('loggerApiClient'),
  defaultFactory: {
    create: () => {
      return new LoggerApiClient(
        apiPlugin.get(),
        deviceIdServicePlugin.get(),
        authServicePlugin.get(),
      );
    },
  },
});

export const browserLoggingTransporterPlugin =
  ProviderPluginFactory.create<BrowserLoggingTransporter>({
    key: Symbol('browserLoggingTransporter'),
    defaultFactory: {
      create: () => {
        return new BrowserLoggingTransporter();
      },
    },
  });

export const newRelicTransporterPlugin =
  ProviderPluginFactory.create<Transporter>({
    key: Symbol('newRelicTransporter'),
    defaultFactory: {
      create: () => {
        return process.env.VUE_APP_ENV_FOR_ISSUE_LOGGING_SCRIPT
          ? new NewRelicTransporter()
          : new NoopNewRelicTransporter();
      },
    },
  });

export const saveLogsToMemoryTransporterPlugin =
  ProviderPluginFactory.create<Transporter>({
    key: Symbol('saveLogsToMemoryTransporter'),
    defaultFactory: {
      create: () => {
        return new SaveLogsToMemoryTransporter();
      },
    },
  });

export const dynatraceTransporterPlugin =
  ProviderPluginFactory.create<Transporter>({
    key: Symbol('dynatraceTransporter'),
    defaultFactory: {
      create: () => {
        return new DynatraceTransporter();
      },
    },
  });

export const backendLoggingTransporterPlugin =
  ProviderPluginFactory.create<BackendLoggingTransporter>({
    key: Symbol('backendLoggingTransporter'),
    defaultFactory: {
      create: () => {
        return new BackendLoggingTransporter(
          storagePlugin.get(),
          loggerApiPlugin.get(),
          authServicePlugin.get(),
        );
      },
    },
  });

export const loggerServicePlugin = ProviderPluginFactory.create<
  LoggerService,
  loggerServiceOptions
>({
  key: Symbol('loggerService'),
  defaultFactory: {
    create: (app, options) => {
      return new LoggerService(options);
    },
  },
});

export const loggerExecutePlugin = ExecPluginFactory.fn(async () => {
  loggerServicePlugin.get().addTransporters(getTransporters());
  loggerServicePlugin.get().setLoggerInterceptors(apiPlugin.get());
  backendLoggingTransporterPlugin.get().flushLogsPeriodically();

  const registration = await navigator.serviceWorker.ready;
  registration?.active?.postMessage({
    version: process.env.VUE_APP_VERSION,
    type: ServiceWorkerEventTypes.SetAppVersion,
  });
});

const getTransporters = () => {
  const transporters: Transporter[] = [
    (window.browserLogging = browserLoggingTransporterPlugin.get()),
    backendLoggingTransporterPlugin.get(),
  ];

  if (process.env.VUE_APP_ENV_FOR_ISSUE_LOGGING_SCRIPT) {
    transporters.push(newRelicTransporterPlugin.get());
    transporters.push(dynatraceTransporterPlugin.get());
  }

  if (process.env.VUE_APP_CI) {
    transporters.push(
      (window.memoryLogsHistory =
        saveLogsToMemoryTransporterPlugin.get() as SaveLogsToMemoryTransporterInterface),
    );
  }

  return transporters;
};

export const loggerTransportersPlugin = MultiPluginFactory.with({
  saveLogsToMemoryTransporterPlugin,
  logsEntityPlugin,
  loggerApiPlugin,
  newRelicTransporterPlugin,
  dynatraceTransporterPlugin,
  browserLoggingTransporterPlugin,
  backendLoggingTransporterPlugin,
  loggerExecutePlugin,
});

export const loggerPlugin = MultiPluginFactory.with({
  loggerServicePlugin,
});

export const visibilityChangeLogPlugin = MultiPluginFactory.with({
  visibilityChangeLogExecutePlugin,
});
