import { ref, Ref, watch, WatchStopHandle } from 'vue';
import { errorPlugin } from '@/features/core/errors';
import {
  Order,
  OrderPluginDto,
  StartConfirmationPlugin,
} from '@/features/orders';
import type { StartPickingSwitchers } from '@/features/orders/types';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import { PickingAlreadyStartedError } from '../errors';
import { startPickingRegisterPlugin } from './startPickingRegisterPlugin';
import { useOnlineStatus } from './useOnlineStatus';

export async function startPickingProcess(
  order: Order,
  switchers: StartPickingSwitchers,
): Promise<void> {
  try {
    let startOrderPlugin = new PluginPipeline<OrderPluginDto>();

    setHooks(startOrderPlugin);

    startOrderPlugin = startPickingRegisterPlugin(
      order,
      switchers,
      startOrderPlugin,
    );

    await startOrderPlugin.execute({
      order,
    });
  } catch (error) {
    switchers.isProcessing.value = false;
    switchers.isUserTokenRefreshing.value = false;
    if (error instanceof PipelineExecutionError) {
      if (
        error.dataTransferObject.error instanceof PickingAlreadyStartedError
      ) {
        switchers.isOrderAlreadyStarted.value = true;
      } else {
        errorPlugin.get().handle(error.originalError);
      }
    } else {
      errorPlugin.get().handle(error);
    }
  }
}

const setHooks = (startOrderPlugin: PluginPipeline<OrderPluginDto>): void => {
  const cleanUpIsOnlineWatcher: Ref<WatchStopHandle | undefined> =
    ref(undefined);
  const { isOnline } = useOnlineStatus();
  const { close } = useDynamicDialog();

  startOrderPlugin.beforeEach((dto, pluginName) => {
    if (pluginName === StartConfirmationPlugin.name) {
      cleanUpIsOnlineWatcher.value = watch(isOnline, (newValue) => {
        if (!newValue && cleanUpIsOnlineWatcher.value !== undefined) {
          close();
          cleanUpIsOnlineWatcher.value();
        }
      });
    }
  });

  startOrderPlugin.afterEach((dto, pluginName) => {
    if (
      pluginName === StartConfirmationPlugin.name &&
      cleanUpIsOnlineWatcher.value !== undefined
    ) {
      cleanUpIsOnlineWatcher.value();
    }
  });
};
