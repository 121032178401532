export class PromiseSubject<T> extends Promise<T> {
  private readonly _resolveFn: (value: T | PromiseLike<T>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly _rejectFn: (reason?: any) => void;

  constructor(
    executor?: (
      resolve: (value: T | PromiseLike<T>) => void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reject: (reason?: any) => void,
    ) => void,
  ) {
    let resolve: (value: T | PromiseLike<T>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: (reason?: any) => void;
    super((res, rej) => {
      resolve = res;
      reject = rej;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._resolveFn = resolve!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._rejectFn = reject!;
    executor?.(this._resolveFn, this._rejectFn);
  }

  resolve(value: T | PromiseLike<T>): void {
    this._resolveFn(value);
  }

  reject(reason?: T): void {
    this._rejectFn(reason);
  }
}
