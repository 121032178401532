export * from './merhcant';

import { apiPlugin } from '@/features/core/api';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { MerchantApiClient } from './merhcant';

export const merchantApiClientPlugin =
  ProviderPluginFactory.create<MerchantApiClient>({
    key: Symbol('MerchantApiClient'),
    defaultFactory: {
      create: () => new MerchantApiClient(apiPlugin.get()),
    },
  });
