import { App, InjectionKey } from 'vue';
import { resolvePluginOptions, VuePlugin } from './plugin';

export interface ProviderPluginFactoryOptions<T, O> {
  key: InjectionKey<T>;
  defaultFactory?: ProviderImplementationFactory<T, O>;
}

export class ProviderPluginFactory {
  static create<T, O = unknown>(
    options: ProviderPluginFactoryOptions<T, O>,
  ): ProviderPlugin<T, O> {
    return new ProviderPlugin<T, O>(options);
  }
}

export type ProviderPluginOptions<T, O> = O & {
  factory?: ProviderImplementationFactory<T, O>;
};

export interface ProviderImplementationFactory<T, O = unknown> {
  create(app: App, options: O): T;
}

export class ProviderPlugin<T, O> implements VuePlugin {
  private instance?: T;

  constructor(private options: ProviderPluginFactoryOptions<T, O>) {}

  install(
    app: App,
    options?: ProviderPluginOptions<T, O> | (() => ProviderPluginOptions<T, O>),
  ): void {
    const opts: ProviderPluginOptions<T, O> = resolvePluginOptions(options);
    const factory = opts?.factory || this.options.defaultFactory;

    if (!factory) {
      throw new Error(
        `ProviderPlugin: No factory provided for plugin '${this.options.key.toString()}'`,
      );
    }

    this.instance = factory.create(app, opts);

    app.provide(this.options.key, this.instance);
  }

  get(): T {
    if (!this.instance) {
      throw new Error(
        `ProviderPlugin: Plugin '${this.options.key.toString()}' has not been installed yet!
Please check the order of this plugin in the plugins configuration and make sure it's dependencies are listed first.`,
      );
    }

    return this.instance;
  }
}
