import { Order } from '@/features/orders/entities';
import { Product } from '@/features/products/entities';
import { StorageZone } from '@/features/storageZone/entities';
import { Entity, EntityType } from '@/features/core/entity-repository';

export function getEntityByPushType(
  entityName: string | undefined,
): EntityType<Entity> | undefined {
  if (!entityName) {
    return undefined;
  }

  const entityMap: Record<string, EntityType<Entity>> = {
    orders: Order,
    products: Product,
    'storage-zones': StorageZone,
  };

  return entityMap[entityName];
}
