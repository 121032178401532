import { ProviderPluginFactory } from '@/features/core/plugin';
import { reportsServicePlugin } from '@/features/reports';
import { FailedTransmissionsService } from './failed-transmissions-service';

export * from './failed-transmissions-service';

export const failedTransmissionsServicePlugin =
  ProviderPluginFactory.create<FailedTransmissionsService>({
    key: Symbol('FailedTransmissionsService'),
    defaultFactory: {
      create: () => {
        return new FailedTransmissionsService(reportsServicePlugin.get());
      },
    },
  });
