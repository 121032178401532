<template>
  <svg
    width="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.44035 1.76334C8.35321 1.60117 8.184 1.5 7.99991 1.5C7.81581 1.5 7.6466 1.60117 7.55947 1.76334L1.55955 12.9298C1.47629 13.0848 1.48059 13.2721 1.57086 13.4231C1.66112 13.5741 1.82409 13.6665 1.99999 13.6665H14.4998C14.4998 13.1142 14.0521 12.6665 13.4998 12.6665H2.83624L7.99991 3.05635L11.6847 9.91411L12.0584 10.617C12.3177 11.1046 12.9232 11.2897 13.4108 11.0305L12.966 10.1939C12.9586 10.1751 12.9501 10.1565 12.9403 10.1382L12.5666 9.4427L12.4719 9.26459L12.4707 9.26423L8.44035 1.76334Z"
      fill="#E00016"
    />
    <path
      d="M7.99991 6.49993C8.27604 6.49993 8.4999 6.72378 8.4999 6.99992V9.16656C8.4999 9.44269 8.27604 9.66655 7.99991 9.66655C7.72377 9.66655 7.49991 9.44269 7.49991 9.16656V6.99992C7.49991 6.72378 7.72377 6.49993 7.99991 6.49993Z"
      fill="#E00016"
    />
    <path
      d="M8.01651 10.3332C7.90114 10.3332 7.78836 10.3674 7.69243 10.4315C7.5965 10.4956 7.52174 10.5867 7.47758 10.6933C7.43343 10.7999 7.42188 10.9172 7.44439 11.0303C7.4669 11.1435 7.52245 11.2474 7.60403 11.329C7.68561 11.4106 7.78955 11.4661 7.90271 11.4886C8.01586 11.5112 8.13315 11.4996 8.23974 11.4555C8.34632 11.4113 8.43743 11.3365 8.50152 11.2406C8.56562 11.1447 8.59983 11.0319 8.59983 10.9165C8.59983 10.7618 8.53837 10.6135 8.42898 10.5041C8.31959 10.3947 8.17121 10.3332 8.01651 10.3332Z"
      fill="#E00016"
    />
  </svg>
</template>
