import { TourDropApiClient } from './tour-drop-api-client';
import { TourDropItem } from '../entities';
import { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';

export const registerTourDropApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  dropApi: TourDropApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(TourDropItem, {
    save: async (entity: TourDropItem): Promise<TourDropItem> =>
      dropApi.patchDrop(entity),
  });
};
