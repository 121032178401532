import {
  DriverNotification,
  DriverNotificationsApiClientInterface,
} from '../types';
import {
  first,
  from,
  fromEvent,
  map,
  Observable,
  Subscription,
  switchMap,
  timer,
} from 'rxjs';
import { DriverNotificationsRegistry } from './driver-notifications-registry';
import { ref, watch } from 'vue';
import { AuthService } from '@/features/core/auth';
import { loggerServicePlugin } from '@/features/core/logger';

export class DriverNotificationsService {
  private hasDeviceToken = ref(false);
  private subscription!: Subscription;
  private started = false;

  constructor(
    private apiClient: DriverNotificationsApiClientInterface,
    private driverNotificationsRegistry: DriverNotificationsRegistry,
    private authService: AuthService,
  ) {
    void this.setDeviceTokenAvailability();
    this.authService.onDeviceTokenChange(
      () => void this.setDeviceTokenAvailability(),
    );
  }

  private async setDeviceTokenAvailability() {
    const deviceToken = await this.authService.getDeviceToken();
    this.hasDeviceToken.value = Boolean(deviceToken);
  }

  async processDriverNotifications(): Promise<void> {
    const newNotifications: DriverNotification[] =
      await this.apiClient.getNewDriverNotifications();
    if (newNotifications.length === 0) return;
    const processedNotifications: Set<number> = new Set();
    newNotifications.forEach((newNotification) => {
      if (newNotification.readStatus) return;
      this.driverNotificationsRegistry.getRegistry().forEach((processor) => {
        if (
          processor.typeResponsibilities.includes(
            newNotification.type.toLowerCase(),
          )
        ) {
          void processor.processDriverNotification(newNotification);
          processedNotifications.add(newNotification.id);
        }
      });
    });
    if (processedNotifications.size > 0) {
      try {
        await this.apiClient.markDriverNotificationsAsRead(
          Array.from(processedNotifications.values()),
        );
      } catch (err) {
        loggerServicePlugin.get().error(err);
      }
    }
  }

  startPeriodicalTask(): void {
    if (this.started) return;
    this.subscription = timer(0, 60000) // execute directly and then every minute
      .pipe(
        // Check if Device Token is available. If not, wait until device token is available
        switchMap(() => {
          if (this.hasDeviceToken.value) {
            return from([true]);
          }
          return new Observable((subscriber) => {
            const unwatch = watch(this.hasDeviceToken, (newValue) => {
              if (newValue) {
                unwatch();
                subscriber.next(true);
                subscriber.complete();
              }
            });
          });
        }),
        // Check if Browser is online or offline. If offline, wait until browser is back online
        switchMap(() => {
          if (navigator.onLine) {
            return from([true]);
          } else {
            return fromEvent(window, 'online').pipe(
              first(),
              map(() => true),
            );
          }
        }),
      )
      .subscribe(() => void this.processDriverNotifications());

    this.started = true;
  }
  stopPeriodicalTask(): void {
    this.subscription.unsubscribe();
  }
}
