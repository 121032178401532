<template>
  <div class="counter ut-font_fix" :class="{ 'counter__left-position': left }">
    <span class="counter__item" :data-e2e="`${dataE2E}-quantity-label-${sku}`">
      {{ orderItem.quantity }}</span
    >
    <span>of</span>
    <span
      class="counter__item"
      :data-e2e="`${dataE2E}-quantityOriginal-label-${sku}`"
      >{{ orderItem.quantityOriginal }}</span
    >
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { OrderItem } from '@/features/orders/types';

export default defineComponent({
  name: 'CounterBox',
  props: {
    currentTab: {
      type: String,
    },
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    dataE2E: {
      type: String,
      default: 'picking',
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const sku = computed<string>(() => {
      return props.orderItem.product
        ? props.orderItem.product.sku
        : props.orderItem.id;
    });
    return {
      sku,
    };
  },
});
</script>

<style lang="scss" scoped>
.counter {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0 4px;
  font-size: 12px;
  &__item {
    font-size: 16px;
    line-height: 1em;
    font-weight: 600;
  }
  &__left-position {
    justify-content: left;
  }
}
</style>
