<template>
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0.75C0 0.335786 0.335786 0 0.75 0H13.25C13.6642 0 14 0.335786 14 0.75V8.25C14 8.66421 13.6642 9 13.25 9C12.8358 9 12.5 8.66421 12.5 8.25V1.5H1.5V12.25C1.5 12.3881 1.61193 12.5 1.75 12.5H3.12951C3.45525 11.3597 4.50514 10.525 5.75 10.525C6.99486 10.525 8.04475 11.3597 8.37049 12.5H12.1295C12.4552 11.3597 13.5051 10.525 14.75 10.525C15.995 10.525 17.045 11.3599 17.3706 12.5004H18.7499C18.8879 12.5004 18.9999 12.3884 18.9999 12.2504V8.79489L16.4606 6.0603C15.8969 5.45323 15.9321 4.50413 16.5391 3.94043L20.2995 7.99004C20.4283 8.12876 20.4999 8.31107 20.4999 8.50037V12.2504C20.4999 13.2169 19.7164 14.0004 18.7499 14.0004H17.3704C17.0445 15.1405 15.9947 15.975 14.75 15.975C13.5052 15.975 12.4553 15.1403 12.1295 14H8.37048C8.04474 15.1403 6.99485 15.975 5.75 15.975C4.50515 15.975 3.45526 15.1403 3.12952 14H1.75C0.783502 14 0 13.2165 0 12.25V0.75ZM4.475 13.25C4.475 12.5458 5.04584 11.975 5.75 11.975C6.45416 11.975 7.025 12.5458 7.025 13.25C7.025 13.9542 6.45416 14.525 5.75 14.525C5.04584 14.525 4.475 13.9542 4.475 13.25ZM13.475 13.25C13.475 12.5458 14.0458 11.975 14.75 11.975C15.4542 11.975 16.025 12.5458 16.025 13.25C16.025 13.9542 15.4542 14.525 14.75 14.525C14.0458 14.525 13.475 13.9542 13.475 13.25Z"
      fill="#101316"
    />
  </svg>
</template>
