export class SessionStorageService {
  setItem(key: string, value: unknown): void {
    sessionStorage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value),
    );
  }

  getItem<T>(key: string): T | null {
    const data = sessionStorage.getItem(key);

    if (data === null) {
      return null;
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(data);
    } catch (e) {
      return data as unknown as T; // TODO: remove the type assertion
    }
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
