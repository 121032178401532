import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { storageZoneApiPlugin } from '../api';
import { StorageZoneService } from './storage-zone-service';

export * from './storage-zone-service';

export const storageZoneServicePlugin =
  ProviderPluginFactory.create<StorageZoneService>({
    key: Symbol('StorageZoneService'),
    defaultFactory: {
      create: () => {
        return new StorageZoneService(
          storagePlugin.get(),
          storageZoneApiPlugin.get(),
        );
      },
    },
  });
