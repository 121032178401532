import { Ref, ref } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { appCrashServicePlugin } from '@/features/app-crash';
import { driverAppRedirectServicePlugin } from '@/features/driver-app-redirect';
import { getParamFromHashtagUrl, scrollToOrder } from '../helpers';
import { UseOrderList } from '../types';
import { OrderCard } from '../components';

export function useOrderList(): UseOrderList {
  const search = ref('');
  const searchIsActive = ref(false);

  const activatedSearch = (active: boolean) => {
    searchIsActive.value = active;
  };

  const redirectGuard = async (next: NavigationGuardNext): Promise<void> => {
    const pluginSuccesses = [
      await appCrashServicePlugin.get().checkUnfinishedProcessAndRedirect(),
      await driverAppRedirectServicePlugin
        .get()
        .checkUserRoleAndRedirectIfDriver(),
    ];
    let nextCalled = false;
    for (const isSuccess of pluginSuccesses) {
      if (!nextCalled) {
        next(!isSuccess);
        nextCalled = true;
      }
    }
    if (!nextCalled) {
      next();
    }
  };

  const handleRoute = (
    route: RouteLocationNormalized,
    cardsRefs: Ref<{ [key: string]: typeof OrderCard }>,
  ): Promise<string | null> => {
    return new Promise((resolve) => {
      const orderId = getParamFromHashtagUrl(route, 'id');

      if (!orderId) {
        return resolve(null);
      }

      const card = cardsRefs.value[`card-${String(orderId)}`];

      //If route is on picking tab, then scroll to the related order
      if (route.hash.startsWith('#picking?id=') && card) {
        void scrollToOrder(card);
        return resolve(orderId);
      }

      if (route.hash.startsWith('#handover?id=') && card) {
        return resolve(orderId);
      }

      return resolve(null);
    });
  };

  return {
    search,
    searchIsActive,
    activatedSearch,
    redirectGuard,
    handleRoute,
  };
}
