<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.71966 7.78031L0.719669 4.78032C0.426777 4.48743 0.426777 4.01256 0.719669 3.71966L3.71966 0.719669C4.01255 0.426777 4.48743 0.426777 4.78032 0.719669C5.07321 1.01256 5.07321 1.48743 4.78032 1.78033L3.06065 3.49999L12 3.5C15.8094 3.5 19.0002 6.68606 19.0002 10.501C19.0002 14.3166 15.8102 17.501 12.0002 17.501V17.5L2 17.5C2 16.6716 2.67157 16 3.5 16L12.0002 16C14.9972 16 17.5002 13.4996 17.5002 10.501C17.5002 7.46341 15.0378 5.00098 12.0002 5.00098V5H3.06066L4.78032 6.71966C5.07321 7.01255 5.07321 7.48742 4.78032 7.78031C4.48743 8.07321 4.01256 8.07321 3.71966 7.78031Z"
    />
  </svg>
</template>
