/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/require-await */
import { StatusToActionServiceInterface } from '@/features/tours';
import {
  DetailedDropAction,
  DropActionsMiddlemile,
  DropStatusMiddlemile,
  TourDrop,
  TourDropFilterOptions,
} from '../types';
import { $t } from '@/i18n';

export class DropStatusToActionMiddlemileService
  implements
    StatusToActionServiceInterface<
      TourDrop,
      DropStatusMiddlemile,
      DropActionsMiddlemile
    >
{
  private returnOpenActions = async (
    tourDrop: TourDrop,
    filterOptions: TourDropFilterOptions,
  ): Promise<DetailedDropAction[]> => {
    return [this.createStartDeliveryAction()];
  };

  private returnDeliveryInProgressActions = async (
    tourDrop: TourDrop,
    filterOptions: TourDropFilterOptions,
  ): Promise<DetailedDropAction[]> => {
    return [
      this.createConfirmDeliveryAction(),
      this.createRouteGuidanceAction(),
    ];
  };

  private returnDeliveryCompletedActions = async (
    tourDrop: TourDrop,
    filterOptions: TourDropFilterOptions,
  ): Promise<DetailedDropAction[]> => {
    return [this.createStartUnloadingAction()];
  };

  private returnUnloadingActions = async (
    tourDrop: TourDrop,
    filterOptions: TourDropFilterOptions,
  ): Promise<DetailedDropAction[]> => {
    if (filterOptions.from === 'CrossdockUnloading') {
      return [this.createCompleteUnloadingAction()];
    }
    return [this.createContinueUnloadingAction()];
  };

  private returnLoadingEmptyFramesActions = async (
    tourDrop: TourDrop,
    filterOptions: TourDropFilterOptions,
  ): Promise<DetailedDropAction[]> => {
    if (filterOptions.from === 'CrossdockLoadingEmptyFrame') {
      return [this.createFinishLoadingAction()];
    } else if (filterOptions.from === 'CrossdockUnloading') {
      return [this.createContinueLoadingAction()];
    }
    return [this.createContinueLoadingAction()];
  };

  private returnDropCompletedActions = async (
    tourDrop: TourDrop,
    filterOptions: TourDropFilterOptions,
  ): Promise<DetailedDropAction[]> => {
    return [];
  };

  statusToActionMap: Map<
    DropStatusMiddlemile,
    (
      tourDrop: TourDrop,
      filterOptions: TourDropFilterOptions,
    ) => Promise<DetailedDropAction[]>
  > = new Map([
    [DropStatusMiddlemile.open, this.returnOpenActions],
    [
      DropStatusMiddlemile.delivery_in_progress,
      this.returnDeliveryInProgressActions,
    ],
    [
      DropStatusMiddlemile.delivery_completed,
      this.returnDeliveryCompletedActions,
    ],
    [DropStatusMiddlemile.unloading, this.returnUnloadingActions],
    [
      DropStatusMiddlemile.loading_empty_frames,
      this.returnLoadingEmptyFramesActions,
    ],
    [DropStatusMiddlemile.drop_completed, this.returnDropCompletedActions],
  ]);

  createStartDeliveryAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.start_drop_off,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.start_drop_off}"].text`,
        ),
        'data-e2e': 'start-drop-off-action-button',
      },
    };
  }

  createConfirmDeliveryAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.confirm_arrival,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.confirm_arrival}"].text`,
        ),
        'data-e2e': 'confirm-button',
      },
    };
  }

  createStartUnloadingAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.start_unloading,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.start_unloading}"].text`,
        ),
        'data-e2e': 'start-unloading-button',
      },
    };
  }
  createContinueUnloadingAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.continue_unloading,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.continue_unloading}"].text`,
        ),
        'data-e2e': 'continue-unloading',
      },
    };
  }

  createCompleteUnloadingAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.complete_unloading,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.complete_unloading}"].text`,
        ),
      },
    };
  }

  createContinueLoadingAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.continue_loading,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.continue_loading}"].text`,
        ),
        'data-e2e': 'continue-loading',
      },
    };
  }

  createFinishLoadingAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.finish_loading,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.finish_loading}"].text`,
        ),
      },
    };
  }

  createRouteGuidanceAction(): DetailedDropAction {
    return {
      action: DropActionsMiddlemile.open_route_guidance,
      ctaData: {
        label: $t(
          `components.drops-list.actions["${DropActionsMiddlemile.open_route_guidance}"].text`,
        ),
        type: 'secondary',
        'data-e2e': 'route-guidance-link',
      },
    };
  }

  async getActions(
    tourDrop: TourDrop,
    filterOptions: TourDropFilterOptions = { from: '' },
  ): Promise<DetailedDropAction[]> {
    const actions = this.statusToActionMap.get(
      tourDrop.status as DropStatusMiddlemile,
    );
    return (await actions?.call(this, tourDrop, filterOptions)) ?? [];
  }
}
