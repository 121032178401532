import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import { DropStatus, DropStatusCleveron, DropStatusMiddlemile } from '../types';
import { ExchangedItem, Order } from '@/features/orders';
import {
  BottleDepositReturn,
  RejectedDeliveryItem,
} from '@/features/tour-drops/types';

export class NewDrop extends RestorableEntity implements NewEntity {
  type = 'tourDrop';
}
export class TourDropItem extends NewDrop implements Entity {
  id!: string;
  tourId!: string;
  reference!: string;
  address!: {
    floor: string;
    address1: string;
    address2: string;
    zipCode: string;
    city: string;
    countryName: string;
    countryIsoCode: string;
    phoneNumber: string;
    firstName?: string;
    lastName?: string;
  };
  newCustomer!: boolean;
  orders?: Order[];
  orderIds!: string[];
  startTime!: string;
  endTime!: string;
  actions!: string[];
  status!: DropStatus | DropStatusMiddlemile | DropStatusCleveron;
  statusChangedAt?: string | null;
  customerNote?: string;
  deliveryUnitReferences!: string[];
  orderReferences!: string[];
  rejectedDeliveryItems?: RejectedDeliveryItem[];
  bottleDepositReturns?: BottleDepositReturn[];
  exchangedDeliveryItems?: ExchangedItem[];
  pauseTime!: string | null;
  hasExchangeable!: boolean;
  surveyId?: number;
  clearingRequired!: boolean;
}

export class TourDropStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Tour_1).stores({
      [`${storeName}`]:
        '&id, tourId, reference, status, actions, startTime, endTime',
    });
    version(DBVersions.Tour_3).stores({
      [`${storeName}`]:
        '&id, tourId, reference, status, statusChangedAt, actions, startTime, endTime',
    });

    return Promise.resolve();
  }
}
