import { ApiClient } from '@/features/core/api';
import { ErrorHandler } from '@/features/core/errors';
import { SurveyAnswerItem } from '../entities';
import {
  DropSurveyAnswersAlreadySubmittedError,
  PostDropSurveyAnswersError,
  isSurveyAlreadySubmitted,
} from '../errors';

export class SurveyApiClient {
  constructor(private api: ApiClient, private errorHandler: ErrorHandler) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSurvey(surveyId: number): Promise<any | undefined> {
    const surveyUrl = `/surveys/${surveyId}.json`;
    const response = await fetch(surveyUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (
      response.ok === false ||
      response.headers.get('Content-Type')?.includes('json') === false
    ) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await response.json();
  }

  async postDropSurveyAnswers(
    surveyAnswerItem: SurveyAnswerItem,
  ): Promise<SurveyAnswerItem> {
    const { surveyId, dropReference, answers } = surveyAnswerItem;
    const request = {
      data: {
        type: 'drop-survey-answers',
        attributes: {
          surveyId,
          dropReference,
          answers,
        },
      },
    };

    try {
      await this.api.client.post(`/drop-survey-answers`, request, {
        disableErrorHandling: true,
        innerErrorCode: PostDropSurveyAnswersError.Code,
      });
    } catch (error) {
      if (isSurveyAlreadySubmitted(error)) {
        this.errorHandler.handle(new DropSurveyAnswersAlreadySubmittedError());
      } else {
        const postDropSurveyAnswersError = new PostDropSurveyAnswersError();
        this.errorHandler.handle(postDropSurveyAnswersError);
        throw postDropSurveyAnswersError;
      }
    }

    return surveyAnswerItem;
  }
}
