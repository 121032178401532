import { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderPluginDto } from '../types';

export class SetLastOrderStatusChangedPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  constructor(private date?: Date) {}
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    dataTransferObject.order.lastLocalStatusChange = this.date || new Date();
    return dataTransferObject;
  }
}
