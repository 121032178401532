import { MultiPluginFactory } from '@/features/core/plugin';
import { oauthLoginApiClientPlugin } from './api';

export * from './views';
export * from './api';
export * from './composables';
export * from './types';

export const oauthLoginConfigPlugin = MultiPluginFactory.with({
  oauthLoginApiClientPlugin,
});
