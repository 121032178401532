import type { Ref } from 'vue';
import { $t } from '@/i18n';

export interface FeatureToggle {
  title: string;
  description?: string;
  active: boolean;
}

export interface FeatureToggles {
  [key: string]: FeatureToggle;
}

export interface UseFeatureToggle {
  featureList: Ref<FeatureToggles>;
}

export const features: FeatureToggles = {
  manualDataLoading: {
    title: $t('components.feature-toggle.manual-data-loading.title.text'),
    description: $t(
      'components.feature-toggle.manual-data-loading.description.text',
    ),
    active: false,
  },
  disableManualVerification: {
    title: $t(
      'components.feature-toggle.disable-manual-verification.title.text',
    ),
    description: $t(
      'components.feature-toggle.disable-manual-verification.description.text',
    ),
    active:
      process.env
        .VUE_APP_FEATURE_TOGGLE_MANUAL_VERIFICATION_DISABLED_BY_DEFAULT ===
      'true',
  },
  allowCrashReinstate: {
    title: $t('components.feature-toggle.allow-crash-reinstate.title.text'),
    description: $t(
      'components.feature-toggle.allow-crash-reinstate.description.text',
    ),
    active: process.env.VUE_APP_ALLOW_CRASH_REINSTATE
      ? process.env.VUE_APP_ALLOW_CRASH_REINSTATE === 'true'
      : true,
  },
  manipulateOrderStates: {
    title: $t('components.feature-toggle.manipulate-order-states.title.text'),
    description: $t(
      'components.feature-toggle.manipulate-order-states.description.text',
    ),
    active: false,
  },
  disableTimeDependentCTAs: {
    title: $t(
      'components.feature-toggle.disable-time-dependent-cta.title.text',
    ),
    description: $t(
      'components.feature-toggle.disable-time-dependent-cta.description.text',
    ),
    active: false,
  },
};

export const clickCount = 5;
export const timeToResetClickCounter = 3500;
