<template>
  <div class="profile">
    <template v-if="isPicker">
      <dl class="item" v-if="lastProductSyncData">
        <dt class="item-label" data-e2e="product-sync-date-label">
          {{ $t('components.profile.last-product-sync.text') }}
        </dt>
        <dd class="item-value" data-e2e="product-sync-date-value">
          {{ lastProductSyncData }}
        </dd>
      </dl>
      <dl class="item">
        <dt class="item-label" data-e2e="store-name-label">
          {{ $t('components.profile.store-name.text') }}
        </dt>
        <dd class="item-value" data-e2e="store-name-value">
          {{ user?.merchant?.name }}
        </dd>
      </dl>
      <dl class="item">
        <dt class="item-label" data-e2e="street-merchant-address-label">
          {{ $t('components.profile.merchant-address.text') }}
        </dt>
        <dd class="item-value" data-e2e="street-merchant-address-value">
          {{ user?.merchant?.formattedAddress }}
        </dd>
      </dl>
      <dl class="item">
        <dt class="item-label" data-e2e="employee-merchant-reference-label">
          {{ $t('components.profile.merchant-reference.text') }}
        </dt>
        <dd class="item-value" data-e2e="employee-merchant-reference-value">
          {{ user?.merchant?.reference }}
        </dd>
      </dl>
      <dl class="item">
        <dt class="item-label" data-e2e="employee-email-label">
          {{ $t('components.profile.merchant-email.text') }}
        </dt>
        <dd class="item-value" data-e2e="employee-email-value">
          {{ user?.email }}
        </dd>
      </dl>
    </template>
    <template v-else>
      <dl class="item">
        <dt class="item-label" data-e2e="hub-name-label">Aldi Hub</dt>
        <dd
          v-if="originAddress"
          class="item-value"
          data-e2e="employee-merchant-reference-value"
        >
          {{ originAddress.address1 }} <br />
          {{ originAddress.zipCode }} {{ originAddress.city }}
        </dd>
      </dl>
      <dl class="item">
        <dt class="item-label" data-e2e="employee-email-label">
          {{ $t('components.profile.employee-id.text') }}
        </dt>
        <dd class="item-value wrap-anywhere" data-e2e="employee-email-value">
          {{ user?.email }}
        </dd>
      </dl>
    </template>
  </div>
  <LogoutButton :disabled="true" />
  <Button
    v-if="showReceiveDataButton"
    class="mt-3"
    @click="receiveData"
    data-e2e="receive-data-button"
  >
    {{ $t('common.receive-data.text') }}
  </Button>
  <p
    v-if="hasFailedTransmissions || hasPendingTransmissions"
    class="transmission-note"
  >
    <InfoIcon width="16" fill="rgb(var(--aldi-gray-mid))" />
    <span>
      {{ $t('components.profile.logout-disabled-transmission.first-part') }}
      <span class="link" @click="() => toggleTransmissionsWindowVisibility()">
        {{ $t('components.profile.logout-disabled-transmission.second-part') }}
      </span>
    </span>
  </p>
  <div v-if="hasFailedTransmissions" class="menu">
    <hr class="menu__separator" />
    <div
      class="menu__link menu__link--error"
      @click="() => toggleTransmissionsWindowVisibility()"
    >
      <InfoIcon fill="rgb(var(--aldi-red-100))" />
      <span>{{ $t('components.profile.failed-transmission.text') }}</span>
      <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
    </div>
  </div>
  <div v-else-if="isPicker && hasPendingTransmissions" class="menu">
    <hr class="menu__separator" />
    <div
      class="menu__link"
      data-e2e="ongoing-transmissions-link"
      @click="() => toggleTransmissionsWindowVisibility()"
    >
      <LoadingIcon class="loading-icon-animation" />
      <span>{{ $t('components.profile.ongoing-transmission.text') }}</span>
      <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
    </div>
  </div>
  <hr class="menu__separator" />
  <div
    class="menu__link menu__link--no-icon"
    @click="toggleInfoMenu"
    data-e2e="info-link"
  >
    <span>{{ $t('components.profile.information.text') }}</span>
    <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
  </div>
  <hr class="menu__separator" />
  <div
    class="menu__link menu__link--no-icon"
    @click="pushUserReport"
    data-e2e="report-link"
  >
    <span>{{ $t('components.profile.user-report.text') }}</span>
    <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
  </div>
  <Teleport to="body">
    <InfoMenu
      v-if="infoMenuOpen"
      :isPicker="isPicker"
      @close="toggleInfoMenu"
    />
  </Teleport>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/features/core/router';
import { oauthServicePlugin } from '@/features/oauth';
import { userServicePlugin, useUser } from '@/features/user';
import { tourCrashServicePlugin } from '@/features/tour-crash';
import {
  entityRepositoryPlugin,
  StorageEntityRepository,
} from '@/features/core/entity-repository';
import { useFeatureToggle } from '@/features/feature-toggle';
import { useTransmissions } from '@/features/transmissions';
import { Button } from '@/features/ui';
import LogoutButton from '@/features/ui/components/LogoutButton.vue';
import { useTours } from '@/features/tours/composables';
import { ChevronRight, InfoIcon, LoadingIcon } from '@/features/ui/icons';
import { useProfileReceiveData } from '../composables/useProfileReceiveData';
import InfoMenu from '../components/InfoMenu.vue';
import { configurationServicePlugin } from '@/features/configuration';

export default defineComponent({
  name: 'Profile',
  components: {
    LogoutButton,
    InfoIcon,
    ChevronRight,
    LoadingIcon,
    Button,
    InfoMenu,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const route = useRoute();
    const { user, loading, loadUser } = useUser();

    const { refreshAll } = useProfileReceiveData();
    const isLogoutDisabled = computed(
      () =>
        route.meta?.disabledLogout === true ||
        hasPendingTransmissions.value ||
        hasFailedTransmissions.value,
    );

    const isPicker = computed(() => {
      const userRoles = user?.value?.roles;
      if (userRoles) {
        return !userServicePlugin.get().isDriverRole(userRoles);
      }
      return false;
    });

    const { originAddress } = useTours();

    onMounted(async () => {
      await loadUser();
      initTransmissionsCheck();
    });

    const infoMenuOpen = ref(false);
    const toggleInfoMenu = () => {
      infoMenuOpen.value = !infoMenuOpen.value;
    };

    const logout = async () => {
      if (!hasPendingTransmissions.value) {
        tourCrashServicePlugin.get().removeLastRoute();
        await oauthServicePlugin.get().logout('empowerId');
        await userServicePlugin.get().clearUser();
        (
          entityRepositoryPlugin.get() as StorageEntityRepository
        ).clearRefCache();
      }
    };

    const receiveData = async () => {
      const pickingOrderDependencyActive = await configurationServicePlugin
        .get()
        .getFeatureOption(
          'driverApp',
          'pickingOrdersDependencyActive',
          'boolean',
        );

      await refreshAll({
        refreshStorageZones: isPicker.value || !pickingOrderDependencyActive,
        refreshOrders: isPicker.value || !pickingOrderDependencyActive,
        refreshTours: !isPicker.value,
        removeSyncData: true,
      });

      if (isPicker.value) {
        if (route?.name !== 'default') {
          await router.get().push('/');
        } else {
          emit('update');
        }
      } else {
        if (route?.name !== 'tours') {
          await router.get().push('/tours');
        } else {
          emit('update');
        }
      }
    };

    //#region transmissions
    const {
      hasPendingTransmissions,
      hasFailedTransmissions,
      toggleTransmissionsWindowVisibility,
      initTransmissionsCheck,
    } = useTransmissions();
    //#endregion

    //#region feature list
    const { featureList } = useFeatureToggle();

    const showReceiveDataButton = computed(
      () =>
        process.env.VUE_APP_SHOW_RECEIVE_DATA_BUTTON === 'true' ||
        featureList.value.manualDataLoading.active,
    );
    //#endregion

    const pushUserReport = async () => {
      await router.get().push({
        name: 'user-report',
      });
    };

    return {
      logout,
      receiveData,
      user,
      loading,
      isLogoutDisabled,
      isPicker,
      hasPendingTransmissions,
      hasFailedTransmissions,
      toggleTransmissionsWindowVisibility,
      showReceiveDataButton,
      originAddress,
      toggleInfoMenu,
      infoMenuOpen,
      pushUserReport,
      InfoMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.profile {
  display: grid;
  font-size: 16px;
  color: $base-header-color;
  gap: 17px 0;
  line-height: 1.3em;
  letter-spacing: 0.015em;
}

.item {
  display: grid;
  gap: 8px 0;
}

.item-label {
  font-weight: 600;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.logout-button {
  margin-top: 18px;
}

.menu {
  margin-top: 32px;
}

.menu__separator {
  margin: 24px 0 0;
  height: 1px;
  background: rgba(var(--aldi-mid-blue-40), 0.6);
  border: none;
}

.menu__link {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0 8px;
  padding: 16px 0;
  cursor: pointer;
  color: rgba(var(--aldi-mid-blue-100), 1);

  &:first-of-type {
    margin-top: 8px;
  }

  &--no-icon {
    grid-template-columns: 1fr auto;
  }

  &--error {
    color: rgba(var(--aldi-red-100), 1);
  }
}

.transmission-note {
  display: flex;
  margin-top: 8px;
  color: rgba(var(--aldi-gray-mid), 1);
  gap: 0 4px;
}

.link {
  text-decoration: underline;
  color: rgba(var(--aldi-mid-blue-100), 1);
  cursor: pointer;
}

.spinner {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}
</style>
