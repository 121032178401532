import { authServicePlugin } from '@/features/core/auth';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { merchantApiClientPlugin } from '@/features/merchant';
import { UserHelperService } from './user-helper-service';
import { UserService } from './user-service';

export const userHelperServicePlugin =
  ProviderPluginFactory.create<UserHelperService>({
    key: Symbol('UserHelperServicePlugin'),
    defaultFactory: {
      create: () =>
        new UserHelperService(storagePlugin.get(), authServicePlugin.get()),
    },
  });

export const userServicePlugin = ProviderPluginFactory.create({
  key: Symbol('UserService'),
  defaultFactory: {
    create: () =>
      new UserService(
        storagePlugin.get(),
        authServicePlugin.get(),
        merchantApiClientPlugin.get(),
        eventBusServicePlugin.get(),
      ),
  },
});

export const userServiceExecutePlugin = ExecPluginFactory.fn(() => {
  void userServicePlugin.get().listenToActivity();
});
