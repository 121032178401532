<template>
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5674 17.6315C9.66208 16.7262 8.19434 16.7262 7.28906 17.6315L11.9446 22.2871C12.3884 22.7308 13.1023 22.7395 13.5567 22.3133C13.572 22.2995 13.5871 22.2852 13.6018 22.2705L25.5783 10.294C24.6712 9.38686 23.2004 9.38686 22.2932 10.294L12.7615 19.8257L10.5674 17.6315Z"
    />
  </svg>

  <!-- <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.56736 8.6315C2.66208 7.72622 1.19434 7.72622 0.289062 8.6315L4.94463 13.2871C5.38835 13.7308 6.10233 13.7395 6.5567 13.3133C6.57203 13.2995 6.58708 13.2852 6.60184 13.2705L18.5783 1.29401C17.6712 0.386858 16.2004 0.386857 15.2932 1.29401L5.76155 10.8257L3.56736 8.6315Z"
      fill="#258900"
    />
  </svg> -->
</template>
