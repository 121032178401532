import { ref } from 'vue';

export class RetrySynchronisations {
  retryCount;
  public showSynchronisationFailPopup = ref(false);
  constructor(retryCount = 1) {
    this.retryCount = retryCount;
  }
  retry(): void {
    const params = new URLSearchParams(window.location.search);
    const retryNumber = params.has('retry_number')
      ? Number(params.get('retry_number'))
      : 0;
    if (this.retryCount && retryNumber < this.retryCount) {
      window.location.href =
        window.location.href + '?retry_number=' + String(retryNumber + 1);
    } else {
      this.showSynchronisationFailPopup.value = true;
    }
  }
}
