import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import type {
  OrderItemRaw,
  OrderItemResponse,
  OrderParsePluginDto,
} from '@/features/orders/types';
import { OrderItemStatus } from '@/features/orders/types';

/**
 * Set each order items status as staged if it's not cancelled
 **/
export class NormalizeItemStatusForHandoverPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public readonly requiredPlugins = [NormalizeOrderPlugin];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    dataTransferObject.rawItems.forEach(
      (item: OrderItemRaw | OrderItemResponse) => {
        item.status =
          item.status.toLowerCase() === OrderItemStatus.cancelled
            ? OrderItemStatus.cancelled
            : OrderItemStatus.staged;
      },
    );
    return dataTransferObject;
  }
}
