<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1133 4.0791C10.2849 4.0791 9.61328 3.40753 9.61328 2.5791H19.1133C19.5275 2.5791 19.8633 2.91489 19.8633 3.3291V21.8291C19.8633 22.2433 19.5275 22.5791 19.1133 22.5791H6.61328C6.19907 22.5791 5.86328 22.2433 5.86328 21.8291V2.7041C6.69171 2.7041 7.36328 3.37567 7.36328 4.2041L7.36328 21.0791H18.3633V4.0791L11.1133 4.0791Z"
      fill="#0D3A93"
    />
    <path
      d="M9.61328 7.8291C9.61328 7.41489 9.94907 7.0791 10.3633 7.0791H15.3633C15.7775 7.0791 16.1133 7.41489 16.1133 7.8291C16.1133 8.24332 15.7775 8.5791 15.3633 8.5791H10.3633C9.94907 8.5791 9.61328 8.24332 9.61328 7.8291Z"
      fill="#0D3A93"
    />
    <path
      d="M9.61328 11.3291C9.61328 10.9149 9.94907 10.5791 10.3633 10.5791H13.2799C13.6942 10.5791 14.0299 10.9149 14.0299 11.3291C14.0299 11.7433 13.6942 12.0791 13.2799 12.0791H10.3633C9.94907 12.0791 9.61328 11.7433 9.61328 11.3291Z"
      fill="#0D3A93"
    />
  </svg>
</template>
