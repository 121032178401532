import { MultiPluginFactory } from '@/features/core/plugin';
import {
  healthMonitoringExecPlugin,
  healthMonitoringServicePlugin,
} from './services';

export * from './services';

export const healthMonitoringPlugin = MultiPluginFactory.with({
  healthMonitoringServicePlugin,
  healthMonitoringExecPlugin,
});
