<template>
  <div class="product-box" :class="{ 'flex-between': isSpaceBetween }">
    <div
      v-for="(type, key) in temperatureClassList"
      :key="key"
      class="product-box__item"
    >
      <ProductItem
        v-if="countOfProduct(type.name) > 0"
        :id="id"
        :name="type.label"
        :count="countOfProduct(type.name)"
      >
        <ProductIcons :name="type.label" />
      </ProductItem>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { OrderItem } from '@/features/orders/types';
import { basicTemperatureClasses, TemperatureClass } from '../types';
import ProductIcons from './ProductIcons.vue';
import ProductItem from './ProductItem.vue';
import { cloneItem } from '@/utils/helpers/clone';

export default defineComponent({
  name: 'ProductBox',
  components: { ProductItem, ProductIcons },
  props: {
    id: String,
    products: {
      type: Array as PropType<OrderItem[]>,
      default: () => {
        return [];
      },
    },
    isSpaceBetween: {
      type: Boolean,
      default: false,
    },
  },
  setup: function (props) {
    const temperatureClassList = ref<TemperatureClass[]>(
      cloneItem(basicTemperatureClasses),
    );

    const countOfProduct = (temperatureClassName: string | string[]) => {
      let typeCountArray = props.products.filter((item) => {
        if (typeof temperatureClassName === 'string') {
          return item.product && typeof item.product !== 'string'
            ? item.product.temperatureClass === temperatureClassName
            : false;
        }

        return item.product && typeof item.product !== 'string'
          ? temperatureClassName.includes(item.product.temperatureClass)
          : false;
      });

      return typeCountArray.length;
    };

    return {
      countOfProduct,
      temperatureClassList,
      ...props,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px 0 16px;

  &__item {
    flex-basis: 72px;
  }
}

.flex-between {
  justify-content: space-between;
}
</style>
