import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { Order } from '../entities';
import type { OrderParsePluginDto } from '@/features/orders/types';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import { LoadExistingOrderPlugin } from '@/features/orders/plugins/load-existing-order';

/**
 * Use items from existing order
 **/
export class KeepItemsFromExistingOrderPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public readonly requiredPlugins = [
    LoadExistingOrderPlugin,
    NormalizeOrderPlugin,
  ];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    dataTransferObject.order.items = (
      dataTransferObject.existingOrder as Order
    ).items;

    return dataTransferObject;
  }
}
