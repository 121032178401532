import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { Storage } from '@/features/core/storage';
import {
  PostDropSurveyAnswersData,
  SurveyAnswerItem,
  SurveyApiClient,
} from '..';

export class SurveyServiceClient {
  constructor(
    private surveyApiClient: SurveyApiClient,
    private storage: Storage,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSurvey(surveyId: number): Promise<any | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await this.surveyApiClient.getSurvey(surveyId);
  }

  async postDropSurveyAnswers(
    data: PostDropSurveyAnswersData,
    scheduled = true,
    waitForSync = false,
  ): Promise<void> {
    const result = entityRepositoryPlugin
      .get()
      .save(SurveyAnswerItem.from({ ...data, id: data.dropReference }), {
        waitForSync,
      });

    if (scheduled) {
      await result.scheduled;
    }
    if (waitForSync) {
      await result.completed;
    }
  }

  async clearAll(): Promise<void> {
    await this.storage.removeAll(SurveyAnswerItem);
  }
}
