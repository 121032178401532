import {
  DeliveryType,
  DetailedTourAction,
  StatusToActionServiceInterface,
  Tour,
  TourActions,
  TourFilterOptions,
  TourStatus,
} from '../types';
import { $t } from '@/i18n';
import { computed } from 'vue';
import { useNow } from '@vueuse/core';
import { formatHours } from '@/composables/useDateFormat';
import { configurationServicePlugin } from '@/features/configuration';

export class TourStatusToActionService
  implements StatusToActionServiceInterface<Tour, TourStatus, TourActions>
{
  async getActions(
    tour: Tour,
    filterOptions: TourFilterOptions = { from: '' },
  ): Promise<DetailedTourAction[]> {
    const actions = this.statusToActionMap.get(tour.status);
    return (await actions?.call(this, tour, filterOptions)) ?? [];
  }

  private returnOpenActions = async (
    tour: Tour,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filterOptions: TourFilterOptions,
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<DetailedTourAction[]> => {
    return [
      this.createStartLoadingAction(new Date(tour.startLoadingTime ?? '')),
    ];
  };

  private returnLoadingActions = async (
    tour: Tour,
    filterOptions: TourFilterOptions,
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<DetailedTourAction[]> => {
    if (filterOptions.from === 'ToursListItem') {
      return [this.continueLoadingAction()];
    }
    return [this.createFinishLoadingAction()];
  };

  private returnWaitForDeliveryActions = async (
    tour: Tour,
    filterOptions: TourFilterOptions,
  ): Promise<DetailedTourAction[]> => {
    const hotspotActivationHintActive = await configurationServicePlugin
      .get()
      .getFeatureOption('driverApp', 'hotspotActivationHintActive', 'boolean');

    const hasAnyOrders = tour.drops.some(
      (drop) => drop.orderReferences.length > 0,
    );

    if (filterOptions.from === 'ToursListItem') {
      if (hotspotActivationHintActive && hasAnyOrders) {
        return [this.createOpenHotspotHintAction()];
      }
      return [this.createStartDeliveryAction()];
    }
    if (filterOptions.from === 'HotspotHint') {
      return [this.createStartDeliveryFromHotspotHint()];
    }
    return [this.createContinueDeliveryAction()];
  };

  private returnDeliveryInProgressActions = async (
    tour: Tour,
    filterOptions: TourFilterOptions,
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<DetailedTourAction[]> => {
    if (filterOptions.from === 'ToursListItem') {
      return [this.createContinueDeliveryAction()];
    }
    if (filterOptions.from === 'DropListItemHub') {
      return [this.createStartHeadingBackAction()];
    }
    return [];
  };

  private returnHeadingBackToHubActions = async (
    tour: Tour,
    filterOptions: TourFilterOptions,
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<DetailedTourAction[]> => {
    if (filterOptions.from === 'ToursListItem') {
      return [this.createContinueHeadingBackAction()];
    }
    if (filterOptions.from === 'DropListItemHub') {
      return [
        tour.deliveryMode !== 'cleveron'
          ? this.createConfirmDarkStoreArrival()
          : this.createConfirmCleveronDarkStoreArrival(),
        this.createRouteGuidanceAction(),
      ];
    }
    return [];
  };

  private returnCarClearanceActions = async (
    tour: Tour,
    filterOptions: TourFilterOptions,
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<DetailedTourAction[]> => {
    if (filterOptions.from.endsWith('TourFinishChecklist')) {
      return [
        tour.deliveryMode !== 'cleveron'
          ? this.createFinishTourAction()
          : this.createCleveronFinishTourAction(),
      ];
    }

    if (filterOptions.from.endsWith('TourFinishComplete')) {
      return [
        tour.deliveryMode !== 'cleveron'
          ? this.createFinishTourArrival()
          : this.createCleveronFinishTourArrival(),
      ];
    }
    if (filterOptions.from.endsWith('DropListItemHub')) {
      return [
        this.createContinueCarClearanceAction(),
        this.createRouteGuidanceAction(),
      ];
    }
    return [
      tour.deliveryType === DeliveryType.HOME_DELIVERY
        ? this.createContinueDeliveryAction()
        : this.createContinueCarClearanceActionCleveron(),
    ];
  };

  private returnCompletedActions = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tour: Tour,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filterOptions: TourFilterOptions,
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<DetailedTourAction[]> => {
    return [];
  };

  statusToActionMap: Map<
    TourStatus,
    (
      tour: Tour,
      filterOptions: TourFilterOptions,
    ) => Promise<DetailedTourAction[]>
  > = new Map([
    [TourStatus.open, this.returnOpenActions],
    [TourStatus.loading, this.returnLoadingActions],
    [TourStatus.wait_for_delivery, this.returnWaitForDeliveryActions],
    [TourStatus.delivery_in_progress, this.returnDeliveryInProgressActions],
    [TourStatus.heading_back_to_hub, this.returnHeadingBackToHubActions],
    [TourStatus.car_clearance, this.returnCarClearanceActions],
    [TourStatus.completed, this.returnCompletedActions],
  ]);

  createStartLoadingAction(startLoadingTime: Date): DetailedTourAction {
    const now = useNow();
    return {
      action: TourActions.start_loading,
      ctaData: {
        label: computed(() => {
          if (startLoadingTime > now.value) {
            return $t(
              `components.tour-item.actions["${TourActions.start_loading}_at"].text`,
              {
                loadingTime: formatHours(startLoadingTime, true, true),
              },
            );
          } else {
            return $t(
              `components.tour-item.actions["${TourActions.start_loading}"].text`,
            );
          }
        }),
      },
    };
  }
  continueLoadingAction(): DetailedTourAction {
    return {
      action: TourActions.continue_loading,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.start_loading}"].text`,
        ),
      },
    };
  }
  createFinishLoadingAction(): DetailedTourAction {
    return {
      action: TourActions.finish_loading,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.finish_loading}"].text`,
        ),
        'data-e2e': 'finish-loading-button',
      },
    };
  }
  createStartDeliveryAction(): DetailedTourAction {
    return {
      action: TourActions.start_delivery,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.start_delivery}"].text`,
        ),
      },
    };
  }

  createStartDeliveryFromHotspotHint(): DetailedTourAction {
    return {
      action: TourActions.start_delivery,
      ctaData: {
        label: $t('pages.tour-hotspot.cta.text'),
        'data-e2e': 'confirm-hotspot-button',
      },
    };
  }
  createOpenHotspotHintAction(): DetailedTourAction {
    return {
      action: TourActions.open_hotspot_hint,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.open_hotspot_hint}"].text`,
        ),
      },
    };
  }
  createContinueDeliveryAction(): DetailedTourAction {
    return {
      action: TourActions.continue_delivery,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.continue_delivery}"].text`,
        ),
      },
    };
  }

  createContinueCarClearanceActionCleveron(): DetailedTourAction {
    return {
      action: TourActions.continue_car_clearance,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.continue_car_clearance}"].text`,
        ),
        'data-e2e': 'action-tour-button',
      },
    };
  }

  createContinueCarClearanceAction(): DetailedTourAction {
    return {
      action: TourActions.continue_car_clearance,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.confirm_darkstore_arrival}"].text`,
        ),
        'data-e2e': 'start-confirm-arrival-button',
      },
    };
  }
  createContinueHeadingBackAction(): DetailedTourAction {
    return {
      action: TourActions.continue_heading_back,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.continue_heading_back}"].text`,
        ),
        'data-e2e': 'action-tour-button',
      },
    };
  }
  createStartHeadingBackAction(): DetailedTourAction {
    return {
      action: TourActions.start_heading_back,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.start_heading_back}"].text`,
        ),
        'data-e2e': 'start-heading-back-button',
      },
    };
  }
  createRouteGuidanceAction(): DetailedTourAction {
    return {
      action: TourActions.route_guidance,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.route_guidance}"].text`,
        ),
        variant: 'tertiary',
        icon: 'route_guidance_icon',
        iconRight: true,
        'data-e2e': 'route-guidance-link',
      },
    };
  }
  createConfirmDarkStoreArrival(): DetailedTourAction {
    return {
      action: TourActions.confirm_darkstore_arrival,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.confirm_darkstore_arrival}"].text`,
        ),
        'data-e2e': 'start-confirm-arrival-button',
      },
    };
  }
  createConfirmCleveronDarkStoreArrival(): DetailedTourAction {
    return {
      action: TourActions.confirm_cleveron_darkstore_arrival,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.confirm_darkstore_arrival}"].text`,
        ),
        'data-e2e': 'start-confirm-arrival-button',
      },
    };
  }
  createFinishTourAction(): DetailedTourAction {
    return {
      action: TourActions.finish_tour_checklist,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.finish_tour}"].text`,
        ),
        'data-e2e': 'confirm-button',
      },
    };
  }
  createCleveronFinishTourAction(): DetailedTourAction {
    return {
      action: TourActions.finish_cleveron_tour_checklist,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.finish_tour}"].text`,
        ),
        'data-e2e': 'confirm-button',
      },
    };
  }
  createFinishTourArrival(): DetailedTourAction {
    return {
      action: TourActions.finish_tour,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.finish_tour}"].text`,
        ),
        'data-e2e': 'confirm-button',
      },
    };
  }
  createCleveronFinishTourArrival(): DetailedTourAction {
    return {
      action: TourActions.finish_cleveron_tour,
      ctaData: {
        label: $t(
          `components.tour-item.actions["${TourActions.finish_tour}"].text`,
        ),
        'data-e2e': 'confirm-button',
      },
    };
  }
}
