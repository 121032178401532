import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { ImageCachingQueueServiceImplementation } from './image-caching-service';

export * from './image-caching-service';

export const imageCachingQueueServicePlugin =
  ProviderPluginFactory.create<ImageCachingQueueServiceImplementation>({
    key: Symbol('ImageCachingQueueService'),
    defaultFactory: {
      create: () => {
        return new ImageCachingQueueServiceImplementation(storagePlugin.get());
      },
    },
  });
