import { DriverNotificationsProcessor } from '../types';

export class DriverNotificationsRegistry {
  private registry: Map<string, DriverNotificationsProcessor> = new Map();

  register(
    processorName: string,
    processor: DriverNotificationsProcessor,
  ): void {
    this.registry.set(processorName, processor);
  }

  getRegistry(): Map<string, DriverNotificationsProcessor> {
    return this.registry;
  }
}
