<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    viewBox="0 0 16 16"
    fill="#E00016"
  >
    <path
      d="M8.44035 1.76334C8.35321 1.60117 8.184 1.5 7.99991 1.5C7.81581 1.5 7.6466 1.60117 7.55947 1.76334L1.55955 12.93C1.47629 13.085 1.48059 13.2723 1.57086 13.4233C1.66112 13.5742 1.82409 13.6667 1.99999 13.6667H14.4998C14.4998 13.1144 14.0521 12.6667 13.4998 12.6667H2.83624L7.99991 3.05638L11.6847 9.91423L12.0584 10.6171C12.3177 11.1048 12.9232 11.2899 13.4108 11.0306L12.966 10.194C12.9586 10.1752 12.9501 10.1566 12.9403 10.1383L12.5666 9.44282L12.4719 9.2647L12.4707 9.26435L8.44035 1.76334Z"
    />
    <path
      d="M7.99991 6.5C8.27604 6.5 8.4999 6.72386 8.4999 7V9.16667C8.4999 9.44281 8.27604 9.66667 7.99991 9.66667C7.72377 9.66667 7.49991 9.44281 7.49991 9.16667V7C7.49991 6.72386 7.72377 6.5 7.99991 6.5Z"
    />
    <path
      d="M8.01651 10.3333C7.90114 10.3333 7.78836 10.3675 7.69243 10.4316C7.5965 10.4957 7.52174 10.5868 7.47758 10.6934C7.43343 10.8 7.42188 10.9173 7.44439 11.0305C7.4669 11.1436 7.52245 11.2476 7.60403 11.3291C7.68561 11.4107 7.78955 11.4663 7.90271 11.4888C8.01586 11.5113 8.13315 11.4997 8.23974 11.4556C8.34632 11.4114 8.43743 11.3367 8.50152 11.2408C8.56562 11.1448 8.59983 11.032 8.59983 10.9167C8.59983 10.762 8.53837 10.6136 8.42898 10.5042C8.31959 10.3948 8.17121 10.3333 8.01651 10.3333Z"
    />
  </svg>
</template>
