<template>
  <svg
    width="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="transparent" />
    <path
      d="M33.7614 7.05337C33.4128 6.40468 32.736 6 31.9996 6C31.2632 6 30.5864 6.40468 30.2379 7.05337L6.23821 51.72C5.90518 52.3399 5.92235 53.0891 6.28342 53.693C6.6445 54.2969 7.29635 54.6667 7.99997 54.6667H57.9992C57.9992 52.4575 56.2084 50.6667 53.9993 50.6667H11.345L31.9996 12.2255L46.7387 39.6569L48.2336 42.4685C49.2707 44.419 51.6927 45.1595 53.6432 44.1224L51.8639 40.776C51.8346 40.7007 51.8003 40.6264 51.7611 40.5534L50.2663 37.7713L49.8875 37.0588L49.8827 37.0574L33.7614 7.05337Z"
      fill="#E00016"
    />
    <path
      d="M31.9996 26C33.1042 26 33.9996 26.8954 33.9996 28V36.6667C33.9996 37.7712 33.1042 38.6667 31.9996 38.6667C30.8951 38.6667 29.9997 37.7712 29.9997 36.6667V28C29.9997 26.8954 30.8951 26 31.9996 26Z"
      fill="#E00016"
    />
    <path
      d="M32.066 41.3333C31.6045 41.3333 31.1534 41.4702 30.7697 41.7266C30.386 41.983 30.0869 42.3474 29.9103 42.7737C29.7337 43.2001 29.6875 43.6693 29.7776 44.1219C29.8676 44.5745 30.0898 44.9903 30.4161 45.3166C30.7425 45.6429 31.1582 45.8651 31.6108 45.9552C32.0634 46.0452 32.5326 45.999 32.9589 45.8224C33.3853 45.6458 33.7497 45.3467 34.0061 44.963C34.2625 44.5793 34.3993 44.1282 34.3993 43.6667C34.3993 43.0478 34.1535 42.4543 33.7159 42.0168C33.2783 41.5792 32.6849 41.3333 32.066 41.3333Z"
      fill="#E00016"
    />
  </svg>
</template>
