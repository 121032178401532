<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.55069 4.68427C7.06253 4.19612 7.06253 3.40466 7.55069 2.9165L9.07045 4.43627C9.07659 4.44194 9.08265 4.44776 9.08862 4.45373L14.1928 9.5579C14.4369 9.80197 14.4369 10.1977 14.1928 10.4418L9.08862 15.5459C9.08265 15.5519 9.07659 15.5577 9.07045 15.5634L7.55069 17.0832C7.06253 16.595 7.06253 15.8036 7.55069 15.3154L12.867 9.99984L7.55069 4.68427Z"
      fill="#71777E"
    />
  </svg>
</template>
