import { DeviceIdService } from '@/features/device-id';
import { EntityRepository } from '@/features/core/entity-repository';
import { PushProvider, PushService } from '@/features/push-notification';
import { AuthService } from '@/features/core/auth';
import { Device } from '../entities';

export class DeviceService {
  constructor(
    private entityRepository: EntityRepository,
    private deviceIdService: DeviceIdService,
    private pushService: PushService,
    private authService: AuthService,
  ) {
    void this.setDeviceId();
    this.pushProvider = this.pushService.getProvider();
  }

  private deviceId = 'Unknown';
  private pushProvider: PushProvider | null = null;

  async updateAndSync(): Promise<void> {
    const isDeviceStored = await this.checkIfDeviceStored();
    const pushToken = await this.getPushToken();
    const lastLogin = await this.getTokenCreationTime();

    await this.entityRepository.save(
      Device.from({
        type: 'device',
        id: this.deviceId,
        lastLogin,
        pushToken,
        appVersion: process.env.VUE_APP_VERSION,
        isRegistered: isDeviceStored,
      }),
      { waitForSync: true },
    ).completed;
  }

  private async getPushToken(): Promise<string> {
    return this.pushProvider ? await this.pushProvider.getToken() : 'Unknown';
  }

  private async getTokenCreationTime(): Promise<string> {
    const tokenCreationTime = await this.authService.getUserTokenCreationTime();
    return tokenCreationTime || 'Unknown';
  }

  private async checkIfDeviceStored(): Promise<boolean> {
    const devices = await this.entityRepository.getAll(Device);
    const device = devices.value[0];
    if (!device) {
      return false;
    }
    if (device.id !== this.deviceId) {
      // remove stored device entry with old deviceId
      this.entityRepository.remove(device);
      return false;
    }
    return true;
  }

  private async setDeviceId() {
    this.deviceId = (await this.deviceIdService.getDeviceId()) || 'Unknown';
  }
}
