import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { SingletonFactory } from '@/utils/helpers/singletonFactory';
import { ClearNotification } from './clear-notification';

export const clearNotificationSingleton =
  SingletonFactory.getInstance(ClearNotification);

export const clearNotificationPlugin = ProviderPluginFactory.create({
  key: Symbol('clearNotificationPlugin'),
  defaultFactory: {
    create: () => clearNotificationSingleton,
  },
});

export const clearNotificationExecutePlugin = ExecPluginFactory.fn(() => {
  void clearNotificationPlugin.get().clearAll();
});

export const clearNotificationConfigPlugin = MultiPluginFactory.with({
  clearNotificationPlugin,
  clearNotificationExecutePlugin,
});
