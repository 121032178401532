import {
  DriverNotification,
  DriverNotificationsProcessor,
} from '@/features/driver-notifications';
import { Storage } from '@/features/core/storage';
import { TourDriverNotificationItem } from '../entities/tour-driver-notifiaction';
import { useTourDriverNotification } from '../composables';

export class ToursNotificationProcessor
  implements DriverNotificationsProcessor
{
  typeResponsibilities = ['new_tour_assignment', 'tour_assignment_removed'];

  constructor(private storage: Storage) {}

  async processDriverNotification(
    notification: DriverNotification,
  ): Promise<void> {
    await this.storage.save(
      TourDriverNotificationItem.from({
        id: notification.id.toString(),
        notificationType: notification.type,
        payload: JSON.stringify(notification.payload),
      }),
    );
    const { updateNotification } = useTourDriverNotification();
    void updateNotification();
  }
}
