<template>
  <div class="counter">
    <CartIcon stroke="#101316" fill="#101316"></CartIcon>
    <p class="counter__content ut-font_fix">
      {{
        getTranslatedText('pages.picking-item-counter.item-count.text', {
          quantityCount: formatCountToSting(quantity),
          quantityOriginalCount: formatCountToSting(quantityOriginal),
        })
      }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CartIcon from '@/features/ui/icons/CartIcon.vue';
import { formatCount } from '@/utils/helpers/ItemsCountFormatter';
import { $t } from '@/i18n';

export default defineComponent({
  components: {
    CartIcon,
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    quantityOriginal: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const formatCountToSting = (item: number) => {
      return formatCount(item);
    };

    const getTranslatedText = (identifier: string, data = {}) => {
      return $t(identifier, data);
    };

    return {
      formatCountToSting,
      getTranslatedText,
    };
  },
});
</script>

<style lang="scss" scoped>
.counter {
  display: grid;
  padding: 8px 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1.77778px 1.77778px 8.88889px rgba(0, 0, 0, 0.16);
  grid-auto-flow: column;
  gap: 0 8px;

  &__content {
    font-size: 16px;
    letter-spacing: 0.04em;
  }
}
</style>
