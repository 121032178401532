import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderItem, OrderParsePluginDto } from '../types';
import { OrderItemStatus } from '../types';

export class CountingOriginalForCancelAndStagedPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    const groupedItems = dataTransferObject.order.items;
    groupedItems
      .map((item: OrderItem) => {
        return {
          canceledItem: groupedItems.findIndex(
            (i: OrderItem) =>
              i.id === item.id &&
              i.status === OrderItemStatus.cancelled &&
              i.amount,
          ),
          stagedItem: groupedItems.findIndex(
            (i: OrderItem) =>
              i.id === item.id &&
              i.status === OrderItemStatus.staged &&
              i.amount,
          ),
        };
      })
      .filter((pair) => pair.canceledItem >= 0 && pair.stagedItem >= 0)
      .filter(
        (pair, index, arr) =>
          arr.findIndex(
            (p) =>
              pair.canceledItem === p.canceledItem &&
              pair.stagedItem === p.stagedItem,
          ) === index,
      )
      .forEach((pair) => {
        const { canceledItem, stagedItem } = pair;
        const totalQuantityOriginal =
          groupedItems[canceledItem].quantityOriginal +
          groupedItems[stagedItem].quantityOriginal;
        const totalAmountOriginal =
          groupedItems[canceledItem].amountOriginal +
          groupedItems[stagedItem].amountOriginal;

        const canceledAmount =
          totalAmountOriginal > groupedItems[stagedItem].amount
            ? totalAmountOriginal - groupedItems[stagedItem].amount
            : 0;

        groupedItems[canceledItem].quantityOriginal = totalQuantityOriginal;
        groupedItems[canceledItem].amountOriginal = Number(
          totalAmountOriginal.toFixed(2),
        );
        groupedItems[canceledItem].amount = Number(canceledAmount.toFixed(2));
        groupedItems[stagedItem].quantityOriginal = totalQuantityOriginal;
        groupedItems[stagedItem].amountOriginal = Number(
          totalAmountOriginal.toFixed(2),
        );
      });

    return dataTransferObject;
  }
}
