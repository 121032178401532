import { loggerServicePlugin } from '@/features/core/logger';
import { ref } from 'vue';
import { UseOnlineStatus } from '../types';
import { useEventListener } from '@vueuse/core';

try {
  navigator.connection.addEventListener('change', (event) => {
    let data = event;
    try {
      data = JSON.parse(JSON.stringify(event)) as Event;
    } catch (e) {
      // do nothing
    }

    loggerServicePlugin.get().debug('Connection change', data);
  });

  window.addEventListener('offline', function () {
    loggerServicePlugin.get().debug('Device is offline');
  });

  window.addEventListener('online', function () {
    loggerServicePlugin.get().debug('Device is online');
  });
} catch (error: unknown) {
  //do nothing...
}

export function useOnlineStatus(): UseOnlineStatus {
  const isOnline = ref(window?.navigator.onLine ?? false);

  useEventListener(window, 'offline', () => {
    isOnline.value = false;
  });

  useEventListener(window, 'online', () => {
    isOnline.value = true;
  });

  return {
    isOnline,
  };
}
