export function debounce<T>(
  func: (...args: T[]) => unknown,
  timeout: number,
): (...args: T[]) => void {
  let timer: number | undefined;

  return (...args: T[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout) as unknown as number;
  };
}
