import { RouteLocationNormalized, RouteParams } from 'vue-router';
import router from '@/features/core/router';
import { errorPlugin } from '@/features/core/errors';
import { NotPossibleToGoBackError } from '../errors/error-classes';
import { isBoolean } from '@vueuse/core';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { useRejectionModal } from '@/features/handover/composables';

interface ProcessRouteBackMeta {
  canLeaveRoute: boolean;
  redirectTo: string | undefined;
  redirectParams?: string[];
}

interface UseRouteProcessGuard {
  canLeaveRoute: (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => boolean;
  getRedirectLocation: (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => RouteLocationNormalized | null | boolean;
  beforeEachGuard: (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => void;
  setupProcessGuard: () => void;
}

export function useRouteProcessGuard(): UseRouteProcessGuard {
  let stepBackPrevented = false;

  let lastPosition = 0;

  const canLeaveRoute = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    const leavingProcessMeta = from?.meta
      ?.processRouteGuard as ProcessRouteBackMeta;
    return !(
      leavingProcessMeta !== undefined && !leavingProcessMeta.canLeaveRoute
    );
  };

  const getRouteParams = (
    redirectParams: undefined | string[],
    fromRouteParams: RouteParams,
  ): RouteParams => {
    if (!redirectParams) return {};
    const params = {} as RouteParams;
    redirectParams.forEach((paramName: string) => {
      if (fromRouteParams[paramName])
        params[paramName] = fromRouteParams[paramName];
    });
    return params;
  };

  const getRedirectLocation = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): RouteLocationNormalized | null | boolean => {
    const leavingProcessMeta = from.meta
      .processRouteGuard as ProcessRouteBackMeta;
    if (canLeaveRoute(to, from)) {
      if (
        leavingProcessMeta !== undefined &&
        leavingProcessMeta.redirectTo !== undefined
      ) {
        return {
          name: leavingProcessMeta.redirectTo,
          params: getRouteParams(
            leavingProcessMeta.redirectParams,
            from.params,
          ),
        } as RouteLocationNormalized;
      }

      return null;
    } else {
      const error = new NotPossibleToGoBackError();
      errorPlugin.get().handle(error);

      return false;
    }
  };

  const beforeEachGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    const { showDialog, close } = useDynamicDialog();
    const { showRejectionModal, toggleRejectionModal } = useRejectionModal();
    if (showDialog.value) {
      close();
      return false;
    }
    if (showRejectionModal.value) {
      toggleRejectionModal();
      return false;
    }
    const previousPosition = lastPosition;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    lastPosition = history.state?.position ?? 0;
    if (previousPosition > lastPosition) {
      const locationToRedirect = getRedirectLocation(to, from);
      if (locationToRedirect !== null && !isBoolean(locationToRedirect)) {
        return { ...locationToRedirect, replace: true };
      } else if (locationToRedirect === false) {
        stepBackPrevented = true;
        return false;
      }
    } else {
      return;
    }
  };

  const setupProcessGuard = () => {
    router.get().beforeEach(beforeEachGuard);
    router.get().afterEach(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      lastPosition = history.state.position;
      if (stepBackPrevented) {
        lastPosition++;
        stepBackPrevented = false;
      }
    });
  };

  return {
    canLeaveRoute,
    getRedirectLocation,
    setupProcessGuard,
    beforeEachGuard,
  };
}
