import { Order } from '@/features/orders';

/**
 * Returns 'true' if the order has age restrictions, and 'false' otherwise
 * order.ageVerification.requiredAge > 0.
 *
 * @param order instance of Order
 */
export function isAgeRestrictedOrder(order: Order): boolean {
  if (order.ageVerification) {
    return order.ageVerification.requiredAge > 0;
  }
  return false;
}
