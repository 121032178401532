<template>
  <footer>
    <p>© 2021 - Spryker Systems GmbH</p>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>

<style scoped lang="scss">
footer {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  text-align: center;
  color: #fff;
  background-color: #000;
}
</style>
