<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20.5C7.30559 20.5 3.50002 16.6944 3.50002 12C3.50002 7.30558 7.30559 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 13.1738 20.2625 14.2903 19.8336 15.3053C19.7829 15.4253 19.7295 15.544 19.6735 15.6611C19.3758 16.2839 19.5174 17.058 20.0898 17.4439L20.2971 17.5836C20.4251 17.3938 20.5467 17.1994 20.6617 17.0005C20.7668 16.8189 20.8664 16.6336 20.9601 16.4449C21.0507 16.2627 21.1358 16.0774 21.2154 15.8891C21.7209 14.6926 22 13.378 22 12C22 6.47715 17.5229 2 12 2C6.47717 2 2.00002 6.47715 2.00002 12C2.00002 17.5228 6.47717 22 12 22C13.378 22 14.6927 21.7209 15.8891 21.2153C16.0774 21.1358 16.2627 21.0507 16.4449 20.9601C16.6336 20.8664 16.8189 20.7668 17.0006 20.6617C17.1898 20.5523 17.375 20.4368 17.5561 20.3156L17.417 20.1079C17.033 19.5342 16.2595 19.39 15.6356 19.6856C15.5268 19.7372 15.4167 19.7866 15.3053 19.8336C14.2903 20.2625 13.1738 20.5 12 20.5Z"
    />
    <path
      d="M12 7.25C11.827 7.25 11.6578 7.30132 11.5139 7.39746C11.37 7.49361 11.2578 7.63027 11.1916 7.79015C11.1254 7.95004 11.1081 8.12597 11.1418 8.2957C11.1756 8.46544 11.2589 8.62135 11.3813 8.74372C11.5037 8.86609 11.6596 8.94943 11.8293 8.98319C11.999 9.01695 12.175 8.99962 12.3349 8.93339C12.4947 8.86717 12.6314 8.75502 12.7276 8.61112C12.8237 8.46723 12.875 8.29806 12.875 8.125C12.875 7.89294 12.7828 7.67038 12.6187 7.50628C12.4546 7.34219 12.2321 7.25 12 7.25Z"
    />
    <path
      d="M12 10.25C12.4142 10.25 12.75 10.5858 12.75 11V16.25C12.75 16.6642 12.4142 17 12 17C11.5858 17 11.25 16.6642 11.25 16.25V11C11.25 10.5858 11.5858 10.25 12 10.25Z"
    />
  </svg>
</template>
