import { StylesManager, SurveyModel } from 'survey-core';
import { computed, Ref, ref } from 'vue';
import { surveyServiceClientPlugin } from '../services';
import {
  PostDropSurveyAnswersData,
  SurveyAction,
  SurveyInfo,
  SurveyStatus,
  UseSurvey,
} from '../types';
import { SurveyInitFailedError } from '../errors';

let surveyModel: SurveyModel | undefined;
const surveyInfo: Ref<SurveyInfo | undefined> = ref();
const processing: Ref<boolean> = ref(false);

const surveyStatus = ref<SurveyStatus>();
const isStartScreen = computed(() => surveyStatus.value === SurveyStatus.start);
const isMainScreen = computed(() => surveyStatus.value === SurveyStatus.main);
const isCompleteScreen = computed(
  () => surveyStatus.value === SurveyStatus.complete,
);

export function useSurvey(): UseSurvey {
  const isSurveyTouched = () =>
    surveyModel && Object.keys(surveyModel?.data).length > 0;
  const showStartButton = () => isStartScreen.value && !isSurveyTouched();
  const showBackButton = computed(() => !isStartScreen.value);
  const showNextButton = computed(() => {
    switch (surveyStatus.value) {
      case SurveyStatus.complete:
        return false;
      case SurveyStatus.start:
        return isSurveyTouched() as boolean;
      case SurveyStatus.main:
        // Show next button on the survey's page in case of the current question is already filled.
        return (surveyModel &&
          Object.keys(surveyModel?.data).length >
            surveyModel?.currentPageNo) as boolean;
      default:
        return false;
    }
  });

  const setSurveyStatus = (newSurveyStatus: SurveyStatus) => {
    surveyStatus.value = newSurveyStatus;
  };

  const setSurveyInfo = (newSurveyInfo: SurveyInfo) => {
    surveyInfo.value = newSurveyInfo;
  };

  const postDropSurveyAnswers = async (
    data: PostDropSurveyAnswersData,
  ): Promise<void> => {
    await surveyServiceClientPlugin.get().postDropSurveyAnswers(data);
  };

  const initSurvey = async (surveyId: number) => {
    setSurveyStatus(SurveyStatus.start);
    if (!surveyModel) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const surveyJson = await surveyServiceClientPlugin
        .get()
        .getSurvey(surveyId);
      if (!surveyJson) {
        throw new SurveyInitFailedError();
      }
      StylesManager.applyTheme('modern');
      surveyModel = new SurveyModel(surveyJson);
      surveyModel.onComplete.add(() => setSurveyStatus(SurveyStatus.complete));
    }
  };

  const startSurvey = () => {
    setSurveyStatus(SurveyStatus.main);
  };

  const submitSurvey = async () => {
    if (surveyInfo.value?.surveyId && surveyInfo.value?.dropReference) {
      await postDropSurveyAnswers({
        surveyId: surveyInfo.value.surveyId,
        dropReference: surveyInfo.value.dropReference,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        answers: surveyModel?.data,
      });
    }
    surveyModel?.dispose();
    surveyInfo.value = undefined;
    surveyModel = undefined;
  };

  const restoreSurvey = () => {
    if (surveyModel) {
      surveyModel.clear(false, false);
      surveyModel.currentPageNo = surveyModel.visiblePageCount - 1;
    }
  };

  const back = () => {
    switch (surveyStatus.value) {
      case SurveyStatus.main:
        if (surveyModel?.isFirstPage) {
          setSurveyStatus(SurveyStatus.start);
        } else {
          surveyModel?.prevPage();
        }
        break;
      case SurveyStatus.complete:
        restoreSurvey();
        setSurveyStatus(SurveyStatus.main);
        break;
      default:
        setSurveyStatus(SurveyStatus.start);
    }
  };

  const next = () => {
    switch (surveyStatus.value) {
      case SurveyStatus.start:
        if (surveyModel) {
          surveyModel.start();
          setSurveyStatus(SurveyStatus.main);
        }
        break;
      case SurveyStatus.main:
        if (surveyModel?.isLastPage) {
          surveyModel.completeLastPage();
          setSurveyStatus(SurveyStatus.complete);
        } else {
          surveyModel?.nextPage();
        }
        break;
      default:
        setSurveyStatus(SurveyStatus.complete);
        break;
    }
  };

  const applyAction = async (action: SurveyAction) => {
    processing.value = true;
    switch (action) {
      case SurveyAction.start:
        startSurvey();
        break;
      case SurveyAction.submit:
        await submitSurvey();
        break;
      case SurveyAction.back:
        back();
        break;
      case SurveyAction.next:
        next();
        break;
      default:
        break;
    }
    processing.value = false;
  };

  return {
    isStartScreen,
    isMainScreen,
    isCompleteScreen,
    processing,
    postDropSurveyAnswers,
    applyAction,
    surveyInfo,
    setSurveyInfo,
    showBackButton,
    showNextButton,
    showStartButton,
    surveyModel: computed<SurveyModel | undefined>(() => surveyModel),
    initSurvey,
  };
}
