<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 15.25H5.625"
      stroke="#0D3A93"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <path
      d="M9.125 15.25H16.3433"
      stroke="#0D3A93"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <circle cx="7.375" cy="15.25" r="1.25" fill="#0D3A93" stroke="#0D3A93" />
    <path
      d="M3 10H13.5"
      stroke="#0D3A93"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <circle cx="15.25" cy="10" r="1.25" fill="#0D3A93" stroke="#0D3A93" />
    <path
      d="M3 4.75H5.625"
      stroke="#0D3A93"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <path
      d="M9.125 4.75H16.3585"
      stroke="#0D3A93"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <circle cx="7.375" cy="4.75" r="1.25" fill="#0D3A93" stroke="#0D3A93" />
  </svg>
</template>
