export enum DropActions {
  start_drop_off = 'start-drop-off',
  confirm_arrival = 'confirm-arrival',
  start_picking_receptacles = 'start-picking-receptacles',
  all_picked_up = 'all-picked-up',
  start_handover = 'start-handover',
  continue_handover = 'continue-handover',
  complete_handover = 'complete-handover',
  complete_picking_receptacles = 'complete-picking-receptacles',
  cancel_drop = 'cancel-drop',
  pause_drop = 'pause-drop',
  start_survey = 'start-survey',
  complete_drop = 'complete-drop',
}

export enum DropStatus {
  open = 'open',
  picking_receptacles_completed = 'picking_receptacles_completed',
  wait_for_delivery = 'wait_for_delivery',
  delivery_in_progress = 'delivery_in_progress',
  delivery_completed = 'delivery_completed',
  start_picking_receptacles = 'start_picking_receptacles',
  complete_picking = 'complete_picking',
  drop_paused = 'drop_paused',
  drop_canceled = 'drop_canceled',
  start_handover = 'start_handover',
  age_verification = 'age_verification',
  age_rejected = 'age_rejected',
  handover_completed = 'handover_completed',
  picking_receptacles = 'picking_receptacles',
  handover_in_progress = 'handover_in_progress',
  survey_in_progress = 'survey_in_progress',
  drop_completed = 'drop_completed',
}

export enum DropActionsCleveron {
  start_drop_off = 'start-drop-off',
  complete_delivery = 'complete_delivery',
  load_cleveron = 'load_cleveron',
  complete_load_cleveron = 'complete_load_cleveron',
  continue_load_cleveron = 'continue_load_cleveron',
  clear_boxes = 'clear_boxes',
  logout_cleveron = 'logout_cleveron',
  complete_box_clearing = 'complete_box_clearing',
  continue_box_clearing = 'continue_box_clearing',
  complete_log_out = 'complete_log_out',
  continue_log_out = 'continue_log_out',
  complete_cleaning = 'complete_cleaning',
  continue_cleaning = 'continue_cleaning',
}

export enum DropStatusCleveron {
  open = 'open',
  delivery_in_progress = 'delivery_in_progress',
  delivery_completed = 'delivery_completed',
  loading_cleveron = 'loading_cleveron',
  cleveron_loading_completed = 'cleveron_loading_completed',
  box_clearing_completed = 'box_clearing_completed',
  log_out_completed = 'log_out_completed',
  cleaning_completed = 'cleaning_completed',
}

export enum DropPathCleveron {
  loading_cleveron = 'loading-cleveron',
  logout_cleveron = 'logout-cleveron',
  cleaning_cleveron = 'cleaning-cleveron',
  box_clearing_cleveron = 'box-clearing-cleveron',
}

export enum DropStatusMiddlemile {
  open = 'open',
  delivery_in_progress = 'crossdock_delivery_in_progress',
  delivery_completed = 'crossdock_delivery_completed',
  unloading = 'crossdock_unloading',
  loading_empty_frames = 'crossdock_loading_empty_frames',
  drop_completed = 'crossdock_drop_completed',
}

export enum DropActionsMiddlemile {
  start_drop_off = 'start-drop-off',
  open_route_guidance = 'open-route-guidance',
  confirm_arrival = 'confirm-arrival',
  continue_unloading = 'continue_unloading',
  start_unloading = 'start_unloading',
  complete_unloading = 'unloading_complete',
  continue_loading = 'continue_loading',
  finish_loading = 'finish_loading',
}
