<template>
  <svg
    width="18"
    viewBox="0 0 18 18"
    fill="#FFC800"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7495 2.0625C12.7495 1.75184 12.4976 1.5 12.187 1.5H5.81195C5.50129 1.5 5.24945 1.75184 5.24945 2.0625V4.58661C4.96444 5.35869 4.44267 6.84119 4.2407 7.41659C4.16381 7.63565 4.125 7.86346 4.125 8.09558V15.1907C4.125 15.9187 4.71575 16.5 5.4375 16.5H13.7813C13.7813 15.8787 13.2776 15.375 12.6563 15.375H5.4375C5.33083 15.375 5.25 15.2911 5.25 15.1907V8.09558C5.25 7.99031 5.26735 7.88848 5.3022 7.78919C5.4833 7.27326 5.91425 6.04877 6.20462 5.25H11.7944C12.0854 6.04964 12.5165 7.27333 12.6977 7.78916C12.7324 7.88795 12.75 7.99144 12.75 8.09741V12.375C12.75 12.9963 13.2537 13.5 13.875 13.5V8.09741C13.875 7.86571 13.8363 7.63607 13.7591 7.41627C13.557 6.84111 13.0354 5.36028 12.7495 4.58651V2.0625ZM11.6245 4.125H6.37445V2.625H11.6245V4.125Z"
    />
  </svg>
</template>
