import { ExecPluginFactory } from '@/features/core/plugin';
import { authServicePlugin } from '@/features/core/auth';
import { processedOrderServicePlugin } from '@/features/processed-order';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { loggerServicePlugin } from '@/features/core/logger';
import { AppCrashServiceConfig } from '../types';
import { AppCrashService } from './app-crash';

export * from './app-crash';

export const appCrashServicePlugin = ProviderPluginFactory.create<
  AppCrashService,
  AppCrashServiceConfig
>({
  key: Symbol('AppCrashService'),
  defaultFactory: {
    create: (app, options) => {
      return new AppCrashService(
        options,
        entityRepositoryPlugin.get(),
        authServicePlugin.get(),
        processedOrderServicePlugin.get(),
        loggerServicePlugin.get(),
      );
    },
  },
});

export const appCrashExecutePlugin = ExecPluginFactory.fn(() => {
  appCrashServicePlugin.get().setupNavigationGuard();
});
