import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { apiPlugin } from '@/features/core/api';
import { TourDropApiClient } from './tour-drop-api-client';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import { registerTourDropApi } from './tourDropRegister';

export * from './tour-drop-api-client';
export * from './tourDropRegister';

export interface DropsApiClientInterface {
  dropsApiClient: TourDropApiClient;
}

export const tourDropsApiPlugin =
  ProviderPluginFactory.create<DropsApiClientInterface>({
    key: Symbol('DropsApiClient'),
    defaultFactory: {
      create: () => ({
        dropsApiClient: new TourDropApiClient(apiPlugin.get()),
      }),
    },
  });

export const tourDropsApiRegistryPlugin = ExecPluginFactory.fn(() => {
  registerTourDropApi(
    onlineEntityRepositoryRegistryPlugin.get(),
    tourDropsApiPlugin.get().dropsApiClient,
  );
});
