import { ApiClient } from '@/features/core/api';
import { Device } from '../entities';

interface DeviceRequestData {
  appVersion: string;
  lastLoginTimestamp: string;
  pushToken: string;
  deviceId: string;
}

export class DeviceApiClient {
  constructor(private api: ApiClient) {}

  async save(device: Device): Promise<Device> {
    const requestData = this.getDeviceRequestData(device);
    if (device.isRegistered) {
      await this.api.client.patch(`/devices/${device.id}`, {
        appVersion: requestData.appVersion,
        lastLoginTimestamp: requestData.lastLoginTimestamp,
        pushToken: requestData.pushToken,
      });
    } else {
      await this.api.client.post(`/devices`, requestData);
    }
    return new Device();
  }

  private getDeviceRequestData(device: Device): DeviceRequestData {
    const { appVersion, lastLogin, pushToken, id } = device;
    return {
      appVersion,
      lastLoginTimestamp: lastLogin,
      pushToken,
      deviceId: id,
    };
  }
}
