import {
  ClearAllNotificationsError,
  ClearNotificationsByTagError,
} from './errors';

declare let self: {
  registration: ServiceWorkerRegistration;
  location: URL;
};

export class ClearNotification {
  private async getRegistration(): Promise<ServiceWorkerRegistration> {
    const registration = navigator.serviceWorker
      ? await navigator.serviceWorker.getRegistration()
      : self.registration;
    if (!registration) {
      throw new Error('Worker registration missing in ClearNotification');
    }
    return registration;
  }

  async clearAll(): Promise<void> {
    // NOTE: Label print page open in new window and we should prevent clear all notification
    if (
      typeof window !== 'undefined' &&
      window.location.href.includes('print')
    ) {
      return;
    }
    try {
      const registration = await this.getRegistration();
      const notifications = await registration.getNotifications();

      notifications.forEach((notification) => {
        notification.close();
      });
    } catch (error) {
      console?.warn(new ClearAllNotificationsError(error));
    }
  }

  async clearNotificationByTag(tag: string): Promise<void> {
    try {
      const registration = await this.getRegistration();
      const notifications = await registration.getNotifications({
        tag,
      });
      notifications.forEach((notification) => {
        notification.close();
      });
    } catch (error) {
      console?.warn(new ClearNotificationsByTagError(tag, error));
    }
  }
}
