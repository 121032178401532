<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.86325 17.9122L2.48449 11.5225L11.5 2.50695L20.5155 11.5224L11.8027 20.2291C11.2169 20.8149 11.2169 21.7647 11.8027 22.3505L22.1065 12.0528C22.3954 11.7639 22.3954 11.2809 22.1065 10.9921L12.0304 0.915959C11.7375 0.623066 11.2626 0.623066 10.9697 0.915959L0.893498 10.9922C0.600605 11.2851 0.600605 11.7599 0.893498 12.0528L8.86325 20.0336C9.44903 19.4478 9.44903 18.498 8.86325 17.9122Z"
      fill="#0D3A93"
    />
    <path
      d="M13.9448 8.20527C13.6714 7.9319 13.2282 7.9319 12.9548 8.20527C12.6815 8.47864 12.6815 8.92185 12.9548 9.19522L14.435 10.6754H7.95C7.5634 10.6754 7.25 10.9888 7.25 11.3754L7.25 13.4502C7.25 14.2234 7.8768 14.8502 8.65 14.8502V12.0754H14.388L12.9548 13.5086C12.6815 13.7819 12.6815 14.2252 12.9548 14.4985C13.2282 14.7719 13.6714 14.7719 13.9448 14.4985L16.5964 11.8469C16.8698 11.5735 16.8698 11.1303 16.5964 10.8569L13.9448 8.20527Z"
      fill="#0D3A93"
    />
  </svg>
</template>
