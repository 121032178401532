<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="exclamation_circle">
      <g id="Icon">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.75 8.25C11.75 7.83579 12.0858 7.5 12.5 7.5C12.9142 7.5 13.25 7.83579 13.25 8.25V13.75C13.25 14.1642 12.9142 14.5 12.5 14.5C12.0858 14.5 11.75 14.1642 11.75 13.75V8.25ZM12.9861 15.8975C12.8422 15.8013 12.6731 15.75 12.5 15.75C12.2679 15.75 12.0454 15.8422 11.8813 16.0063C11.7172 16.1704 11.625 16.3929 11.625 16.625C11.625 16.7981 11.6763 16.9672 11.7725 17.1111C11.8686 17.255 12.0053 17.3672 12.1652 17.4334C12.325 17.4996 12.501 17.5169 12.6707 17.4832C12.8404 17.4494 12.9963 17.3661 13.1187 17.2437C13.2411 17.1213 13.3244 16.9654 13.3582 16.7957C13.3919 16.626 13.3746 16.45 13.3084 16.2902C13.2422 16.1303 13.13 15.9936 12.9861 15.8975ZM12.5 4C7.80558 4 4 7.80558 4 12.5C4 13.6738 4.23751 14.7903 4.66638 15.8053C4.71342 15.9166 4.76276 16.0268 4.81435 16.1356C5.11002 16.7595 4.96583 17.533 4.39214 17.917L4.18439 18.0561C4.06319 17.875 3.94773 17.6898 3.83828 17.5005C3.73319 17.3189 3.63365 17.1336 3.53987 16.9449C3.44935 16.7627 3.3642 16.5774 3.28465 16.3891C2.77913 15.1926 2.5 13.878 2.5 12.5C2.5 6.97715 6.97715 2.5 12.5 2.5C18.0228 2.5 22.5 6.97715 22.5 12.5C22.5 18.0228 18.0228 22.5 12.5 22.5C11.122 22.5 9.80736 22.2209 8.61088 21.7154C8.4226 21.6358 8.23727 21.5507 8.05512 21.4601C7.86642 21.3664 7.68112 21.2668 7.49946 21.1617C7.30064 21.0467 7.10618 20.9251 6.91638 20.7971L7.05613 20.5898C7.44204 20.0174 8.21605 19.8758 8.8389 20.1735C8.95604 20.2295 9.07466 20.2829 9.19468 20.3336C10.2097 20.7625 11.3262 21 12.5 21C17.1944 21 21 17.1944 21 12.5C21 7.80558 17.1944 4 12.5 4Z"
          fill="#D70000"
        />
      </g>
    </g>
  </svg>
</template>
