import { apiPlugin } from '@/features/core/api';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { configurationServicePlugin } from '@/features/configuration';
import { authServicePlugin } from '@/features/core/auth';
import { ProductApiClient } from './product';
import { registerProductsApi } from './productRegister';

export * from './product';
export * from './productRegister';

export interface ProductsApiClient {
  product: ProductApiClient;
}

export const productsApiPlugin =
  ProviderPluginFactory.create<ProductsApiClient>({
    key: Symbol('ProductsApiClient'),
    defaultFactory: {
      create: () => ({
        product: new ProductApiClient(
          apiPlugin.get(),
          authServicePlugin.get(),
          configurationServicePlugin.get(),
        ),
      }),
    },
  });

export const productsApiRegistryPlugin = ExecPluginFactory.fn(() => {
  const { product } = productsApiPlugin.get();
  registerProductsApi(onlineEntityRepositoryRegistryPlugin.get(), product);
});
