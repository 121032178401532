import { apiPlugin, empowerIdApiPlugin } from '@/features/core/api';
import { OauthLoginApiClient } from './login';
import { ProviderPluginFactory } from '@/features/core/plugin';

export const oauthLoginApiClientPlugin =
  ProviderPluginFactory.create<OauthLoginApiClient>({
    key: Symbol('OauthLoginApiClient'),
    defaultFactory: {
      create: () =>
        new OauthLoginApiClient(apiPlugin.get(), empowerIdApiPlugin.get()),
    },
  });
