import { appCrashServicePlugin, appCrashExecutePlugin } from './services';
import { MultiPluginFactory } from '@/features/core/plugin';

export * from './types';
export * from './services';

export const appCrashPlugin = MultiPluginFactory.with({
  appCrashServicePlugin,
  appCrashExecutePlugin,
});
