import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { resolverServicePlugin } from '@/features/core/resolver/service';
import { authServicePlugin } from '@/features/core/auth';
import { ApiClient } from './api-client';
import { BaseApiClient, BaseApiClientOptions } from './base-api-client';
import { errorPlugin } from '@/features/core/errors';

export const empowerIdApiPlugin = ProviderPluginFactory.create<
  BaseApiClient,
  BaseApiClientOptions
>({
  key: Symbol('empowerIdApiPlugin'),
  defaultFactory: {
    create: (app, options) => new BaseApiClient(options, errorPlugin.get()),
  },
});

export const apiPlugin = ProviderPluginFactory.create<
  ApiClient,
  BaseApiClientOptions
>({
  key: Symbol('ApiClient'),
  defaultFactory: {
    create: (app, options) =>
      new ApiClient(
        options,
        authServicePlugin.get(),
        resolverServicePlugin.get(),
        errorPlugin.get(),
      ),
  },
});

export const productSearchApiPlugin = ProviderPluginFactory.create<
  BaseApiClient,
  BaseApiClientOptions
>({
  key: Symbol('ProductSearchApiClient'),
  defaultFactory: {
    create: (app, options) => new BaseApiClient(options, errorPlugin.get()),
  },
});

export const empowerIdApiConfigPlugin = MultiPluginFactory.with({
  empowerIdApiPlugin,
});

export const apiConfigPlugin = MultiPluginFactory.with({
  apiPlugin,
  productSearchApiPlugin,
});
