<template>
  <div class="bags__item" v-if="sku" :data-e2e="`bags-item-${name}`">
    <h3 class="bags__item__title" :data-e2e="`bags-name-label-${name}`">
      {{ name }}
    </h3>
    <BaseButton
      icon
      btnOutline
      :disabled="quantity <= minimumBagCount"
      @click="updateQuantity(quantity - 1)"
      :data-e2e="`bags-decrease-button-${name}`"
    >
      <MinusIcon stroke="currentColor" fill="currentColor" />
    </BaseButton>
    <div class="bags-quantity__block">
      <span
        class="bags-quantity__value text-bold"
        :data-e2e="`bags-${name}-quantity-text`"
        >{{ quantity }}</span
      >
    </div>
    <BaseButton
      icon
      btnOutline
      @click="updateQuantity(quantity + 1)"
      :data-e2e="`bags-increase-button-${name}`"
    >
      <PlusIcon stroke="currentColor" fill="currentColor" />
    </BaseButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseButton from '@/features/ui/components/Button.vue';
import MinusIcon from '@/features/ui/icons/MinusIcon.vue';
import PlusIcon from '@/features/ui/icons/PlusIcon.vue';

export default defineComponent({
  components: { PlusIcon, MinusIcon, BaseButton },
  props: {
    sku: { type: String, required: true },
    quantity: { type: Number, default: 0 },
    minimumBagCount: { type: Number, default: 0 },
    name: { type: String, required: true },
  },
  setup(props, { emit }) {
    const updateQuantity = (quantity: number) => {
      quantity = Math.max(0, quantity);
      emit('update:quantity', quantity);
    };

    return { updateQuantity };
  },
});
</script>

<style lang="scss" scoped>
.bags {
  margin-top: 30px;

  &__item {
    display: grid;
    align-items: center;
    padding: 16px 0;
    background: #fff;
    grid-template-columns: 1fr auto 32px auto;
    column-gap: 12px;
    align-content: stretch;

    &__title {
      font-size: 16px;
      text-align: left;
      line-height: 1em;
    }

    &-subtitle {
      margin-top: 4px;
      font-size: 14px;
      line-height: 1.5em;
      font-weight: 400;
    }

    + .bags__item {
      border-top: 1px solid #b6c4df;
    }
  }

  &-quantity__block {
    display: contents;
  }
}
</style>
