import { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderPluginDto } from '../types';

export class SetCustomerNoteAsTargetRoutePlugin
  implements PipelinePlugin<OrderPluginDto>
{
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    if (dataTransferObject.order.cartNote) {
      dataTransferObject.targetRoute = {
        name: 'handover-customer-note',
        params: {
          id: dataTransferObject.order.id,
        },
      };
    }

    return dataTransferObject;
  }
}
