import { authServicePlugin } from '@/features/core/auth';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { DriverAppRedirectService } from './driver-app-redirect';

export * from './driver-app-redirect';

export const driverAppRedirectServicePlugin =
  ProviderPluginFactory.create<DriverAppRedirectService>({
    key: Symbol('DriverAppRedirectService'),
    defaultFactory: {
      create: () => new DriverAppRedirectService(authServicePlugin.get()),
    },
  });
