import { MonitoringServiceInterface } from '../types/monitoring-service-interface';
import { Logger } from '@/features/core/logger';
import { Storage } from '@/features/core/storage';
import { Sync } from '@/features/sync-scheduler';

export class OpenSyncMonitor implements MonitoringServiceInterface {
  constructor(
    public monitoringIntervalInMin: number,
    private storage: Storage,
    private loggerService: Logger,
  ) {}

  async trigger(): Promise<void> {
    const syncs = await this.storage.getAll(Sync);
    if (syncs.length) {
      this.loggerService.info(
        'open sync',
        syncs.map((sync) => {
          const syncData = sync.data as { entitySnapshot: unknown };
          if (syncData) {
            delete syncData.entitySnapshot;
          }
          return {
            ...sync,
            data: syncData,
          };
        }),
      );
    }
  }
}
