import { Ref } from 'vue';

export interface OauthLoginAuthParams {
  username: string;
  password: string;
  [param: string]: unknown;
}

export interface OauthLoginAuthResponse {
  code?: string;
  [param: string]: unknown;
}

export interface OauthTokenAuthResponse {
  tokenType: string;
  expiresIn: string;
  accessToken: string;
  refresh_token: string;
  id: string;
  id_token: string;
  [param: string]: unknown;
}

export interface OauthLoginError {
  error: string;
  error_description: string;
}
export interface UseOauthLoginError {
  title: OauthLoginError['error'];
  description: OauthLoginError['error_description'];
}

export interface UseOauthLogin {
  loading: Ref<boolean>;
  error: Ref<UseOauthLoginError | null>;
  login(params: OauthLoginAuthParams): Promise<void>;
  isSameMerchant: Ref<boolean>;
}

export interface UseOauthLoginConfigParams {
  [key: string]: string;
}

export interface UseOauthLoginConfigRedirectUri {
  redirect_uri?: string;
}

export type UseOauthLoginConfig = UseOauthLoginConfigParams &
  UseOauthLoginConfigRedirectUri;

export interface UseChangeLog {
  version: string;
  content: Ref<string[]>;
  hasContent: Ref<boolean | undefined>;
}

export interface ChangeLog {
  [key: string]: string[] | string | undefined;
}

export const SKIP_WAITING = 'SKIP_WAITING';
export const UPDATE_SW = 'UPDATE_SW';
export const STATE = 'salte.auth.provider.empowerId.state';
export const CODE = 'salte.auth.provider.empowerId.code.raw';
