import {
  ProviderPluginFactory,
  ExecPluginFactory,
} from '@/features/core/plugin';
import { HealthMonitoringService } from './health-monitoring-service';
import { OpenSyncMonitor } from './open-sync-monitor';
import { loggerServicePlugin } from '@/features/core/logger';
import { environmentConfigurationPlugin } from '@/features/core/environment-configuration';
import { storagePlugin } from '@/features/core/storage';

export const healthMonitoringServicePlugin =
  ProviderPluginFactory.create<HealthMonitoringService>({
    key: Symbol('HealthMonitoringServicePlugin'),
    defaultFactory: {
      create: () => {
        const healthMonitoringServiceConfig = environmentConfigurationPlugin
          .get()
          .getConfig('health-monitoring');
        return new HealthMonitoringService(
          parseInt(
            healthMonitoringServiceConfig.monitoringIntervalInSeconds.toString(),
          ),
          loggerServicePlugin.get(),
        );
      },
    },
  });

export const healthMonitoringExecPlugin = ExecPluginFactory.fn(() => {
  const healthMonitoringServiceConfig = environmentConfigurationPlugin
    .get()
    .getConfig('health-monitoring');
  const openSyncMonitoringConfig = environmentConfigurationPlugin
    .get()
    .getConfig('open-sync-monitor');
  if (healthMonitoringServiceConfig.isActive) {
    if (openSyncMonitoringConfig.isActive) {
      healthMonitoringServicePlugin
        .get()
        .add(
          new OpenSyncMonitor(
            parseInt(
              openSyncMonitoringConfig.monitoringIntervalInMin.toString(),
            ),
            storagePlugin.get(),
            loggerServicePlugin.get(),
          ),
        );
    }

    healthMonitoringServicePlugin.get().run();
  }
});
