<template>
  <div>
    <div class="card__footer" v-if="orderItem.status !== 'picked'">
      <div class="card__footer-button">
        <Button
          :disabled="orderItem.quantity === orderItem.quantityOriginal"
          :data-e2e="`picking-order-cancel-button-${sku}`"
          icon
          btnOutline
          @click="onReplace"
        >
          <ReplacementIcon width="28" fill="currentColor" />
        </Button>
      </div>
      <div class="card__footer-button">
        <Button
          :data-e2e="`picking-decrease-button-${sku}`"
          :disabled="!canDecrease"
          icon
          btnOutline
          @click="$emit('decrease', orderItem.id)"
        >
          <MinusIcon stroke="currentColor" fill="currentColor" />
        </Button>
      </div>
      <CounterBox :order-item="orderItem" :current-tab="currentTab" />
      <div class="card__footer-button">
        <Button
          v-if="showIncreaseButton"
          :data-e2e="`picking-increase-button-${sku}`"
          :disabled="!canIncrease"
          icon
          btnOutline
          @click="$emit('increase', orderItem.id)"
        >
          <PlusIcon stroke="currentColor" fill="currentColor" />
        </Button>
      </div>
      <div class="card__footer-button">
        <Button
          :data-e2e="`picking-picked-button-${sku}`"
          :disabled="!canDecrease || currentTab === TabVariants.Changed"
          :active="isActivePickedButton"
          icon
          btnOutline
          @click="onSubmit"
        >
          <CheckIcon fill="currentColor" />
        </Button>
      </div>
    </div>
    <Button
      v-if="showUnableToScanBarcodeButton"
      class="validate-barcode-button"
      :data-e2e="`unable-scan-barcode-button-${sku}`"
      text
      plain
      @click="onUnableToScanBarcode"
      :disabled="orderItem.isBarcodeValidated"
    >
      {{ $t('errors.barcode.cant-scan-barcode.text') }}
    </Button>
    <div
      :class="[
        'card__footer',
        hasReplacement ? 'card__footer_replacement' : 'card__footer_picked',
      ]"
      v-if="orderItem.status === 'picked'"
    >
      <CounterBox :order-item="orderItem" :current-tab="currentTab" />
      <Button
        v-if="hasReplacement"
        icon
        btnOutline
        @click="onHandleReplace"
        :data-e2e="`picking-edit-button-${sku}`"
        class="has-replacement"
      >
        <EditIcon stroke="currentColor" />
      </Button>
      <Button
        v-else
        icon
        btnOutline
        @click="onEdit"
        :data-e2e="`picking-edit-button-${sku}`"
      >
        <EditIcon stroke="currentColor" />
      </Button>
    </div>
    <div v-if="isAlertDisplay" class="card__alert alert">
      <p class="alert__title">
        {{
          $t(
            'components.picking-product-card-footer-default.alert-title.text',
            {
              orderItemQuantity: orderItem.quantity,
              orderItemQuantityOriginal: orderItem.quantityOriginal,
            },
          )
        }}
      </p>
      <div class="alert__footer">
        <Button
          btnOutline
          @click="completePick"
          :data-e2e="`picking-confirm-button-${sku}`"
        >
          {{
            $t(
              'components.picking-product-card-footer-default.alert-confirm.text',
            )
          }}
        </Button>
        <Button
          plain
          btnOutline
          @click="setAlertDisplayState(false)"
          :data-e2e="`picking-cancel-button-${sku}`"
        >
          {{
            $t(
              'components.picking-product-card-footer-default.alert-cancel.text',
            )
          }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRef } from 'vue';
import { useProduct } from '@/features/picking/composables';
import {
  MinusIcon,
  PlusIcon,
  CheckIcon,
  EditIcon,
  Button,
  TabVariants,
} from '@/features/ui';
import { ReplacementIcon } from '@/features/ui/icons';
import CounterBox from '@/features/ui/components/CounterBox.vue';
import { OrderItem, OrderItemStatus } from '@/features/orders/types';

export default defineComponent({
  name: 'ProductCardFooterDefault',
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    currentTab: String,
    hasReplacement: {
      type: Boolean,
      default: false,
    },
    disableManualVerification: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'decrease',
    'increase',
    'submit',
    'edit',
    'replace',
    'handle-replace',
    'validateItem',
  ],
  components: {
    CounterBox,
    EditIcon,
    MinusIcon,
    PlusIcon,
    CheckIcon,
    Button,
    ReplacementIcon,
  },
  setup(props, { emit }) {
    const orderItemRef = toRef(props, 'orderItem');
    const { canDecrease, canIncrease, isFilled } = useProduct(orderItemRef);

    const isAlertDisplay = ref(false);

    const onSubmit = () => {
      if (!isFilled.value) {
        isAlertDisplay.value = true;
      } else {
        isAlertDisplay.value = false;
        emit('submit', props.orderItem.id);
      }
    };

    const onEdit = () => {
      emit('edit', props.orderItem.id);
    };

    const onHandleReplace = () => {
      emit('handle-replace', props.orderItem);
    };

    const onUnableToScanBarcode = () => {
      emit('validateItem', props.orderItem);
    };

    const isActivePickedButton = computed(
      () =>
        props.orderItem.status === OrderItemStatus.picked &&
        props.currentTab !== TabVariants.Changed,
    );

    const setAlertDisplayState = (value: boolean) => {
      isAlertDisplay.value = value;
    };

    const completePick = () => {
      setAlertDisplayState(false);
      emit('submit', props.orderItem.id);
    };

    const sku = computed<string>(() => {
      return props.orderItem.product
        ? props.orderItem.product.sku
        : props.orderItem.id;
    });

    const onReplace = () => {
      emit('replace', props.orderItem);
    };

    const isTypeZeroItem = (item: OrderItem) => {
      return item.product?.rwpType === 0;
    };

    const showIncreaseButton = computed(() => {
      return (
        props.disableManualVerification ||
        props.orderItem?.isBarcodeValidated ||
        !isTypeZeroItem(props.orderItem)
      );
    });

    const showUnableToScanBarcodeButton = computed(
      () =>
        !props.disableManualVerification &&
        props.orderItem.status !== 'picked' &&
        props.orderItem.product?.rwpType === 0,
    );

    return {
      completePick,
      setAlertDisplayState,
      isAlertDisplay,
      onSubmit,
      onEdit,
      canDecrease,
      canIncrease,
      isActivePickedButton,
      sku,
      onReplace,
      onHandleReplace,
      TabVariants,
      showIncreaseButton,
      onUnableToScanBarcode,
      showUnableToScanBarcodeButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.text-block__padding {
  padding: 0 7px;
}
.active {
  border-color: #0d3a93;
}
.passive {
  border-color: transparent;
}
.card {
  position: relative;
  overflow: hidden;
  background: #fff;
  border-style: solid;
  border-width: 2px;
  border-radius: 20px;
  box-shadow: 0 3px 16px rgba(13, 58, 147, 0.12);
  transition: border-color ease-in 0.2s;

  &.picked {
    border: 2px solid #0d3a93;
  }

  &__title {
    margin: 0 auto;
    max-width: 50%;
    font-size: 16px;
    line-height: 1.4em;
    font-weight: 600;
  }
  &__subtitle {
    margin: 4px 0;
    font-size: 8px;
    line-height: 1.4em;
    letter-spacing: 0.04em;
  }
  &__body {
    margin-top: 5px;
  }
  &__footer {
    z-index: 1;
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    grid-template-columns: auto auto 1fr auto auto;
    gap: 0 16px;

    &_replacement {
      display: block;
    }
  }
  &__footer-button {
    width: 44px;
  }
  .counter {
    grid-column: 3/4;
  }
  &__footer_picked {
    justify-items: end;
    grid-template-columns: 1fr auto 1fr;
    .counter {
      grid-column: 2/3;
    }
  }
  &__alert {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px 16px 16px;
    width: 100%;
    height: 100%;
    background-color: white;
  }
}

.alert {
  display: grid;
  gap: 32px 0;
  align-content: center;
  &__title {
    margin: 0 auto;
    width: 86%;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.44em;
  }
  &__footer {
    display: grid;
    gap: 16px 0;
  }
}
.product-img {
  margin: 0 auto;
  width: 112px;
  min-width: 11px;
  height: 112px;
}

.has-replacement {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 2;
}
.validate-barcode-button {
  height: auto;
  font-size: 14px;
  text-decoration: underline;
  color: #71777e;
  line-height: 24px;
}
</style>
