import { TourDocumentService } from './tour-document-service';
import { ProviderPluginFactory } from '@/features/core/plugin';
import { tourDocumentApiPlugin } from '../api';

export const tourDocumentServicePlugin =
  ProviderPluginFactory.create<TourDocumentService>({
    key: Symbol('TourDocumentService'),
    defaultFactory: {
      create: () => new TourDocumentService(tourDocumentApiPlugin.get()),
    },
  });
