import { Ref, ref } from 'vue';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { errorPlugin } from '@/features/core/errors';
import { Order } from '@/features/orders';
import { UseOrders } from '@/common';

const order = ref<Order | undefined>();

export function useOrders(): UseOrders {
  const orders: Ref<Order[]> = ref([]);
  const loadingOrders = ref(false);
  const loadingSingleOrder = ref(false);

  const loadOrders = async () => {
    try {
      loadingOrders.value = true;

      const ordersList = await entityRepositoryPlugin.get().getAll(Order);
      orders.value = ordersList.value;

      return ordersList.value;
    } catch (error) {
      errorPlugin.get().handle(error);
    } finally {
      loadingOrders.value = false;
    }
  };

  const loadOrder = async (id: string) => {
    try {
      loadingSingleOrder.value = true;
      const result = await entityRepositoryPlugin.get().getById(Order, {
        id,
      });
      order.value = result.value;
      return result.value;
    } catch (error) {
      errorPlugin.get().handle(error);
    } finally {
      loadingSingleOrder.value = false;
    }
  };
  return {
    orders,
    order,
    loadingOrders,
    loadingSingleOrder,
    loadOrders,
    loadOrder,
  };
}
