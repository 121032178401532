import { Type } from '@/utils/types';
import { Storable } from './storable';
import { Storage } from './storage';

export class StorageSetup {
  isSetupStarted = false;
  isSetupDone = false;

  private setupStrategyMap = new Map<
    Type<Storable>,
    StorageSetupStrategy | undefined
  >();

  constructor(
    private storage: Storage,
    private globalSetup: GlobalStorageSetup,
  ) {}

  add(storable: Type<Storable>, setupStrategy?: StorageSetupStrategy): void {
    if (this.isSetupStarted) {
      throw new Error(
        `StorageSetup: Cannot add storable setup as setup already started!`,
      );
    }

    this.setupStrategyMap.set(storable, setupStrategy);
  }

  async setup(): Promise<void> {
    if (this.isSetupDone || this.isSetupStarted) return;

    this.isSetupStarted = true;

    await this.globalSetup.setup(this.storage, this.setupStrategyMap, this);

    this.isSetupDone = true;
  }
}

export interface GlobalStorageSetup {
  setup(
    storage: Storage,
    setupMap: Map<Type<Storable>, StorageSetupStrategy | undefined>,
    storageSetup: StorageSetup,
  ): Promise<void>;
}

export interface StorageSetupStrategy {
  setup(meta: StorageSetupMeta): Promise<void>;
}

export interface StorageSetupMeta {
  storage: Storage;
  storeName: string;
}
