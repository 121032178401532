import { MultiPluginFactory } from '@/features/core/plugin';
import { tourDocumentServicePlugin } from './services/';
import { tourDocumentApiPlugin } from './api';

export * from './components';
export * from './services';
export * from './api';

export const tourDocumentPlugin = MultiPluginFactory.with({
  tourDocumentApiPlugin,
  tourDocumentServicePlugin,
});
