import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { storageZoneServicePlugin } from '@/features/storageZone';
import { productsServicePlugin } from '@/features/products/services';
import { dataSyncServicePlugin } from '@/features/data-sync';
import { DataBackgroundSyncServiceImplementation } from './data-background-sync-service';

export const dataBackgroundSyncServicePlugin =
  ProviderPluginFactory.create<DataBackgroundSyncServiceImplementation>({
    key: Symbol('DataBackgroundSyncService'),
    defaultFactory: {
      create: () => {
        return new DataBackgroundSyncServiceImplementation(
          storagePlugin.get(),
          storageZoneServicePlugin.get(),
          productsServicePlugin.get(),
          dataSyncServicePlugin.get(),
        );
      },
    },
  });
