<template>
  <Dialog
    :confirmText="$t('components.route-leave-guard.dialog-confirm.text')"
    :contentText="$t('components.route-leave-guard.dialog-content.text')"
    :isTitleCentered="false"
    :title-text="dialogText"
    :visible="isDialogVisible"
    fullWidth
    isSpaceToContentExist
    showOnlyConfirm
    @confirmed="closeDialog"
  />

  <Dialog
    :isTitleCentered="false"
    :show-only-confirm="true"
    :visible="isSameDayDialogVisible"
    confirm-text="Close"
    content-text="Please complete this order first before starting another."
    title-text="Order cannot be opened"
    @confirmed="cancelSameDayNotification"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import router from '@/features/core/router';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { Dialog } from '@/features/ui/components';
import { ChangeUrlEvent } from '@/features/route-leave-guard/events';
import { useRouteLeaveGuard } from '../composables';

export default defineComponent({
  name: 'LeavingRouteConfirmation',
  components: {
    Dialog,
  },
  setup() {
    const {
      closeDialog,
      isDialogVisible,
      dialogText,
      setupNavigationGuard,
      showDialog,
      isSameDayDialogVisible,
      cancelSameDayNotification,
      unregisterNavigationGuard,
    } = useRouteLeaveGuard();

    onMounted(() => {
      setupNavigationGuard();
    });

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    eventBusServicePlugin.get().on(ChangeUrlEvent, async (data) => {
      if (data.options?.bypassRouteNavigationGuard) {
        unregisterNavigationGuard();
        await router.get().push(data.url);
        setupNavigationGuard();

        return;
      }

      await router.get().push(data.url);
    });

    return {
      dialogText,
      closeDialog,
      isDialogVisible,
      showDialog,
      isSameDayDialogVisible,
      cancelSameDayNotification,
    };
  },
});
</script>
