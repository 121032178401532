import type { Storage } from '@/features/core/storage';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { Order } from '../entities';
import type { OrderParsePluginDto } from '@/features/orders/types';

/**
 * Get existing order with the same id as parsedOrder and store it in DTO
 **/
export class LoadExistingOrderPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  constructor(private storage: Storage) {}
  public async execute(
    dataTransferObject: OrderParsePluginDto,
  ): Promise<OrderParsePluginDto> {
    dataTransferObject.existingOrder = await this.storage.getById(Order, {
      id: dataTransferObject.orderRaw.id,
    });

    return dataTransferObject;
  }
}
