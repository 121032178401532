import { CompletePipelineWith, PipelinePlugin } from '@ads/plugin-pipeline';
import { BarcodeLookupPluginDto } from '../types';
import { createBarcodeLookupResult } from '../helpers';
import { BarcodeFoundService } from '../services';

/**
 * Checks the list of known products for an exact barcode match,
 * and if found, completes the pipeline with it.
 *
 * If not found, it will add the list of sorted products as the
 * dataset in the DTO for future operations.
 *
 * For details on how this plugin works check the documentation linked below
 * (look for the "Product/item lookup" section)
 *
 * @see https://aldi-sued.atlassian.net/l/cp/0HUfAQ2s
 */
export class SearchProductByBarcodePlugin
  implements PipelinePlugin<BarcodeLookupPluginDto>
{
  constructor(private barcodeFoundService: BarcodeFoundService) {}

  public async execute(
    dataTransferObject: BarcodeLookupPluginDto,
    completePipelineWith: CompletePipelineWith<BarcodeLookupPluginDto>,
  ): Promise<BarcodeLookupPluginDto> {
    const sortedProductList =
      await this.barcodeFoundService.getSortedProductsList();
    const barcodeProduct = this.barcodeFoundService.findScannedProductInList(
      dataTransferObject.barcode,
      sortedProductList,
    );

    if (barcodeProduct) {
      return completePipelineWith({
        ...dataTransferObject,
        result: createBarcodeLookupResult(barcodeProduct, {
          barcodeType: 'normal',
          weight: null,
        }),
      });
    }

    return {
      ...dataTransferObject,
      dataset: sortedProductList,
    };
  }
}
