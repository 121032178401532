import { DriverAppRedirect } from '../types';
import { router } from '@/features/core/router';
import { AuthService } from '@/features/core/auth';
import { userServicePlugin } from '@/features/user';

export class DriverAppRedirectService implements DriverAppRedirect {
  constructor(private authService: AuthService) {}

  async checkUserRoleAndRedirectIfDriver(): Promise<boolean> {
    const userRoles = await this.authService.getUserRoles();
    if (userServicePlugin.get().isDriverRole(userRoles)) {
      await router.get().push('tours');

      return true;
    }
    return false;
  }
}
