import { apiPlugin } from '@/features/core/api';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { User } from '../entities';
import { UserApiClient } from './user';

export * from './user';

export const userApiPlugin = ProviderPluginFactory.create<UserApiClient>({
  key: Symbol('UserApiClient'),
  defaultFactory: {
    create: () => new UserApiClient(apiPlugin.get()),
  },
});

export const userApiRegistryPlugin = ExecPluginFactory.fn(() => {
  const user = userApiPlugin.get();
  onlineEntityRepositoryRegistryPlugin.get().register(User, {
    getById: (options) => user.getById(options.id),
  });
});
