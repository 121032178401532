<template>
  <footer class="footer">
    <slot></slot>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  padding: 24px;
  width: 100%;
  background: #fff;
  border-top: 2px solid var(--border-color);
}
</style>
