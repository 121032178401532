export function buildUrl<T extends Record<string, string | number | boolean>>(
  baseUrl: string,
  params: T,
): string {
  let url = baseUrl;
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${encodeURIComponent(
        value,
      )}`;
    }
  }

  return url;
}
