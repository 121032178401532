import { AppCrashMeta, TourCrash, TourCrashServiceConfig } from '../types';
import { RouteLocationNormalized, Router } from 'vue-router';

export class TourCrashService implements TourCrash {
  constructor(private options: TourCrashServiceConfig, private router: Router) {
    void this.initialCheck();
  }

  public init = async (): Promise<void> => {
    await this.checkUnfinishedProcessAndRedirect();
    this.setupNavigationGuard();
  };

  private initialCheck = async (): Promise<void> => {
    const isCrash = await this.checkUnfinishedProcessAndRedirect();
    if (isCrash) {
      this.setupNavigationGuard();
    }
  };

  private updateLastRoute = (to: RouteLocationNormalized): void => {
    const routeAppCrashMeta = to.meta?.appCrashFeature as AppCrashMeta;
    const shouldTrackRoute = routeAppCrashMeta?.trackRoute;
    if (!shouldTrackRoute) return;
    this.saveLastRoute(to.fullPath);
  };

  private checkUnfinishedProcessAndRedirect = async (): Promise<boolean> => {
    if (
      this.options.skipAppCrashRedirect ||
      sessionStorage.getItem('post_login_redirect_url')
    ) {
      return false;
    }
    const lastRoute = localStorage.getItem('lastTourRoute');
    if (lastRoute) {
      await this.router.push(lastRoute);
      return true;
    }
    return false;
  };

  public removeLastRoute = (): void => {
    localStorage.removeItem('lastTourRoute');
  };

  private saveLastRoute(routePath: string): void {
    const saveToLocalStorage = (): void => {
      return localStorage.setItem('lastTourRoute', routePath);
    };

    return saveToLocalStorage();
  }

  private setupNavigationGuard = (): void => {
    this.router.afterEach((to: RouteLocationNormalized, _, failure) => {
      if (!failure) {
        this.updateLastRoute(to);
      }
    });
  };
}
