import { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';
import { DeviceApiClient } from './device-api';
import { Device } from '../entities';

export const registerDeviceApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  configurationApi: DeviceApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(Device, {
    save: configurationApi.save.bind(configurationApi),
  });
};
