/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
import { toRawRecursive } from '@/utils/helpers/toRaw';
import { InferType } from '@/utils/types';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { getStorableType, Storage } from '@/features/core/storage';
import { Entity, EntityType } from './entity';
import {
  EntityRepository,
  RepositoryReadByIdOptions,
  RepositoryReadByIdsOptions,
  RepositoryReadOptions,
  RepositoryWriteOptions,
  RepositoryWriteResult,
} from './entity-repository';
import {
  SyncSchedulerEvent,
  SyncStatus,
  UpdateSyncStatusEvent,
} from '@/features/sync-scheduler';
import { OnlineEntityRepository } from './online-entity-repository';
import { EventBus } from '@/features/core/event-bus';
import { EntityUpdate } from '@/features/service-worker';
import { PromiseSubject } from '@/utils/helpers/PromiseSubject';
import { EntityUpdatedEvent } from './events';
import { stripFks } from '@/features/core/storage/fk-resolver';
import { getEntityByName } from '@/utils/helpers/getEntityByName';

interface EntityCleanup {
  entity: EntityType<any>;
  optionsHash?: string;
  id?: string;
}

interface MemorizedRefParams {
  entity: EntityType<any>;
  refMapType: 'allRefMap' | 'refMap';
  secondLevelParam: string;
}

interface StorageRepositoryWriteOptions extends RepositoryWriteOptions {
  api: keyof OnlineEntityRepository;
  entityType: EntityType<any>;
  entityId?: string;
}

export interface StorageEntityRepositoryOptions {
  storage: Storage;
  apiRepository: EntityRepository;
  eventBus: EventBus;
}

export class StorageEntityRepository implements EntityRepository {
  private storage: Storage;
  private apiRepository: EntityRepository;
  private eventBus: EventBus;
  private refMap: Map<EntityType<any>, Map<string, WeakRef<Ref<Entity>>>> =
    new Map();
  private allRefMap: Map<EntityType<any>, Map<string, WeakRef<Ref<Entity[]>>>> =
    new Map();
  private finalRegistry = new FinalizationRegistry(this.cleanup.bind(this));

  constructor(private options: StorageEntityRepositoryOptions) {
    this.storage = this.options.storage;
    this.apiRepository = this.options.apiRepository;
    this.eventBus = this.options.eventBus;

    this.eventBus.on(EntityUpdatedEvent, (event) => {
      void this.updateEntity(event.updateEntityPayload);
    });
  }

  async getAll<T extends EntityType<any>>(
    entity: T,
    options: RepositoryReadOptions = {},
  ): Promise<ComputedRef<InferType<T>[]>> {
    const optionsHash = JSON.stringify(options);
    const memorizedRef = this.getMemorizedRef<InferType<T>[]>({
      entity,
      refMapType: 'allRefMap',
      secondLevelParam: optionsHash,
    });

    if (memorizedRef && memorizedRef.value.length) {
      return memorizedRef;
    }

    const data = await this.storage.getAll(entity, options);

    const dataRef = ref(data) as Ref<InferType<T>[]>;
    if (!dataRef.value) {
      return computed(() => dataRef.value);
    }
    this.finalRegistry.register(dataRef.value, { entity, optionsHash });

    if (!this.allRefMap.has(entity)) {
      this.allRefMap.set(entity, new Map());
    }

    this.allRefMap.get(entity)?.set(optionsHash, new WeakRef(dataRef));

    return computed(() => dataRef.value);
  }

  async getById<T extends EntityType<any>>(
    entity: T,
    options: RepositoryReadByIdOptions,
  ): Promise<ComputedRef<InferType<T> | undefined>> {
    const { id } = options;

    const memorizedRef = this.getMemorizedRef<InferType<T>>({
      entity,
      refMapType: 'refMap',
      secondLevelParam: id,
    });

    if (memorizedRef) {
      return memorizedRef;
    }

    const data = await this.storage.getById(entity, options);
    const dataRef = ref(data) as Ref<InferType<T>>;
    if (!dataRef.value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return computed(() => dataRef.value);
    }
    this.finalRegistry.register(dataRef.value, { entity, id });

    if (!this.refMap.has(entity)) {
      this.refMap.set(entity, new Map());
    }

    this.refMap.get(entity)?.set(id, new WeakRef(dataRef));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return computed(() => dataRef.value);
  }

  async getByIds<T extends EntityType<any>>(
    entity: T,
    options: RepositoryReadByIdsOptions,
  ): Promise<ComputedRef<InferType<T>[]>> {
    const optionsHash = JSON.stringify(options);

    const memorizedRef = this.getMemorizedRef<InferType<T>[]>({
      entity,
      refMapType: 'allRefMap',
      secondLevelParam: optionsHash,
    });

    if (memorizedRef) {
      return memorizedRef;
    }

    const data = await this.storage.getByIds(entity, options);
    const dataRef = ref(data) as Ref<InferType<T>[]>;
    if (!dataRef.value) {
      return computed(() => dataRef.value);
    }
    this.finalRegistry.register(dataRef.value, { entity, optionsHash });

    if (!this.allRefMap.has(entity)) {
      this.allRefMap.set(entity, new Map());
    }
    this.allRefMap.get(entity)?.set(optionsHash, new WeakRef(dataRef));

    return computed(() => dataRef.value);
  }

  save<T extends Entity>(
    entity: T,
    options?: RepositoryWriteOptions,
  ): RepositoryWriteResult<ComputedRef<T>> {
    const entityType = getStorableType(entity);
    const data = entityType.from(toRawRecursive(entity));

    const update = async () => {
      const result = await this.storage.save(data, options);
      const resultRef = ref(result) as Ref<Entity>;
      const entityId = result.id;

      if (!resultRef.value) {
        return computed(() => resultRef.value as unknown as T);
      }
      this.finalRegistry.register(resultRef.value, {
        entity,
        entityId,
      });

      const weakRef = new WeakRef(resultRef);
      const entityTypeMap = this.refMap.get(entityType);

      if (!entityTypeMap) {
        this.refMap.set(entityType, new Map([[entityId, weakRef]]));
      } else {
        const entityRef = entityTypeMap.get(entityId);
        const refData =
          entityRef && (entityRef.deref() as unknown as Ref<Entity>);

        if (refData) {
          refData.value = result;
        } else {
          entityTypeMap.set(entityId, weakRef);
        }
      }

      return computed(() => resultRef.value as unknown as T);
    };

    const exec = async () => {
      const result = await this.apiRepository.save(stripFks(data), options)
        .completed;

      return computed(() => result as unknown as T);
    };

    return this.execWrite(update, exec, {
      ...options,
      api: 'save',
      entityType,
      entityId: entity.id,
    });
  }

  remove(
    entity: Entity,
    options?: RepositoryWriteOptions,
  ): RepositoryWriteResult<void> {
    const update = async () => {
      await this.storage.remove(entity, options);
      return computed(() => void 0);
    };

    const exec = async () => {
      await this.apiRepository.remove(entity, options).completed;
      return computed(() => void 0);
    };

    const result = this.execWrite(update, exec, {
      ...options,
      api: 'remove',
      entityType: getStorableType(entity),
      entityId: entity.id,
    });
    return {
      scheduled: result.scheduled.then(() => void 0),
      completed: result.completed.then(() => void 0),
    };
  }

  removeAll(
    entity: EntityType<any>,
    options?: RepositoryWriteOptions,
  ): RepositoryWriteResult<void> {
    const update = async () => {
      await this.storage.removeAll(entity, options);
      return computed(() => void 0);
    };

    const exec = async () => {
      await this.apiRepository.removeAll(entity, options).completed;
      return computed(() => void 0);
    };

    const result = this.execWrite(update, exec, {
      ...options,
      api: 'removeAll',
      entityType: entity,
    });

    return {
      scheduled: result.scheduled.then(() => void 0),
      completed: result.completed.then(() => void 0),
    };
  }

  clearRefCache(): void {
    this.allRefMap.clear();
    this.refMap.clear();
  }

  private execWrite<T extends Entity>(
    updateFn: () => Promise<ComputedRef<T | undefined>>,
    execFn: () => Promise<ComputedRef<T | undefined>>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: StorageRepositoryWriteOptions,
  ): RepositoryWriteResult<ComputedRef<T>> {
    const syncStatusPromise = new PromiseSubject<SyncStatus>();
    // eslint-disable-next-line no-async-promise-executor,@typescript-eslint/no-misused-promises
    const scheduled = new Promise<ComputedRef<T>>(async (resolve, reject) => {
      try {
        const result = await updateFn();

        if (!options.waitForSync) {
          await this.updateEntity([
            {
              entity: options.entityType,
              ids: options.entityId ? [options.entityId] : undefined,
              updated: options.api === 'save',
              removed: options.api !== 'save',
            },
          ]);

          this.eventBus.emit(
            new SyncSchedulerEvent({
              api: options.api,
              entity: (options.entityType.from({}) as Entity).type,
              entityId: options.entityId,
              entitySnapshot: toRawRecursive(result?.value),
            }),
          );

          this.eventBus.on(UpdateSyncStatusEvent, (event) => {
            if (
              event.syncStatus.data.entityId === options.entityId &&
              getEntityByName(event.syncStatus.data.entity) ===
                options.entityType
            ) {
              syncStatusPromise.resolve(event.syncStatus);
            }
          });
        }

        resolve(result as ComputedRef<T>);
      } catch (error) {
        reject(error);
      }
    });

    // eslint-disable-next-line no-async-promise-executor,@typescript-eslint/no-misused-promises
    const completed = new Promise<ComputedRef<T>>(async (resolve, reject) => {
      try {
        if (options.waitForSync) {
          const result = (await execFn()) as ComputedRef<T>;
          await this.updateEntity([
            {
              entity: options.entityType,
              ids: options.entityId ? [options.entityId] : undefined,
              updated: options.api === 'save',
              removed: options.api !== 'save',
            },
          ]);
          resolve(result);
        } else {
          await syncStatusPromise.then((syncStatus) => syncStatus.completed);
          let result: T;
          if (options.entityId) {
            result = await this.storage.getById(options.entityType, {
              id: options.entityId,
            });
          }
          resolve(computed(() => result));
        }
      } catch (error) {
        reject(error);
      }
    });

    return {
      scheduled,
      completed,
    };
  }

  private cleanup(entityCleanup: EntityCleanup): void {
    const { entity, optionsHash } = entityCleanup;
    const refMapType = optionsHash ? 'allRefMap' : 'refMap';
    const paramType = optionsHash ? 'optionsHash' : 'id';
    const weakRefMap = this[refMapType]?.get(entity);

    if (!weakRefMap) {
      return;
    }

    weakRefMap.delete(entityCleanup[paramType] || '');
    if (weakRefMap.size === 0) {
      this[refMapType].delete(entity);
    }
  }

  //TODO: change to private when sheduler branch is been merged
  public async updateEntity(
    payload: EntityUpdate<EntityType<any>>[],
  ): Promise<void> {
    for (const entityUpdate of payload) {
      const internalRefMap = this.refMap.get(entityUpdate.entity);
      const internalAllRefMap = this.allRefMap.get(entityUpdate.entity);

      if (entityUpdate.updated) {
        if (internalAllRefMap) {
          for (const key of internalAllRefMap?.keys()) {
            const serializedOptions = JSON.parse(key);
            const storageData = await this.storage.getAll(
              entityUpdate.entity,
              serializedOptions,
            );
            const refData = internalAllRefMap
              .get(key)
              ?.deref() as unknown as Ref<Entity[]>;

            if (refData) {
              refData.value.splice(0, refData.value.length);
              refData.value.push(...storageData);
            }
          }
        }

        if (entityUpdate.ids && entityUpdate.ids.length > 0 && internalRefMap) {
          for (const id of entityUpdate.ids) {
            const refData = internalRefMap.get(id)?.deref();

            const storageData = await this.storage.getById(
              entityUpdate.entity,
              { id },
            );
            if (refData) {
              refData.value = storageData;
            }
          }
        }
      } else if (entityUpdate.removed) {
        if (entityUpdate.ids && entityUpdate.ids.length > 0) {
          if (internalRefMap) {
            for (const id of entityUpdate.ids) {
              const data = internalRefMap.get(id)?.deref();
              if (data) {
                data.value = undefined as unknown as Entity;
              }
            }
          }
          if (internalAllRefMap) {
            for (const key of internalAllRefMap.keys()) {
              const serializedOptions = JSON.parse(key);
              const storageData = await this.storage.getAll(
                entityUpdate.entity,
                serializedOptions,
              );
              const refData = internalAllRefMap
                .get(key)
                ?.deref() as unknown as Ref<Entity[]>;

              if (refData) {
                refData.value.splice(0, refData.value.length);
                refData.value.push(...storageData);
              }
            }
          }
        } else {
          if (internalAllRefMap) {
            for (const key of internalAllRefMap.keys()) {
              const refData = internalAllRefMap
                .get(key)
                ?.deref() as unknown as Ref<Entity[]>;
              if (refData) {
                refData.value.splice(0, refData.value.length);
              }
            }
          }
          if (internalRefMap) {
            for (const id of internalRefMap.keys()) {
              const data = internalRefMap.get(id)?.deref();
              if (data) {
                data.value = undefined as unknown as Entity;
              }
            }
          }
        }
      }
    }
  }

  private getMemorizedRef<T>({
    entity,
    refMapType,
    secondLevelParam,
  }: MemorizedRefParams): ComputedRef<T> | undefined {
    if (this[refMapType].has(entity)) {
      const secondLevel = this[refMapType]?.get(entity);
      if (!secondLevel) {
        return;
      }

      if (secondLevel.has(secondLevelParam)) {
        const refData = secondLevel.get(secondLevelParam)?.deref();

        if (refData !== undefined) {
          return computed(() => refData.value as unknown as T);
        } else {
          secondLevel.delete(secondLevelParam);
          if (secondLevel.size === 0) {
            this[refMapType].delete(entity);
          }
        }
      }
    }
  }
}
