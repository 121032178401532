<template>
  <svg
    width="40"
    viewBox="0 0 40 40"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 5.83301C25 6.47328 25.2407 7.05733 25.6365 7.49961C25.9137 7.80922 26.2668 8.04935 26.6666 8.19066C26.9273 8.28279 27.2078 8.33291 27.5 8.33291H27.9125L27.9162 8.33292L31.6662 8.33292V11.666L31.6666 11.698V12.4994C31.6666 12.7916 31.7168 13.0721 31.8089 13.3327C31.9502 13.7325 32.1903 14.0857 32.5 14.3628C32.9423 14.7586 33.5263 14.9993 34.1666 14.9993L34.1662 7.08297C34.1662 6.75146 34.0345 6.43353 33.8001 6.19912C33.5657 5.9647 33.2477 5.83301 32.9162 5.83301L25 5.83301Z"
    />
    <path
      d="M8.33289 31.6654L13.7491 31.6654C14.4395 31.6654 14.9991 32.225 14.9991 32.9153C14.9991 33.6057 14.4395 34.1653 13.7491 34.1653H7.08289C6.39253 34.1653 5.83289 33.6057 5.83289 32.9153L5.83289 26.2488C5.83289 25.5585 6.39253 24.9989 7.08289 24.9989C7.77324 24.9989 8.33289 25.5585 8.33289 26.2488L8.33289 31.6654Z"
    />
    <path
      d="M31.6662 31.6654H26.2491C25.5588 31.6654 24.9991 32.225 24.9991 32.9153C24.9991 33.6057 25.5588 34.1653 26.2491 34.1653L32.9162 34.1653C33.6066 34.1653 34.1662 33.6057 34.1662 32.9153V26.2488C34.1662 25.5585 33.6066 24.9989 32.9162 24.9989C32.2259 24.9989 31.6662 25.5585 31.6662 26.2488L31.6662 31.6654Z"
    />
    <path
      d="M7.08289 14.9992C6.39253 14.9992 5.83289 14.4396 5.83289 13.7493V7.08297C5.83289 6.39264 6.39253 5.83301 7.08289 5.83301L13.7491 5.83301C14.4395 5.83301 14.9991 6.39263 14.9991 7.08297C14.9991 7.7733 14.4395 8.33292 13.7491 8.33292L8.33289 8.33292L8.33289 13.7493C8.33289 14.4396 7.77324 14.9992 7.08289 14.9992Z"
    />
    <path
      d="M18.75 23.749C18.75 24.4393 19.3096 24.999 20 24.999C20.6903 24.999 21.25 24.4393 21.25 23.749V16.2493C21.25 15.559 20.6903 14.9993 20 14.9993C19.3096 14.9993 18.75 15.559 18.75 16.2493V23.749Z"
    />
    <path
      d="M26.2504 24.999C25.56 24.999 25.0004 24.4393 25.0004 23.749V16.2493C25.0004 15.559 25.56 14.9993 26.2504 14.9993C26.9407 14.9993 27.5004 15.559 27.5004 16.2493V23.749C27.5004 24.4393 26.9407 24.999 26.2504 24.999Z"
    />
    <path
      d="M12.4996 23.749C12.4996 24.4393 13.0592 24.999 13.7496 24.999C14.4399 24.999 14.9996 24.4393 14.9996 23.749V16.2493C14.9996 15.559 14.4399 14.9993 13.7496 14.9993C13.0592 14.9993 12.4996 15.559 12.4996 16.2493L12.4996 23.749Z"
    />
  </svg>
</template>
