<template>
  <SimpleAccordeon>
    <template v-slot:headline>
      <div class="transmissions-headline" :data-e2e="`${e2ePrefix}-headline`">
        <slot name="headline" />
      </div>
    </template>
    <template v-slot:content>
      <div
        v-for="(transmission, index) in transmissionsList"
        :key="index"
        class="transmission-details"
        :data-e2e="`${e2ePrefix}-details-${transmission.id}`"
      >
        <div
          class="transmission-details__status"
          :data-e2e="`${e2ePrefix}-details-status`"
        >
          {{ transmission.title }}
        </div>
        <div :data-e2e="`${e2ePrefix}-details-order-reference`">
          {{ transmission.orderReference }}
        </div>
      </div>
    </template>
  </SimpleAccordeon>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SimpleAccordeon from '@/features/ui/components/SimpleAccordeon.vue';

export default defineComponent({
  name: 'TransmissionsAccordeon',
  components: {
    SimpleAccordeon,
  },
  props: {
    transmissionsList: {
      type: Array,
      required: true,
    },
    e2ePrefix: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.transmissions-headline {
  display: flex;
  align-items: center;
}

.transmission-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  &__status {
    text-transform: capitalize;
  }
}

.transmissions-headline-title {
  display: flex;
  align-items: center;
  margin-right: 4px;
}
</style>
