<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.49525 4.43481C4.90946 5.0206 4.90946 5.97035 5.49525 6.55613L9.43743 10.4981C9.73032 10.791 10.2052 10.791 10.4981 10.4981C10.791 10.2052 10.791 9.73037 10.4981 9.43748L6.55607 5.49553L6.54822 5.48778L5.49525 4.43481Z"
    />
    <path
      d="M11.9999 13.0606L6.78021 18.2802L6.7688 18.2914L5.49525 19.5649C4.90946 18.9792 4.90946 18.0294 5.49525 17.4436L16.3813 6.55786L16.3795 6.55613L18.5009 4.43481C19.0866 5.0206 19.0866 5.97035 18.5009 6.55613L18.1792 6.87778C18.1662 6.89307 18.1525 6.90796 18.138 6.9224L13.0605 11.9999L18.1891 17.1285C18.2035 17.1429 18.2171 17.1576 18.23 17.1728L18.5009 17.4436C19.0866 18.0294 19.0866 18.9792 18.5009 19.5649L16.3795 17.4436L16.3812 17.4419L11.9999 13.0606Z"
    />
  </svg>
</template>
