import type { UseHandoverOrder } from '@/features/handover/types';
import type { BottleDepositReturn, RejectedDeliveryItem, UseDrops } from '..';
import { ExchangedItem, OrderItem, OrderItemStatus } from '@/features/orders';
import type { ErrorHandler } from '@/features/core/errors';
import type { UseOrders } from '@/common';
import type { UseTours } from '@/features/tours/types/useTours';
import type { DeliveryItem } from '@/features/tours';

export class DropsHandoverService {
  constructor(
    private currentTour: UseTours['currentTour'],
    private useHandoverOrder: UseHandoverOrder,
    private errorPlugin: ErrorHandler,
    private currentActiveDrop: UseDrops['currentActiveDrop'],
    private useOrders: UseOrders,
  ) {}

  // As soon as we receive grouped deliveryItems from Spryker, we can remove this function.
  private getDeliveryItemReference(
    orderReference: string,
    productSku: string,
  ): string | undefined {
    const deliveryItem = this.currentTour.value?.deliveryItems.find(
      (deliveryItem: DeliveryItem) =>
        deliveryItem.orderReference === orderReference &&
        deliveryItem.productReference === productSku,
    );
    return deliveryItem?.deliveryItemReference;
  }

  public getBottleDepositReturns(): BottleDepositReturn[] {
    return (
      this.useHandoverOrder.order.value?.bottleDeposit?.map((option) => ({
        orderReference: this.useHandoverOrder.order.value?.orderReference ?? '',
        key: option.id,
        quantity: option.quantity,
      })) ?? []
    );
  }

  public prepareRejectedDeliveryItems(): RejectedDeliveryItem[] {
    const rejectedOrderItems: OrderItem[] | undefined =
      this.useHandoverOrder.order.value?.items.filter(
        (item) => item.status === OrderItemStatus.rejected,
      );
    if (!rejectedOrderItems) return [];

    return rejectedOrderItems.flatMap((rejectedOrderItem) => {
      if (!this.useHandoverOrder.order.value) {
        this.errorPlugin.handle(new Error('NO_ORDER_REFERENCE'));
        return [];
      }
      const deliveryItemReference = this.getDeliveryItemReference(
        this.useHandoverOrder.order.value?.orderReference,
        rejectedOrderItem.product.sku,
      );
      return [
        {
          reference: deliveryItemReference ?? 'NO_DELIVERY_ITEM_REFERENCE',
          quantity: rejectedOrderItem.quantity,
          amount: rejectedOrderItem.weights?.map((i) => i.weight) ?? [],
          unit: rejectedOrderItem.unit,
          rejectionReason: this.getRejectionReason(
            rejectedOrderItem.rejectionReason,
          ),
        },
      ];
    });
  }

  private getRejectionReason(
    rejectionReason: string | undefined,
  ): RejectedDeliveryItem['rejectionReason'] {
    if (!rejectionReason?.includes(':')) {
      return {
        key: 'other',
        otherReasonText: rejectionReason,
      };
    }

    return {
      key: rejectionReason.split(':')[0],
    };
  }

  public getExchangedItems(): ExchangedItem[] {
    return (
      this.useHandoverOrder.order.value?.exchange?.map((option) => ({
        reference: option.id,
        quantity: option.quantity,
      })) ?? []
    );
  }

  public async getExchangeableOrderItems(): Promise<OrderItem[]> {
    const exchangeableDeliveryItems = this.getExchangeableDeliveryItems();
    await this.useOrders.loadOrders();

    return this.useOrders.orders.value.flatMap((order) => {
      const exchangePossibleOrderItems = order.items.filter(
        (orderItem) =>
          orderItem.status === OrderItemStatus.staged &&
          orderItem.quantity > 0 &&
          exchangeableDeliveryItems.find(
            (deliveryItem) =>
              deliveryItem.productReference === orderItem.product.sku &&
              deliveryItem.orderReference === order.orderReference,
          ),
      );

      return exchangePossibleOrderItems.map((orderItem) => {
        const deliveryItem = exchangeableDeliveryItems.find(
          (deliveryItem) =>
            deliveryItem.productReference === orderItem.product.sku &&
            deliveryItem.orderReference === order.orderReference,
        );
        if (!deliveryItem) {
          return orderItem;
        }
        orderItem.id = deliveryItem.deliveryItemReference;
        return orderItem;
      });
    });
  }

  private getExchangeableDeliveryItems() {
    return (
      this.currentTour.value?.deliveryItems.filter(
        (deliveryItem) =>
          this.currentActiveDrop.value?.deliveryUnitReferences.includes(
            deliveryItem.deliveryUnitReference,
          ) && deliveryItem.exchangeable,
      ) ?? []
    );
  }
}
