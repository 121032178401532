import { getArrayDiffFrom } from '@/utils/helpers/getArrayDiffFrom';
/**
 * Returns a new array with all elements missing in both arrays.
 * Object reference comparison.
 *
 * @param arr1 The first array
 * @param arr2 The second array
 */
export function getArrayDiff<T>(arr1: T[], arr2: T[]): T[] {
  return Array.from(
    new Set([...getArrayDiffFrom(arr1, arr2), ...getArrayDiffFrom(arr2, arr1)]),
  );
}
