import { LogLevel, MessageTransferObject, Transporter } from '../types';

export class BrowserLoggingTransporter implements Transporter {
  defaultLogLevel: LogLevel = process.env.NODE_ENV === 'development' ? 20 : 30;
  private currentLogLevel: LogLevel = this.defaultLogLevel;

  write(logLevel: LogLevel, msgObject: MessageTransferObject): void {
    if (logLevel < this.currentLogLevel) {
      return;
    }
    const message = this.getFormatedMessage(msgObject);
    switch (logLevel) {
      case 20:
        console.debug(message);
        break;
      case 30:
        console.info(message);
        break;
      case 40:
        console.warn(message);
        break;
      case 50:
      case 60:
        console.error(message);
        break;
    }
  }

  private getFormatedMessage(msgObject: MessageTransferObject) {
    const additionalData = msgObject.additionalData
      ? JSON.stringify(msgObject.additionalData)
      : '';
    return `${msgObject.msg}\n${additionalData}`;
  }

  setLogLevel(logLevel: LogLevel): void {
    this.currentLogLevel = logLevel;
  }
}
