import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { User } from '../entities';
import { useUser } from '@/features/user';

/**
 * Load user and store it to the DTO
 */

export class LoadUserPlugin<T extends { user: User | undefined }>
  implements PipelinePlugin<T>
{
  public async execute(dto: T): Promise<T> {
    const { user, loadUser } = useUser();
    await loadUser();
    dto.user = user.value;
    return dto;
  }
}
