export abstract class SingletonFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static instances: Map<string, any> = new Map();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getInstance<T extends new (...args: any[]) => any>(
    classConstructor: T,
    ...args: ConstructorParameters<T>
  ): InstanceType<T> {
    const key = classConstructor.toString();
    if (!this.instances.has(key)) {
      this.instances.set(key, new classConstructor(...args));
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.instances.get(key);
  }

  static clearInstances(): void {
    SingletonFactory.instances.clear();
  }
}
