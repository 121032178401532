<template>
  <div class="filter">
    <Button
      class="filter__button"
      :class="{
        'primary-btn__active': Boolean(temperatureClassesCount),
      }"
      :btnOutline="true"
      :active="Boolean(temperatureClassesCount)"
      @click="toggleShowFilter"
      data-e2e="order-filter-button"
    >
      <FilterIcon />
      {{ $t('components.order-filter.title.text') }}
      <span
        v-if="Boolean(temperatureClassesCount)"
        data-e2e="filter-product-count-label"
      >
        ({{ temperatureClassesCount }})
      </span>
    </Button>
    <Popup :visible="visibleFilter" :bottom="true" @close="toggleShowFilter">
      <template v-slot:header>
        <FilterIcon class="header__icon" />
        {{ $t('components.order-filter.title.text') }}
      </template>

      <h3 class="filter__sub-title">
        {{ $t('components.order-filter.description.text') }}
      </h3>

      <div class="filter__type">
        <Checkbox
          v-for="product in temperatureClassList"
          :key="product.name"
          :id="product.name"
          :fieldId="product.name"
          v-model:checked="product.active"
          :color="product.color"
          :e2eDescription="`temperature-class-label-${product.name}`"
        >
          <ProductIcons :name="product.label" class="mr-2" />
          {{ $t(product.label) }}
        </Checkbox>
      </div>
      <h3 class="filter__sub-title">
        {{ $t('components.order-filter.sort-by.text') }}
      </h3>
      <div class="filter__sort">
        <RadioGroup
          :options="sortedlist"
          :value="sortBy"
          id="sortedList"
          @selectedChange="updateInput"
        />
      </div>

      <h3 class="filter__sub-title">
        {{
          $t(
            'components.order-filter.show-picking-in-progress-orders-title.text',
          )
        }}
      </h3>

      <div class="filter__picking-in-progress-orders">
        <div class="filter__picking-in-progress-orders__description">
          {{
            $t(
              'components.order-filter.show-picking-in-progress-orders-description.text',
            )
          }}
        </div>
        <div class="filter__picking-in-progress-orders__toggler">
          <CheckboxToggler big v-model="pickingInProgressOrdersVisible" />
        </div>
      </div>
    </Popup>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from 'vue';
import { StorageSortDirection } from '@/features/core/storage';
import {
  BasicTemperatureClass,
  basicTemperatureClasses,
  ProductIcons,
} from '@/features/products';
import {
  Button,
  Checkbox,
  CheckboxToggler,
  FilterIcon,
  Popup,
  RadioGroup,
  sortTypes,
  SortTypes,
  SortTypeValue,
} from '@/features/ui';
import { cloneItem } from '@/utils/helpers/clone';
import { useCheckInOrders } from '../composables';

export default defineComponent({
  name: 'OrderFilter',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    btnOutline: {
      type: Boolean,
      default: false,
    },
    typesOfItem: {
      type: Object as PropType<BasicTemperatureClass[]>,
      default: basicTemperatureClasses,
    },
    filterSorting: {
      default: null,
      type: Object as PropType<SortTypeValue>,
    },
  },
  components: {
    FilterIcon,
    Popup,
    RadioGroup,
    Checkbox,
    Button,
    ProductIcons,
    CheckboxToggler,
  },
  setup(props, { emit }) {
    const visibleFilter = ref(false);
    const pickingInProgressOrdersVisible: Ref<boolean> = ref(false);

    const temperatureClassList = ref(
      cloneItem<BasicTemperatureClass>(props.typesOfItem),
    );

    const temperatureClassesCount = computed(
      () =>
        temperatureClassList.value.filter(
          (product: BasicTemperatureClass) => product.active,
        ).length,
    );

    const listItem = ref<string[]>([]);

    const sortedlist: SortTypes[] = reactive(sortTypes);

    const sortBy = ref(
      sortedlist?.find(
        (item) =>
          item.value?.sortBy === props.filterSorting?.sortBy &&
          item.value?.sort === props.filterSorting?.sort,
      )?.value,
    );

    const updateInput = function (select: SortTypeValue) {
      sortBy.value = select;
    };

    const { checkInBanner } = useCheckInOrders();

    const toggleShowFilter = () => {
      if (visibleFilter.value) {
        emit(
          'changeFilter',
          listItem.value,
          sortBy.value?.sortBy,
          sortBy.value?.sort,
          pickingInProgressOrdersVisible.value,
        );
      }
      checkInBanner.value = visibleFilter.value;
      visibleFilter.value = !visibleFilter.value;
    };

    watch(temperatureClassesCount, () => {
      listItem.value = [];
      const selectedProdType = temperatureClassList.value.filter(
        (product: BasicTemperatureClass) => product.active,
      );
      if (selectedProdType.length > 0) {
        sortBy.value = {
          sortBy: 'itemsCount.total',
          sort: StorageSortDirection.DESC,
        };
        selectedProdType.forEach((product: BasicTemperatureClass) => {
          if (product.active) {
            listItem.value.push(product.name);
          }
        });
      } else {
        sortBy.value = sortedlist[0].value;
      }
    });

    function clearFilter(): void {
      temperatureClassList.value.forEach((item: BasicTemperatureClass) => {
        item.active = false;
      });
    }

    return {
      props,
      visibleFilter,
      toggleShowFilter,
      temperatureClassList,
      sortedlist,
      sortBy,
      updateInput,
      temperatureClassesCount,
      clearFilter,
      pickingInProgressOrdersVisible,
    };
  },
});
</script>
<style lang="scss" scoped>
.filter {
  &__type {
    margin-bottom: 8px;
  }

  &__button {
    position: fixed;
    bottom: 20px;
    left: 16px;
    padding: 6px 24px;
    width: auto;
    font-size: 18px;
    background: #fff;
    border: none;
    border-radius: 18px;
    box-shadow: 1.77778px 1.77778px 8.88889px rgba(0, 0, 0, 0.16);
    line-height: 24px;
    gap: 0 4px;

    span {
      display: inline-block;
      margin: 0 3px;
    }

    & > img {
      margin-right: 8px;
    }

    &.primary-btn__active {
      & > img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &__sub-title {
    padding: 7px 0;
    margin: 0 0 23px;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    border-bottom: 1px solid $stroke-default-color;
  }

  &__sort {
    margin-bottom: 8px;
  }

  &__picking-in-progress-orders {
    display: flex;

    &__description {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 10px;
      font-size: 14px;
      text-align: left;
      flex-grow: 1;
    }

    &__toggler {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
    }
  }
}

.layout__with_banner {
  .filter__button {
    bottom: 108px;
    box-shadow: 0 4px 5px rgba(13, 58, 147, 0.2),
      0 1px 10px rgba(13, 58, 147, 0.15);
  }
}

.header__icon {
  position: relative;
  top: -1px;
}

.checkbox__icon,
.header__icon {
  margin-right: 8px;
}
</style>
