import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { ProcessedOrderService } from './processed-order-service';

export * from './processed-order-service';

export const processedOrderServicePlugin =
  ProviderPluginFactory.create<ProcessedOrderService>({
    key: Symbol('ProcessedOrderServiceInterface'),
    defaultFactory: {
      create: () => {
        return new ProcessedOrderService(storagePlugin.get());
      },
    },
  });
