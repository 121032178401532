<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.92503 13.2233C7.24608 12.5444 6.14527 12.5444 5.46631 13.2233L8.95799 16.715C9.29746 17.0545 9.84787 17.0545 10.1873 16.715C10.1888 16.7136 10.1902 16.7122 10.1916 16.7107C10.195 16.7074 10.1985 16.704 10.2019 16.7006L19.1842 7.71827C18.5039 7.03791 17.4008 7.03791 16.7204 7.71827L9.57019 14.8685L7.92503 13.2233Z"
    />
  </svg>
</template>
