import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { productsApiPlugin } from '../api';
import { ProductsService } from './products-service';

export * from './products-service';

export const productsServicePlugin =
  ProviderPluginFactory.create<ProductsService>({
    key: Symbol('ProductsService'),
    defaultFactory: {
      create: () => {
        return new ProductsService(
          storagePlugin.get(),
          productsApiPlugin.get().product,
        );
      },
    },
  });
