import { ProviderPluginFactory } from '@/features/core/plugin';
import {
  EnvironmentConfiguration,
  EnvironmentConfigurationOptions,
} from './environemnt-configuration';

export const environmentConfigurationPlugin = ProviderPluginFactory.create<
  EnvironmentConfiguration,
  EnvironmentConfigurationOptions
>({
  key: Symbol('EnvironmentConfigurationPlugin'),
  defaultFactory: {
    create: (app, options) => new EnvironmentConfiguration(options),
  },
});
