export const roundInputWithExponentToDigits = (
  input: number | string,
  outputDigits: number,
  inputExponent: number = outputDigits,
): number => {
  const inputValue = typeof input === 'string' ? parseFloat(input) : input;
  return (
    Math.round(inputValue * Math.pow(10, inputExponent)) /
    Math.pow(10, outputDigits)
  );
};
