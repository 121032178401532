<template>
  <div class="wrapper">
    <div class="hero">
      <img
        class="ut-img-contain"
        src="@/assets/images/overview-hero@2x.png"
        alt=""
        height="278"
        data-e2e="popup-info-img"
      />
    </div>
    <div class="note" v-if="order">
      <div class="note__header header">
        <h1 class="note__title header__item">
          {{ $t('pages.customer-note.headline.text') }}
        </h1>
        <h2 class="note__id header__item" data-e2e="order-pickupCode-label">
          {{ isPicking ? order?.orderReference : order?.pickupCode }}
        </h2>
      </div>
      <p class="note__message" data-e2e="order-cartNote-label">
        {{ order?.cartNote }}
      </p>
      <div class="note__footer">
        <Button
          btnOutline
          @click="goNext"
          :disabled="processing"
          data-e2e="start-picking-button"
        >
          {{ $t('pages.customer-note.button-confirm.text') }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import { router } from '@/features/core/router';
import { useOrder } from '@/features/picking';
import Button from '@/features/ui/components/Button.vue';
import {
  isOrderPicking,
  OrderEventNames,
  OrderLocalStatus,
  ordersServicePlugin,
} from '@/features/orders';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { RedirectToCheckinNotifyEvent } from '@/features/orders/checkin';

export default defineComponent({
  components: {
    Button,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { order, loadOrder } = useOrder();
    const processing = ref(false);
    const isPicking = computed(() =>
      !order.value ? true : isOrderPicking(order.value),
    );

    onMounted(async () => {
      await loadOrder(props.id);
    });

    const goNext = async () => {
      if (!order.value) {
        return;
      }

      await router.get().push({
        name: 'handover-storagezones',
        params: {
          id: props.id,
        },
      });

      processing.value = false;
    };

    const eventBusCheckin = eventBusServicePlugin
      .get()
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .on(RedirectToCheckinNotifyEvent, async () => {
        if (order.value) {
          await ordersServicePlugin
            .get()
            .trackEvent(order.value, OrderEventNames.pausing_started, {
              skipSaving: true,
              allowMultipleTracking: true,
            });
          order.value.localStatus = OrderLocalStatus.Paused;
          await ordersServicePlugin.get().saveOrder(order.value);
        }
      });

    onBeforeUnmount(() => {
      eventBusCheckin.cancel();
    });

    return {
      order,
      goNext,
      processing,
      isPicking,
    };
  },
});
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 12px 16px;
}

.hero {
  margin: 60px auto auto;
  width: 235px;
  max-width: 90%;
}

.note {
  padding: 0 8px 28px;
  margin-top: 38px;
  text-align: left;

  &__title,
  &__id {
    font-size: 16px;
    line-height: 1.18em;
  }

  &__title {
    letter-spacing: 0.015em;
  }

  &__id {
    font-weight: 500;
  }

  &__message {
    padding-right: 14px;
    margin-top: 16px;
    line-height: 26px;
  }

  &__footer {
    position: relative;
    z-index: 1;
    margin-top: 29px;
  }
}

.header {
  &__item {
    padding: 9px 0 4px;
    border-bottom: 0.5px solid rgba(182, 196, 223, 0.6);
  }
}
</style>
