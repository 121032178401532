import { ApiClient, Response } from '@/features/core/api';
import {
  DriverNotification,
  DriverNotificationsApiClientInterface,
  DriverNotificationResponse,
} from '../types';
import {
  GetDriverNotificationsError,
  MarkDriverNotificationAsReadError,
} from '../error';

export class DriverNotificationsApiClient
  implements DriverNotificationsApiClientInterface
{
  constructor(private api: ApiClient) {}

  public async getNewDriverNotifications(): Promise<DriverNotification[]> {
    const driverNotificationsApiResponse = await this.api.client.get<
      Response<DriverNotificationResponse[]>
    >('/driver-notifications?filter[notification.readStatus]=false', {
      innerErrorCode: GetDriverNotificationsError.Code,
    });

    return driverNotificationsApiResponse.data.data.map(
      (driverNotificationResponse) => ({
        id: parseInt(driverNotificationResponse.id),
        ...driverNotificationResponse.attributes,
      }),
    );
  }

  public async markDriverNotificationsAsRead(ids: number[]): Promise<void> {
    await this.api.client.patch(
      '/driver-notifications',
      {
        data: ids.map((id: number) => ({
          type: 'driver-notifications',
          id: id,
          attributes: {
            readStatus: true,
          },
        })),
      },
      {
        innerErrorCode: MarkDriverNotificationAsReadError.Code,
      },
    );
  }
}
