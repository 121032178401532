import { ProviderPluginFactory } from '@/features/core/plugin';
import { DeviceFeedbackService } from './services';
import { DeviceFeedbackServiceInterface } from './types';

export const deviceFeedbackServicePlugin =
  ProviderPluginFactory.create<DeviceFeedbackServiceInterface>({
    key: Symbol('deviceFeedbackService'),
    defaultFactory: {
      create: () => {
        return new DeviceFeedbackService();
      },
    },
  });
