import { MultiPluginFactory } from '@/features/core/plugin';
import { productsApiPlugin, productsApiRegistryPlugin } from './api';
import { productEntityPlugin } from './entities';
import { productsServicePlugin } from './services';

export * from './api';
export * from './types';
export * from './entities';
export * from './components';
export * from './composables';
export * from './services';

export const productsPlugin = MultiPluginFactory.with({
  productsApiPlugin,
  productsApiRegistryPlugin,
  productEntityPlugin,
  productsServicePlugin,
});
