import { CompletePipelineWith, PipelinePlugin } from '@ads/plugin-pipeline';
import {
  createBarcodeLookupResult,
  findConcreteItemByBarcodePrefixMatch,
  skuLeadingDigitsMatchAnyBarcodes,
} from '../helpers';
import { BarcodeLookupPluginDto } from '../types';
import { BarcodeFoundService } from '../services';
import { findEntityWithEmbeddedBarcode } from '../helpers/findEntityWithEmbeddedBarcode';

/**
 * Looks for 6-digit prefix match and price/weight embedded barcode matches
 * in RW Type 1 products
 *
 * For details on how this plugin works check the documentation linked below
 * (look for the "Product/item lookup" section)
 *
 * @see https://aldi-sued.atlassian.net/l/cp/0HUfAQ2s
 */
export class FindEmbeddedBarcodeEntityPlugin
  implements PipelinePlugin<BarcodeLookupPluginDto>
{
  constructor(private barcodeFoundService: BarcodeFoundService) {}

  public async execute(
    dataTransferObject: BarcodeLookupPluginDto,
    completePipelineWith: CompletePipelineWith<BarcodeLookupPluginDto>,
  ): Promise<BarcodeLookupPluginDto> {
    if (dataTransferObject.dataset.length > 0) {
      const findConcreteItemResult = findConcreteItemByBarcodePrefixMatch(
        dataTransferObject.dataset,
        dataTransferObject.barcode,
        (sku) => (product) =>
          product.rwpType === 1 &&
          skuLeadingDigitsMatchAnyBarcodes(product.barcodes, sku),
      );

      const priceEmbedded = await findEntityWithEmbeddedBarcode({
        barcodeType: 'priceEmbedded',
        barcode: dataTransferObject.barcode,
        dataset: dataTransferObject.dataset,
        barcodeFoundService: this.barcodeFoundService,
        findConcreteItemResult,
      });
      if (priceEmbedded) {
        return completePipelineWith({
          ...dataTransferObject,
          result: priceEmbedded,
        });
      }

      const weightEmbedded = await findEntityWithEmbeddedBarcode({
        barcodeType: 'weightEmbedded',
        barcode: dataTransferObject.barcode,
        dataset: dataTransferObject.dataset,
        barcodeFoundService: this.barcodeFoundService,
        findConcreteItemResult,
      });
      if (weightEmbedded) {
        return completePipelineWith({
          ...dataTransferObject,
          result: weightEmbedded,
        });
      }

      if (findConcreteItemResult.concreteItem) {
        return completePipelineWith({
          ...dataTransferObject,
          result: createBarcodeLookupResult(
            findConcreteItemResult.concreteItem,
            {
              barcodeType: 'normal',
              weight: null,
            },
          ),
        });
      }
    }

    return dataTransferObject;
  }
}
