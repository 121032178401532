/**
 * Returns a new array with all elements, that are different in both arrays, including duplicates.
 */
export function getArrayDiffWithDuplicates<T>(arr1: T[], arr2: T[]): T[] {
  const mapOfDifference = [
    ...arr2.reduce(
      (acc, v) =>
        acc.set(
          JSON.stringify(v),
          (Number(acc.get(JSON.stringify(v))) || 0) - 1,
        ),
      arr1.reduce(
        (acc, v) =>
          acc.set(
            JSON.stringify(v),
            (Number(acc.get(JSON.stringify(v))) || 0) + 1,
          ),
        new Map(),
      ),
    ),
  ];

  return mapOfDifference.reduce((acc: T[], [v, count]) => {
    const diffValues = Array(Math.abs(count)).fill(JSON.parse(v));
    return acc.concat(diffValues);
  }, []);
}
