<template>
  <div v-if="visible" class="dialog">
    <div class="dialog__wrap">
      <h1 class="dialog__title" data-e2e="verification-dialog-title">
        {{ $t('components.verification-dialog.title.text') }}
      </h1>
      <p data-e2e="verification-dialog-message">
        {{ $t('components.verification-dialog.description.text') }}
      </p>
      <Button
        data-e2e="verification-dialog-verify-button"
        class="dialog__button"
        @click.stop.prevent="handleVerify"
      >
        {{ $t('components.verification-dialog.button.text') }}
      </Button>
      <LogoutButton :hide="true" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { oauthServicePlugin } from '@/features/oauth';
import Button from './Button.vue';
import LogoutButton from './LogoutButton.vue';

export default defineComponent({
  name: 'VerificationDialog',
  components: { LogoutButton, Button },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const handleVerify = async () => {
      await oauthServicePlugin.get().handleReLogin('empowerId');
    };

    return {
      handleVerify,
    };
  },
});
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(var(-aldi-gray-light), 0.05);
  backdrop-filter: blur(6px);

  &__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 16px 24px 24px;
    margin-right: -50%;
    width: calc(100% - 32px);
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 44px rgba(var(--aldi-mid-blue-100), 0.15);
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }

  &__title {
    margin: 10px 0 16px;
    font-size: 24px;
    color: #101316;
    line-height: 32px;
  }

  &__button {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
</style>
