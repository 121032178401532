import { UserReportsApiClient } from '../api';
import { UserReport } from '../types';

export class UserReportsServiceClient {
  constructor(private userReportsApiClient: UserReportsApiClient) {}

  async postReports(userReport: UserReport): Promise<void> {
    return await this.userReportsApiClient.postReports(userReport);
  }
}
