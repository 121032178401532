import { ProviderPluginFactory } from '@/features/core/plugin';
import { apiPlugin } from '@/features/core/api';
import { TourDocumentApiClient } from './tour-document-api-client';
import { environmentConfigurationPlugin } from '@/features/core/environment-configuration';

export const tourDocumentApiPlugin =
  ProviderPluginFactory.create<TourDocumentApiClient>({
    key: Symbol('TourDocumentApiClient'),
    defaultFactory: {
      create: () =>
        new TourDocumentApiClient(
          apiPlugin.get(),
          environmentConfigurationPlugin.get().getConfig('tourDocument')
            .apiUrl as string,
        ),
    },
  });
