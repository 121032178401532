<template>
  <footer class="tour-loading-footer">
    <slot></slot>
  </footer>
</template>

<style lang="scss" scoped>
.tour-loading-footer {
  position: sticky;
  bottom: 0;
  display: grid;
  padding: 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  gap: 8px;
  border-top: 1px solid rgba(182, 196, 223, 0.6);
}
</style>
