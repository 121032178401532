<template>
  <svg
    width="18"
    viewBox="0 0 18 18"
    fill="#FF7800"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.125 3.9375C1.125 3.62684 1.37684 3.375 1.6875 3.375H11.25C14.3566 3.375 16.875 5.8934 16.875 9C16.875 12.1066 14.3566 14.625 11.25 14.625L3.75 14.625C3.75 14.3369 3.85832 14.074 4.03646 13.875C4.24246 13.6448 4.54181 13.5 4.875 13.5L11.25 13.5C13.7353 13.5 15.75 11.4853 15.75 9C15.75 6.51472 13.7353 4.5 11.25 4.5H2.25V6.07729C3.22044 6.32707 3.9375 7.208 3.9375 8.25641C3.9375 9.30482 3.22044 10.1858 2.25 10.4355V13.4062C2.25 13.7394 2.10516 14.0388 1.875 14.2448C1.67597 14.4229 1.41313 14.5312 1.125 14.5312V9.9375C1.125 9.62684 1.37684 9.375 1.6875 9.375C1.71112 9.375 1.7344 9.37646 1.75725 9.37928C2.34608 9.34326 2.8125 8.85431 2.8125 8.25641C2.8125 7.63509 2.30882 7.13141 1.6875 7.13141C1.37684 7.13141 1.125 6.87957 1.125 6.56891L1.12501 6.5657L1.125 6.5625V3.9375Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.4375 7.6875C8.4375 6.54841 9.36091 5.625 10.5 5.625C11.6391 5.625 12.5625 6.54841 12.5625 7.6875C12.5625 8.82659 11.6391 9.75 10.5 9.75C9.36091 9.75 8.4375 8.82659 8.4375 7.6875ZM10.5 6.75C9.98223 6.75 9.5625 7.16973 9.5625 7.6875C9.5625 8.20527 9.98223 8.625 10.5 8.625C11.0178 8.625 11.4375 8.20527 11.4375 7.6875C11.4375 7.16973 11.0178 6.75 10.5 6.75Z"
    />
    <path
      d="M6.375 11.625C6.78921 11.625 7.125 11.2892 7.125 10.875C7.125 10.4608 6.78921 10.125 6.375 10.125C5.96079 10.125 5.625 10.4608 5.625 10.875C5.625 11.2892 5.96079 11.625 6.375 11.625Z"
    />
  </svg>
</template>
