import { SomethingWrongError } from '@/features/core/errors/error-classes';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [SearchProductError.Code]: 'SearchProductError';
    [SearchProductWrongDataError.Code]: 'SearchProductWrongDataError';
  }
}

export class SearchProductError extends SomethingWrongError {
  static Code = 25 as const;
}

export class SearchProductWrongDataError extends SomethingWrongError {
  static Code = 26 as const;
}
