import { createI18n } from 'vue-i18n';
import { fallbackLanguages, languages } from './locales';

const messages = { ...languages };

export const i18n = createI18n({
  locale: typeof navigator !== 'undefined' ? navigator.language : 'en',
  fallbackLocale: fallbackLanguages,
  fallbackWarn: false,
  fallbackFormat: true,
  fallbackRoot: true,
  silentTranslationWarn: () => process.env.NODE_ENV === 'development',
  silentFallbackWarn: () => process.env.NODE_ENV === 'development',
  missingWarn: false,
  legacy: false,
  allowComposition: true,
  globalInjection: true,
  messages: messages,
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
  datetimeFormats: {
    en: {
      short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      'short-time': {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      },
      'short-time-leading-zero': {
        hour: '2-digit',
        minute: 'numeric',
      },
      'short-time-12hr': {
        hour: 'numeric',
        minute: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    'de-DE': {
      short: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
      'short-time': {
        hour: 'numeric',
        minute: 'numeric',
      },
      'short-time-leading-zero': {
        hour: '2-digit',
        minute: 'numeric',
      },
      'short-time-12hr': {
        hour: 'numeric',
        minute: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
});

/* The following code is a workaround due to an unfixed error in the vue-i18n plugin */
const { global: g } = i18n;
export const { t } = g;
export const { d } = g;

export const $t = t;
export const $d = d;

export * from './composables';
