import pkceChallenge from 'pkce-challenge';
import { AuthService } from '@/features/core/auth';
import router from '@/features/core/router';
import { UserService } from '@/features/user';
import { SessionStorageService } from '@/features/session-storage';
import { LoggerService } from '@/features/core/logger';
import { OauthConfig, SalteConfig } from './types';
import { OauthClient } from './oauthClient';

export class OauthService {
  private client: OauthClient | undefined;
  private readonly hostUri: string;
  private config: OauthConfig;
  private isLogoutProcessing = false;

  constructor(
    config: OauthConfig,
    private authService: AuthService,
    private loggerService: LoggerService,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
  ) {
    this.hostUri = config.hostUri;
    this.config = config;
  }

  async init(providerId: string): Promise<void> {
    const providerConfig = this.getSalteConfig(providerId);
    const pkceChallengeValue = pkceChallenge();

    this.sessionStorageService.setItem(
      'code_verifier',
      pkceChallengeValue.code_verifier,
    );

    const userToken: string | undefined = await this.authService.getUserToken();

    this.client = new OauthClient({
      ...providerConfig,
      code_challenge_method: 'S256',
      code_challenge: pkceChallengeValue.code_challenge,
    });

    this.client.userToken = userToken;
  }

  async shouldLogin(providerId: string): Promise<void> {
    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated && providerId) {
      await this.login(providerId);
    }
  }

  public async login(providerId: string): Promise<void> {
    if (providerId) {
      await this.init(providerId);
      try {
        if (this.client) {
          this.sessionStorageService.removeItem('logout');
          this.client.login();
        }
      } catch (e: unknown) {
        this.loggerService.error(e as Error);
      }
    }
  }

  public async handleReLogin(providerId: string): Promise<void> {
    if (providerId) {
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });
      this.sessionStorageService.setItem(
        'post_login_redirect_url',
        window?.location.pathname,
      );
      await this.init(providerId);
      try {
        if (this.client) {
          this.client.login();
        }
      } catch (e: unknown) {
        this.loggerService.error(e as Error);
      }
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    return Boolean(await this.authService.getUserToken());
  }

  async setToken(token: string): Promise<void> {
    await this.authService.updateUserToken(token);
  }

  async setRefreshToken(token: string): Promise<void> {
    await this.authService.updateRefreshToken(token);
  }

  async setDeviceToken(token: string): Promise<void> {
    await this.authService.updateDeviceToken(token);
  }

  public getSalteConfig(providerId: string): SalteConfig {
    const config = this.config.providers[providerId];
    if (!config) {
      throw new Error(
        `There is no ${providerId} in oauthServicePlugin providers `,
      );
    }

    const salteConfig: SalteConfig = {
      responseType: config.responseType,
      clientID: config.clientId,
      redirectUrl: this.hostUri + '/login',
      url: config.authUri,
      grant_type: config.grant_type,
      tokenUri: config.tokenUri,
      logoutUri: config.logoutUri,
      scope: config.scope,
    };

    return salteConfig;
  }

  async logout(
    providerId: string,
    goToNextWithoutLogout = false,
    isLoggedOutTitle = true,
  ): Promise<void> {
    if (this.isLogoutProcessing) {
      return;
    }
    try {
      this.isLogoutProcessing = true;
      await this.init(providerId);
      await this.authService.removeUserToken();
      await this.userService.clearUser();
      this.sessionStorageService.removeItem('state');
      if (isLoggedOutTitle) {
        this.sessionStorageService.setItem('logout', true);
      }
      this.sessionStorageService.removeItem('post_login_redirect_url');
      if (goToNextWithoutLogout) {
        return;
      }

      this.loggerService.info('User logged out');
      if (this.client) {
        this.client.logout();
      }
    } catch (e: unknown) {
      this.loggerService.error(e as Error);
      await this.authService.removeUserToken();
      await router.get().push('/');
    } finally {
      this.isLogoutProcessing = false;
    }
  }
}
