import { ProviderPluginFactory } from '@/features/core/plugin';
import { UrlBuilderService } from './url-builder-service';

export * from './url-builder-service';

export const urlBuilderServicePlugin =
  ProviderPluginFactory.create<UrlBuilderService>({
    key: Symbol('UrlBuilderService'),
    defaultFactory: {
      create: () => new UrlBuilderService(),
    },
  });
