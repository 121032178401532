import { MultiPluginFactory } from '@/features/core/plugin';
import { userApiPlugin, userApiRegistryPlugin } from '@/features/user/api';
import { userEntityPlugin } from './entities';
import {
  userHelperServicePlugin,
  userServiceExecutePlugin,
  userServicePlugin,
} from './services';

export * from './api';
export * from './composables';
export * from './plugins';
export * from './entities';
export * from './services';

export const userPlugin = MultiPluginFactory.with({
  userApiPlugin,
  userApiRegistryPlugin,
  userEntityPlugin,
  userServicePlugin,
  userServiceExecutePlugin,
});

export const userHelperPlugin = MultiPluginFactory.with({
  userHelperServicePlugin,
});
