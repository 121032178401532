/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 * Get value from the object by path.
 */
export function getPath(obj: object, path: string): unknown {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
  return path.split('.').reduce((acc, part) => (acc as any)?.[part], obj);
}

/**
 * Set value to the object by path.
 */
export function setPath(obj: object, path: string, value: unknown): void {
  const parts = path.split('.');
  const last = parts.pop();

  if (!last) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
  const parent = parts.reduce((acc, part) => (acc as any)?.[part], obj);

  if (!parent) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  (parent as any)[last] = value;
}

/**
 * Expand paths in the object.
 *
 * - Arrays from "path.to.array.prop" to ["path.to.array.0.prop", "path.to.array.1.prop", ...]
 */
export function expandPaths(obj: object, path: string): string[] {
  const paths: string[] = [];
  const pathStack = path.split('.');
  let pathPrefix = '';

  while (pathStack.length && obj) {
    // Length of the pathStack is checked in the while condition
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currPath = pathStack.shift()!;
    pathPrefix += `${currPath}.`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    obj = (obj as any)[currPath];

    if (Array.isArray(obj)) {
      obj.forEach((item, i) =>
        paths.push(
          ...expandPaths(item, pathStack.join('.')).map(
            (p) => `${pathPrefix}${i}.${p}`,
          ),
        ),
      );
    }
  }

  if (paths.length === 0) {
    paths.push(path);
  }

  return paths;
}
