import { LoggerService } from '@/features/core/logger';

interface SortableDrop {
  reference: string;
  startTime: string;
}

export class DropsSortingService {
  private missingIndexErrorSent = false;
  private duplicateIndexErrorSent = false;

  public constructor(private loggerService: LoggerService) {}

  public sortDrops<T extends SortableDrop>(drops: T[]): T[] {
    const dropsWithIndices = drops.map((drop) => ({
      drop,
      index: this.getIndexFromReference(drop.reference),
    }));

    const missingIndices = dropsWithIndices.filter((drop) => !drop.index);
    if (missingIndices.length > 0 && !this.missingIndexErrorSent) {
      missingIndices.forEach(({ drop }) =>
        this.loggerService.error(
          `Sorting Logic Failed: No numeric suffix found in the drop-reference '${drop.reference}', required for sorting.`,
        ),
      );

      this.missingIndexErrorSent = true;
    }

    const duplicateIndices = dropsWithIndices.filter(
      (drop, index) =>
        drop.index &&
        dropsWithIndices.findIndex((other) => other.index === drop.index) !=
          index,
    );
    if (duplicateIndices.length > 0 && !this.duplicateIndexErrorSent) {
      duplicateIndices.forEach(({ drop }) =>
        this.loggerService.error(
          `Sorting Logic Failed: Duplicate numeric suffixes detected in drop-reference '${drop.reference}'. Expected unique suffixes for sorting.`,
        ),
      );

      this.duplicateIndexErrorSent = true;
    }

    if (missingIndices.length > 0 || duplicateIndices.length > 0) {
      return drops.sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
      );
    }

    return dropsWithIndices
      .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
      .map(({ drop }) => drop);
  }

  private getIndexFromReference(reference: string): number | null {
    const index = /_(\d+$)/.exec(reference)?.[1];

    return index ? parseInt(index) : null;
  }
}
