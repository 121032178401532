interface BarcodeType {
  isEAN: boolean;
  isUPC: boolean;
}

const determineBarcodeType = (barcode: string): BarcodeType => {
  let isEAN = false;
  let isUPC = false;
  // Check if string is numeric
  if (/^\d+$/.test(barcode)) {
    isEAN = barcode.length === 13;
    isUPC = barcode.length === 12;
  }
  return {
    isEAN,
    isUPC,
  };
};

export interface CalculateCheckDigitResult {
  /**
   * Check digit found in the input barcode
   */
  checkDigit: number;
  /**
   * Calculated (expected) check digit based on the input barcode
   */
  calculatedCheckDigit: number;
}

/**
 * Calculates and returns the check digit for the provided barcode if it is valid
 * (both the one provided in the input barcode and the calculated/expected value)
 *
 * If the barcode is invalid, `null` is returned instead.
 */
export const calculateBarcodeCheckDigit = (
  inputBarcode: string,
): CalculateCheckDigitResult | null => {
  const barcode = inputBarcode.trim();
  const { isEAN, isUPC } = determineBarcodeType(barcode);
  if (!isEAN && !isUPC) {
    return null;
  }

  const digits = barcode.split('').map((digit) => parseInt(digit, 10));
  const checkDigit = digits.pop() as number;

  const multiplierEven = isEAN ? 3 : 1; // EAN 13: https://boxshot.com/barcode/tutorials/ean-13-calculator/
  const multiplierOdd = isUPC ? 3 : 1; // UPC-A: https://boxshot.com/barcode/tutorials/upc-a-calculator/

  const sum = digits.reduce((acc, digit, index) => {
    const multiplier = (index + 1) % 2 === 0 ? multiplierEven : multiplierOdd;
    return acc + digit * multiplier;
  }, 0);

  const sumModTen = sum % 10;
  const calculatedCheckDigit = sumModTen === 0 ? 0 : 10 - sumModTen;

  return { checkDigit, calculatedCheckDigit };
};
