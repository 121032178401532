import { productSearchApiPlugin } from '@/features/core/api';
import { authServicePlugin } from '@/features/core/auth';
import { ProductsSearchApiClient } from './product-search';
import { ProviderPluginFactory } from '@/features/core/plugin';

export const productsSearchApiClientPlugin =
  ProviderPluginFactory.create<ProductsSearchApiClient>({
    key: Symbol('ProductsSearchApiClient'),
    defaultFactory: {
      create: () =>
        new ProductsSearchApiClient(
          productSearchApiPlugin.get(),
          authServicePlugin.get(),
        ),
    },
  });
