<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 3C8.14348 3 6.36301 3.7375 5.05025 5.05025C3.7375 6.36301 3 8.14349 3 10C3 11.8565 3.7375 13.637 5.05025 14.9497C6.36301 16.2625 8.14348 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 8.14349 16.2625 6.36301 14.9497 5.05025C13.637 3.7375 11.8565 3 10 3ZM6.11091 6.11091C7.14236 5.07946 8.54131 4.5 10 4.5C11.4587 4.5 12.8576 5.07946 13.8891 6.11091C14.9205 7.14236 15.5 8.54131 15.5 10C15.5 11.4587 14.9205 12.8576 13.8891 13.8891C12.8576 14.9205 11.4587 15.5 10 15.5C8.54131 15.5 7.14236 14.9205 6.11091 13.8891C5.07946 12.8576 4.5 11.4587 4.5 10C4.5 8.54131 5.07946 7.14236 6.11091 6.11091Z"
    />
    <path
      d="M16.5952 17.6557L15.5 16.5605C16.0858 15.9748 17.0355 15.9748 17.6213 16.5605L19.53 18.4697L19.5359 18.4756L20.6853 19.625C20.0995 20.2108 19.1498 20.2108 18.564 19.625L16.6029 17.6636C16.6003 17.661 16.5977 17.6583 16.5952 17.6557Z"
    />
  </svg>
</template>
