import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { errorPlugin } from '@/features/core/errors';
import { LoadUserError } from '../errors';
import { ref } from 'vue';
import { User } from '../entities';
import { authServicePlugin } from '@/features/core/auth';
import { UseUser, UseUserLoadParams } from '../types';
import { userServicePlugin } from '@/features/user';

export function useUser(): UseUser {
  const user = ref<User | undefined>();
  const loading = ref(false);
  const error = ref({
    name: '',
    message: '',
  });

  const loadUser = async (params?: UseUserLoadParams): Promise<void> => {
    loading.value = true;

    try {
      const id =
        params?.id || (await authServicePlugin.get().getMerchantReference());

      if (!id) {
        throw new Error('useUser: No User Id to load the user!');
      }

      const userRef = await entityRepositoryPlugin.get().getById(User, {
        id,
      });

      user.value = userRef.value;
    } catch (e) {
      errorPlugin.get().handle(new LoadUserError());

      error.value = {
        name: 'error',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        message: e?.data?.data?.error ?? e,
      };
    } finally {
      loading.value = false;
    }
  };

  const isDriver = async () => {
    const userRoles = await authServicePlugin.get().getUserRoles();
    if (userRoles) {
      return userServicePlugin.get().isDriverRole(userRoles);
    }
    return false;
  };

  return {
    user,
    loading,
    error,
    loadUser,
    isDriver,
  };
}
