<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.33 6.73045L10.7487 10.6033C10.3925 11.1378 9.60719 11.1378 9.25092 10.6034L6.66927 6.73092C5.61097 5.14346 5.82029 3.02967 7.16937 1.68058C8.73254 0.117417 11.267 0.117553 12.83 1.68091C14.1786 3.02989 14.3879 5.14318 13.33 6.73045ZM9.99971 9.02241L12.0818 5.89854C12.7432 4.90614 12.6124 3.58486 11.7692 2.74144C10.792 1.764 9.20737 1.7639 8.23003 2.74125C7.38654 3.58474 7.25567 4.90635 7.91735 5.89887L9.99971 9.02241Z"
      fill="#101316"
    />
    <path
      d="M15.9999 8.0001L15.2499 8.00016C14.8357 8.0002 14.5 8.33601 14.5 8.75022C14.5 9.16444 14.8358 9.5002 15.2501 9.50016L16 9.5001C17.1044 9.50013 17.9998 10.3955 17.9998 11.5001C17.9998 12.6047 17.1044 13.5001 15.9999 13.5001L3.99981 13.5002C2.06687 13.5002 0.5 15.0672 0.5 17.0002C0.5 18.9331 2.06687 20.5002 3.99982 20.5002L19 20.5001C19 19.6717 18.3284 19.0001 17.5 19.0001L3.99982 19.0002C2.8954 19.0002 2 18.1048 2 17.0002C2 15.8955 2.8954 15.0002 3.99982 15.0002L15.9999 15.0001C17.9329 15.0001 19.4998 13.4331 19.4998 11.5001C19.4998 9.56715 17.9328 8.0001 15.9999 8.0001Z"
      fill="#101316"
    />
    <path
      d="M2 9.5001C1.17157 9.5001 0.5 8.82853 0.5 8.0001C1.20829 8.0001 1.91659 8.00004 2.62488 7.99998C3.33317 7.99992 4.04146 7.99986 4.74976 7.99986C5.16397 7.99986 5.49976 8.33564 5.49976 8.74986C5.49976 9.16407 5.16397 9.49986 4.74976 9.49986L2 9.5001Z"
      fill="#101316"
    />
  </svg>
</template>
