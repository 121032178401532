<template>
  <div class="login-layout">
    <div class="container">
      <div class="root-wrapper">
        <Suspense>
          <template #default>
            <RouterView />
          </template>
          <template #fallback>
            <img
              alt="loading"
              src="@/assets/images/loading.gif"
              data-e2e="loading"
            />
          </template>
        </Suspense>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { RouterView } from 'vue-router';

export default defineComponent({
  name: 'LoginLayout',
  components: {
    RouterView,
  },
});
</script>

<style scoped lang="scss">
.login-layout {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
}

.root-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0;
  max-width: 600px;
}
</style>
