import { MultiPluginFactory } from '@/features/core/plugin';
import { deviceIdEntityPlugin, deviceIdServicePlugin } from './plugins';

export * from './composables';
export * from './services';
export * from './types';
export * from './plugins';

export const deviceIdPlugin = MultiPluginFactory.with({
  deviceIdEntityPlugin,
  deviceIdServicePlugin,
});
