import { DeliveryItem, Tour } from '../types';
import {
  OrderAdapterService,
  Order,
  Customer,
  OrderItem,
  OrderItemStatus,
} from '@/features/orders';
import { Product } from '@/features/products';
import { TourDrop } from '@/features/tour-drops';
import { Storage } from '@/features/core/storage';
import { AgeVerification, Status } from '@/features/age-verification';
import { LoggerService } from '@/features/core/logger';

const PRODUCT_NOT_FOUND_MESSAGE = 'Product not found: ';

export class ToursOrderAdapterService implements OrderAdapterService<Tour> {
  constructor(private storage: Storage, private logger: LoggerService) {}

  async createOrders(tours: Tour[]): Promise<Order[]> {
    const orders: Order[] = [];
    const products = await this.storage.getAll(Product);
    tours.forEach((tour: Tour) => {
      tour.drops.forEach((tourDrop) => {
        const deliveryItems: DeliveryItem[] = tour.deliveryItems.filter(
          (deliveryItem) => deliveryItem.dropReference === tourDrop.reference,
        );
        orders.push(this.createOrder(tourDrop, deliveryItems, products));
      });
    });
    return orders;
  }

  private createOrder(
    tourDrop: TourDrop,
    deliveryItems: DeliveryItem[],
    products: Product[],
  ): Order {
    const order = new Order();
    order.ageVerification = this.createAgeVerification(tourDrop);
    order.id = tourDrop.reference;
    order.orderReference = tourDrop.orderReferences.pop() ?? '';
    order.customer = this.createCustomer(tourDrop);
    order.items = this.createOrderItems(deliveryItems, products);
    return order;
  }

  private createAgeVerification(tourDrop: TourDrop): AgeVerification | null {
    if (tourDrop.maxAgeRestriction && tourDrop.maxAgeRestriction > 0) {
      return {
        status: Status.NotVerified,
        dateOfBirth: null,
        userIdType: null,
        rejectionReason: null,
        requiredAge: tourDrop.maxAgeRestriction,
      } as AgeVerification;
    }
    return null;
  }

  private createCustomer(tourDrop: TourDrop): Customer {
    if ('firstName' in tourDrop.address && 'lastName' in tourDrop.address) {
      return {
        firstName: tourDrop.address.firstName,
        lastName: tourDrop.address.lastName,
        phone: tourDrop.address.phoneNumber,
      } as Customer;
    }
    return {
      phone: tourDrop.address.phoneNumber,
    } as Customer;
  }

  private createOrderItems(
    deliveryItems: DeliveryItem[],
    products: Product[],
  ): OrderItem[] {
    return deliveryItems
      .map((deliveryItem) => {
        const product = products.find(
          (product) => product?.sku === deliveryItem.productReference,
        );
        if (product) {
          return this.createOrderItem(deliveryItem, product);
        } else {
          this.logger.error(
            PRODUCT_NOT_FOUND_MESSAGE + deliveryItem.productReference,
          );
        }
      })
      .filter((orderItem): orderItem is OrderItem => !!orderItem);
  }

  private createOrderItem(
    deliveryItem: DeliveryItem,
    product: Product,
  ): OrderItem {
    return {
      id: product.sku,
      amount:
        product.rwpType === 1
          ? deliveryItem.amount.reduce((a, b) => a + b)
          : deliveryItem.amount[0],
      amountOriginal:
        product.rwpType === 1
          ? deliveryItem.amountOriginal.reduce((a, b) => a + b)
          : deliveryItem.amountOriginal[0],
      quantity: deliveryItem.quantity,
      quantityOriginal: deliveryItem.quantityOriginal,
      status: OrderItemStatus.out_for_delivery,
      unit: product.unit,
      product: product,
      isReplaceable: false,
      weights:
        product.rwpType === 1
          ? deliveryItem.amount.map((amount) => {
              return {
                relatedBarcode: '',
                weight: amount,
              };
            })
          : null,
    } as OrderItem;
  }
}
