import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { $t } from '@/i18n';
import { isAgeRestrictedOrder } from '@/utils/helpers/isAgeRestrictedOrder';
import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import type { OrderPluginDto } from '../types';

export class HandleRestrictedOrderPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  public async execute(
    dataTransferObject: OrderPluginDto,
    completePipelineWith: CompletePipelineWith<OrderPluginDto>,
  ): Promise<OrderPluginDto> {
    if (isAgeRestrictedOrder(dataTransferObject.order)) {
      const { confirm } = useDynamicDialog();
      const confirmed = await confirm({
        title: $t('components.age-confirmation-popup.title.text'),
        isTitleCentered: false,
        contentText: $t('components.age-confirmation-popup.content.text'),
        confirmText: $t('components.age-confirmation-popup.confirm.text'),
        cancelText: $t('components.age-confirmation-popup.cancel.text'),
        e2e: `order-agerestricted-popup-${dataTransferObject.order.id}`,
      });
      if (!confirmed) {
        return completePipelineWith(dataTransferObject);
      }
    }
    return dataTransferObject;
  }
}
