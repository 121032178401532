<template>
  <svg
    width="28"
    viewBox="0 0 28 28"
    fill="currentColor"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6604 7.32721L20.1604 10.8272C19.8187 11.1689 19.2647 11.1689 18.9229 10.8272C18.5812 10.4855 18.5812 9.93149 18.9229 9.58978L20.9292 7.5835L6.99972 7.58349L7 11.6668C7 12.6333 6.2165 13.4168 5.25 13.4168L5.25 10.5227L5.24972 10.5002L5.24972 6.70849C5.24972 6.22525 5.64147 5.8335 6.12472 5.8335L20.9292 5.8335L18.9229 3.82721C18.5812 3.48551 18.5812 2.93149 18.9229 2.58978C19.2647 2.24807 19.8187 2.24807 20.1604 2.58978L23.6604 6.08978C24.0021 6.43149 24.0021 6.98551 23.6604 7.32721Z"
    />
    <path
      d="M4.14973 20.9569C4.10695 21.0601 4.08334 21.1732 4.08334 21.2918C4.08334 21.4105 4.10695 21.5236 4.14973 21.6268C4.19243 21.73 4.25573 21.8267 4.33962 21.9105L7.83962 25.4105C8.18133 25.7523 8.73534 25.7523 9.07705 25.4105C9.41876 25.0688 9.41876 24.5148 9.07705 24.1731L7.07077 22.1668H21.875C22.3582 22.1668 22.75 21.7751 22.75 21.2918V14.5835C21.7835 14.5835 21 15.367 21 16.3335V20.4168H7.07077L9.07705 18.4105C9.41876 18.0688 9.41876 17.5148 9.07705 17.1731C8.73534 16.8314 8.18133 16.8314 7.83962 17.1731L4.34008 20.6726"
    />
    <path d="M4.14973 20.9569C4.19177 20.8553 4.25379 20.76 4.33577 20.677Z" />
  </svg>
</template>
