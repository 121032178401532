<template>
  <div class="item">
    <h3 class="item__title" :data-e2e="`product-name-label-${name}-${id}`">
      {{
        name ? $t(`components.product-item.${name?.toLowerCase()}.text`) : ''
      }}
    </h3>
    <div class="item__body">
      <span
        class="item__text ut-font_fix"
        :data-e2e="`product-count-label-${name}-${id}`"
      >
        {{ count }}
      </span>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: String,
    count: [String, Number],
    id: String,
  },
  setup: function (props) {
    return { ...props };
  },
});
</script>

<style lang="scss" scoped>
.item {
  padding: 8px;
  text-align: left;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

  &:first-child {
    margin-top: 0;
  }

  &__title {
    font-size: 12px;
    font-weight: normal;
    color: $default-text-color;
    line-height: 1;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 14px;
    flex-direction: row;
  }

  &__text {
    font-size: 30px;
    line-height: 1;
  }
}
</style>
