<template>
  <svg
    width="24"
    viewBox="0 0 24 25"
    fill="#101316"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.93495 1.5C8.96843 1.5 8.18491 2.2835 8.18491 3.25V6H5.25002C4.83579 6 4.5 6.33579 4.5 6.75V20.75C4.5 21.1642 4.83579 21.5 5.25002 21.5H19.3754C19.3754 20.6716 18.7038 20 17.8753 20H6.00004V7.5H8.18491V8.75C8.18491 9.16421 8.5207 9.5 8.93493 9.5C9.34915 9.5 9.68495 9.16421 9.68495 8.75V7.5H14.2503V8.75C14.2503 9.16421 14.5861 9.5 15.0003 9.5C15.4145 9.5 15.7503 9.16421 15.7503 8.75V7.5H18.0003V16C18.0003 16.8284 18.6719 17.5 19.5004 17.5V6.75C19.5004 6.55109 19.4214 6.36032 19.2807 6.21967C19.14 6.07902 18.9493 6 18.7504 6H15.7503V3.25C15.7503 2.2835 14.9668 1.5 14.0003 1.5H9.93495ZM14.2503 6V3.25C14.2503 3.11193 14.1384 3 14.0003 3H9.93495C9.79688 3 9.68495 3.11193 9.68495 3.25V6H14.2503Z"
    />
  </svg>
</template>
