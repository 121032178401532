import { ProviderPluginFactory } from '@/features/core/plugin';
import { ReportsService } from './reports-service';
import { ReportServiceConfig } from '../types';
import { reportsApiClientPlugin } from '../api';

export * from './reports-service';

export const reportsServicePlugin =
  ProviderPluginFactory.create<ReportsService>({
    key: Symbol('OrdersService'),
    defaultFactory: {
      create: (_, options: ReportServiceConfig) =>
        new ReportsService(reportsApiClientPlugin.get(), options),
    },
  });
