<template>
  <svg
    width="24"
    viewBox="0 0 24 25"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.90362 18.3787C10.4894 18.9645 10.4894 19.9142 9.90362 20.5L8.59458 19.191C8.58237 19.1802 8.57041 19.169 8.55875 19.1574L7.68375 18.2824L5.93375 16.5324L2.43375 13.0324C2.2931 12.8917 2.21408 12.7009 2.21408 12.502C2.21408 12.3031 2.2931 12.1124 2.43375 11.9717L5.93375 8.47171L7.68375 6.72171L7.78254 6.62292L7.7823 6.62268L9.90362 4.50136C10.4894 5.08714 10.4894 6.03689 9.90362 6.62268L9.6353 6.891C9.6301 6.89651 9.6248 6.90197 9.61941 6.90737L8.74441 7.78237L6.99441 9.53237L4.7761 11.7507L19.5 11.7507L19.5 4.78639C19.5 4.37218 19.8358 4.03639 20.25 4.03639C20.6642 4.03639 21 4.37218 21 4.78639L21 12.5007C21 12.9149 20.6642 13.2507 20.25 13.2507L4.77338 13.2507L6.99441 15.4717L8.74441 17.2217L8.84184 17.3191L8.84296 17.318L9.90362 18.3787Z"
      stroke="none"
    />
  </svg>
</template>
