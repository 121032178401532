import { OrderBag } from '../types';

export function parseConfigurationBags(configurationBags: string): OrderBag[] {
  if (!configurationBags) return [];
  return configurationBags.split(',').map((i) => ({
    sku: i.split(':')[1],
    name: i.split(':')[0],
    quantity: 0,
  }));
}
