/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ServiceWorkerEventTypes } from '@/features/service-worker';
import { E2ETestHelper, SWNotificationEventPush } from '../types';
import { MessageTransferObject } from '@/features/core/logger';

export class E2ETestHelperService implements E2ETestHelper {
  private isNotificationPayload(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ): payload is SWNotificationEventPush {
    if (!payload) {
      return false;
    }
    if (!payload.entity || typeof payload.entity !== 'string') {
      return false;
    }
    if (!payload.action || typeof payload.action !== 'string') {
      return false;
    }
    if (payload.ids && !Array.isArray(payload.ids)) {
      return false;
    }
    return true;
  }

  async emitPushNotification(payload: unknown): Promise<void> {
    if (!this.isNotificationPayload(payload)) {
      console.error('Notification payload is invalid.');
      console.error(
        "Example of correct payload: {entity: 'orders', action: 'get', ids: ['45']}",
      );
      return;
    }

    await navigator.serviceWorker.ready.then((registration) => {
      registration?.active?.postMessage({
        ...payload,
        type: ServiceWorkerEventTypes.Push,
      });
    });
  }

  getLogsHistory = (): MessageTransferObject[] => {
    if (!window.memoryLogsHistory) {
      console.error('window.memoryLogsHistory is not defined');
      return [];
    }

    const logsHistory = window.memoryLogsHistory.getLogHistory();

    window.memoryLogsHistory.clearLogHistory();

    return logsHistory;
  };
}
