import { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderPluginDto } from '../types';

export class ResetOrderQuantityPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    dataTransferObject.order.items = dataTransferObject.order.items.map(
      (orderItem) => ({
        ...orderItem,
        quantity: 0,
        amount: 0,
        weights: [],
      }),
    );
    return dataTransferObject;
  }
}
