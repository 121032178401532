<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    stroke="#0D3A93"
    fill="#0D3A93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12H12H19"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.5 12.75V12.75C3.5 11.9216 4.17157 11.25 5 11.25V11.25L5 12.75L3.5 12.75Z"
      stroke="none"
    />
    <path
      d="M20.5 11.25V11.25C20.5 12.0784 19.8284 12.75 19 12.75V12.75L19 11.25L20.5 11.25Z"
      stroke="none"
    />
  </svg>
</template>
