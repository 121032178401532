<template>
  <svg
    width="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#0D3A93"
  >
    <path
      d="M18.1517 1.84827C17.8792 1.57692 17.5556 1.36225 17.1997 1.21666C16.8438 1.07108 16.4625 0.997463 16.0779 1.00007C15.6934 1.00267 15.3131 1.08144 14.9592 1.23183C14.6052 1.38222 14.2846 1.60125 14.0158 1.87627L2.41598 13.4761L1 19L6.52391 17.5832L18.1237 5.9834C18.3988 5.71471 18.6179 5.39416 18.7684 5.04028C18.9188 4.68639 18.9976 4.3062 19.0002 3.92167C19.0028 3.53715 18.9292 3.15592 18.7835 2.80003C18.6379 2.44415 18.4232 2.12066 18.1517 1.84827V1.84827Z"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6846 2.20825L17.7917 6.31539"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
