import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import { TemperatureClassList } from '../types';

export class NewProduct extends RestorableEntity implements NewEntity {
  type = 'product';
}

export class Product extends NewProduct implements Entity {
  id!: string;
  sku!: string;
  image!: string | null;
  productName!: string;
  temperatureClass!: TemperatureClassList;
  barcodes!: string[];
  ageRestricted!: number;
  rwpType!: number;
  pickingOrder!: number;
  categoryUrlSlugText!: string;
  unit!: string;
  price!: number | null;
  maxQuantity!: number | null;
  noImage!: boolean;
  isBag!: boolean;
  serviceType!: string | null;
}

export class ProductStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Products_1).stores({
      [storeName]: '&id,sku,productType,productName,barcode',
    });
    version(DBVersions.Products_2).stores({
      [storeName]: '&id,sku,productType,productName,barcode,rwpType',
    });
    version(DBVersions.Products_3)
      .stores({
        [storeName]: '&id,sku,temperatureClass,productName,barcode,rwpType',
      })
      .upgrade((trans) => {
        const table = trans.table(storeName);
        void table.toCollection().modify((product) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          product.temperatureClass = product.productType;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          delete product.productType;
        });
      });
    version(DBVersions.Products_4).stores({
      [storeName]:
        '&id,sku,temperatureClass,productName,barcode,rwpType,categoryUrlSlugText',
    });
    version(DBVersions.Products_5).stores({
      [storeName]:
        '&id,sku,temperatureClass,productName,*barcodes,rwpType,categoryUrlSlugText',
    });

    return Promise.resolve();
  }
}
