<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0019 0C15.5067 0.000999958 20.0005 4.37182 20.0005 9.74559C20.0005 10.3646 19.9399 10.9855 19.8187 11.5935C19.8069 11.6796 19.7867 11.7649 19.7656 11.8536C19.7614 11.8711 19.7572 11.8887 19.7531 11.9065C19.6026 12.5535 19.3764 13.2004 19.0856 13.8184C18.0646 16.1543 15.846 19.7512 11.0542 23.626C10.7573 23.866 10.3857 24 9.99789 24C9.61516 24 9.23848 23.866 8.94259 23.626C4.14477 19.7512 1.92713 16.1543 0.911216 13.8184C0.619368 13.2004 0.393161 12.5585 0.242692 11.8975C0.202298 11.7255 0.182101 11.6175 0.178062 11.5795C-0.00371208 10.6676 -0.0441064 9.72659 0.0467806 8.79563C0.525452 3.95583 4.64768 0.172993 9.64141 0.00699971C9.7626 0.00199992 9.88176 0 10.0019 0ZM10.0003 14.9994C12.7617 14.9994 15.0004 12.7609 15.0004 9.99958C15.0004 7.23828 12.7617 4.99979 10.0003 4.99979C7.23876 4.99979 5.00013 7.23828 5.00013 9.99958C5.00013 12.7609 7.23876 14.9994 10.0003 14.9994Z"
      fill="#101316"
    />
  </svg>
</template>
