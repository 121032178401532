import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewDataSync extends RestorableEntity implements NewEntity {
  type = 'dataSync';
}

export class DataSync extends NewDataSync implements Entity {
  id!: string;
  lastSync!: string;
}

export class DataSyncStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.DataSync_1).stores({ [storeName]: '&id,lastSync' });

    return Promise.resolve();
  }
}
