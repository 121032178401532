import { AuthService } from '@/features/core/auth';
import { DataFetchQueueService } from '@/features/data-fetch-queue';
import { AppBootChecker } from '../types';

export class AppBootCheckerService implements AppBootChecker {
  constructor(
    private authService: AuthService,
    private dataFetchQueueService: DataFetchQueueService,
  ) {}

  async triggerSync(): Promise<void> {
    if (!(await this.authService.canLoadData())) {
      return;
    }

    if (await this.dataFetchQueueService.isEmpty()) {
      return;
    }

    await this.dataFetchQueueService.triggerSyncForOutstandingEntities();
  }
}
