import { Sync } from '@/features/sync-scheduler';
import { ordersServicePlugin } from '@/features/orders';
import { storageZoneServicePlugin } from '@/features/storageZone';
import { storagePlugin } from '@/features/core/storage';
import { toursServicePlugin } from '@/features/tours';
import { orderMetadataServicePlugin } from '@/features/orderMetadata';

interface RefreshOptions {
  refreshStorageZones?: boolean;
  refreshOrders?: boolean;
  refreshTours?: boolean;
  removeSyncData?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useProfileReceiveData() {
  const refreshAll = async (options: RefreshOptions = {}): Promise<void> => {
    options = {
      refreshStorageZones: false,
      refreshOrders: false,
      refreshTours: false,
      removeSyncData: true,
      ...options,
    };

    if (options.refreshStorageZones) {
      await storageZoneServicePlugin.get().fetchAllStorageZones();
    }

    if (options.refreshOrders) {
      const orders = await ordersServicePlugin.get().fetchAllOrders();
      await orderMetadataServicePlugin.get().clearAll();
      await orderMetadataServicePlugin.get().checkAndSetOrdersMetadata(orders);
    }

    if (options.removeSyncData) {
      await storagePlugin.get().removeAll(Sync);
    }

    if (options.refreshTours) {
      await toursServicePlugin.get().fetchAllTours();
    }
  };
  return {
    refreshAll,
  };
}
