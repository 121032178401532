import {
  BaseError,
  SomethingWrongError,
} from '@/features/core/errors/error-classes';
import axios, { AxiosError } from 'axios';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [PatchTourError.Code]: 'PatchTourError';
  }
}

export class PatchTourError extends SomethingWrongError {
  static Code = 30 as const;
}

export class DriverNotAssignedError extends SomethingWrongError {
  static Code = 31 as const;
}

export class GetTourRequestError extends SomethingWrongError {
  static Code = 32 as const;
}

export class NoActiveTourError extends SomethingWrongError {
  static Code = 107 as const;
}

export class NotAllowedToAccessRouteWithNotActiveTour extends SomethingWrongError {
  static Code = 108 as const;
}

export class NotAllowedToAccessRouteWithNotActiveDrop extends SomethingWrongError {
  static Code = 109 as const;
}

export class StartDeliveryFailedError extends SomethingWrongError {
  static Code = 104 as const;
}

function getErrorCode(error: BaseError): number | undefined {
  return error.getCode && error.getCode();
}

export const isDriverNotAssignedError = (
  error: AxiosError | BaseError,
): boolean => {
  if (axios.isAxiosError(error)) {
    return error.response?.status === 404;
  }
  return getErrorCode(error) === 31;
};
