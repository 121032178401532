import {
  Entity,
  NewEntity,
  RestorableEntity,
} from '@/features/core/entity-repository';
import {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewDevice extends RestorableEntity implements NewEntity {
  type = 'device';
}

export class Device extends NewDevice implements Entity {
  id!: string;
  pushToken!: string;
  appVersion!: string;
  lastLogin!: string;
  isRegistered!: boolean;
}

export class DeviceStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Device_1).stores({
      [storeName]: '&id',
    });

    return Promise.resolve();
  }
}
