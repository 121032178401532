import { computed, ComputedRef, Ref, ref, toRaw } from 'vue';
import router from '@/features/core/router';
import {
  Order,
  OrderItem,
  OrderItemStatus,
  ordersServicePlugin,
  OrderWeight,
} from '@/features/orders';
import { TabVariants } from '@/features/ui';
import { UseOrder } from '@/features/picking/types';
import { EventBus, eventBusServicePlugin } from '@/features/core/event-bus';
import { ChangeUrlEvent } from '@/features/route-leave-guard/events';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { $t } from '@/i18n';
import { isCancellableOrder } from '@/utils/helpers/isCancellableOrder';
import { sumOrderProperties } from '@/features/picking/helpers/sumOrderProperties';
import { loggerServicePlugin } from '@/features/core/logger';
import { useDebounce } from '@/composables/useDebounce';
import { useOverpickingThresholds } from './useOverpickingThresholds';
import { partitionPickingItems } from '../helpers';

export function useOrder(): UseOrder {
  const order: Ref<Order | null> = ref(null);
  const orderId = ref('');
  const loading = ref(false);
  const processing = ref(false);

  const currentTab = ref('not_picked');
  const selectedId = ref<string | null>(null);
  const visibleWeightPopup = ref(false);
  const debouncer = useDebounce();
  const overpickingThresholds = useOverpickingThresholds();

  const eventBusService: EventBus = eventBusServicePlugin.get();

  const saveOrder = async () => {
    if (!order.value) {
      return;
    }

    await ordersServicePlugin.get().saveOrder(order.value);
  };

  const debouncedSaveOrder = debouncer.debounce(
    () => void saveOrder(),
    'saveOrder',
    2000,
  );

  const interruptOngoingSavings = (): void =>
    void debouncer.interruptOngoingOperations();

  const itemsList = computed(() => {
    if (!order.value) {
      return [];
    }
    return _isReplacement.value
      ? order.value.items.filter((orderItem) => orderItem.originalId)
      : order.value.items.filter((orderItem) => !orderItem.originalId);
  });

  const findBySku = (sku: string) => {
    return itemsList.value.find((item) => item.product.sku === sku);
  };

  const _isReplacement = ref(false);

  const onIncrease = (
    sku: string,
    isReplacement = false,
    shouldSaveOrder = false,
  ) => {
    if (!order.value) {
      return;
    }
    _isReplacement.value = isReplacement;

    const item = findBySku(sku);
    if (item && item.quantity < item.quantityOriginal) {
      item.quantity += 1;
    }
    if (shouldSaveOrder) {
      debouncedSaveOrder();
    }
  };

  const onDecrease = (
    sku: string,
    isReplacement = false,
    shouldSaveOrder = false,
  ) => {
    if (!order.value) {
      return;
    }
    _isReplacement.value = isReplacement;

    const item = findBySku(sku);

    if (item && item.quantity > 0) {
      item.quantity -= 1;
    }
    if (shouldSaveOrder) {
      debouncedSaveOrder();
    }
  };

  const totalAmount: ComputedRef<number> = computed(() =>
    sumOrderProperties(order.value, 'amount'),
  );

  const totalAmountOriginal: ComputedRef<number> = computed(() =>
    sumOrderProperties(order.value, 'amountOriginal'),
  );

  const quantity: ComputedRef<number> = computed(() =>
    sumOrderProperties(order.value, 'quantity'),
  );

  const quantityOriginal: ComputedRef<number> = computed(() =>
    sumOrderProperties(order.value, 'quantityOriginal'),
  );

  const partitionedOrderItems = computed(() =>
    partitionPickingItems(order.value, overpickingThresholds.value),
  );
  const pickedItems = computed(() => partitionedOrderItems.value.picked);
  const changedItems = computed(() => partitionedOrderItems.value.changed);
  const notPickedItems = computed(() => partitionedOrderItems.value.notPicked);

  //If the order doesn't exist then navigates back and displays a notification
  const handleNotFoundOrder = (id: string): void => {
    order.value = null;
    loggerServicePlugin.get().warn(`Order ${id} returned with null`);
    notificationPlugin.get().show({
      text: $t('errors.order-not-found.text', {
        id: id,
      }),
      type: NotificationType.Error,
    });

    loading.value = false;

    eventBusService.emit(
      new ChangeUrlEvent('/', { bypassRouteNavigationGuard: true }),
    );
  };

  const loadOrder = async (id: string): Promise<void> => {
    loading.value = true;
    orderId.value = id;
    const result = await ordersServicePlugin.get().getOrderById(id);

    if (result === null) {
      handleNotFoundOrder(id);

      return;
    }

    const countAllQuantity = sumOrderProperties(result.value, 'quantity');
    if (!countAllQuantity) {
      result.value?.items?.map((item) => {
        if (item.status !== OrderItemStatus.picked) {
          item.status = OrderItemStatus.not_picked;
        }
      });
    }
    order.value = result.value;
    loading.value = false;
  };

  const ordersOnTab = computed(() => {
    if (order.value && order.value.items?.length) {
      switch (currentTab.value) {
        case TabVariants.NotPicked:
          return notPickedItems.value;
        case TabVariants.Picked:
          return pickedItems.value;
        case TabVariants.Changed:
          return changedItems.value;
        default:
          return order.value.items;
      }
    }
    return [];
  });

  const filterQuantity = computed(() => {
    const changed =
      changedItems.value.filter((item) => !item.originalId).length ?? 0;
    const picked = pickedItems.value.length ?? 0;
    const not_picked = notPickedItems.value.length ?? 0;

    const all = changed + picked;

    return {
      picked,
      not_picked,
      changed,
      all,
    };
  });

  const getAmountOfItemsToPick = (): number => {
    if (!order.value) {
      return 0;
    }
    const pickedCount = order.value.items.filter(
      (item) => item.status === OrderItemStatus.picked,
    ).length;

    return order.value.items.length - pickedCount;
  };

  const isOrderReadyToComplete = (): boolean => {
    return !!order.value && !getAmountOfItemsToPick();
  };

  const completeOrder = async (): Promise<void> => {
    if (!order.value) {
      return;
    }
    processing.value = true;

    order.value.items.forEach((item) => {
      delete item.replacements;
    });

    debouncer.destroy();

    await saveOrder();

    await router.get().push({
      name: isCancellableOrder(order.value)
        ? 'review-order'
        : 'complete-picking',
      params: {
        id: order.value.id,
      },
    });
    processing.value = false;
  };

  const currentOrderItemId = ref('');
  const pickedProduct = computed((): OrderItem | undefined => {
    if (!order.value) {
      return;
    }
    return _isReplacement.value
      ? order.value.items.find(
          (item) => item.id === currentOrderItemId.value && item.originalId,
        )
      : order.value.items.find(
          (item) => item.id === currentOrderItemId.value && !item.originalId,
        );
  });

  const onSubmit = async (
    orderItem: OrderItem,
    isReplacement = false,
  ): Promise<void> => {
    if (!order.value) return;
    currentOrderItemId.value = orderItem.id;
    _isReplacement.value = isReplacement;
    // Change status of item without reactivity (if it is the last item to submit) to avoid ui update
    if (getAmountOfItemsToPick() === 1) {
      const rawItem = toRaw(orderItem);
      rawItem.status = OrderItemStatus.picked;
    } else {
      orderItem.status = OrderItemStatus.picked;
    }

    if (isOrderReadyToComplete()) {
      await completeOrder();
      return;
    }

    debouncedSaveOrder();
  };

  const onEdit = (id: string, isReplacement = false): void => {
    if (!order.value) {
      return;
    }
    currentOrderItemId.value = id;
    _isReplacement.value = isReplacement;

    if (pickedProduct.value) {
      pickedProduct.value.status = OrderItemStatus.not_picked;
    }
  };

  const isAllItemsPicked = computed(() => {
    if (order.value && Array.isArray(order.value.items)) {
      return !order.value.items.some(
        (item) => item.status === OrderItemStatus.not_picked,
      );
    }
    return true;
  });

  const onChangeTab = (tab: string): void => {
    currentTab.value = tab;
  };

  const onEditWeight = (
    id: string,
    barcode = '',
    weight?: number | null,
  ): void => {
    if (!order.value) {
      return;
    }

    order.value.items.forEach((item) => {
      if (item.id === id) {
        if (!item.weights) item.weights = [];
        if (
          item.weights.length &&
          item.weights[item.weights.length - 1].weight === 0
        ) {
          item.weights.pop();
        }
        if (item.amount < item.amountOriginal || item.amountOriginal === 0) {
          item.weights?.push({
            weight: weight || 0,
            relatedBarcode: barcode,
          });
          item.amount += weight || 0;
          item.quantity = item.weights?.filter(
            (itemWeight: OrderWeight) => itemWeight.weight > 0,
          ).length;
        }
      }
    });

    const currentOrderItem = order.value.items.find(
      (orderItem: OrderItem) => orderItem.id === id,
    );

    if (!currentOrderItem) {
      return;
    }

    if (currentTab.value === TabVariants.NotPicked) {
      selectedId.value = id;
      visibleWeightPopup.value = true;
      return;
    }

    currentOrderItem.status = OrderItemStatus.not_picked;
  };

  const onChangeWeightBlock = async (
    selectedItem: OrderItem,
  ): Promise<void> => {
    if (!selectedItem || !order.value) {
      return;
    }

    const selectedItemIndex = order.value.items.findIndex(
      (item) =>
        item.id === selectedItem.id &&
        item.originalId === selectedItem.originalId,
    );

    order.value.items[selectedItemIndex] = selectedItem;

    if (isOrderReadyToComplete()) {
      await completeOrder();
      return;
    }

    await saveOrder();
  };

  const openWeightPopup = (id: string): void => {
    selectedId.value = id;
    visibleWeightPopup.value = true;
  };

  return {
    order,
    onIncrease,
    onDecrease,
    quantity,
    quantityOriginal,
    totalAmount,
    totalAmountOriginal,
    loadOrder,
    onSubmit,
    onEdit,
    onEditWeight,
    onChangeTab,
    ordersOnTab,
    currentTab,
    visibleWeightPopup,
    selectedId,
    onChangeWeightBlock,
    isAllItemsPicked,
    filterQuantity,
    loading,
    processing,
    openWeightPopup,
    interruptOngoingSavings,
  };
}
