<template>
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 0.75C4 0.335786 4.33579 0 4.75 0H8.75C9.16421 0 9.5 0.335786 9.5 0.75V1.74976H21.5C21.9142 1.74976 22.25 2.08554 22.25 2.49976C22.25 2.91397 21.9142 3.24976 21.5 3.24976H21V15.2499C21 15.6641 20.6642 15.9999 20.25 15.9999H1.75C1.33579 15.9999 1 15.6641 1 15.2499V6.74732C1 6.33311 1.33579 5.99732 1.75 5.99732C2.16421 5.99732 2.5 6.33311 2.5 6.74732V14.4999H19.5V8.99976H13.5V11.4998C13.5 12.3282 12.8284 12.9998 12 12.9998V8.24976C12 7.83554 12.3358 7.49976 12.75 7.49976H19.5V3.24976H9.5V5.75C9.5 6.16421 9.16421 6.5 8.75 6.5H4.75C4.33579 6.5 4 6.16421 4 5.75V3.24976C3.16667 3.24976 2.33333 3.24951 1.5 3.24951C0.671573 3.24951 0 2.57794 0 1.74951L4 1.74976V0.75ZM5.5 5C5.5 3.83266 5.5 2.66715 5.5 1.5H8V5H5.5Z"
      fill="#101316"
    />
  </svg>
</template>
