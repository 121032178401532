import { EventBusEvent } from '@/features/core/event-bus';
import { EntityType } from '@/features/core/entity-repository';
import { EntityUpdate } from './types';

export class AppServiceWorkerPushEvent implements EventBusEvent {
  name = 'AppServiceWorkerPushEvent';

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public readonly updateEntityPayload: EntityUpdate<EntityType<any>>[],
  ) {}
}

export class AppServiceWorkerNotifySyncEvent implements EventBusEvent {
  name = 'AppServiceWorkerNotifySyncEvent';
  constructor(
    public readonly entityType: string = '',
    public readonly entityId: string = '',
    public readonly customTag: string = '',
  ) {}
}
