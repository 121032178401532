import { ApiClient } from '@/features/core/api';
import { Storage } from '@/features/core/storage';
import { getUUID } from '@/utils/helpers/getUUID';
import { DeviceId } from '../entities';

export class DeviceIdService {
  constructor(private storage: Storage, private api: ApiClient) {
    void this.init();
  }

  async init(): Promise<void> {
    const deviceId =
      (await this.getDeviceId()) || (await this.generateAndSaveDeviceId());
    this.api.client.interceptors.request.use((config) => {
      if (!config.headers) {
        config.headers = {};
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      config.headers['X-Device-Id'] = deviceId;
      return config;
    });
  }

  async getDeviceId(): Promise<string | undefined> {
    const [data] = await this.storage.getAll(DeviceId);
    if (!data) {
      return;
    }
    return DeviceId.from(data).id;
  }

  async generateAndSaveDeviceId(): Promise<string> {
    const generateDeviceId = getUUID();
    const data = DeviceId.from({ id: generateDeviceId });
    await this.storage.save(data);
    return generateDeviceId;
  }
}
