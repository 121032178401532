import { StorageZoneApiClient } from './storageZone';
import { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';
import { StorageZone } from '../entities';

export const registerStorageZoneApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  storageZoneApi: StorageZoneApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(StorageZone, {
    getAll: storageZoneApi.getAll.bind(storageZoneApi),
  });
};
