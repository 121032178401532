import { MultiPluginFactory } from '@/features/core/plugin';
import { deviceEntityPlugin } from './entities';
import { deviceServicePlugin } from './services';
import { deviceApiPlugin, deviceApiRegistryPlugin } from './api';

export * from './types';
export * from './entities';
export * from './services';
export * from './api';

export const devicePlugin = MultiPluginFactory.with({
  deviceEntityPlugin,
  deviceApiPlugin,
  deviceApiRegistryPlugin,
  deviceServicePlugin,
});
