import { ExecPluginFactory } from '@/features/core/plugin';
import { storageSetupPlugin } from '@/features/core/storage';
import { ProcessedOrder, ProcessedOrderStorageSetup } from './processedOrder';

export * from './processedOrder';

export const processedOrderEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin
    .get()
    .add(ProcessedOrder, new ProcessedOrderStorageSetup());
});
