/**
 * Returns a new array with all elements from the second that weren't found in the first, without duplicates.
 * Object reference comparison.
 *
 * @param arr1 The array from which the difference will be calculated
 * @param arr2 The array the elements of will be searched in the first
 */
export function getArrayDiffFrom<T>(arr1: T[], arr2: T[]): T[] {
  const arr1Set = new Set(arr1);
  const arr2WithoutDuplicates = Array.from(new Set(arr2));

  return arr2WithoutDuplicates.filter((id) => !arr1Set.has(id));
}
