import { OrderItem, isOrderItemRandomWeight } from '@/features/orders';
import { OverpickingThresholds } from '../composables';

const getMultipliedOriginalAmount = (item: OrderItem, multiplier?: number) =>
  typeof multiplier === 'number'
    ? item.amountOriginal * multiplier
    : item.amountOriginal;

/**
 * Determines whether the provided order item is considered "changed"
 *
 * For RWP type 0 any different in quantity means the item was "changed"
 *
 * For RWP type 1 and above the quantity is ignored and only the amount
 * (weight) is considered for "changed" status.
 */
export const isOrderItemChanged = (
  item: OrderItem,
  overpickingThreshold?: OverpickingThresholds,
): boolean => {
  if (!isOrderItemRandomWeight(item)) {
    // Non-RW quantity is different from what was ordered
    return item.quantity !== item.quantityOriginal;
  }
  const minAmount = getMultipliedOriginalAmount(
    item,
    overpickingThreshold?.minMultiplier,
  );
  const maxAmount = getMultipliedOriginalAmount(
    item,
    overpickingThreshold?.maxMultiplier,
  );
  // RW amount is above or below minimum picking threshold
  return item.amount < minAmount || item.amount > maxAmount;
};
