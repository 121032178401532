import { Order } from '@/features/orders';

type OrderItemNumericProperties =
  | 'amount'
  | 'amountOriginal'
  | 'quantity'
  | 'quantityOriginal';

export const sumOrderProperties = (
  order: Pick<Partial<Order>, 'items'> | null | undefined,
  property: OrderItemNumericProperties,
): number =>
  order?.items ? order.items.reduce((acc, item) => acc + item[property], 0) : 0;
