import { Cancellable } from '@/utils/types';
import { EntityUpdate } from '@/features/service-worker';
import { Entity, EntityType } from '@/features/core/entity-repository';
import { notificationPlugin } from '@/features/core/notifications';
import router from '@/features/core/router';
import { onBeforeUnmount } from 'vue';

let checkInNotification: Cancellable;

export function notifyAboutCheckin(
  eventData: EntityUpdate<EntityType<Entity>>[],
): void {
  const route = router.get().currentRoute.value;
  if (eventData) {
    if (route?.meta?.showCustomerCheckinBanner === true) {
      notificationPlugin.get().showMessage();
    }
  }

  onBeforeUnmount(() => {
    checkInNotification?.cancel();
  });
}
