import { BaseError, ErrorContext } from '@/features/core/errors/error-classes';
import { $t } from '@/i18n';
import { LogLevel } from '@/features/core/logger';

interface BarcodeNotFoundContext extends ErrorContext {
  barcodeValue: string;
}

interface BarcodeLimitContext extends ErrorContext {
  limit: number;
}

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [BarcodeNotFoundInOrderError.Code]: 'BarcodeNotFoundInOrderError';
    [ProductPickingCompletedError.Code]: 'ProductPickingCompletedError';
  }
}

export class BarcodeNotFoundInOrderError extends BaseError<BarcodeNotFoundContext> {
  static Code = 10 as const;
  static Message = $t('errors.barcode.barcode-not-found-in-order.message');
}

export class ProductPickingCompletedError extends BaseError {
  static Code = 11 as const;
  static Message = $t('errors.barcode.product-picking-completed.message');
  static LogLevel: LogLevel = 30;
}

export class WrongTourFrameError extends BaseError<BarcodeNotFoundContext> {
  static Code = 12 as const;
  static Message = $t('errors.barcode.wrong-tour-frame.message');
  static LogLevel: LogLevel = 30;
}

export class WrongTourFrameOrderError extends BaseError<BarcodeNotFoundContext> {
  static Code = 13 as const;
  static Message = $t('errors.barcode.wrong-tour-frame-order.message');
  static LogLevel: LogLevel = 30;
}

export class BarcodeNotFoundError extends BaseError<BarcodeNotFoundContext> {
  static Code = 14 as const;
  static Message = $t('errors.barcode.barcode-not-found.message');
}

export class ValidatePickingError extends BaseError {
  static Code = 15 as const;
  static Message = $t('errors.barcode.validate-picking.message');
}

export class BarcodeHasLimit extends BaseError<BarcodeLimitContext> {
  static Code = 16 as const;
  static Message = $t('errors.barcode.barcode-has-limit.message');
}

export class TourFrameAlreadyLoadedError extends BaseError {
  static Code = 17 as const;
  static Message = $t('errors.barcode.tour-frame-already-loaded.message');
  static LogLevel: LogLevel = 30;
}

export class PreviousFrameNotCheckedError extends BaseError {
  static Code = 18 as const;
  static Message = $t('errors.barcode.previous-frame-not-checked.message');
  static LogLevel: LogLevel = 30;
}

export class WrongReceptacleError extends BaseError<
  BarcodeNotFoundContext & { receptacleType: string }
> {
  static Code = 19 as const;
  static Message = $t('errors.barcode.wrong-receptacle.message');
  static LogLevel: LogLevel = 30;
}

export class ReceptacleAlreadyScannedError extends BaseError<{
  receptacleType: string;
  position: string;
  parentPosition: string;
}> {
  static Code = 20 as const;
  static Message = $t('errors.barcode.receptacle-already-scanned.message');
  static LogLevel: LogLevel = 30;
}

export class ReceptacleNotFoundError extends BaseError<BarcodeNotFoundContext> {
  static Code = 21 as const;
  static Message = $t('errors.barcode.receptacle-not-found.message');
  static LogLevel: LogLevel = 30;
}

export class WrongCodeFormatError extends BaseError {
  static Code = 20 as const;
  static Message = $t('errors.barcode.wrong-code-format.message');
  static LogLevel: LogLevel = 30;
}

export class WrongParentlessReceptacleError extends BaseError<
  BarcodeNotFoundContext & { receptacleType: string }
> {
  static Code = 33 as const;
  static Message = $t('errors.barcode.wrong-receptacle.without-parent');
}

export class ParentlessReceptacleAlreadyScannedError extends BaseError<{
  receptacleType: string;
  position: string;
  parentPosition: string;
}> {
  static Code = 34 as const;
  static Message = $t(
    'errors.barcode.receptacle-already-scanned.without-parent',
  );
}

export class NonFrozenFramesLoadedFirstError extends BaseError {
  static Code = 21 as const;
  static Message = $t('errors.barcode.non-frozen-loaded-first.message');
}
