export enum TourStatus {
  open = 'open',
  loading = 'loading',
  wait_for_delivery = 'wait_for_delivery',
  delivery_in_progress = 'delivery_in_progress',
  heading_back_to_hub = 'heading_back_to_hub',
  car_clearance = 'car_clearance',
  completed = 'completed',
}

export const FinishedStatuses = [TourStatus.completed];

export const ActiveStatuses = [
  TourStatus.open,
  TourStatus.loading,
  TourStatus.wait_for_delivery,
  TourStatus.delivery_in_progress,
];

export enum TourActions {
  start_loading = 'start_loading',
  continue_loading = 'continue_loading',
  start_delivery = 'start_delivery',
  continue_delivery = 'continue_delivery',
  finish_tour_checklist = 'finish_tour_checklist',
  finish_cleveron_tour_checklist = 'finish_cleveron_tour_checklist',
  finish_tour = 'finish_tour',
  finish_cleveron_tour = 'finish_cleveron_tour',
  finish_loading = 'finish_loading',
  start_heading_back = 'start_heading_back',
  route_guidance = 'route_guidance',
  continue_heading_back = 'continue_heading_back',
  continue_car_clearance = 'continue_car_clearance',
  confirm_darkstore_arrival = 'confirm_darkstore_arrival',
  confirm_cleveron_darkstore_arrival = 'confirm_cleveron_darkstore_arrival',
  cancel_drop = 'cancel_drop',
  picking_receptacles = 'picking_receptacles',
  handover_in_progress = 'handover_in_progress',
  open_hotspot_hint = 'open_hotspot_hint',
}

export enum DropStatus {
  open = 'open',
  delivery_in_progress = 'delivery_in_progress',
  delivery_completed = 'delivery_completed',
  start_picking_receptacles = 'start_picking_receptacles',
  complete_picking = 'complete_picking',
  picking_receptacles_completed = 'picking_receptacles_completed',
  drop_paused = 'drop_paused',
  drop_canceled = 'drop_canceled',
  start_handover = 'start_handover',
  age_verification = 'age_verification',
  age_rejected = 'age_rejected',
  handover_completed = 'handover_completed',
  picking_receptacles = 'picking_receptacles',
  handover_in_progress = 'handover_in_progress',
}

export enum DeliveryType {
  HOME_DELIVERY = 'homeDelivery',
  CLEVERON = 'cleveron',
  MIDDLEMILE = 'middlemile',
}

export enum DeliveryUnitType {
  bag = 'BAG',
  box = 'BOX',
  frame = 'FRAME',
}
