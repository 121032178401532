<template>
  <svg
    width="24"
    viewBox="0 0 24 24"
    fill="#D6D8DA"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.06043 5.62132C8.47465 5.03553 8.47465 4.08579 9.06043 3.5L10.8842 5.32372C10.8915 5.33053 10.8988 5.33751 10.9059 5.34467L17.0309 11.4697C17.3238 11.7626 17.3238 12.2374 17.0309 12.5303L10.9059 18.6553C10.8988 18.6625 10.8915 18.6695 10.8842 18.6763L9.06043 20.5C8.47465 19.9142 8.47465 18.9645 9.06043 18.3787L15.44 12L9.06043 5.62132Z"
    />
  </svg>
</template>
