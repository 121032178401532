import { OrderItem, OrderItemStatus } from '@/features/orders/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { AgeVerificationDTO } from '../types';

/**
 * Set status for age restricted staged items to rejected.
 */

export class SetItemRejectionStatusPlugin
  implements PipelinePlugin<AgeVerificationDTO>
{
  public execute(dto: AgeVerificationDTO): AgeVerificationDTO {
    dto.order.items.forEach((item: OrderItem) => {
      if (
        item.product.ageRestricted > 0 &&
        item.status === OrderItemStatus.staged
      ) {
        item.status = OrderItemStatus.rejected;
      }
    });
    return dto;
  }
}
