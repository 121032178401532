import { TourDocumentApiClient } from '../api/tour-document-api-client';
import { DecodeTourDocumentError } from '../errors';

export class TourDocumentService {
  constructor(private tourDocumentApiClient: TourDocumentApiClient) {}

  public async getTourDocument(tourReference: string): Promise<string> {
    let tourDocument = await this.tourDocumentApiClient.getTourDocument(
      tourReference,
    );
    try {
      tourDocument = decodeURIComponent(escape(atob(tourDocument)));
      return tourDocument;
    } catch (error) {
      throw new DecodeTourDocumentError();
    }
  }
}
