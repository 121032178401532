import { Product } from '@/features/products';
import { OrderItem } from '@/features/orders';

export const isItemEntity = (
  entity: OrderItem | Product,
): entity is OrderItem => 'product' in entity;

export const isItemEntityArray = (
  entityArray: OrderItem[] | Product[],
): entityArray is OrderItem[] => isItemEntity(entityArray[0]);
