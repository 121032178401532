import { computed, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  DropActionsCleveron,
  DropPathCleveron,
  dropsServicePlugin,
  dropsSortingServicePlugin,
  DropStatusCleveron,
  TourDrop,
  TourDropItem,
  UseDropsCleveron,
} from '@/features/tour-drops';
import { errorPlugin } from '@/features/core/errors';
import { useTours } from '@/features/tours/composables/useTours';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { navigateToMap } from '@/features/ui/composables';
import router from '@/features/core/router';

const processing = ref<boolean>(false);

const currentActiveDrop = ref<TourDrop | null | undefined>();

const recentlyDoneDrops: Ref<TourDrop[]> = ref([]);

const availableDrops = computed<TourDrop[]>(
  () =>
    drops.value.filter(
      (drop: TourDrop) => drop.status !== DropStatusCleveron.cleaning_completed,
    ) || [],
);

const activeDrops = computed(() =>
  drops.value.filter(
    (drop) =>
      drop.status !== DropStatusCleveron.open &&
      drop.status !== DropStatusCleveron.cleaning_completed,
  ),
);

const drops: Ref<TourDrop[]> = ref([]);

export function useDropsCleveron(): UseDropsCleveron {
  const { currentTour } = useTours();
  const route = useRoute();

  const isBoxClearingNeeded = (drop: TourDrop) => {
    return drop.clearingRequired;
  };

  const hasOrders = (drop: TourDrop): boolean => {
    return drop.orderReferences.length > 0;
  };

  const getDropActions = (drop: TourDrop) => {
    const dropStatusToActionMap: {
      [key in DropStatusCleveron]?: DropActionsCleveron;
    } = {
      [DropStatusCleveron.open]: DropActionsCleveron.start_drop_off,
      [DropStatusCleveron.delivery_in_progress]:
        DropActionsCleveron.complete_delivery,
      [DropStatusCleveron.delivery_completed]: hasOrders(drop)
        ? DropActionsCleveron.load_cleveron
        : isBoxClearingNeeded(drop)
        ? DropActionsCleveron.clear_boxes
        : DropActionsCleveron.logout_cleveron,
      [DropStatusCleveron.loading_cleveron]:
        DropActionsCleveron.complete_load_cleveron,
      [DropStatusCleveron.cleveron_loading_completed]: isBoxClearingNeeded(drop)
        ? DropActionsCleveron.complete_box_clearing
        : DropActionsCleveron.complete_log_out,
      [DropStatusCleveron.box_clearing_completed]:
        DropActionsCleveron.complete_log_out,
      [DropStatusCleveron.log_out_completed]:
        DropActionsCleveron.complete_cleaning,
    };

    return [
      dropStatusToActionMap[
        drop.status as DropStatusCleveron
      ] as DropActionsCleveron,
    ];
  };

  const mapDropActions = (drops: TourDrop[]) =>
    drops.map((drop) => {
      const actions = getDropActions(drop);
      if (actions.length) {
        return {
          ...drop,
          actions,
        };
      } else {
        return drop;
      }
    });

  const loadDrops = async (): Promise<void> => {
    if (!currentTour.value) {
      return;
    }
    const filtering = {
      tourId: { equals: currentTour.value.id },
    };

    const dropItems = await entityRepositoryPlugin.get().getAll(TourDropItem, {
      filter: filtering,
    });

    drops.value = dropsSortingServicePlugin
      .get()
      .sortDrops(dropItems.value)
      .map(
        (dropItem, dropIndex): TourDrop => ({
          ...dropItem,
          dropIndex,
        }),
      );

    drops.value = mapDropActions(drops.value);
  };

  const setCurrentActiveDrop = (tourDrop?: TourDrop | null | undefined) => {
    currentActiveDrop.value = tourDrop;
  };

  const completedDrops = computed<TourDrop[]>(() => {
    return drops.value?.filter(
      (drop) => drop.status === DropStatusCleveron.cleaning_completed,
    );
  });

  const addToRecentlyDoneDrops = (drop: TourDrop) => {
    recentlyDoneDrops.value.push(drop);
  };

  const resetRecentlyDoneDrops = () => {
    recentlyDoneDrops.value = [];
  };

  const updateDropStatus = async (tourDrop: TourDrop) => {
    try {
      await dropsServicePlugin.get().patchDrop(tourDrop);
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  };

  const applyAction = async (
    action: string,
    tourDrop: TourDrop | null | undefined = null,
  ) => {
    processing.value = true;
    if (!tourDrop) {
      tourDrop = currentActiveDrop.value;
    }
    setCurrentActiveDrop(tourDrop);

    switch (action) {
      case DropActionsCleveron.start_drop_off:
        await startDropOff(tourDrop);
        break;
      case DropActionsCleveron.complete_delivery:
        await completeDelivery(tourDrop);
        break;
      case DropActionsCleveron.load_cleveron:
        await loadCleveron(tourDrop);
        break;
      case DropActionsCleveron.clear_boxes:
      case DropActionsCleveron.complete_load_cleveron:
        await completeLoadCleveron(tourDrop);
        break;
      case DropActionsCleveron.logout_cleveron:
      case DropActionsCleveron.complete_box_clearing:
        await completeBoxClearing(tourDrop);
        break;
      case DropActionsCleveron.complete_log_out:
        await completeLogOut(tourDrop);
        break;
      case DropActionsCleveron.complete_cleaning:
        await completeCleaning(tourDrop);
        break;
      case DropActionsCleveron.continue_load_cleveron:
        await loadCleveron(tourDrop, false);
        break;
      case DropActionsCleveron.continue_box_clearing:
        await completeLoadCleveron(tourDrop, false);
        break;
      case DropActionsCleveron.continue_log_out:
        await completeBoxClearing(tourDrop, false);
        break;
      case DropActionsCleveron.continue_cleaning:
        await completeLogOut(tourDrop, false);
        break;
    }
    processing.value = false;
  };

  const startDropOff = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;

    tourDrop.status = DropStatusCleveron.delivery_in_progress;
    tourDrop.actions = [DropActionsCleveron.complete_delivery];

    await updateDropStatus(tourDrop);
    await navigateToMap(tourDrop.address);
  };

  const completeDelivery = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;

    tourDrop.status = DropStatusCleveron.delivery_completed;
    tourDrop.actions = getDropActions(tourDrop);

    await updateDropStatus(tourDrop);
  };

  const loadCleveron = async (
    tourDrop: TourDrop | null | undefined,
    updateStatus = true,
  ) => {
    if (!tourDrop) return;

    if (updateStatus) {
      tourDrop.status = DropStatusCleveron.loading_cleveron;
    }
    tourDrop.actions = [DropActionsCleveron.complete_load_cleveron];

    await router.get().push({
      name: DropPathCleveron.loading_cleveron,
      params: {
        tourId: currentTour.value?.id,
        dropId: tourDrop.reference,
      },
    });

    if (updateStatus) {
      await updateDropStatus(tourDrop);
    }
  };

  const completeLoadCleveron = async (
    tourDrop: TourDrop | null | undefined,
    updateStatus = true,
  ) => {
    if (!tourDrop) return;

    if (updateStatus) {
      tourDrop.status = DropStatusCleveron.cleveron_loading_completed;
    }

    tourDrop.actions = getDropActions(tourDrop);

    if (updateStatus) {
      await updateDropStatus(tourDrop);
    }
    await router.get().push({
      name: isBoxClearingNeeded(tourDrop)
        ? DropPathCleveron.box_clearing_cleveron
        : DropPathCleveron.logout_cleveron,
      params: {
        tourId: currentTour.value?.id,
        dropId: tourDrop.reference,
      },
    });
  };

  const completeBoxClearing = async (
    tourDrop: TourDrop | null | undefined,
    updateStatus = true,
  ) => {
    if (!tourDrop) return;

    if (updateStatus) {
      tourDrop.status = DropStatusCleveron.box_clearing_completed;
    }
    tourDrop.actions = [DropActionsCleveron.complete_log_out];

    if (updateStatus) {
      await updateDropStatus(tourDrop);
    }
    await router.get().push({
      name: DropPathCleveron.logout_cleveron,
      params: {
        tourId: currentTour.value?.id,
        dropId: tourDrop.reference,
      },
    });
  };

  const completeLogOut = async (
    tourDrop: TourDrop | null | undefined,
    updateStatus = true,
  ) => {
    if (!tourDrop) return;

    if (updateStatus) {
      tourDrop.status = DropStatusCleveron.log_out_completed;
    }
    tourDrop.actions = [DropActionsCleveron.complete_cleaning];

    if (updateStatus) {
      await updateDropStatus(tourDrop);
    }
    await router.get().push({
      name: DropPathCleveron.cleaning_cleveron,
      params: {
        tourId: currentTour.value?.id,
        dropId: tourDrop.reference,
      },
    });
  };

  const completeCleaning = async (tourDrop: TourDrop | null | undefined) => {
    if (!tourDrop) return;

    tourDrop.status = DropStatusCleveron.cleaning_completed;
    tourDrop.actions = [];
    recentlyDoneDrops.value.push(tourDrop);

    await updateDropStatus(tourDrop);
    await router.get().push({
      name: 'tour-drops',
      params: {
        tourId: currentTour.value?.id,
      },
    });
  };

  return {
    drops,
    processing,
    availableDrops,
    activeDrops,
    completedDrops,
    recentlyDoneDrops,
    currentActiveDrop,
    loadDrops,
    setCurrentActiveDrop,
    applyAction,
    addToRecentlyDoneDrops,
    resetRecentlyDoneDrops,
    hasOrders,
  };
}
