import { PipelinePlugin } from '@ads/plugin-pipeline';
import router from '@/features/core/router';
import { OrderPluginDto } from '../types';

export class NavigateToTargetRoutePlugin
  implements PipelinePlugin<OrderPluginDto>
{
  public async execute(
    dataTransferObject: OrderPluginDto,
  ): Promise<OrderPluginDto> {
    if (dataTransferObject.targetRoute) {
      await router.get().push(dataTransferObject.targetRoute);
    }
    return dataTransferObject;
  }
}
