import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { loggerServicePlugin } from '../logger';
import { DexieStorage, DexieStorageSetup } from './dexie';
import { storageFkResolverMixin } from './fk-resolver';
import { Storage } from './storage';
import { GlobalStorageSetup, StorageSetup } from './storage-setup';

export const storagePlugin = ProviderPluginFactory.create<Storage>({
  key: Symbol('Storage'),
  defaultFactory: {
    create: () =>
      storageFkResolverMixin(new DexieStorage(loggerServicePlugin.get())),
  },
});

export const storageGlobalSetupPlugin =
  ProviderPluginFactory.create<GlobalStorageSetup>({
    key: Symbol('StorageGlobalSetup'),
    defaultFactory: { create: () => new DexieStorageSetup() },
  });

export const storageSetupPlugin = ProviderPluginFactory.create<StorageSetup>({
  key: Symbol('StorageSetup'),
  defaultFactory: {
    create: () =>
      new StorageSetup(storagePlugin.get(), storageGlobalSetupPlugin.get()),
  },
});

export const storageSetupInitPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin
    .get()
    .setup()
    .catch((e) => {
      console.error('Storage setup failed', e);
    });
});

export const storageConfigPlugin = MultiPluginFactory.with({
  storagePlugin,
  storageGlobalSetupPlugin,
  storageSetupPlugin,
});
