import type { Storage } from '@/features/core/storage';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import { Product } from '@/features/products/entities';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderParsePluginDto } from '@/features/orders/types';

/**
 * Add products from order items to DTO variable
 **/
export class LoadOrderProductsPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  constructor(private storage: Storage) {}
  public readonly requiredPlugins = [NormalizeOrderPlugin];

  public async execute(
    dataTransferObject: OrderParsePluginDto,
  ): Promise<OrderParsePluginDto> {
    dataTransferObject.products = await this.storage.getAll(Product, {
      filter: { sku: { anyOf: dataTransferObject.order.productSkus } },
      sortBy: 'pickingOrder',
    });

    return dataTransferObject;
  }
}
