<template>
  <div class="wrapper">
    <div class="filters" v-if="filters">
      <button
        v-for="(item, key) in filters"
        :key="key + item?.value"
        class="filters__item"
        :class="{ filters__item_active: item?.value === currentTab }"
        @click="onSelectTab(item?.value)"
        :data-e2e="`header-tab-${item?.value}`"
      >
        {{ item?.label }}
        <span v-if="filterQuantity" :data-e2e="`tab-count-label-${item.value}`"
          >({{ filterQuantity[key] }})</span
        >
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { HandoverFilterRecord, handoverFilters } from '@/features/ui/types';
import { MappedOrderItems } from '@/features/handover/constants';

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<HandoverFilterRecord>,
      default: handoverFilters,
    },
    filterQuantity: {
      type: Object as PropType<MappedOrderItems>,
      default: null,
    },
    currentTab: {
      type: String,
      default: 'all',
    },
  },
  emits: ['changeTab'],
  setup(props, { emit }) {
    const selectedTab = computed(() => {
      return props.currentTab;
    });

    const onSelectTab = (tab: string) => {
      emit('changeTab', tab);
    };

    return {
      onSelectTab,
      selectedTab,
    };
  },
});
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 50%;
    background-color: white;
    filter: drop-shadow(1.77778px 1.77778px 8.88889px rgba(20, 67, 144, 0.12));
    content: '';
  }
}

.filters {
  overflow: auto;
  font-size: 18px;
  white-space: nowrap;
  background-color: white;
  line-height: 1.16em;

  &__item {
    display: inline-block;
    padding: 24px 12px;
    color: $input-color;
    background: none;
    border: none;

    &_active {
      font-weight: 600;
      color: $primary-btn-bg;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__item:first-child {
    padding-left: 24px;
  }

  &__item:last-child {
    padding-right: 24px;
  }
}
</style>
