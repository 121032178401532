import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import { ref, Ref } from 'vue';
import { loggerServicePlugin } from '@/features/core/logger';
import { errorPlugin } from '@/features/core/errors';
import {
  Order,
  ordersServicePlugin,
  OrderLocalStatus,
  UseCancelPicking,
} from '@/features/orders';
import { appCrashServicePlugin } from '@/features/app-crash';
import { updateOrderTimestamp } from '@/utils/helpers/updateOrderTimestamp';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';

export function useCancelPicking(): UseCancelPicking {
  const router = useRouter();
  const cancellationInProcess: Ref<boolean> = ref(false);

  const cancelPicking = async (order: Order) => {
    try {
      cancellationInProcess.value = true;
      if (
        ![OrderLocalStatus.PickingInProgress, OrderLocalStatus.Paused].includes(
          order.localStatus,
        )
      ) {
        throw new Error(
          `Order cant be cancel because its localStatus is not "picking-in-progress" nor "paused`,
        );
      }

      order.localStatus = OrderLocalStatus.PickingCancelled;

      updateOrderTimestamp(order);

      await ordersServicePlugin.get().saveOrder(order);

      loggerServicePlugin
        .get()
        .info(`Order ${order.id} cancelled (no items found)`, {
          orderId: order.id,
        });

      notificationPlugin.get().show({
        text: $t('pages.review-order.cancel-pinking-toast.message', {
          orderId: order.orderReference,
        }),
        type: NotificationType.Info,
      });

      await appCrashServicePlugin.get().updateProcessedOrderData(order);
    } catch (error) {
      errorPlugin.get().handle(error);
    } finally {
      cancellationInProcess.value = false;
      await router.push({
        name: 'default',
      });
    }
  };

  return {
    cancelPicking,
    cancellationInProcess,
  };
}
