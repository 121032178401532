import { MultiPluginFactory } from '@/features/core/plugin';
import { configurationApiPlugin, configurationApiRegistryPlugin } from './api';
import { configurationEntityPlugin } from './entities';
import { configurationServicePlugin } from './services';

export * from './api';
export * from './plugins';
export * from './entities';
export * from './services';
export * from './types';

export const configurationPlugin = MultiPluginFactory.with({
  configurationEntityPlugin,
  configurationApiPlugin,
  configurationApiRegistryPlugin,
  configurationServicePlugin,
});
