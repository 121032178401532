export const unitToDisplay = (unit: string, amount: number): string | null => {
  if (!unit) {
    return null;
  }
  unit = unit.toLowerCase();
  if (unit === 'lb' && amount !== 1) {
    return 'lbs';
  }
  return unit;
};
