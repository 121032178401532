import { MultiPluginFactory } from '@/features/core/plugin';
import { surveyApiClientPlugin, surveyApiRegistryPlugin } from './api';
import { surveyServiceClientPlugin, surveyServicePlugin } from './services';
import { surveyAnswerEntityPlugin } from './entities';

export * from './api';
export * from './composables';
export * from './entities';
export * from './errors';
export * from './services';
export * from './types';

export const surveyPlugin = MultiPluginFactory.with({
  surveyApiClientPlugin,
  surveyApiRegistryPlugin,
  surveyAnswerEntityPlugin,
  surveyServicePlugin,
  surveyServiceClientPlugin,
});
