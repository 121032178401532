import { Ref } from 'vue';
import { asyncComputed } from '@vueuse/core';
import { configurationServicePlugin } from '@/features/configuration';

export interface OverpickingThresholds {
  /**
   * The configured percentage, if any
   */
  percentage: number | undefined;
  /**
   * Multiplier to get the minimum amount from the ordered quantity
   * @example If the `percentage` is 20, this will be `0.8`
   */
  minMultiplier: number;
  /**
   * Multiplier to get the maximum amount from the ordered quantity
   * @example If the `percentage` is 20, this will be `1.2`
   */
  maxMultiplier: number;
}

export const useOverpickingThresholds = (): Ref<
  OverpickingThresholds | undefined
> =>
  asyncComputed(async (): Promise<OverpickingThresholds> => {
    const percentage = await configurationServicePlugin
      .get()
      .getFeatureOption(
        'randomWeight',
        'overpickingThresholdPercent',
        'number',
      );
    // Transform the percentage into a value which can be used directly for
    // multiplication with the total to get the thresholds (e.g. 25 is changed
    // to 1.25, and with a 2 kg amount the max threshold is 2 * 1.25 = 2.5 kg)
    const thresholdFloat = percentage ? percentage / 100 : 0;
    return {
      percentage,
      minMultiplier: 1 - thresholdFloat,
      maxMultiplier: 1 + thresholdFloat,
    };
  });
