import { Order } from '@/features/orders/entities';
import { Product } from '@/features/products/entities';
import { StorageZone } from '@/features/storageZone/entities';
import { DataSync } from '@/features/data-sync/entities';
import { EntityType, Entity } from '@/features/core/entity-repository';
import { Sync } from '@/features/sync-scheduler';
import { AuthData } from '@/features/core/auth';
import { TourItem } from '@/features/tours/entities';
import { TourDropItem } from '@/features/tour-drops/entities';
import { SurveyAnswerItem } from '@/features/survey/entities';

export function getEntityByName(
  entityName: string,
): EntityType<Entity> | undefined {
  const entityMap: Record<string, EntityType<Entity>> = {
    [Order.from({}).type]: Order,
    [Product.from({}).type]: Product,
    [StorageZone.from({}).type]: StorageZone,
    [Sync.from({}).type]: Sync,
    [AuthData.from({}).type]: AuthData,
    [DataSync.from({}).type]: DataSync,
    [TourItem.from({}).type]: TourItem,
    [TourDropItem.from({}).type]: TourDropItem,
    [TourDropItem.from({}).type]: TourDropItem,
    [SurveyAnswerItem.from({}).type]: SurveyAnswerItem,
  };
  return entityMap[entityName];
}
