import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { EntityRepository } from './entity-repository';
import {
  onlineEntityRepositoryPlugin,
  onlineEntityRepositoryRegistryPlugin,
} from './online-entity-repository';
import { StorageEntityRepository } from './storage-entity-repository';
import { eventBusServicePlugin } from '@/features/core/event-bus';

export const entityRepositoryPlugin =
  ProviderPluginFactory.create<EntityRepository>({
    key: Symbol('EntityRepository'),
    defaultFactory: {
      create: () =>
        new StorageEntityRepository({
          storage: storagePlugin.get(),
          apiRepository: onlineEntityRepositoryPlugin.get(),
          eventBus: eventBusServicePlugin.get(),
        }),
    },
  });

export const entityRepositoryConfigPlugin = MultiPluginFactory.with({
  onlineEntityRepositoryRegistryPlugin,
  onlineEntityRepositoryPlugin,
  entityRepositoryPlugin,
});
