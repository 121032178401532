import { eventBusServicePlugin } from '@/features/core/event-bus';
import { ExecPluginFactory } from '@/features/core/plugin';
import {
  CheckinNotifyEvent,
  notifyAboutCheckin,
} from '@/features/orders/checkin';

export const orderCheckinNotifyPlugin = ExecPluginFactory.fn(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  eventBusServicePlugin.get().on(CheckinNotifyEvent, notifyAboutCheckin);
});
