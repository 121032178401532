/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { notificationPlugin } from '@/features/core/notifications';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL ?? ''}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    async updated() {
      console.log('New content is available; please refresh.');
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration?.waiting) {
        if (navigator.serviceWorker.controller) {
          notificationPlugin.get().showMessage();
        }
      }
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
