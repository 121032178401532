import { PipelinePlugin } from '@ads/plugin-pipeline';
import { loggerServicePlugin } from '@/features/core/logger';
import { OrderLocalStatus, useOnlineStatus } from '@/features/orders';
import { ConfigLogging, OrderEventNames, OrderPluginDto } from '../types';

export class LogStartOrderEventsPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  private configLogging: ConfigLogging = {
    [OrderLocalStatus.PickingReady]: {
      message: 'Picking start triggered for Order ',
    },
    [OrderLocalStatus.Paused]: {
      message: 'Picking paused continued for Order ',
    },
    [OrderLocalStatus.PickingInProgress]: {
      message: 'Picking in progress continued for Order ',
    },
    [OrderLocalStatus.HandoverReady]: {
      message: 'Handover started for Order ',
    },
    [OrderLocalStatus.HandoverInProgress]: {
      message: 'Handover started for Order ',
    },
  };
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    const { isOnline } = useOnlineStatus();
    const currentEvent =
      this.configLogging[dataTransferObject.order.localStatus];

    if (
      dataTransferObject.order.localStatus ===
        OrderLocalStatus.PickingInProgress &&
      dataTransferObject.order.events
        .map((event) => event.name)
        .includes(OrderEventNames.picking_started)
    ) {
      currentEvent.message = 'Picking for Order started';
    }

    loggerServicePlugin
      .get()
      .info(
        `${currentEvent.message} ${dataTransferObject.order.id}${
          isOnline.value ? '' : ' (offline)'
        }`,
        {
          orderId: dataTransferObject.order.id,
          orderReference: dataTransferObject.order.orderReference,
        },
      );
    return dataTransferObject;
  }
}
