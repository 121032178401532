import { EventList, OrderEventNames } from '@/features/orders/types';

export function setPickingEventsAsAlreadyPatched(
  events: EventList[],
): EventList[] {
  return events.map((event) => ({
    ...event,
    isPatchedToBackEnd:
      event.name === OrderEventNames.picking_completed ||
      event.name === OrderEventNames.picking_started ||
      event.name === OrderEventNames.picking_resumed ||
      event.name === OrderEventNames.staging_started ||
      event.name === OrderEventNames.staging_completed,
  }));
}
