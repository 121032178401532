import { UrlBuilderService } from '@/features/url';
import { OauthConfig } from '@/features/oauth/types';

const urlBuilderService = new UrlBuilderService();

export const configService: OauthConfig = {
  hostUri: urlBuilderService.getHostUrl(),
  providers: {
    empowerId: {
      clientId: process.env.VUE_APP_OAUTH_CLIENT_ID || 'CLIENT_ID',
      authUri: urlBuilderService.getAppOauthUrl() + '/oauth/v2/ui/authorize',
      tokenUri: urlBuilderService.getAppOauthUrl() + '/oauth/v2/token',
      logoutUri: urlBuilderService.getAppOauthUrl() + '/oauth/v2/ui/logout',
      responseType: 'code',
      grant_type: 'code',
      redirect_uri: urlBuilderService.getHostUrl(),
      scope: 'openid',
    },
  },
};
